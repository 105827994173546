import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { TechnicalInformationContent } from "./TechnicalInformationContent"
import type { TechnicalInformationFormData } from "./TechnicalInformationSchema"

import { Button } from "@/components/common/Button"

type AddTechnicalInformationProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: TechnicalInformationFormData) => void
}

const AddTechnicalInformationModal: FunctionComponent<AddTechnicalInformationProps> = ({ open, onClose, onSubmit }) => {
  const methods = useFormContext()

  const handleSubmit = useCallback(
    (value: TechnicalInformationFormData) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const isSaveButtonEnabled = methods.formState.isValid

  const handleResetClick = useCallback(() => {
    methods.reset()
    onClose("cancel")
  }, [onClose, methods])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        methods.reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>Technical Information</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <TechnicalInformationContent />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button
          onClick={methods.handleSubmit((data) => handleSubmit(data as TechnicalInformationFormData))}
          disabled={!isSaveButtonEnabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddTechnicalInformationModal
