import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { AddCircleOutlined, EditOutlined, PauseCircleOutlined } from "@mui-symbols-material/w300"

interface CompanyHeaderDropdownMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  isActive: boolean
  onEdit: () => void
  onDeactivate: () => void
  onActivate: () => void
}

const CompanyHeaderDropdownMenu: React.FC<CompanyHeaderDropdownMenuProps> = ({
  anchorEl,
  onClose,
  isActive,
  onEdit,
  onDeactivate,
  onActivate,
}) => {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary='Edit' />
      </MenuItem>
      {isActive ? (
        <MenuItem onClick={onDeactivate}>
          <ListItemIcon>
            <PauseCircleOutlined />
          </ListItemIcon>
          <ListItemText primary='Deactivate' />
        </MenuItem>
      ) : (
        <MenuItem onClick={onActivate}>
          <ListItemIcon>
            <AddCircleOutlined />
          </ListItemIcon>
          <ListItemText primary='Activate' />
        </MenuItem>
      )}
    </Menu>
  )
}

export default CompanyHeaderDropdownMenu
