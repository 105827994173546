import { Box, Typography } from "@mui/material"
import { CloseFilledOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"

import { Button } from "@/components/common/Button"

interface CancelTemplateProps {
  onClose: (open: boolean) => void
  confirmCancel: () => void
  title?: string
  message?: string
  goBackText?: string
  cancelText?: string
}

export const CancelTemplate: FC<CancelTemplateProps> = ({
  onClose,
  confirmCancel,
  title = "Oh no! Are you sure you want to cancel?",
  message = "By cancelling now your the information will not be saved. Click on “Go Back” if this action was a mistake.",
  goBackText = "Go Back",
  cancelText = "Yes, Cancel",
}) => (
  <>
    <Box pt={2} pr={2} className='flex'>
      <Button size='small' appearance='text' onClick={() => onClose(false)} className='w-full justify-end'>
        <CloseFilledOutlined className='text-blue-gray' />
      </Button>
    </Box>
    <Box p={5} pt={2} className='align-center w-full justify-center text-center'>
      <WarningOutlined className='mb-4 size-[48px] text-secondary-600' />
      <Typography variant='h5' color='primary' className='mt-6 font-normal'>
        {title}
      </Typography>
      <Typography variant='h6' className='mt-6 px-3 text-base leading-[150%] tracking-[-0.85px] text-gray-700'>
        {message}
      </Typography>
      <Box mt={5} className='flex justify-end'>
        <Button appearance='text' variant='secondary' className='mr-2' onClick={() => onClose(false)}>
          {goBackText}
        </Button>
        <Button variant='error' onClick={confirmCancel}>
          {cancelText}
        </Button>
      </Box>
    </Box>
  </>
)
