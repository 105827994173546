import { TableCell, TableRow } from "@mui/material"
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from "@mui-symbols-material/w300"
import type { Table } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { twMerge } from "tailwind-merge"

interface SortableTableHeadRowProps<T> {
  table: Table<T>
}

export const SortableTableHeadRow = <T,>({ table }: SortableTableHeadRowProps<T>): ReactElement[] =>
  table.getHeaderGroups().map((headerGroup) => (
    <TableRow key={headerGroup.id} className='flex w-full'>
      {headerGroup.headers.map((header) => {
        const tableCellClasses = twMerge([
          header.column.getSize() === 150 ? "grow" : "grow-0",
          "flex h-[64px] items-center border-0 px-4 text-gray-700 first:pr-0",
        ])

        return (
          <TableCell
            key={header.id}
            className={tableCellClasses}
            onClick={header.column.getToggleSortingHandler()}
            style={{ width: header.getSize() }} // @see: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
          >
            {header.isPlaceholder ? null : (
              <div
                className={
                  header.column.getCanSort() ? "flex cursor-pointer select-none items-center" : "flex items-center"
                }
              >
                {(header.column.getCanSort() &&
                  {
                    asc: <ArrowUpwardOutlined fontSize='small' className='mr-2' />,
                    desc: <ArrowDownwardOutlined fontSize='small' className='mr-2' />,
                    false: null,
                  }[header.column.getIsSorted() as string]) ||
                  (header.column.getCanSort() ? <span className='invisible mr-2 w-5' /> : null)}
                {flexRender(header.column.columnDef.header, header.getContext())}
              </div>
            )}
          </TableCell>
        )
      })}
    </TableRow>
  ))
