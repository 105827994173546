import type { SvgIconComponent } from "@mui-symbols-material/w300"
import type { ReactNode } from "react"
import { z } from "zod"

export const NotificationSchema = z.object({
  id: z
    .number()
    .int()
    .positive()
    .optional()
    .default(() => Date.now()), // Auto-generate a unique ID if not provided
  message: z.string().min(1).optional(), // Message must be a non-empty string
  type: z.enum(["success", "info", "warning", "error"]), // Enum for allowed notification types
  autoHideDuration: z.number().optional().default(3000), // Optional with a default of 5000ms
  read: z.boolean().default(false), // Default read status is false
  icon: z.custom<SvgIconComponent>().optional(), // Icon is optional and should match the SvgIconComponent type
  silent: z.boolean().optional().default(false), // Silent is optional with default false
  createdAt: z
    .date()
    .optional()
    .default(() => new Date()), // Default to current date/time
  content: z.custom<ReactNode>().optional(),
})
export const NotificationsArraySchema = z.array(NotificationSchema)

export type Notification = z.infer<typeof NotificationSchema>
