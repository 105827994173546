import { Box, Tooltip } from "@mui/material"
import type { Cell, Row } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"
import type { VirtualItem } from "@tanstack/react-virtual"
import React from "react"

import type { Product } from "@/graphql/codegen/graphql"

interface ProductRowProps {
  row: Row<Product>
  isExpanded: boolean
  virtualRow: VirtualItem
  getFlexibleColumnWidth: (cell: any) => string
  children?: React.ReactNode
}

const ROW_HEIGHT = 64

export const ProductRow: React.FC<ProductRowProps> = ({
  row,
  isExpanded,
  virtualRow,
  getFlexibleColumnWidth,
  children,
}) => {
  return (
    <Box
      className='absolute left-0 top-0 flex w-full flex-col'
      style={{
        transform: `translateY(${virtualRow.start}px)`,
      }}
    >
      <Box
        className={`grid items-center border-b border-gray-200 text-gray-700 ${isExpanded ? "bg-primary-100" : "bg-white"}`}
        style={{
          gridTemplateColumns: row
            .getVisibleCells()
            .map((cell) => getFlexibleColumnWidth({ column: cell.column }))
            .join(" "),
          height: ROW_HEIGHT,
        }}
      >
        {row.getVisibleCells().map((cell: Cell<Product, unknown>) =>
          cell.column.id === "activityStatusReason" ? (
            <Box key={cell.id} className='flex size-full items-center justify-center px-2'>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Box>
          ) : (
            <Box key={cell.id} className='flex size-full items-center overflow-hidden px-2'>
              <Tooltip title={cell.getValue() as string} enterDelay={1000}>
                <Box className='w-full truncate'>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
              </Tooltip>
            </Box>
          )
        )}
      </Box>
      {isExpanded && <Box className='box-border overflow-hidden bg-white p-2'>{children}</Box>}
    </Box>
  )
}
