import { z } from "zod"

/*
 * Regex Explanation:
 *
 * Breakdown:
 *
 * ^\/: The string must start with a /.
 *
 * (?:[a-z0-9_-]+\/)*: Zero or more path segments followed by a slash.
 *
 * [a-z0-9_-]+: The final path segment without a trailing slash.
 *
 * \/?$: Allows an optional trailing slash at the end and ensures the string ends after the last segment.
 * Examples of Valid Slugs:
 * ```
 * /
 * /about
 * /user/profile
 * /posts/2021-09-15/my-first-post/
 * /a/b/c/d
 * ```
 * Examples of Invalid Slugs:
 *
 * about (doesn't start with /)
 *
 * `/About` - contains uppercase letters if only lowercase are allowed
 *
 * `/user//profile` - double slashes
 *
 * `/user/pro file` - contains spaces
 *
 * `/user/profile?` - contains invalid characters
 */
export const validSlugSchema = z
  .string()
  .max(50, {
    message: "Slug must be at most 50 characters long.",
  })
  .refine(
    (slug) => {
      if (!slug.startsWith("/")) return false
      const parts = slug.split("/").filter(Boolean)
      return parts.every((part) => /^[a-z0-9_-]+$/.test(part))
    },
    {
      message: "Invalid slug format.",
    }
  )
