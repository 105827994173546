import { Box, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { useLocations } from "../../hooks/useLocations"

import { CompanyDepartment, type ContactCreateInput } from "@/graphql/codegen/graphql"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"

export const AddContactGeneralInformation: FC = () => {
  const { control } = useFormContext<ContactCreateInput>()
  const {
    companyLocations: { locations },
  } = useLocations()

  return (
    <form>
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        1. General information
      </Typography>
      <Box className='space-y-10'>
        <Controller
          name='firstName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
                First Name<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                error={!!error}
                placeholder='First Name'
                fullWidth
                variant='outlined'
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='lastName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
                Last Name<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                error={!!error}
                fullWidth
                placeholder='Last Name'
                variant='outlined'
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='companyDepartment'
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Company Department</label>
              <Select
                {...field}
                displayEmpty
                input={<OutlinedInput />}
                SelectDisplayProps={{
                  className:
                    "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                }}
                classes={{
                  icon: "text-gray-600",
                }}
                placeholder='Company Department'
                IconComponent={KeyboardArrowDownOutlined}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                variant='outlined'
                fullWidth
              >
                {Object.keys(CompanyDepartment).map((companyDepartment) => (
                  <MenuItem key={companyDepartment} value={companyDepartment}>
                    {companyDepartment}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />
        <Controller
          name='title'
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Title</label>
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                placeholder='Title'
                fullWidth
                variant='outlined'
              />
            </Box>
          )}
        />
        <Controller
          name='physicalLocationId'
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Office Location</label>
              <Select
                {...field}
                displayEmpty
                input={<OutlinedInput />}
                SelectDisplayProps={{
                  className:
                    "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                }}
                classes={{
                  icon: "text-gray-600",
                }}
                IconComponent={KeyboardArrowDownOutlined}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                variant='outlined'
                placeholder='Select office location'
                fullWidth
              >
                {locations.map((location) => (
                  <MenuItem key={location.locationId} value={location.locationId}>
                    {`${location.address1} - ${location.city} ${location.regionIsoCode}`}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />
      </Box>
    </form>
  )
}
