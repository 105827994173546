import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, AlertTitle, Box, Chip, CircularProgress, Fade, IconButton, Typography } from "@mui/material"
import { AddOutlined, EditOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useShallow } from "zustand/react/shallow"

import AddPaymentModal from "./AddPaymentModal"
import { PaymentsSchema } from "./PaymentsSchema"

import { Button } from "@/components/common/Button"
import { CompanyPaymentTermType } from "@/graphql/codegen/graphql"
import type {
  CompanyGetSuccess,
  CompanyPaymentTermCreateFailure,
  CompanyPaymentTermCreateInput,
  CompanyPaymentTermUpdateFailure,
  CompanyPaymentTermUpdateInput,
} from "@/graphql/codegen/graphql"
import { useCreateCompanyPaymentTerm } from "@/screens/Companies/hooks/useCreateCompanyPaymentTerm"
import { useGetCompanies } from "@/screens/Companies/hooks/useGetCompanies"
import { useGetCompanyPaymentTerm } from "@/screens/Companies/hooks/useGetCompanyPaymentTerm"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const PaymentsContainer = (): ReactElement => {
  const [open, setOpen] = useState(false)
  const { companyId: queryCompanyId } = useParams({ from: "/companies/$companyId/edit" })
  const notify = useNotificationsStore(useShallow(({ enqueueNotification }) => enqueueNotification))
  const { createCompanyPayment, updateCompanyPayment } = useCreateCompanyPaymentTerm()
  const {
    paymentTermDetailsFetching,
    companyPaymentListFetching,
    companyPaymentList = [],
    paymentTermDetails,
    reexecuteCompanyPaymentTerm,
  } = useGetCompanyPaymentTerm(queryCompanyId)
  const { companyDetails } = useGetCompanies(queryCompanyId)
  const { name } = (companyDetails?.company.get as CompanyGetSuccess)?.company || {}

  const companyPaymentTermId = companyPaymentList[0]?.companyPaymentTermId ?? null

  const methods = useForm({
    defaultValues: {
      paymentTermId: "",
    },
    resolver: zodResolver(PaymentsSchema),
    mode: "all",
  })

  const handleSubmit = useCallback(
    async (data: CompanyPaymentTermUpdateInput | CompanyPaymentTermCreateInput) => {
      const { error, data: result } =
        paymentTermDetails?.paymentTermId && companyPaymentTermId
          ? await updateCompanyPayment({
              input: { paymentTermId: data.paymentTermId, companyPaymentTermId },
            })
          : await createCompanyPayment({
              input: {
                companyId: queryCompanyId,
                paymentTermType: CompanyPaymentTermType.SellingTerm,
                paymentTermId: data.paymentTermId,
              },
            })

      const failure = paymentTermDetails?.paymentTermId
        ? (result?.companyPaymentTerm?.update as CompanyPaymentTermUpdateFailure)?.error
        : (result?.companyPaymentTerm?.create as CompanyPaymentTermCreateFailure)?.error
      const actionType = paymentTermDetails?.paymentTermId ? "update" : "add"

      if (error || failure) {
        notify({ message: `Failed to ${actionType} payment information.`, type: "error" })
      } else {
        const notificationMessage =
          actionType === "update"
            ? "Payment information successfully updated."
            : "Payment information successfully added."
        notify({ message: notificationMessage, type: "success" })
        reexecuteCompanyPaymentTerm()
      }
      methods.reset()
      setOpen(false)
    },
    [
      paymentTermDetails?.paymentTermId,
      companyPaymentTermId,
      updateCompanyPayment,
      createCompanyPayment,
      queryCompanyId,
      methods,
      notify,
      reexecuteCompanyPaymentTerm,
    ]
  )

  const handleEditClick = useCallback(() => {
    methods.reset({
      paymentTermId: paymentTermDetails?.paymentTermId,
    })
    setOpen(true)
  }, [methods, paymentTermDetails?.paymentTermId])

  return (
    <Box my={2} className='size-full'>
      {paymentTermDetailsFetching || companyPaymentListFetching ? (
        <Box className='flex size-full items-center justify-center'>
          <CircularProgress size={56} />
        </Box>
      ) : (
        <>
          <Fade in={!!paymentTermDetails?.paymentTermId} unmountOnExit timeout={500}>
            <Alert
              icon
              severity='info'
              variant='filled'
              classes={{
                message: "w-full py-5",
              }}
            >
              <Box className='flex justify-between'>
                <Box>
                  <Typography variant='subtitle1' className='gray-900 mb-2 text-lg' fontWeight='normal'>
                    Company Location
                  </Typography>
                  <Typography variant='body2' className='mt-1 text-blue-gray-600' fontWeight='normal'>
                    {name || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle1' className='gray-900 mb-2 text-lg' fontWeight='normal'>
                    Payment Terms
                  </Typography>
                  <Chip label={paymentTermDetails?.name} className='rounded bg-primary-200 font-[12px] text-gray-900' />
                </Box>
                <IconButton
                  color='primary'
                  aria-label='edit payment term'
                  className='h-10 items-start'
                  onClick={handleEditClick}
                >
                  <EditOutlined />
                </IconButton>
              </Box>
            </Alert>
          </Fade>
          <Fade in={!paymentTermDetails?.paymentTermId} unmountOnExit timeout={500}>
            <Alert
              icon={<WarningOutlined color='primary' />}
              severity='info'
              variant='filled'
              classes={{
                message: "w-full flex flex-row justify-between",
              }}
            >
              <Box>
                <AlertTitle className='font-normal leading-7'>Add company payment information</AlertTitle>
              </Box>
              <Box className='flex items-center'>
                <Button
                  color='inherit'
                  size='small'
                  appearance='outlined'
                  className='text-primary-500 self-start'
                  startIcon={<AddOutlined />}
                  onClick={() => setOpen(true)}
                >
                  Add Payment Information
                </Button>
              </Box>
            </Alert>
          </Fade>
        </>
      )}
      <FormProvider {...methods}>
        <AddPaymentModal
          open={open}
          isUpdate={!!paymentTermDetails?.paymentTermId}
          onClose={() => {
            methods.reset()
            setOpen(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </Box>
  )
}

export default PaymentsContainer
