import { Box, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import React, { useCallback } from "react"

import { AccountManagerChips } from "@/components/common/Filters/AccountManagerChip"
import { StatusChips } from "@/components/common/Filters/StatusChip"
import type { CompanyListFilter, CompanyStatus } from "@/graphql/codegen/graphql"
import { TypeChips } from "@/screens/Companies/components/filters/company/TypeChip"
import type { TreeNode } from "@/types/tree"

export const CompanySearchFilterChips: React.FC<{ companyTypes: TreeNode<string>[] }> = ({ companyTypes }) => {
  const navigate = useNavigate({ from: "/companies/" })
  const { statuses, accountManagers, types } = useSearch({ from: "/companies/", strict: true })

  const handleRemoveFilter = useCallback(
    (key: keyof CompanyListFilter, value: string | CompanyStatus) => {
      void navigate({
        search: (prev: CompanyListFilter) => {
          const newFilter = { ...prev }

          if (Array.isArray(newFilter[key])) {
            ;(newFilter[key] as (string | CompanyStatus)[]) = (newFilter[key] as (string | CompanyStatus)[]).filter(
              (item) => item !== value
            )
            if ((newFilter[key] as Array<string | CompanyStatus>).length === 0) {
              delete newFilter[key]
            }
          } else {
            delete newFilter[key]
          }

          return newFilter
        },
        replace: true,
      })
    },
    [navigate]
  )

  if (!statuses?.length && !accountManagers?.length && !types?.length) {
    return null
  }

  return (
    <Box data-testid='company-search-filter-chips' display='flex' flexWrap='wrap' alignItems='center' gap={2}>
      <Typography variant='body1'>Filters:</Typography>
      <StatusChips
        statuses={statuses}
        onRemove={(key, value) => handleRemoveFilter(key as keyof CompanyListFilter, value)}
      />
      <AccountManagerChips
        accountManagers={accountManagers || []}
        onRemove={(key, value) => handleRemoveFilter(key as keyof CompanyListFilter, value)}
      />
      <TypeChips
        companyTypes={companyTypes}
        selectedTypes={types || []}
        onRemove={(key, value) => handleRemoveFilter(key as keyof CompanyListFilter, value)}
      />
    </Box>
  )
}
