import { MoreHorizOutlined } from "@mui-symbols-material/w300"
import type { ReactNode } from "react"
import { isValidElement } from "react"
import { z } from "zod"

import { validSlugSchema } from "@/types/router/validators.schema.ts"

export const breadcrumbItemSchema = z.object({
  label: z.union([
    z.string(),
    z.custom<ReactNode>((value) => isValidElement(value) || typeof value === "string" || typeof value === "number", {
      message: "Invalid label: must be a string, number, or a valid ReactNode",
    }),
  ]),
  pathname: validSlugSchema,
})

const reactNodeOrString = z.union([
  z.string(),
  z.custom<ReactNode>((value) => isValidElement(value) || typeof value === "string" || typeof value === "number", {
    message: "Invalid ReactNode or string",
  }),
])

export const breadcrumbsPropsSchema = z
  .object({
    items: z.array(breadcrumbItemSchema),
    currentPath: z.string().optional(),
    separator: reactNodeOrString.optional().default("/"),
    maxItems: z.number().optional().default(2),
    itemsBeforeCollapse: z.number().optional().default(1),
    itemsAfterCollapse: z.number().optional().default(1),
    collapseIcon: reactNodeOrString
      .optional()
      .default(() => <MoreHorizOutlined className='!h-4 rounded-sm bg-gray-200 text-gray-700' />),
  })
  .refine((data) => data.items.length <= 1 || data.currentPath, {
    message: "currentPath must be specified if items length is greater than 1",
    path: ["currentPath"],
  })

export type BreadcrumbItemType = z.input<typeof breadcrumbItemSchema>

export type BreadcrumbsProps = z.input<typeof breadcrumbsPropsSchema>
