import type { TableBodyProps } from "@mui/material"
import { TableBody } from "@mui/material"

/**
 * Body section of the table that contains the table rows.
 * Should contain AerosTableRow components.
 *
 * @example
 * ```tsx
 * <AerosTableBody>
 *   <AerosTableRow>
 *     <AerosTableCell>Data 1</AerosTableCell>
 *     <AerosTableCell>Data 2</AerosTableCell>
 *   </AerosTableRow>
 * </AerosTableBody>
 * ```
 *
 * @param props - Extends MUI TableBodyProps
 */
export const AerosTableBody = (props: TableBodyProps): JSX.Element => {
  return <TableBody {...props}></TableBody>
}
