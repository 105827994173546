import { create } from "zustand"

import type { Company } from "@/graphql/codegen/graphql"

interface CompanyStore {
  company: Company | undefined
  setCompany: (company: Company) => void
}

export const useCompanyStore = create<CompanyStore>((set) => ({
  company: undefined,
  setCompany: (company: Company) => set({ company }),
}))
