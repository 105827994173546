import { Box, FormControl, InputAdornment, TextField } from "@mui/material"
import { FilterAltOutlined, SearchOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ChangeEvent, ReactNode } from "react"

import { getRefinedSearchParams } from "../utils"

import { Button } from "@/components/common/Button"

type ProductSearchBarProps = {
  onShowFilter: () => void
}

export const ProductSearchBar = ({ onShowFilter }: ProductSearchBarProps): ReactNode => {
  const currentSearch = useSearch({
    from: "/companies/$companyId/edit",
  })
  const navigate = useNavigate()
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const queryValue = e.target.value

    const updatedSearchParams = getRefinedSearchParams({
      ...currentSearch,
      query: queryValue,
    })

    void navigate({
      to: "/companies/$companyId/edit/products",
      search: updatedSearchParams.search,
    })
  }

  return (
    <Box className='flex w-full items-center space-x-4'>
      <div className='flex w-full max-w-lg items-center overflow-hidden rounded-lg border border-gray-400'>
        <FormControl className='w-full border-r border-gray-300'>
          <TextField
            data-testid='search-input'
            defaultValue={currentSearch?.query}
            variant='outlined'
            placeholder='Search by product name'
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlined className='text-gray-600' />
                  </InputAdornment>
                ),
                classes: {
                  notchedOutline: "border-0",
                  root: "flex items-center justify-center gap-2 rounded border-none px-4 font-normal text-black hover:outline-none",
                  input:
                    "border-none outline-none hover:border-none hover:outline-none focus:border-none focus:outline-none focus:ring-0 px-0 font-thin text-ellipsis",
                },
                onChange: handleInputChange,
              },
            }}
          />
        </FormControl>
      </div>
      <Button aria-label='filter' appearance='outlined' onClick={onShowFilter} startIcon={<FilterAltOutlined />}>
        Filter
      </Button>
    </Box>
  )
}
