import { useEffect, useRef } from "react"
import { useQuery } from "urql"

import type { AccountManager, Query } from "@/graphql/codegen/graphql"
import { AccountManagerListQueryDocument } from "@/graphql/codegen/graphql"
import { AccountManagerListSchema } from "@/schemas/accountManagersListSchema.ts"
import { useAccountManagerStore } from "@/stores/accountManagerStore.ts"

// Define the hook
export const useAccountManagers = (): {
  visibleManagers: AccountManager[]
  fetching: boolean
  loadMore: () => void
} => {
  // Get state and actions from the Zustand store
  const { visibleManagers, offset, limit, setVisibleManagers, addManagers, increaseOffset } = useAccountManagerStore()

  const [{ data: accountsManagerListData, fetching: accountsManagerListFetching }] = useQuery<
    Pick<Query, "accountManager">
  >({
    query: AccountManagerListQueryDocument,
    variables: { input: { offset, limit } }, // Use offset and limit for pagination
  })

  const previousDataRef = useRef<AccountManager[]>([])

  useEffect(() => {
    if (accountsManagerListData?.accountManager?.list?.__typename === "AccountManagerListSuccess") {
      const list: unknown = accountsManagerListData.accountManager.list.accountManagers

      // Validate the data with Zod
      try {
        const validatedList = AccountManagerListSchema.parse(list)

        if (offset === 0) {
          // On initial load or when offset is reset, replace the list
          setVisibleManagers(validatedList)
        } else {
          // On subsequent loads, append to the list
          addManagers(validatedList)
        }

        // Save the current list to detect changes
        previousDataRef.current = validatedList
      } catch (error) {
        console.error("Invalid data format", error)
      }
    }
  }, [accountsManagerListData, offset, setVisibleManagers, addManagers])

  const loadMoreManagers = () => {
    // Increase the offset to fetch the next batch of data
    increaseOffset()
  }

  return {
    visibleManagers,
    fetching: accountsManagerListFetching,
    loadMore: loadMoreManagers,
  }
}
