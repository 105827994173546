import { List } from "@mui/material"
import { useRouter } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useMemo } from "react"

import SideNavItem from "@/components/SideNav/SideNavItem.tsx"
import { addIcons } from "@/utils/breadcrumbs/addIconToRoute.ts"
import { addLabels } from "@/utils/breadcrumbs/addLabelToRoute.ts"
import { getRoutes, getTopLevelRoutes } from "@/utils/breadcrumbs/availableRoutes.ts"
import { iconMapping, labelMapping } from "@/utils/constant/navigation.tsx"
import { sortRoutesByLabelMapping } from "@/utils/utils"

const SideNav = (): ReactElement => {
  const router = useRouter()

  // Get all routes
  const routes = useMemo(
    () =>
      getRoutes({
        routeTree: router.routeTree,
        options: {
          excludePatterns: ["**/auth/**"],
        },
      }),
    [router.routeTree]
  )

  const topLevelRoutes = useMemo(() => getTopLevelRoutes(routes), [routes])
  const routesWithLabels = useMemo(() => addLabels(topLevelRoutes, labelMapping), [topLevelRoutes])
  const routesWithIcons = useMemo(() => addIcons(routesWithLabels, iconMapping), [routesWithLabels])
  const routesWithLabelsAndIcons = useMemo(
    () => sortRoutesByLabelMapping(routesWithIcons, labelMapping),
    [routesWithIcons]
  )

  const visibleRoutes = routesWithLabelsAndIcons.filter((r) => Boolean(r.icon))

  return (
    <List className='space-y-2'>
      {visibleRoutes.map((route) => (
        <SideNavItem key={route.pathname} item={route} />
      ))}
    </List>
  )
}

export default SideNav
