import { Box } from "@mui/material"
import Alert from "@mui/material/Alert"
import Badge from "@mui/material/Badge"
import type { SnackbarCloseReason, SnackbarOrigin } from "@mui/material/Snackbar"
import Snackbar from "@mui/material/Snackbar"
import type { FC } from "react"

import type { Notification } from "@/schemas/notification.ts"

type NotificationTemplateProps = Notification & {
  open: boolean
  unreadCount: number
  handleSnackbarClose: (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => void
  handleExited: () => void
  anchorOrigin: SnackbarOrigin
}

export const NotificationTemplate: FC<NotificationTemplateProps> = ({
  id,
  open,
  handleSnackbarClose,
  handleExited,
  type,
  silent,
  message,
  icon: MuiIcon,
  unreadCount,
  anchorOrigin,
  autoHideDuration,
  content,
}) => {
  if (silent) return null

  return (
    <Snackbar
      key={id}
      open={open}
      onClose={handleSnackbarClose}
      TransitionProps={{ onExited: handleExited }}
      anchorOrigin={anchorOrigin}
      role='alert'
      autoHideDuration={autoHideDuration}
      aria-description={`Global Notification. ${message}`}
      className='max-w-3/4 w-1/4 text-wrap'
    >
      <Box className='relative w-full text-wrap'>
        {unreadCount > 0 && <Badge badgeContent={unreadCount} color='error' className='absolute right-1 top-1' />}
        <Alert severity={type} onClose={handleSnackbarClose} icon={MuiIcon && <MuiIcon />} sx={{ width: "100%" }}>
          {content || message}
        </Alert>
      </Box>
    </Snackbar>
  )
}
