import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Paper, Typography } from "@mui/material"
import type { AnyRoute, LazyRoute } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"
import { type ReactElement, useCallback } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { formatPaymentTerms } from "@/constants/createPaymentTerms"
import type { PaymentTermCreateFailure, PaymentTermCreateInput } from "@/graphql/codegen/graphql"
import { MaturityDateTypeGql, PayPatternGql, PaymentRuleEventGql } from "@/graphql/codegen/graphql"
import { AddPaymentTermsSchema } from "@/screens/Finance/components/AddPaymentTermsSchema"
import FinanceHeader from "@/screens/Finance/components/FinanceHeader"
import PaymentTermSection from "@/screens/Finance/components/PaymentTermSection"
import { useCreatePaymentTerms } from "@/screens/Finance/hooks/useCreatePaymentTerms"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const Component = (): ReactElement => {
  const { enqueueNotification } = useNotificationsStore()
  const { createPaymentTerm } = useCreatePaymentTerms()
  const methods = useForm({
    defaultValues: {
      name: "",
      options: [
        {
          parts: [
            {
              paymentRule: {
                daysAfter: "",
                event: PaymentRuleEventGql.PlacingOrder,
                percentage: "",
              },
              includeSalesDiscount: false,
              discount: {
                percentage: "",
                applicableIfWithinDays: "",
              },
            },
          ],
        },
      ],
      payRunSchedule: {
        isEnabled: false,
        payPattern: PayPatternGql.Weekly,
        startDate: "",
        payDay: "",
        invoiceMaturity: {
          intervalBetweenPayRuns: MaturityDateTypeGql.PayInterval,
          offsetInDays: "",
        },
      },
    },
    resolver: zodResolver(AddPaymentTermsSchema),
    mode: "all",
  })
  const { setError } = methods

  const onSubmit = useCallback(
    async (data: PaymentTermCreateInput) => {
      const formattedData = formatPaymentTerms(data)
      const { error, data: result } = await createPaymentTerm({
        input: formattedData,
      })
      if (error || (result?.paymentTerm?.create as PaymentTermCreateFailure)?.error) {
        setError("root", {
          // Keep this as "name"
          type: "server",
          message: error?.message || (result?.paymentTerm?.create as PaymentTermCreateFailure)?.error?.message,
        })

        enqueueNotification({
          type: "error",
          content: <p>Payment term creation failed</p>,
        })
      } else {
        enqueueNotification({
          type: "success",
          content: <p>Payment term successfully created</p>,
        })
      }
      methods.reset()
    },
    [createPaymentTerm, enqueueNotification, methods, setError]
  )

  return (
    <Box className='size-full px-6 pb-6'>
      <div className='flex h-full flex-col gap-4'>
        <Paper elevation={0} className='flex min-h-0 grow flex-col space-y-8 overflow-hidden bg-white p-6'>
          <FormProvider {...methods}>
            <Box className='flex items-center justify-between gap-4'>
              <Typography variant='h5' color='primary' fontWeight='normal'>
                Create Payment Terms
              </Typography>
              <Button
                appearance='contained'
                onClick={methods.handleSubmit(onSubmit)}
                disabled={!methods.formState.isValid}
              >
                Save And Exit
              </Button>
            </Box>
            <Box className='space-y-2.5 overflow-y-auto'>
              <FinanceHeader />
              <PaymentTermSection />
            </Box>
          </FormProvider>
        </Paper>
      </div>
    </Box>
  )
}

export const Route: LazyRoute<AnyRoute> = createFileRoute("/finance")({
  component: Component,
})

export default Component
