import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, AlertTitle, Box, Typography } from "@mui/material"
import { PersonAddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import ContactCard from "./ContactCard"
import { ContactSubscriptionDialog } from "./ContactSubscriptionDialog"

import { Button } from "@/components/common/Button"
import type { Contact, ContactCreateInput, ContactLocationCreateInput } from "@/graphql/codegen/graphql"
import { CompanyDepartment, ContactChannel, ContactRole, ContactType } from "@/graphql/codegen/graphql"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll"
import AddContactDialog from "@/screens/Companies/create/components/AddContactDialog"
import { ContactModalSchema } from "@/screens/Companies/create/components/AddContactSchema"
import AddContactLocationRoles from "@/screens/Companies/edit/components/contacts/AddContactLocationRoles"
import { useCreateNewContactModal } from "@/screens/Companies/hooks/useCreateNewContact"
import { useGetContacts } from "@/screens/Companies/hooks/useGetContacts"
import AddContactComment from "@/screens/Contacts/edit/components/AddContactComment"
import { useGetContactMethods } from "@/screens/Contacts/edit/hooks/useGetContactMethods"

const ContactContainer = (): ReactElement => {
  const [open, setOpen] = useState(false)
  const [showContactCommentDialog, setShowContactCommentDialog] = useState(false)
  const [showContactLocationRoles, setShowContactLocationRoles] = useState(false)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const navigate = useNavigate()
  const { companyId: queryCompanyId } = useParams({ from: "/companies/$companyId/edit" })
  const { contactList, contactListFetching, triggerQuery, loadMore } = useGetContacts(queryCompanyId)
  const { containerRef } = useInfiniteScroll(loadMore, {
    isLoading: contactListFetching,
    hasMore: contactList.length % 20 === 0, // Assuming 20 is the page size
    threshold: 200,
  })
  const { batchCreateContactMethods } = useGetContactMethods()
  const { createContact, createContactLocation } = useCreateNewContactModal()
  const [showContactSubscriptions, setShowContactSubscriptions] = useState(false)

  const methods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      companyDepartment: "",
      title: "",
      physicalLocationId: "",
      phoneNumber: [{ number: "", type: "Text", isPreferred: false }],
      locationRoles: [{ locationId: "", role: "" }],
      email: "",
    },
    resolver: zodResolver(ContactModalSchema),
    mode: "all",
  })

  const handleAddContact = useCallback(() => {
    methods.reset()
    setOpen(true)
  }, [methods])

  const handleSubmit = useCallback(
    async (data) => {
      const formattedContactDetails: ContactCreateInput = {
        companyId: queryCompanyId,
        firstName: data.firstName,
        lastName: data.lastName,
        companyDepartment: CompanyDepartment[data.companyDepartment as keyof typeof CompanyDepartment],
        title: data.title,
        physicalLocationId: data.physicalLocationId,
      }
      const {
        data: { contact },
      } = await createContact({ input: formattedContactDetails })
      const { contactId } = contact?.create?.createdContact || {}

      const formattedContactMethods = [
        ...data.phoneNumber
          .sort((a) => (a.isPreferred ? -1 : 1))
          .map(({ number, type }) => ({
            contactId,
            contactType: ContactType[type as keyof typeof ContactType],
            value: number,
            contactChannel: ContactChannel.Phone,
          })),
        ...(data.email
          ? [
              {
                contactId,
                value: data.email,
                contactType: ContactType.Work,
                contactChannel: ContactChannel.Email,
              },
            ]
          : []),
      ]

      await batchCreateContactMethods({
        input: {
          contactMethodCreateInputs: formattedContactMethods,
        },
      })

      data.locationRoles.forEach(async ({ locationId, role }) => {
        const formattedContactLocation: ContactLocationCreateInput = {
          contactId,
          locationId: locationId,
          contactRole: ContactRole[role as keyof typeof ContactRole],
        }

        await createContactLocation({ input: formattedContactLocation })
      })
      triggerQuery()
      setOpen(false)
    },
    [createContact, createContactLocation, triggerQuery]
  )

  const handleContactCardClick = useCallback(
    (contactId: string) => {
      void navigate({ to: `/contact/${contactId}` })
    },
    [navigate]
  )

  return (
    <>
      {contactListFetching ? (
        <Typography variant='h5'>Loading...</Typography>
      ) : contactList.length === 0 ? (
        <Box my={2}>
          <Alert
            icon={<WarningOutlined color='primary' />}
            severity='info'
            variant='filled'
            classes={{
              message: "w-full flex flex-row justify-between",
            }}
          >
            <Box>
              <AlertTitle className='font-normal leading-7'>
                Add contacts and define their role at locations created
              </AlertTitle>
              <Box className='w-full font-thin'>One contact per location is required</Box>
            </Box>
            <Box className='flex items-center'>
              <Button size='small' appearance='outlined' onClick={handleAddContact}>
                <PersonAddOutlined />
                Add Contact
              </Button>
            </Box>
          </Alert>
        </Box>
      ) : (
        <Box className='grid w-full grid-cols-[repeat(auto-fill,_minmax(321px,_1fr))] gap-6 px-4' ref={containerRef}>
          {contactList.map((contact: Contact) => (
            <ContactCard
              key={contact.contactId}
              contact={contact}
              onCardClick={() => handleContactCardClick(contact.contactId)}
              onCommentClick={() => {
                setShowContactCommentDialog(true)
                setSelectedContact(contact)
              }}
              onSubscriptionClick={() => {
                setSelectedContact(contact)
                setShowContactSubscriptions(true)
              }}
              onLocationClick={() => {
                setSelectedContact(contact)
                setShowContactLocationRoles(true)
              }}
            />
          ))}
          <ContactCard isEmpty onCardClick={handleAddContact} />
        </Box>
      )}
      <FormProvider {...methods}>
        <AddContactDialog
          open={open}
          onClose={() => {
            methods.reset()
            setOpen(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
      <AddContactComment
        open={showContactCommentDialog}
        name={`${selectedContact?.firstName} ${selectedContact?.lastName}`}
        companyName={selectedContact?.company?.name}
        contactId={selectedContact?.contactId}
        onClose={() => {
          setShowContactCommentDialog(false)
          setSelectedContact(null)
        }}
        onSubmit={() => {
          setShowContactCommentDialog(false)
          setSelectedContact(null)
        }}
      />
      <ContactSubscriptionDialog
        open={showContactSubscriptions}
        onClose={() => setShowContactSubscriptions(false)}
        contactId={selectedContact?.contactId ?? ""}
        name={`${selectedContact?.firstName} ${selectedContact?.lastName}`}
        companyName={selectedContact?.company?.name}
        onSubmit={() => {
          setSelectedContact(null)
          setShowContactSubscriptions(false)
        }}
      />
      <AddContactLocationRoles
        open={showContactLocationRoles}
        contactId={selectedContact?.contactId ?? ""}
        name={`${selectedContact?.firstName} ${selectedContact?.lastName}`}
        companyName={selectedContact?.company?.name}
        companyId={selectedContact?.companyId}
        onClose={() => setShowContactLocationRoles(false)}
        onSubmit={() => {
          setSelectedContact(null)
          setShowContactLocationRoles(false)
        }}
      />
    </>
  )
}

export default ContactContainer
