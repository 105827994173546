import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import {
  AddCircleOutlined,
  EditOutlined,
  NoteAddOutlined,
  NotesOutlined,
  PauseCircleOutlined,
  PersonAddOutlined,
} from "@mui-symbols-material/w300"

import { LocationMenuDialogs } from "./types/dialogs"

interface TableRowDropdownMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  hasNote: boolean
  hasAddress: boolean
  hasContact: boolean
  isActive: boolean
  openDialog: (dialog: LocationMenuDialogs) => void
  open: boolean
}

const TableRowDropdownMenu: React.FC<TableRowDropdownMenuProps> = ({
  anchorEl,
  onClose,
  isActive,
  hasNote,
  hasAddress,
  hasContact,
  openDialog,
  open,
}) => {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      {/* Address group */}
      <MenuItem onClick={() => openDialog(LocationMenuDialogs.EDIT_ADDRESS)} hidden={!hasAddress}>
        <ListItemIcon>
          <EditOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Edit Address' />
      </MenuItem>
      <MenuItem onClick={() => openDialog(LocationMenuDialogs.ADD_ADDRESS)} hidden={hasAddress}>
        <ListItemIcon>
          <EditOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Add Address' />
      </MenuItem>

      {/* Contact group */}
      <MenuItem onClick={() => openDialog(LocationMenuDialogs.EDIT_CONTACT)} hidden={!hasContact}>
        <ListItemIcon>
          <PersonAddOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Edit Contact' />
      </MenuItem>
      <MenuItem onClick={() => openDialog(LocationMenuDialogs.ADD_CONTACT)} hidden={hasContact}>
        <ListItemIcon>
          <PersonAddOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Add Contact' />
      </MenuItem>

      {/* Note group */}
      <MenuItem onClick={() => openDialog(LocationMenuDialogs.EDIT_NOTES)} hidden={!hasNote}>
        <ListItemIcon>
          <NotesOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Edit Note' />
      </MenuItem>
      <MenuItem onClick={() => openDialog(LocationMenuDialogs.ADD_NOTES)} hidden={hasNote}>
        <ListItemIcon>
          <NoteAddOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Add Note' />
      </MenuItem>

      {/* Activation/Deactivation group */}
      <MenuItem hidden={!isActive}>
        <ListItemIcon>
          <PauseCircleOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Deactivate' />
      </MenuItem>
      <MenuItem hidden={isActive}>
        <ListItemIcon>
          <AddCircleOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Activate' />
      </MenuItem>
    </Menu>
  )
}

export default TableRowDropdownMenu
