import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { FileUploadSection } from "components/upload/components/FileUploadSection"
import { type ReactElement, useCallback } from "react"

import { type ProductFormPicturesEditProps, UPLOAD_TYPES } from "./types"

import { Button } from "@/components/common/Button"

const IMAGE_CONFIG = {
  accept: ".jpg,.png,.gif,.jpeg",
  maxSize: 10 * 1024 * 1024, // 10MB
  emptyStateText: "Click to upload or drag image",
  dropStateText: "Drop image here",
}

export const ProductFormPicturesEdit = ({
  open,
  onClose,
  onSubmit,
  signedUrls = [],
  previewFiles,
  onFileSelect,
  uploading,
}: ProductFormPicturesEditProps): ReactElement => {
  const handleSubmit = useCallback(async () => {
    const formData = {
      productFormInfo: "",
      uomId: "",
      signedUrls: signedUrls,
    }
    await onSubmit(formData)
  }, [onSubmit, signedUrls])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogTitle>Edit Product Pictures</DialogTitle>
      <DialogContent>
        <Box className='flex min-h-[150px] justify-between gap-6'>
          {UPLOAD_TYPES.map(({ type, label }) => (
            <FileUploadSection
              key={type}
              type={type}
              label={label}
              fileUrl={
                previewFiles[type]?.previewUrl || signedUrls.find((url) => url.file?.fieldGroup === type)?.url || null
              }
              onFileSelect={onFileSelect}
              uploading={uploading}
              {...IMAGE_CONFIG}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions className='justify-between px-6 pb-4'>
        <Button onClick={onClose} appearance='outlined'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} appearance='contained'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
