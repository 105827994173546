import React, { useMemo } from "react"

import { FilterChip } from "./FilterChip"

import type { ContactSubscription } from "@/graphql/codegen/graphql"

interface SubscriptionChipsProps {
  contactSubscriptions: string[] | undefined
  subscriptions: ContactSubscription[]
  onRemove: (key: keyof any, value: string) => void
}

export const SubscriptionChips: React.FC<SubscriptionChipsProps> = ({
  contactSubscriptions,
  subscriptions,
  onRemove,
}) => {
  const chips = useMemo(() => {
    if (!contactSubscriptions) return null
    return subscriptions
      .filter(({ contactSubscriptionId }) => contactSubscriptions.includes(contactSubscriptionId))
      .map((sub) => (
        <FilterChip
          key={sub.contactSubscriptionId}
          label={`Subscription: ${sub.name}`}
          onDelete={() => onRemove("contactSubscriptions", sub.contactSubscriptionId)}
        />
      ))
  }, [contactSubscriptions, subscriptions, onRemove])

  return <>{chips}</>
}
