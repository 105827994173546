import { Box, Chip, IconButton, Typography } from "@mui/material"
import { ChevronRightOutlined, EditOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

interface LabelInformationContentProps {
  labelInfo: {
    isPrivateLabel: boolean | null
    isPrintedBySupplier: boolean | null
    isAppliedBySupplier: boolean | null
  }
  hasFiles: boolean
  onEdit: () => void
  onViewLabels: () => void
}

export const LabelInformationContent = ({
  labelInfo,
  hasFiles,
  onEdit,
  onViewLabels,
}: LabelInformationContentProps): ReactElement => {
  const getLabelType = (isPrivateLabel: boolean) => (isPrivateLabel ? "Private" : "Supplier's")

  const renderPrintingStatus = (
    isPrintedBySupplier: boolean | null,
    isPrivateLabel: boolean,
    isAppliedBySupplier: boolean | null
  ) => {
    if (!isPrivateLabel) return "-"
    if (isPrintedBySupplier === null) return "-"
    if (isAppliedBySupplier) return "-"

    const printedLabel = isPrintedBySupplier ? "Printed by Supplier" : "Printed by Akrochem"
    const appliedLabel = isAppliedBySupplier ? "Applied by Supplier" : "Applied by Akrochem"

    return (
      <Chip
        label={isPrintedBySupplier ? printedLabel : appliedLabel}
        color={isPrintedBySupplier ? "default" : "primary"}
        className='rounded-none'
        variant='outlined'
        size='small'
      />
    )
  }

  const renderApplicationStatus = (
    isAppliedBySupplier: boolean | null,
    isPrivateLabel: boolean,
    isPrintedBySupplier: boolean | null
  ) => {
    if (!isPrivateLabel) return "-"
    if (isPrintedBySupplier === null) return "-"
    if (isAppliedBySupplier) return "-"

    const appliedLabel = isAppliedBySupplier ? "Applied by Supplier" : "Applied by Akrochem"
    const printedLabel = isPrintedBySupplier ? "Printed by Supplier" : "Printed by Akrochem"

    return (
      <Chip
        label={isAppliedBySupplier ? appliedLabel : printedLabel}
        color={isAppliedBySupplier ? "default" : "primary"}
        variant='outlined'
        size='small'
      />
    )
  }

  return (
    <Box
      className='grid grid-cols-5 divide-y divide-gray-200 border border-gray-200'
      data-testid='label-information-grid'
    >
      {/* Headers */}
      <Box className='col-span-1 flex items-center bg-gray-300 p-4' data-testid='label-type-header'>
        <Typography variant='subtitle1' className='font-medium text-gray-700'>
          Labels Type
        </Typography>
      </Box>
      <Box className='col-span-1 flex items-center bg-gray-300 p-4' data-testid='printing-header'>
        <Typography variant='subtitle1' className='font-medium text-gray-700'>
          Printing
        </Typography>
      </Box>
      <Box className='col-span-1 flex items-center bg-gray-300 p-4' data-testid='application-header'>
        <Typography variant='subtitle1' className='font-medium text-gray-700'>
          Application
        </Typography>
      </Box>
      <Box className='col-span-2 flex items-center bg-gray-300 p-4' data-testid='files-header'>
        <Typography variant='subtitle1' className='font-medium text-gray-700'>
          Files
        </Typography>
      </Box>

      {/* Values */}
      <Box className='flex items-center p-4' data-testid='label-type-value'>
        <Typography variant='body2'>
          {labelInfo.isPrivateLabel !== null ? getLabelType(labelInfo.isPrivateLabel) : "-"}
        </Typography>
      </Box>
      <Box className='flex items-center p-4' data-testid='printing-value'>
        {renderPrintingStatus(
          labelInfo.isPrintedBySupplier,
          Boolean(labelInfo.isPrivateLabel),
          labelInfo?.isAppliedBySupplier
        )}
      </Box>
      <Box className='flex items-center p-4' data-testid='application-value'>
        {renderApplicationStatus(
          labelInfo.isAppliedBySupplier,
          Boolean(labelInfo.isPrivateLabel),
          labelInfo?.isPrintedBySupplier
        )}
      </Box>
      <Box className='flex items-center p-4' data-testid='files-value'>
        {hasFiles ? (
          <Chip
            label={`View ${labelInfo.isPrivateLabel ? "Labels" : "Label"}`}
            variant='filled'
            size='small'
            className='rounded-none bg-primary-200 hover:bg-primary-300'
            onClick={onViewLabels}
            onDelete={onViewLabels}
            deleteIcon={<ChevronRightOutlined className='text-black' />}
            clickable
          />
        ) : (
          <Typography variant='body2'>-</Typography>
        )}
      </Box>
      <Box
        className='flex items-center justify-end p-4'
        sx={{ borderLeft: "none" }}
        data-testid='edit-button-container'
      >
        <IconButton onClick={onEdit} color='primary' data-testid='edit-button' aria-label='Edit label information'>
          <EditOutlined />
        </IconButton>
      </Box>
    </Box>
  )
}
