import { FreightTerms, ShipStatus } from "@/graphql/codegen/graphql"

export const FREIGHT_TERMS = Object.entries(FreightTerms).map(([key, value]) => ({
  label: key,
  value,
}))

export const SHIPMENT_STATUS = Object.entries(ShipStatus).map(([key, value]) => ({
  label: key,
  value,
}))

export const FREIGHT_TERMS_OPTIONS = [
  { label: "Collect", value: FreightTerms.Collect },
  { label: "Prepaid", value: FreightTerms.Prepaid },
  { label: "Prepaid and Add", value: FreightTerms.PrepaidAndAdd },
]

export const SHIPMENT_STATUS_OPTIONS = [
  { label: "Pending", value: ShipStatus.Pending },
  { label: "Shipped", value: ShipStatus.InTransit },
  { label: "Partially Delivered", value: ShipStatus.PartiallyDelivered },
  { label: "Delivered", value: ShipStatus.Delivered },
]
