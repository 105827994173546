// StatusChips.tsx
import { capitalize } from "@mui/material"
import React, { useMemo } from "react"

import type { CompanyStatus } from "@/graphql/codegen/graphql"
import { FilterChip } from "@/screens/Companies/components/filters/FilterChip.tsx"

interface StatusChipsProps {
  statuses: CompanyStatus[] | undefined
  onRemove: (key: keyof any, value: CompanyStatus) => void
}

export const StatusChips: React.FC<StatusChipsProps> = ({ statuses, onRemove }) => {
  const chips = useMemo(() => {
    return (
      statuses?.map((status, idx) => (
        <FilterChip
          key={`${status}-${idx}`}
          label={`Status: ${capitalize(status.toLowerCase())}`}
          onDelete={() => onRemove("statuses", status)}
        />
      )) || null
    )
  }, [statuses, onRemove])

  return <>{chips}</>
}
