import { Box, Typography } from "@mui/material"
import { twMerge } from "tailwind-merge"

interface FieldGroupSlotProps {
  legend?: {
    className?: string
  }
}

interface FieldGroupProps {
  children: React.ReactNode
  legend?: string
  className?: string
  slotProps?: FieldGroupSlotProps
}

/**
 * Groups form fields together and optionally displays a legend to describe the group
 *
 * @param props The component's props
 * @param props.children The children to render (the form fields)
 * @param props.legend The legend to display for the group. Optional
 * @param props.className The class name to apply to the group. Optional
 * @param props.slotProps The slot props to apply to the group. Optional
 *
 * @example
 * ```tsx
 * <FieldGroup legend="My Field Group">
 *   <FieldRow>
 *     <FormField
 *       name="my-field-1"
 *       label="My First Field"
 *       render={({ field, fieldState: { error } }) => (
 *         <TextField
 *           {...field}
 *           placeholder="Enter some text"
 *           error={!!error}
 *           fullWidth
 *           variant="outlined"
 *         />
 *       )}
 *     />
 *     <FormField
 *       name="my-field-2"
 *       label="My Second Field"
 *       render={({ field, fieldState: { error } }) => (
 *         <TextField
 *           {...field}
 *           placeholder="Enter some text"
 *           error={!!error}
 *           fullWidth
 *           variant="outlined"
 *         />
 *       )}
 *     />
 *   </FieldRow>
 * </FieldGroup>
 * ```
 */
const FieldGroup = ({ children, legend, className, slotProps, ...rest }: FieldGroupProps): React.ReactElement => {
  const { legend: legendSlotProps } = slotProps || {}
  const { className: legendClassName, ...legendPropsRest } = legendSlotProps || {}

  return (
    <Box className={twMerge("flex flex-col gap-6", className)} {...rest}>
      {!!legend && (
        <Typography
          component='legend'
          variant='body1'
          fontWeight={700}
          className={twMerge("leading-6 tracking-[0.15px] text-gray-700", legendClassName)}
          {...legendPropsRest}
        >
          {legend}
        </Typography>
      )}
      {children}
    </Box>
  )
}

export default FieldGroup
export { FieldGroup }
export type { FieldGroupProps, FieldGroupSlotProps }
