import { Box, Button, Divider, MenuItem, Select, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AddOutlined } from "@mui-symbols-material/w300"
import dayjs from "dayjs"
import { type FC, useCallback } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"

import { useGetUnitOfMeasurementList } from "../../hooks/useGetUnitOfMeasurementList"

import { FieldGroup, FieldRow, Form, FormField } from "@/components/Forms"
import type { UnitOfMeasurement } from "@/graphql/codegen/graphql"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"
const selectSlotProps = { input: { className: InputFieldClasses } }

export const AddProductPriceBreakdown: FC = () => {
  const {
    unitOfMeasurementList,
    unitOfMeasurementListFetching,
    loadMore: loadMoreUnitOfMeasurement,
  } = useGetUnitOfMeasurementList()

  const { containerRef } = useInfiniteScroll(loadMoreUnitOfMeasurement, {
    isLoading: unitOfMeasurementListFetching,
    hasMore: unitOfMeasurementList.length % 20 === 0,
    threshold: 200,
  })

  const { control, setValue, getValues } = useFormContext()
  const { fields, append } = useFieldArray({
    control,
    name: "pricingTiersToCreate",
  })

  const handlePricingUomChange = useCallback(
    (selectedValue: string) => {
      const selectedUoM: UnitOfMeasurement = unitOfMeasurementList.find(({ id }) => id === selectedValue)
      fields.map((_, index) => {
        setValue(`pricingTiersToCreate.${index}.unitOfMeasurement.id`, selectedUoM.id)
        setValue(`pricingTiersToCreate.${index}.unitOfMeasurement.name`, selectedUoM.name)
      })
    },
    [fields, setValue, unitOfMeasurementList]
  )

  const addPricingTierFields = () => {
    const selectedUoM = getValues("pricingTiersToCreate.0.unitOfMeasurement")
    append({ unitOfMeasurement: selectedUoM, numberOfUnits: "", pricePerUnit: "" })
  }

  return (
    <Form className='gap-6'>
      {fields.map((field, index) => (
        <Box key={field.id}>
          <Typography variant='body2' fontWeight={700} className='text-base text-gray-700'>
            Pricing Tier {index + 1} {index === 0 ? <span className='text-red-600'>&nbsp;*</span> : "(optional)"}
          </Typography>
          <Divider className='mb-5 mt-2' />
          <FieldGroup>
            <FieldRow className='items-baseline'>
              <FormField
                name={`pricingTiersToCreate.${index}.unitOfMeasurement.id`}
                label='Pricing UoM'
                required
                slotProps={{
                  label: {
                    className: "text-gray-800 text-[12px] font-normal",
                  },
                }}
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    slotProps={selectSlotProps}
                    type='text'
                    error={!!fieldState.error}
                    fullWidth
                    variant='outlined'
                    MenuProps={MenuProps}
                    displayEmpty
                    ref={containerRef}
                    onChange={(e) => handlePricingUomChange(e.target.value)}
                  >
                    {unitOfMeasurementList.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormField
                name={`pricingTiersToCreate.${index}.numberOfUnits`}
                label='Product Quantity'
                required
                slotProps={{
                  label: {
                    className: "text-gray-800 text-[12px] font-normal",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant='outlined'
                    placeholder='Input quantity'
                    error={!!fieldState.error}
                    InputProps={{
                      classes: {
                        input: InputFieldClasses,
                      },
                      inputProps: {
                        className: "hide-stepper",
                      },
                    }}
                  />
                )}
              />
              <FormField
                name={`pricingTiersToCreate.${index}.pricePerUnit`}
                label='Currency/Price per Unit'
                required
                slotProps={{
                  label: {
                    className: "text-gray-800 text-[12px] font-normal",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant='outlined'
                    placeholder='Input price per'
                    error={!!fieldState.error}
                    InputProps={{
                      classes: {
                        input: InputFieldClasses,
                      },
                      inputProps: {
                        className: "hide-stepper",
                      },
                    }}
                  />
                )}
              />
            </FieldRow>
          </FieldGroup>
        </Box>
      ))}
      <Box className='flex w-full justify-end'>
        <Button startIcon={<AddOutlined />} onClick={addPricingTierFields} disabled={fields.length > 5}>
          Additional Tier
        </Button>
      </Box>
      <Divider className='mb-4 mt-2' />
      <FieldGroup>
        <FieldRow className='items-baseline'>
          <FormField
            name='effectiveDate'
            label='Effective Date'
            required
            slotProps={{
              label: {
                className: "text-gray-800 text-[12px] font-normal",
              },
            }}
            render={({ field }) => (
              <DatePicker
                {...field}
                name='effectiveDate'
                renderInput={(params) => <TextField {...params} />}
                value={field.value ? dayjs(field.value) : null}
                onChange={(date) => {
                  const formattedDate = dayjs(date).format("YYYY-MM-DD")
                  field.onChange(formattedDate)
                }}
              />
            )}
          />
          <FormField
            name='expirationDate'
            label='Valid Until Date'
            slotProps={{
              label: {
                className: "text-gray-800 text-[12px] font-normal",
              },
            }}
            render={({ field }) => (
              <DatePicker
                {...field}
                name='expirationDate'
                renderInput={(params) => <TextField {...params} />}
                value={field.value ? dayjs(field.value) : null}
                onChange={(date) => {
                  const formattedDate = dayjs(date).format("YYYY-MM-DD")
                  field.onChange(formattedDate)
                }}
              />
            )}
          />
        </FieldRow>
      </FieldGroup>
    </Form>
  )
}
