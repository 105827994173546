import type { Maybe } from "graphql/jsutils/Maybe"
import { useMemo } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import {
  type CompanyLocationAssociationGetSuccess,
  PurchaseOrderCompanyLocationAssociationDocument,
  type Query,
} from "@/graphql/codegen/graphql"

const isSuccessResponse = (data: any) => data?.__typename === "CompanyLocationAssociationGetSuccess"

export interface UsePurchaseOrderCompanyLocationAssociationData {
  purchaseOrderCompanyLocationAssociation?: Maybe<CompanyLocationAssociationGetSuccess>
  purchaseOrderCompanyLocationAssociationFetching: boolean
  purchaseOrderCompanyLocationAssociationError: CombinedError | undefined
}

export type UsePurchaseOrderCompanyLocationAssociation = (
  companyLocationAssociationId?: Maybe<string>
) => UsePurchaseOrderCompanyLocationAssociationData

export const useGetPurchaseOrderCompanyLocationAssociation: UsePurchaseOrderCompanyLocationAssociation = (
  companyLocationAssociationId
) => {
  const [
    {
      data,
      fetching: purchaseOrderCompanyLocationAssociationFetching,
      error: purchaseOrderCompanyLocationAssociationError,
    },
  ] = useQuery<Pick<Query, "companyLocationAssociation">>({
    query: PurchaseOrderCompanyLocationAssociationDocument,
    variables: { companyLocationAssociationId },
    pause: !companyLocationAssociationId,
  })

  const purchaseOrderCompanyLocationAssociation = useMemo(
    () =>
      isSuccessResponse(data?.companyLocationAssociation?.get)
        ? (data?.companyLocationAssociation?.get as CompanyLocationAssociationGetSuccess)
        : null,
    [data?.companyLocationAssociation?.get]
  )

  return {
    purchaseOrderCompanyLocationAssociation,
    purchaseOrderCompanyLocationAssociationFetching,
    purchaseOrderCompanyLocationAssociationError,
  }
}
