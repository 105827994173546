import { Box, TextField, Typography } from "@mui/material"
import type { ReactElement } from "react"
import { Controller, useFormContext } from "react-hook-form"

import type { PaymentTermCreateInput } from "@/graphql/codegen/graphql"

const FinanceHeader = (): ReactElement => {
  const { control } = useFormContext<PaymentTermCreateInput>()

  return (
    <Box className='rounded-md border border-solid border-gray-300 p-6'>
      <Box className='flex items-center justify-between gap-3'>
        <Box className='flex items-center gap-3'>
          <Typography variant='subtitle1' className='pb-4 text-gray-900'>
            Payment Term Name
          </Typography>
        </Box>
      </Box>
      <Controller
        name='name'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box className='relative w-1/3'>
            <TextField
              {...field}
              fullWidth
              variant='outlined'
              inputProps={{ maxLength: 32 }}
              data-testid='paymentName'
              placeholder='-'
            />
            {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
          </Box>
        )}
      />
    </Box>
  )
}

export default FinanceHeader
