import { Autocomplete, Box, CircularProgress, InputAdornment, MenuItem, TextField, Typography } from "@mui/material"
import { CancelOutlined, CheckCircleFilledRounded } from "@mui-symbols-material/w300"
import type { FC } from "react"
import React, { useCallback } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"

interface AutoCompleteProps {
  label: string
  placeholder: string
  id: string
  onInputChange: (value: string) => void
  options: string[]
  loading?: boolean
  name: string
  control: Control
}

const AutoComplete: FC<AutoCompleteProps> = ({
  label,
  placeholder,
  id,
  onInputChange,
  options,
  loading,
  name,
  control,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const handleChange = useCallback(
    (value: string) => {
      field.onChange(value)
      onInputChange(value)
    },
    [field, onInputChange]
  )

  return (
    <Box className='mb-4' position='relative'>
      <Typography className='mb-2 block text-sm font-normal text-gray-700'>
        {label}
        <span className='text-red-600'>*</span>
      </Typography>
      <Autocomplete
        {...field}
        id={id}
        freeSolo
        onInputChange={(event, newInputValue) => handleChange(newInputValue)}
        options={options}
        classes={{
          input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            fullWidth
            variant='outlined'
            error={!!error && field.value.length > 0}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position='end'>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : error ? (
                    <CancelOutlined className='text-secondary' />
                  ) : (
                    <CheckCircleFilledRounded className='text-tertiary-green-500' />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option, state) => (
          <MenuItem {...props} key={option + state.index} disabled onClick={() => null}>
            {option}
          </MenuItem>
        )}
        {...rest}
      />
      {error && <Typography className='text-sm text-red-600'>{error.message}</Typography>}
    </Box>
  )
}

export default AutoComplete
