import { z } from "zod"

const emptyToNullableString = (value: any) => (value === "" ? null : value)
const nullableString = z.preprocess(emptyToNullableString, z.string().nullable())

const emptyToNullableNumber = (value: any) => {
  const numberValue = Number(value)
  return isNaN(numberValue) || value === "" ? null : numberValue
}
const nullableNumber = z.preprocess(emptyToNullableNumber, z.number().nonnegative().nullable())

export const AddPackagingInfoSchema = z.object({
  freightItem: nullableString,
  freightClass: nullableString,
  packagingType: nullableString,
  packageWeight: nullableNumber,
  packageWeightUom: nullableString,
  packageLength: nullableNumber,
  packageWidth: nullableNumber,
  packageHeight: nullableNumber,
  packageDimensionsUom: nullableString,
  palletWeight: nullableNumber,
  palletWeightUom: nullableString,
  palletSizeLength: nullableNumber,
  palletSizeWidth: nullableNumber,
  palletSizeHeight: nullableNumber,
  palletSizeUom: nullableString,
  packagesPerPallet: nullableNumber,
})
