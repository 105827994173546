import type { SelectChangeEvent } from "@mui/material"
import { Box, Checkbox, Chip, MenuItem, Select } from "@mui/material"
import { KeyboardArrowDownOutlined, MoreHorizOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"

import type { AccountManager } from "@/graphql/codegen/graphql.ts"

type AccountManagerSelectProps = {
  options: AccountManager[]
  name: string
  control: Control
}

const AccountManagerSelect: FunctionComponent<AccountManagerSelectProps> = ({ options, name, control }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)
  const [visibleChips, setVisibleChips] = useState<string[]>([])
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const updateVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 40 // 32 is the width of the MoreHorizOutlined icon
      let totalWidth = 0
      const visibleChipsArray: string[] = []

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(field.value[index].accountManagerId)
          totalWidth += chipWidth
        }
      })
      setVisibleChips(visibleChipsArray)
      setIsOverflowing(visibleChipsArray.length < field.value.length)
    }
  }, [field.value])

  useEffect(() => {
    updateVisibleChips()
  }, [field.value])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[]
    const selectedManagers = selectedIds
      .map((id) => options.find((manager) => manager?.accountManagerId === id))
      .filter(Boolean)
    field.onChange(selectedManagers)
  }

  return (
    <>
      <label className='mb-2 block text-sm font-normal text-gray-700'>
        Account Manager<span className='text-red-600'>*</span>
      </label>
      <Select
        id='account-manager-select'
        multiple
        fullWidth
        IconComponent={KeyboardArrowDownOutlined}
        renderValue={() => (
          <Box className='flex overflow-hidden align-middle' ref={containerRef}>
            <Box className='flex flex-nowrap gap-1'>
              {visibleChips.map((accountManagerId) => {
                const manager = options.find(({ accountManagerId: id }) => id === accountManagerId)
                return manager ? (
                  <Chip
                    key={accountManagerId}
                    label={`${manager.firstName} ${manager.lastName}`}
                    className='rounded-sm'
                  />
                ) : null
              })}
            </Box>
            {isOverflowing && (
              <Box className='mx-1 flex size-8 items-center justify-center rounded bg-gray-300'>
                <MoreHorizOutlined className='ml-0 shrink-0' />
              </Box>
            )}
          </Box>
        )}
        className='mb-2 h-10 border-none hover:border-gray-400'
        value={field.value.map(({ accountManagerId }: AccountManager) => accountManagerId)}
        onChange={handleChange}
      >
        {options.map(({ accountManagerId, firstName, lastName }) => (
          <MenuItem key={accountManagerId} value={accountManagerId}>
            <Checkbox
              checked={field.value.some((manager: AccountManager) => manager.accountManagerId === accountManagerId)}
            />
            {`${firstName} ${lastName}`}
          </MenuItem>
        ))}
      </Select>
      {error && <span className='text-sm text-red-600'>{error.message}</span>}
      <Box className='invisible absolute' ref={measureRef}>
        {field.value.map(({ accountManagerId, firstName, lastName }: AccountManager) => (
          <Chip key={accountManagerId} label={`${firstName} ${lastName}`} />
        ))}
      </Box>
    </>
  )
}

export default memo(AccountManagerSelect)
