import type { Client, CombinedError } from "urql"
import { create } from "zustand"

import { type Product, ProductListDocument, ProductSortField, SortingOrder } from "@/graphql/codegen/graphql.ts"

export type ProductSearchState = {
  data: Product[]
  fetching: boolean
  error: CombinedError | Error | undefined
  offset: number
  limit: number
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  setOffset: (offset: number) => void
  resetSearch: () => void
  setData: (data: Product[]) => void
  appendData: (data: Product[]) => void
  incrementOffset: () => void
  resetOffset: () => void
  query: string
  hasMore: boolean
  setQuery: (query: string) => void
  executeSearch: (client: Client, filters?: Record<string, unknown>) => Promise<void>
  loadMore: (client: Client, filters?: Record<string, unknown>) => Promise<void>
}

const deduplicateData = (newData: Product[], existingData: Product[]): Product[] => {
  const uniqueIds = new Set(existingData.map((product) => product.productId))
  return [...existingData, ...newData.filter((product) => !uniqueIds.has(product.productId))]
}

export const useProductSearchStore = create<ProductSearchState>((set, get) => ({
  data: [],
  fetching: false,
  error: undefined,
  offset: 0,
  limit: 20,
  query: "",
  hasMore: true,

  setFetching: (fetching) => set({ fetching }),

  setError: (error) => set({ error }),

  setOffset: (offset) => set({ offset }),

  resetSearch: () =>
    set(() => ({
      data: [],
      offset: 0,
      error: undefined,
    })),

  setData: (data) =>
    set(() => ({
      data,
      error: undefined,
    })),

  appendData: (data) =>
    set((state) => ({
      data: deduplicateData(data, state.data),
      error: undefined,
    })),

  incrementOffset: () =>
    set((state) => ({
      offset: state.offset + state.limit,
    })),

  resetOffset: () => set(() => ({ offset: 0 })),

  setQuery: (query) => set({ query, offset: 0 }), // Reset offset when query changes

  executeSearch: async (client: Client, filters: Record<string, unknown> = {}) => {
    const state = get()
    if (state.fetching) return

    set({ fetching: true })

    try {
      const { category, field = ProductSortField.CompanyName, order = SortingOrder.Asc, ...restFilters } = filters
      const queryInput = {
        filter: {
          ...restFilters,
        },
        offset: state.offset,
        limit: state.limit,
        sort: { field: field as ProductSortField, order: order as SortingOrder },
      }

      const result = await client
        .query(ProductListDocument, {
          input: queryInput,
        })
        .toPromise()

      if (result.data?.product?.list.__typename === "ProductListSuccess") {
        const products = result.data.product.list.products as Product[]
        if (state.offset === 0) {
          set({ data: products })
        } else {
          set((prevState) => ({
            data: deduplicateData(products, prevState.data),
          }))
        }
        set({ hasMore: products.length === state.limit })
      }

      if (result.error) {
        set({ error: result.error })
      }
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  loadMore: async (client: Client, filters: Record<string, unknown> = {}) => {
    const state = get()
    if (state.fetching || !state.hasMore) return

    set({ fetching: true })
    state.incrementOffset()

    try {
      const { category, field = ProductSortField.CompanyName, order = SortingOrder.Asc, ...restFilters } = filters
      const queryInput = {
        filter: {
          ...restFilters,
        },
        offset: state.offset,
        limit: state.limit,
        sort: { field: field as ProductSortField, order: order as SortingOrder },
      }

      const result = await client
        .query(ProductListDocument, {
          input: queryInput,
        })
        .toPromise()

      if (result.data?.product?.list.__typename === "ProductListSuccess") {
        const products = result.data.product.list.products as Product[]
        set((prevState) => ({
          data: deduplicateData(products, prevState.data),
          hasMore: products.length === state.limit,
        }))
      }

      if (result.error) {
        set({ error: result.error })
      }
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },
}))
