import { Alert, AlertTitle, Box } from "@mui/material"
import { AddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useParams, useSearch } from "@tanstack/react-router"
import { type FunctionComponent, useCallback, useEffect, useState } from "react"
import { FormProvider } from "react-hook-form"

import AddProductModal from "./AddProductModal"

import { Button } from "@/components/common/Button"
import { CompanyStatus } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { useAddProduct } from "@/screens/Companies/products/hooks/useAddProduct"
import { ProductFilterDrawer } from "@/screens/Companies/products/ProductFilterDrawer"
import { ProductListTable } from "@/screens/Companies/products/ProductListTable"
import { ProductSearchBar } from "@/screens/Companies/products/ProductSearchBar"
import { useProductSearchStore } from "@/screens/Companies/products/stores/useProductsSearchStore"
import { useCompanyStore } from "@/screens/Companies/stores/useCompanyStore"

const ProductContainer: FunctionComponent = () => {
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const { companyId } = useParams({ from: "/companies/$companyId/edit" })
  /** Search */
  const currentSearch = useSearch({ from: "/companies/$companyId/edit" })

  const data = useProductSearchStore(({ data }) => data)
  const fetching = useProductSearchStore(({ fetching }) => fetching)
  const error = useProductSearchStore(({ error }) => error)
  const refetch = useProductSearchStore(({ loadMore }) => loadMore)
  const loadData = useProductSearchStore(({ executeSearch }) => executeSearch)
  const company = useCompanyStore((state) => state.company)

  const loadMore = useCallback(
    (filters: Record<string, unknown> = {}) => refetch(queryClient, { ...filters, companyId }),
    [refetch, currentSearch]
  )
  const executeSearch = useCallback(
    (filters: Record<string, unknown> = {}) => loadData(queryClient, { ...filters, companyId }),
    [loadData, currentSearch]
  )

  useEffect(() => {
    executeSearch(currentSearch)
  }, [executeSearch, queryClient, currentSearch])

  const { open, setOpen, methods, handleSubmit } = useAddProduct({ companyId })

  const toggleFilterDrawer = useCallback(() => setFilterDrawerOpen((prev) => !prev), [])

  if (!fetching && data.length === 0 && Object.keys(currentSearch).every((key) => !currentSearch[key])) {
    return (
      <Box my={2}>
        <Alert
          icon={<WarningOutlined color='primary' />}
          severity='info'
          variant='filled'
          classes={{
            message: "w-full flex flex-row justify-between",
          }}
        >
          <Box>
            <AlertTitle className='font-normal leading-7'>Add company products and pricing by location</AlertTitle>
            <Box className='w-full font-thin'>Products and pricing need to be added for each ship from location</Box>
          </Box>
          <Box className='flex items-center'>
            <Button size='small' appearance='outlined' onClick={() => setOpen(true)}>
              <AddOutlined />
              Add Product
            </Button>
          </Box>
        </Alert>
        <FormProvider {...methods}>
          <AddProductModal
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            onSubmit={handleSubmit}
          />
        </FormProvider>
      </Box>
    )
  }

  return (
    <Box className='flex h-full flex-col gap-4'>
      {company?.status === CompanyStatus.Active ? (
        <Box className='flex justify-between'>
          <ProductSearchBar onShowFilter={toggleFilterDrawer} />
          <Button appearance='outlined' variant='primary' startIcon={<AddOutlined />} onClick={() => setOpen(true)}>
            Add Product
          </Button>
        </Box>
      ) : (
        <Box className='flex justify-end'>
          <Button appearance='outlined' variant='primary' startIcon={<AddOutlined />} onClick={() => setOpen(true)}>
            Add Product
          </Button>
        </Box>
      )}
      <Box className='grow overflow-hidden'>
        <ProductListTable data={data} loadMore={loadMore} fetching={fetching} error={error} />
      </Box>
      <FormProvider {...methods}>
        <AddProductModal
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
      <ProductFilterDrawer showFilterDrawer={isFilterDrawerOpen} toggleDrawer={toggleFilterDrawer} />
    </Box>
  )
}

export default ProductContainer
