function isObject(value: unknown): value is Record<PropertyKey, unknown> {
  return value != null && typeof value === "object"
}

export const deepEqualIgnoreTypename = (obj1: unknown, obj2: unknown): boolean => {
  // Handle primitive types and non-object values
  if (!isObject(obj1) || !isObject(obj2)) {
    return obj1 === obj2
  }

  // Get the keys, filtering out __typename
  const keys1 = Reflect.ownKeys(obj1).filter((key) => key !== "__typename")
  const keys2 = Reflect.ownKeys(obj2).filter((key) => key !== "__typename")

  // If the number of keys is different, objects are not equal
  if (keys1.length !== keys2.length) {
    return false
  }

  // Check if all keys match
  for (const key of keys1) {
    if (!Reflect.has(obj2, key)) {
      return false
    }

    const val1 = Reflect.get(obj1, key)
    const val2 = Reflect.get(obj2, key)

    if (isObject(val1) && isObject(val2)) {
      // Recursively compare the values
      if (!deepEqualIgnoreTypename(val1, val2)) {
        return false
      }
    } else if (val1 !== val2) {
      return false
    }
  }

  return true
}
