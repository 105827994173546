import { Box, Divider, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import { FormProvider, type UseFormProps, useForm } from "react-hook-form"

import { getRefinedSearchParams } from "../utils"

import { ProductStatusFilter } from "./ProductStatusFilter"

import type { ProductListFilter } from "@/graphql/codegen/graphql.ts"
import { ProductStatusReason } from "@/graphql/codegen/graphql.ts"

const statusList: ProductStatusReason[] = [
  ProductStatusReason.Active,
  ProductStatusReason.Discontinued,
  ProductStatusReason.PendingApproval,
]
const statusKey = "status"

type FormValues = ProductListFilter & {
  status: ProductStatusReason | null
}

export const ProductSearchFilter: FC = () => {
  const navigate = useNavigate({ from: "/companies/$companyId/edit" })
  const currentSearch = useSearch({
    from: "/companies/$companyId/edit",
    strict: true,
  })

  const { status } = currentSearch

  const defaultValues = useMemo(
    () => ({
      status: status || null,
    }),
    [status]
  )

  const methods = useForm<FormValues>({
    defaultValues,
  } as UseFormProps<FormValues>)

  const handleFilterChange = useCallback(
    (name: keyof ProductListFilter, value: any) => {
      const params = getRefinedSearchParams(currentSearch, {
        ...currentSearch,
        [name]: value,
      })

      void navigate(params)
    },
    [currentSearch, navigate]
  )

  return (
    <FormProvider {...methods}>
      <Box className='flex h-full flex-col gap-y-2'>
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Product Status
          </Typography>
          <Divider />
          <ProductStatusFilter
            name={statusKey}
            data={statusList}
            control={methods.control}
            onFilterChange={handleFilterChange}
          />
        </Box>
      </Box>
    </FormProvider>
  )
}
