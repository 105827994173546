import { type ReactElement } from "react"

interface FileIconTemplateProps {
  className?: string
  text: string
  stripeColor: string
}

export const FileIconTemplate = ({ className, text, stripeColor }: FileIconTemplateProps): ReactElement => (
  <svg width='32' height='40' viewBox='0 0 32 40' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <mask id='path-1-inside-1_78_39869' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0C2.89543 0 2 0.895431 2 2V38C2 39.1046 2.89543 40 4 40H30C31.1046 40 32 39.1046 32 38V12L20 0H4Z'
      />
    </mask>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 0C2.89543 0 2 0.895431 2 2V38C2 39.1046 2.89543 40 4 40H30C31.1046 40 32 39.1046 32 38V12L20 0H4Z'
      fill='#F8F7F8'
    />
    <path
      d='M32 12H33V11.5858L32.7071 11.2929L32 12ZM20 0L20.7071 -0.707107L20.4142 -1H20V0ZM3 2C3 1.44772 3.44772 1 4 1V-1C2.34315 -1 1 0.343147 1 2H3ZM3 38V2H1V38H3ZM4 39C3.44772 39 3 38.5523 3 38H1C1 39.6569 2.34315 41 4 41V39ZM30 39H4V41H30V39ZM31 38C31 38.5523 30.5523 39 30 39V41C31.6569 41 33 39.6569 33 38H31ZM31 12V38H33V12H31ZM32.7071 11.2929L20.7071 -0.707107L19.2929 0.707107L31.2929 12.7071L32.7071 11.2929ZM4 1H20V-1H4V1Z'
      fill='#D7D4D5'
      mask='url(#path-1-inside-1_78_39869)'
    />
    <path d='M32 12L22 12C20.8954 12 20 11.1046 20 10L20 0L32 12Z' fill='#D7D4D5' />
    <rect y='20' width='28' height='12' rx='2' fill={stripeColor} />
    <text x='14' y='28' fontSize='7' fontWeight='bold' fill='white' textAnchor='middle' dominantBaseline='middle'>
      {text}
    </text>
  </svg>
)
