import { type JwtPayload, jwtDecode } from "jwt-decode"

export const isTokenExpired = (token: string): boolean => {
  try {
    const { exp } = jwtDecode<JwtPayload>(token)

    if (!exp) {
      return true // Treat tokens with no expiration as expired
    }

    return Date.now() >= exp * 1000
  } catch (e) {
    console.error("Error decoding token:", e)
    return true // Treat errors in decoding as expired tokens
  }
}
