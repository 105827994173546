import { Box, IconButton } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"

import type { Company, CompanyLocationAssociationOutput, Location } from "@/graphql/codegen/graphql"

const getFieldAddress = (locations: Location[], locationId: string) => {
  return locations.find(({ locationId: id }) => id === locationId)?.address1
}

type AssociatedLocationsTableProps = {
  locations: Location[]
  companyNameList: Company[]
  associatedLocationList: CompanyLocationAssociationOutput[]
  onEditClick: (associatedLocation: CompanyLocationAssociationOutput) => void
}

const AssociatedLocationsTable: FunctionComponent<AssociatedLocationsTableProps> = ({
  associatedLocationList,
  locations,
  companyNameList,
  onEditClick,
}) => (
  <Box>
    {associatedLocationList.map((associatedLocation) => {
      const { shipping, billing, office, shippingVia, companyLocationAssociationId, order } = associatedLocation
      const shippingAddress = getFieldAddress(locations, shipping.locationId)
      const billingAddress = getFieldAddress(locations, billing.locationId)
      if (!(shippingAddress && billingAddress)) return null
      return (
        <Box className='flex w-full space-x-8 border border-gray-300 px-6 py-4' key={companyLocationAssociationId}>
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Shipping</label>
            <span className='text-gray-900'>{shippingAddress}</span>
          </Box>
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Billing</label>
            <span className='text-gray-900'>{billingAddress}</span>
          </Box>
          {/* order */}
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Order</label>
            <span className='text-gray-900'>
              {locations.find(({ locationId }) => locationId === order?.locationId)?.address1}
            </span>
          </Box>
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Office</label>
            <span className='text-gray-900'>
              {locations.find(({ locationId }) => locationId === office?.locationId)?.address1}
            </span>
          </Box>
          <Box className='w-full'>
            <label className='mb-2 block text-sm font-normal text-primary'>Ship Via</label>
            <span className='text-gray-900'>
              {companyNameList.find(({ companyId }) => companyId === shippingVia?.companyId)?.name}
            </span>
          </Box>
          <Box className='content-end'>
            <IconButton
              color='primary'
              aria-label='edit associated location details'
              onClick={() => onEditClick(associatedLocation)}
            >
              <EditOutlined />
            </IconButton>
          </Box>
        </Box>
      )
    })}
  </Box>
)

export default AssociatedLocationsTable
