import { Box, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { PaymentRuleEventGql, type PaymentTermCreateInput } from "@/graphql/codegen/graphql"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const eventOptions = [
  {
    label: "Delivery",
    value: PaymentRuleEventGql.Delivery,
  },
  {
    label: "End of invoice month",
    value: PaymentRuleEventGql.EndOfInvoiceMonth,
  },
  {
    label: "Placing order",
    value: PaymentRuleEventGql.PlacingOrder,
  },
  {
    label: "Shipping",
    value: PaymentRuleEventGql.Shipping,
  },
]

const AddPaymentRule = ({
  partsIndex = 0,
  optionsIndex = 0,
}: {
  partsIndex: number
  optionsIndex: number
}): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PaymentTermCreateInput>()

  return (
    <div>
      <Box className='flex gap-3'>
        <Controller
          name={`options.${optionsIndex}.parts.${partsIndex}.paymentRule.daysAfter`}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='mb-2 block text-xs text-gray-800'>
                Days<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                name={`options.${optionsIndex}.parts.${partsIndex}.paymentRule.daysAfter`}
                fullWidth
                variant='outlined'
                placeholder='-'
                InputProps={{
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                  inputProps: {
                    className: "hide-stepper",
                  },
                }}
              />
            </Box>
          )}
        />
        <Typography variant='body2' className='mt-6 content-center text-gray-700'>
          after
        </Typography>
        <Controller
          name={`options.${optionsIndex}.parts.${partsIndex}.paymentRule.event`}
          control={control}
          render={({ field }) => (
            <Box className='relative content-end'>
              <Select
                {...field}
                name={`options.${optionsIndex}.parts.${partsIndex}.paymentRule.event`}
                displayEmpty
                input={<OutlinedInput />}
                SelectDisplayProps={{
                  className:
                    "w-[176px] h-6 px-4 py-2 bg-white focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                }}
                classes={{
                  icon: "text-gray-600",
                }}
                IconComponent={KeyboardArrowDownOutlined}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                variant='outlined'
                fullWidth
              >
                {eventOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />
        <Controller
          name={`options.${optionsIndex}.parts.${partsIndex}.paymentRule.percentage`}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='mb-2 block text-xs text-gray-800'>
                Pay<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                name={`options.${optionsIndex}.parts.${partsIndex}.paymentRule.percentage`}
                fullWidth
                variant='outlined'
                placeholder='-'
                InputProps={{
                  endAdornment: <Typography className='text-sm'>%</Typography>,
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                  inputProps: {
                    className: "hide-stepper",
                  },
                }}
              />
            </Box>
          )}
        />
        <Typography variant='body2' className='mt-6 content-center text-gray-700'>
          of full amount
        </Typography>
      </Box>
      <div className='text-sm text-red-600'>
        <div>{errors?.options?.[optionsIndex]?.parts?.[partsIndex]?.paymentRule?.daysAfter?.message}</div>
        <div>{errors?.options?.[optionsIndex]?.parts?.[partsIndex]?.paymentRule?.percentage?.message}</div>
        <div>{errors?.options?.[optionsIndex]?.parts?.[partsIndex]?.message}</div>
      </div>
    </div>
  )
}

export default AddPaymentRule
