import type { TableContainerProps } from "@mui/material"
import { Paper, TableContainer } from "@mui/material"
import { twMerge } from "tailwind-merge"

/**
 * Outer container component that provides the card-like appearance with borders and rounded corners.
 * Handles scrolling behavior for table content.
 *
 * @example
 * ```tsx
 * <AerosTableContainer>
 *   <AerosTable>
 *     // ... table content
 *   </AerosTable>
 * </AerosTableContainer>
 * ```
 *
 * @param props - Extends MUI TableContainerProps
 * @param props.className - Additional Tailwind classes for container styling
 */
export const AerosTableContainer = ({ className, ...props }: TableContainerProps): JSX.Element => {
  return (
    <TableContainer
      component={Paper}
      className={twMerge([
        "flex max-h-[calc(65vh-200px)] min-h-[400px] flex-col overflow-auto rounded-md border border-gray-300 shadow-none",
        "relative",
        className,
      ])}
      {...props}
    />
  )
}
