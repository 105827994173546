import { useCallback, useState } from "react"

import type { FieldGroupEnum, ReferenceTableName } from "@/graphql/codegen/graphql"
import { useFileUpload } from "@/hooks/useFileUpload"

export enum PDFSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "extraLarge",
}

interface UsePDFUploadProps {
  referenceTableName: ReferenceTableName
  getReferenceId: () => string
}

type UploadState = Record<FieldGroupEnum, boolean>

interface PDFUploadHook {
  uploading: UploadState
  handleGetSignedUrl: (file: File, type: FieldGroupEnum, label: string) => Promise<string | null>
  handleUploadToS3: (signedUrl: string, type: FieldGroupEnum, file: File) => Promise<boolean>
  validatePDFFile: (file: File) => boolean
}

export const usePDFUpload = ({ referenceTableName, getReferenceId }: UsePDFUploadProps): PDFUploadHook => {
  const { getSignedUrl, uploadToS3 } = useFileUpload()
  const [uploading, setUploading] = useState<UploadState>({} as UploadState)

  const handleGetSignedUrl = useCallback(
    async (file: File, type: FieldGroupEnum, label: string) => {
      if (file.type !== "application/pdf") {
        throw new Error("Only PDF files are allowed.")
      }

      if (file.size > 10 * 1024 * 1024) {
        throw new Error("File size should not exceed 10MB.")
      }

      try {
        const signedUrl = await getSignedUrl({
          file,
          type: file.type,
          metadata: {
            fieldGroup: type,
            referenceId: getReferenceId(),
            referenceTableName,
          },
          label,
        })

        return signedUrl
      } catch (error) {
        console.error("Failed to get signed URL:", error)
        return null
      }
    },
    [getSignedUrl, getReferenceId, referenceTableName]
  )

  const handleUploadToS3 = useCallback(
    async (signedUrl: string, type: FieldGroupEnum, file: File) => {
      setUploading((prev) => ({ ...prev, [type]: true }))
      try {
        const success = await uploadToS3(signedUrl, file)
        return success
      } catch (error) {
        console.error("Failed to upload to S3:", error)
        return false
      } finally {
        setUploading((prev) => ({ ...prev, [type]: false }))
      }
    },
    [uploadToS3]
  )

  const validatePDFFile = useCallback((file: File): boolean => {
    if (file.type !== "application/pdf") {
      throw new Error("Only PDF files are allowed.")
    }

    if (file.size > 10 * 1024 * 1024) {
      throw new Error("File size should not exceed 10MB.")
    }

    return true
  }, [])

  return {
    uploading,
    handleGetSignedUrl,
    handleUploadToS3,
    validatePDFFile,
  }
}
