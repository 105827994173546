import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useState } from "react"
import { useMutation } from "urql"

import { Button } from "@/components/common/Button"
import type { CommentMutation, CommentMutationCreateArgs } from "@/graphql/codegen/graphql"
import { CommentParentTableType, CreateCommentMutationDocument } from "@/graphql/codegen/graphql"
import { useAuthStore } from "@/stores/authStore"

type AddContactCommentProps = {
  open: boolean
  name?: string
  contactId: string
  companyName?: string
  type?: CommentParentTableType
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: () => void
}

const AddContactComment: FunctionComponent<AddContactCommentProps> = ({
  open,
  name,
  contactId,
  type = CommentParentTableType.Contact,
  companyName,
  onClose,
  onSubmit,
}) => {
  const { user } = useAuthStore.getState()
  const [commentValue, setCommentValue] = useState<string>("")
  const [, createComment] = useMutation<CommentMutation, CommentMutationCreateArgs>(CreateCommentMutationDocument)
  const handleSubmit = useCallback(async () => {
    await createComment({
      input: {
        createdBy: user?.id ?? "",
        referenceId: contactId,
        type,
        value: commentValue ?? "",
      },
    })
    onSubmit()
    onClose("success")
    setCommentValue("")
  }, [createComment, user?.id, contactId, type, commentValue, onSubmit, onClose])

  const handleResetClick = useCallback(() => {
    setCommentValue("")
    onClose("cancel")
  }, [onClose])

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={(_, reason) => {
            setCommentValue("")
            onClose(reason)
          }}
          fullWidth
        >
          <DialogTitle className='h-[60px] pb-3 pl-10 font-normal text-primary'>
            {name}
            {companyName && (
              <Typography variant='subtitle1' className='text-gray-700'>
                {companyName}
              </Typography>
            )}
          </DialogTitle>
          <IconButton
            aria-label='close'
            onClick={() => {
              setCommentValue("")
              onClose("cancel")
            }}
            className='absolute right-4 top-4 text-primary'
          >
            <CloseOutlined />
          </IconButton>
          <Divider />
          <DialogContent classes={{ root: "p-10" }}>
            <TextareaAutosize
              minRows={3.3}
              maxRows={3.3}
              className='overflowY-auto w-full rounded-md border border-solid border-gray-300 p-4 text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#BFDBFE]'
              value={commentValue}
              onChange={(e) => setCommentValue(e.target.value)}
              placeholder='Add a comment...'
            />
          </DialogContent>
          <DialogActions className={"justify-between px-10 pb-10"}>
            <Button appearance='outlined' onClick={handleResetClick}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={!commentValue}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default AddContactComment
