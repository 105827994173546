import type { AnyRoute } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

import CompanyLocationsTab from "@/screens/Companies/edit/components/CompanyLocationsTab.tsx"

const searchSchema = z.object({
  tab: z.enum(["locations", "associated"]).optional().default("locations"),
})

export const Route: AnyRoute = createFileRoute("/companies/$companyId/edit/_layout/locations")({
  validateSearch: searchSchema.parse,
  preSearchFilters: [
    (search) => ({
      ...search,
      tab: search.tab ?? "locations",
    }),
  ],
  component: CompanyLocationsTab,
})
