import { Box } from "@mui/material"
import type { ReactElement } from "react"

import type { BaseUploadBoxProps } from "../types"

export const DefaultUploadBox = ({
  children,
  className,
  isOver,
  canDrop,
  isHovered,
}: BaseUploadBoxProps): ReactElement => (
  <Box
    className={`hover:border-primary-500 relative flex cursor-pointer items-center justify-center rounded-lg border border-dashed border-gray-300 bg-white transition-all duration-200 ${
      isOver && canDrop ? "border-primary-500" : ""
    } ${isHovered ? "border-primary-500" : ""} ${className || ""}`}
  >
    {children}
  </Box>
)
