import { useCallback, useEffect, useState } from "react"
import { useQuery } from "urql"

import type {
  CompanyListDetailsQuery,
  CompanyListDetailsQueryVariables,
  CompanyListSuccess,
  Location,
} from "@/graphql/codegen/graphql"
import { CompanyListDetailsDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20
const OFFSET = 0

type UseGetIncotermsLocationQueryReturn = {
  data: Location[]
  fetching: boolean
  loadMore: () => void
}

export const useGetIncotermsLocationQuery = (): UseGetIncotermsLocationQueryReturn => {
  const [offset, setOffset] = useState(0)
  const [intermediaryLocations, setIntermediaryLocations] = useState<Location[]>([])

  const [{ data: intermediaryCompanyList, fetching: intermediaryLocationListFetching }] = useQuery<
    CompanyListDetailsQuery,
    CompanyListDetailsQueryVariables
  >({
    query: CompanyListDetailsDocument,
    variables: {
      input: {
        filter: { types: ["INTERMEDIARY_DESTINATION"] },
        limit: LIMIT,
        offset: OFFSET,
      },
    },
  })

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  useEffect(() => {
    if (intermediaryCompanyList) {
      const companyList = (intermediaryCompanyList?.company.list as CompanyListSuccess)?.companies || []
      const shippingLocations = companyList.flatMap((company) => company.locations || []).filter(Boolean)

      setIntermediaryLocations((prev) => (offset === 0 ? shippingLocations : [...prev, ...shippingLocations]))
    }
  }, [intermediaryCompanyList, offset])

  return {
    data: intermediaryLocations,
    fetching: intermediaryLocationListFetching,
    loadMore,
  }
}
