import type { AnyRoute } from "@tanstack/react-router"
import { minimatch } from "minimatch"
import { z } from "zod"

// Define the schema for the route structure using Zod
const RouteObjectSchema: z.ZodType<RouteObject> = z.object({
  pathname: z.string(),
  children: z.array(z.lazy(() => RouteObjectSchema)).optional(),
})

// Define the type for the route structure
export type RouteObject = {
  pathname: string
  children?: RouteObject[]
}

// Define the options for the getRoutes function
export interface GetRoutesOptions {
  excludePatterns?: string[]
}

const isExcludedRoute = (path: string, excludePatterns: string[]): boolean => {
  return excludePatterns.some((pattern) => minimatch(path, pattern))
}

const normalizePath = (path: string): string => {
  return path === "/" ? path : path.replace(/\/+$/, "") // Remove trailing slashes, but keep "/" as is
}

const createRouteObject = (path: string, children: RouteObject[] = []): RouteObject => {
  return {
    pathname: normalizePath(path),
    children: children.length ? children : undefined,
  }
}

const getParentPath = (path: string): string => {
  if (path === "/") {
    return ""
  }

  return normalizePath(path.replace(/\/[^/]+$/, "")) // Remove the last segment
}

const addRouteToMap = (routeMap: Map<string, RouteObject>, path: string): Map<string, RouteObject> => {
  // Normalize the path first
  path = normalizePath(path)

  // Handle the home path case
  if (path === "/") {
    if (!routeMap.has("/")) {
      routeMap.set("/", createRouteObject("/"))
    }
    return routeMap
  }

  const parts = path.split("/").filter(Boolean)
  let currentPath = ""

  parts.forEach((part, index) => {
    currentPath += `/${part}`
    currentPath = normalizePath(currentPath)

    if (!routeMap.has(currentPath)) {
      routeMap.set(currentPath, createRouteObject(currentPath))
    }

    if (index > 0) {
      const parentPath = getParentPath(currentPath)
      const parentRoute = routeMap.get(parentPath)

      if (parentRoute) {
        if (!parentRoute.children) {
          parentRoute.children = []
        }

        if (!parentRoute.children.find((child) => child.pathname === currentPath)) {
          parentRoute.children.push(routeMap.get(currentPath)!)
        }
      }
    }
  })

  return routeMap
}

const parseFlatRoutes = (flatPaths: string[]): RouteObject[] => {
  const routeMap = flatPaths.reduce((map, path) => addRouteToMap(map, path), new Map<string, RouteObject>())
  // Only return top-level routes
  return Array.from(routeMap.values()).filter((route) => {
    const parentPath = getParentPath(route.pathname)
    return !routeMap.has(parentPath)
  })
}

export const getRoutes = ({
  routeTree,
  options = {},
}: {
  routeTree: AnyRoute
  options?: GetRoutesOptions
}): RouteObject[] => {
  const { excludePatterns = [] } = options

  const paths = routeTree.children.map((route: AnyRoute) => route.path)
  const filteredPaths = paths.filter((path: string) => !isExcludedRoute(path, excludePatterns))
  const nestedRoutes = parseFlatRoutes(filteredPaths)
  // Validate the nested route objects against the schema
  nestedRoutes.forEach((route) => RouteObjectSchema.parse(route))
  return nestedRoutes
}

export const getTopLevelRoutes = (routeTree: RouteObject[]): RouteObject[] => {
  return routeTree.map((route) => {
    const { children, ...rest } = route
    return rest
  })
}
