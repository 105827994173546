import { Box, Chip, IconButton, Modal, Typography } from "@mui/material"
import {
  DeleteOutlined,
  EditOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpFilledOutlined,
} from "@mui-symbols-material/w300"
import type { CellContext, ColumnDef } from "@tanstack/react-table"
import { type ReactElement, useCallback, useState } from "react"

import { OrderQuantityColumn } from "./OrderQuantityColumn"

import { CancelTemplate } from "@/components/common/Modals/CancelTemplate"
import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import type { OrderLine } from "@/graphql/codegen/graphql.ts"
import { usePurchaseOrder } from "@/screens/Procurement/hooks/usePurchaseOrder"
import { supplierProductStatusMap } from "@/screens/Procurement/Supplier/utils/supplierReportConstants"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"
import { formatNumber } from "@/utils/formatNumber"

// TODO: Move to constants
// UOM ID for pounds
export const POUND_UOM_ID = "USSTD_WEIGHT_POUND"
export const KILOGRAM_UOM_ID = "METRIC_WEIGHT_KILOGRAM"

const ActionsCell = ({ cell }: CellContext<OrderLine, unknown>): ReactElement => {
  const { row } = cell.getContext()
  const { currentPO } = usePurchaseOrderStore()
  const [isCancelConfirmationOpen, setCancelConfirmationOpen] = useState(false)
  const { updatePO } = usePurchaseOrder()

  const handleCancel = () => {
    setCancelConfirmationOpen(false)
  }

  const handleConfirmCancel = useCallback(async () => {
    if (!currentPO) return
    const filteredOrderedLines = (currentPO.orderLines ?? []).filter(
      ({ productId }) => productId !== row.original.productDetail?.product?.productId
    )
    await updatePO({
      input: {
        orderId: currentPO.orderId,
        orderLines: filteredOrderedLines,
      },
    })
    setCancelConfirmationOpen(false)
  }, [currentPO, row.original.productDetail?.product?.productId, updatePO])

  return (
    <Box className='flex items-center justify-end gap-4 space-x-4'>
      <IconButton
        className='p-0'
        size='small'
        aria-label='delete the entire row'
        onClick={() => setCancelConfirmationOpen(true)}
      >
        <DeleteOutlined />
      </IconButton>
      <IconButton aria-label='expand row' size='small' onClick={() => row.toggleExpanded()} data-testid='expand-button'>
        {row.getIsExpanded() ? <KeyboardArrowUpFilledOutlined /> : <KeyboardArrowDownOutlined />}
      </IconButton>
      <Modal open={isCancelConfirmationOpen} onClose={handleCancel}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            width: 576,
            borderRadius: 2,
          }}
        >
          <CancelTemplate
            title={`Are you sure you want to delete this product
ID ${row.original.productId} ?`}
            message='This action will affect the order total quantities and total amount.'
            cancelText='Yes, Delete'
            goBackText='Cancel'
            onClose={setCancelConfirmationOpen}
            confirmCancel={handleConfirmCancel}
          />
        </Box>
      </Modal>
    </Box>
  )
}

export const getPurchaseOrderProductTableColumns = (
  onOpenPriceDialog: (product: OrderLine) => void
): ColumnDef<OrderLine>[] => [
  {
    header: "Product ID",
    accessorKey: "productId",
    cell: ({ row }) => (
      <Box className='flex items-center gap-x-4'>
        <StatusIndicator
          status={row.original.productDetail?.product?.isActive ?? false}
          map={supplierProductStatusMap}
        />
        {row.original.productId}
      </Box>
    ),
    size: 80,
  },
  {
    header: "Description",
    cell: ({ row }) => (
      <Box className='gap-2'>
        <div className='text-wrap'>Akrochem: {row.original.productDetail?.product?.companyProductDescription}</div>
        <div className='text-wrap'>Supplier: {row.original.productDetail?.product?.vendorDescription}</div>
      </Box>
    ),
    size: 120,
  },
  {
    header: "Lead Time",
    accessorKey: "leadTimeInDays",
    cell: ({ row }) => (
      <Chip
        label={
          `${
            row.original.productDetail?.product?.leadTimeInDays
              ? Math.ceil(row.original.productDetail?.product?.leadTimeInDays / 7) < 10
                ? "0" + Math.ceil(row.original.productDetail?.product?.leadTimeInDays / 7)
                : Math.ceil(row.original.productDetail?.product?.leadTimeInDays / 7)
              : "00"
          }` + " Week"
        }
        variant='outlined'
        className='h-[24px] rounded bg-primary-200 px-2'
      />
    ),
    size: 80,
  },
  {
    header: "Order Quantity",
    cell: OrderQuantityColumn,
    size: 200,
  },
  {
    header: "Price/UoM",
    accessorKey: "pallets",
    cell: ({ row }) => {
      const isDetailsPresent = row.original.numberOfPallets && row.original?.quantityInUom && row.original?.pricePerUom
      const selectedUom = row.original.uomId === POUND_UOM_ID ? "lb" : "kg"
      return (
        <Box className='gap-2'>
          <div className='flex gap-2'>
            <Typography variant='h6' className='font-bold text-gray-700'>
              {isDetailsPresent ? `$${row.original.pricePerUom} / ${selectedUom}` : "Not calculated"}
            </Typography>
            <IconButton
              color='primary'
              className='p-0'
              size='small'
              aria-label='edit price per unit of measurement'
              onClick={() => onOpenPriceDialog(row.original)}
            >
              <EditOutlined />
            </IconButton>
          </div>
          <Typography variant='subtitle2' className='mt-3 text-gray-700'>
            Total:{" "}
            {isDetailsPresent && row.original?.pricePerUom && row.original?.quantityInUom
              ? `$${formatNumber(row.original.pricePerUom * row.original.quantityInUom)}`
              : "-"}
          </Typography>
        </Box>
      )
    },
    size: 100,
  },
  {
    header: "",
    accessorKey: "name",
    cell: ActionsCell,
    size: 50,
  },
]
