import { Checkbox, FormControlLabel } from "@mui/material"
import type { FC } from "react"

import type { CompanyDepartment } from "@/graphql/codegen/graphql.ts"

export const DepartmentCheckbox: FC<{
  department: { original: CompanyDepartment; formatted: string }
  value: CompanyDepartment[]
  onChange: (newValue: CompanyDepartment[]) => void
}> = ({ department, value, onChange }) => {
  const isChecked = value.includes(department.original)

  const handleCheckboxChange = (checked: boolean) => {
    if (checked) {
      // Add department to array if checked
      onChange([...value, department.original])
    } else {
      // Remove department from array if unchecked
      onChange(value.filter((v) => v !== department.original))
    }
  }

  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} onChange={(e) => handleCheckboxChange(e.target.checked)} />}
      label={department.formatted}
    />
  )
}
