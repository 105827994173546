import { create } from "zustand"

import type { AccountManager } from "@/graphql/codegen/graphql"

// Define Zustand store
interface AccountManagerState {
  visibleManagers: AccountManager[]
  offset: number
  limit: number
  setVisibleManagers: (managers: AccountManager[]) => void
  addManagers: (managers: AccountManager[]) => void
  increaseOffset: () => void
  resetOffset: () => void
}

export const useAccountManagerStore = create<AccountManagerState>((set) => ({
  visibleManagers: [],
  offset: 0,
  limit: 20,
  setVisibleManagers: (managers) => set({ visibleManagers: managers }),
  addManagers: (managers) => set((state) => ({ visibleManagers: [...state.visibleManagers, ...managers] })),
  increaseOffset: () => set((state) => ({ offset: state.offset + state.limit })),
  resetOffset: () => set({ offset: 0 }),
}))
