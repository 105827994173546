import { Checkbox, FormControlLabel } from "@mui/material"
import type { ChangeEvent, FC } from "react"
import { memo, useCallback } from "react"
import { useFieldArray } from "react-hook-form"

export const AccountManagerCheckbox: FC<{
  manager: { accountManagerId: string; firstName: string; lastName: string }
  control: any
  name: string
  onFilterChange: (value: string[]) => void
  accountManagersValue: string[]
  className?: string
}> = memo(({ manager, control, name, onFilterChange, accountManagersValue, className }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  const isChecked = accountManagersValue.includes(manager.accountManagerId)

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        append(manager.accountManagerId)
        onFilterChange([...accountManagersValue, manager.accountManagerId])
      } else {
        const indexToRemove = fields.findIndex((field) => field.accountManagerId === manager.accountManagerId)
        remove(indexToRemove)
        onFilterChange(accountManagersValue.filter((v) => v !== manager.accountManagerId))
      }
    },
    [append, remove, fields, accountManagersValue, manager.accountManagerId, onFilterChange]
  )

  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
      label={`${manager.firstName} ${manager.lastName}`}
      className={`${className} w-full text-ellipsis`}
    />
  )
})
