import { useSearch } from "@tanstack/react-router"
import type { ReactElement } from "react"

import CompaniesTable from "@/screens/Companies/components/tables/CompaniesTable.tsx"
import { ContactsTable } from "@/screens/Companies/components/tables/ContactsTable.tsx"
import { LocationsTable } from "@/screens/Companies/components/tables/LocationsTable.tsx"
import { SearchCategory } from "@/screens/Companies/types/enums.ts"

export const TableContainer = (): ReactElement => {
  const { category } = useSearch({ from: "/companies/" })

  if (category === SearchCategory.COMPANY) {
    return <CompaniesTable />
  }

  if (category === SearchCategory.CONTACT) {
    return <ContactsTable />
  }
  if (category === SearchCategory.LOCATION) {
    return <LocationsTable />
  }

  return <>Please select a category</>
}
