import { Box } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import { useSearch } from "@tanstack/react-router"
import { type ReactElement, useEffect } from "react"
import { twMerge } from "tailwind-merge"

import { useContactSubscriptionsStore } from "../stores/useContactSubscriptionsStore"

import { CompanySearchFilterChips } from "./filters/company/CompanySearchFilterChips"
import { LocationSearchFilterChips } from "./filters/location/LocationSearchFilterChip"

import { Button } from "@/components/common/Button"
import CompaniesSearchBar from "@/screens/Companies/components/CompaniesSearchBar.tsx"
import { CompanyTypeButtonGroup } from "@/screens/Companies/components/CompanyTypeButtonGroup.tsx"
import { ContactSearchFilterChips } from "@/screens/Companies/components/filters/contact/ContactSearchFilterChip.tsx"
import { useCompanyTypesStore } from "@/screens/Companies/stores/useCompanyTypesStore.ts"
import { SearchCategory } from "@/screens/Companies/types/enums.ts"

export const CompaniesSearch = ({
  onShowModal,
  onShowFilter,
}: {
  onShowModal: () => void
  onShowFilter: () => void
}): ReactElement => {
  const { category } = useSearch({
    from: "/companies/",
    strict: true,
  })

  const companyTypes = useCompanyTypesStore((state) => state.companyTypes)
  const companyTypeFetching = useCompanyTypesStore((state) => state.fetching)
  const fetchCompanyTypes = useCompanyTypesStore((state) => state.fetchCompanyTypes)

  const contactSubscriptions = useContactSubscriptionsStore((state) => state.contactSubscriptions)
  const contactSubscriptionFetching = useContactSubscriptionsStore((state) => state.fetching)
  const fetchContactSubscriptions = useContactSubscriptionsStore((state) => state.fetchContactSubscriptions)

  useEffect(() => {
    if (!companyTypeFetching && companyTypes.length === 0) {
      fetchCompanyTypes()
    }
    if (!contactSubscriptionFetching && contactSubscriptions.length === 0) {
      fetchContactSubscriptions()
    }
  }, [
    companyTypeFetching,
    companyTypes.length,
    contactSubscriptionFetching,
    contactSubscriptions.length,
    fetchCompanyTypes,
    fetchContactSubscriptions,
  ])

  return (
    <>
      <Box
        data-testid='companies-search'
        className={twMerge(
          "flex items-center",
          category === SearchCategory.COMPANY ? "justify-between" : "justify-end"
        )}
      >
        {category === SearchCategory.COMPANY && (
          <CompanyTypeButtonGroup companyTypes={companyTypes} fetching={companyTypeFetching} />
        )}
        <Button startIcon={<AddOutlined />} appearance='outlined' onClick={onShowModal}>
          New Company
        </Button>
      </Box>
      <Box className='py-6'>
        <CompaniesSearchBar onShowFilter={onShowFilter} />
      </Box>
      <Box data-testid='companies-search-filter-chips' className='flex flex-wrap items-center gap-4 pb-6'>
        {category && category === SearchCategory.COMPANY && <CompanySearchFilterChips companyTypes={companyTypes} />}
        {category && category === SearchCategory.CONTACT && <ContactSearchFilterChips />}
        {category && category === SearchCategory.LOCATION && <LocationSearchFilterChips />}
      </Box>
    </>
  )
}
