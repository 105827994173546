import { useCallback, useEffect, useState } from "react"
import { useQuery } from "urql"

import type { Location } from "@/graphql/codegen/graphql"
import { CompanyListDetailsDocument } from "@/graphql/codegen/graphql.ts"
import type { CompanyListSuccess, Query } from "@/graphql/codegen/graphql.ts"

export const LIMIT = 20
const OFFSET = 0

export const useGetIncotermsLocations = (): {
  akrochemShippingLocations: Location[]
  akrochemLocationListFintermediaryCompanyListetching: boolean
  intermediaryLocations: Location[]
  intermediaryLocationListFetching: boolean
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [akrochemShippingLocations, setAkrochemShippingLocations] = useState<Location[]>([])
  const [intermediaryLocations, setIntermediaryLocations] = useState<Location[]>([])

  const [{ data: akrochemCompanyList, fetching: akrochemLocationListFetching }] = useQuery<Pick<Query, "company">>({
    query: CompanyListDetailsDocument,
    variables: {
      input: {
        filter: { types: ["AKROCHEM", "AKROCHEM_WAREHOUSE", "CONSIGNMENT_WAREHOUSE"] },
        limit: LIMIT,
        offset: OFFSET,
      },
    },
  })

  const [{ data: intermediaryCompanyList, fetching: intermediaryLocationListFetching }] = useQuery<
    Pick<Query, "company">
  >({
    query: CompanyListDetailsDocument,
    variables: {
      input: {
        filter: { types: ["INTERMEDIARY_DESTINATION"] },
        limit: LIMIT,
        offset: OFFSET,
      },
    },
  })

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  useEffect(() => {
    if (akrochemCompanyList) {
      let shippingLocations: Location[] = []
      const companyList = (akrochemCompanyList?.company.list as CompanyListSuccess)?.companies || []
      companyList.filter((company) => {
        if (company.locationsAssociations && company.locationsAssociations.length > 0) {
          return company.locationsAssociations.filter((location) => {
            if (location.shipping) {
              shippingLocations.push({
                ...location.shipping,
                companyLocationAssociationId: location.companyLocationAssociationId,
              })
            }
            return null
          })
        }
        return null
      })
      shippingLocations = shippingLocations.filter(Boolean)
      setAkrochemShippingLocations((prev) =>
        offset === 0 ? shippingLocations.filter(Boolean) : [...prev, ...shippingLocations]
      )
    }
  }, [akrochemCompanyList, offset])

  useEffect(() => {
    if (intermediaryCompanyList) {
      const companyList = (intermediaryCompanyList?.company.list as CompanyListSuccess)?.companies || []
      const shippingLocations = companyList.flatMap((company) => company.locations || []).filter(Boolean)

      setIntermediaryLocations((prev) => (offset === 0 ? shippingLocations : [...prev, ...shippingLocations]))
    }
  }, [intermediaryCompanyList, offset])

  return {
    akrochemShippingLocations,
    akrochemLocationListFetching,
    intermediaryLocations,
    intermediaryLocationListFetching,
    loadMore,
  }
}
