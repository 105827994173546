import { z } from "zod"

export const AddPricingSchema = z
  .object({
    pricingTiersToCreate: z.array(
      z.object({
        unitOfMeasurement: z.object({
          id: z.string().min(1, { message: "Pricing UoM is mandatory" }),
          name: z.string().min(1, { message: "Pricing UoM is mandatory" }),
        }),
        numberOfUnits: z.string().refine((value) => !isNaN(Number(value)) && Number(value) > 0, {
          message: "Product Quantity must be a valid number greater than 0",
        }),
        pricePerUnit: z.string().regex(/^\d+(\.\d{1,5})?$/, {
          message: "Currency/Price must be a valid currency with up to 5 decimal places",
        }),
      })
    ),
    effectiveDate: z.string().min(1, {
      message: "Effective date is mandatory",
    }),
    expirationDate: z.string().optional(),
    freightTerms: z.string().min(1, { message: "Freight terms is mandatory" }),
    incoterms: z.string().min(1, { message: "Incoterms is mandatory" }),
    incotermsLocationId: z.string().min(1, { message: "Incoterms Location is mandatory" }),
    destinationCompanyLocationAssociationId: z.string().min(1, { message: "Shipping Location is mandatory" }),
    sourceCompanyLocationAssociationId: z.string().optional(),
  })
  .refine((data) => !data.expirationDate || new Date(data.effectiveDate) < new Date(data.expirationDate), {
    message: "Valid until date must be after the effective date",
    path: ["expirationDate"],
  })
