import type { InputBaseProps } from "@mui/material"
import { Box } from "@mui/material"
import { MuiTelInput } from "mui-tel-input"
import type { ReactElement } from "react"
import type { Control } from "react-hook-form"
import { Controller } from "react-hook-form"
import { twMerge } from "tailwind-merge"

export const PhoneNumberInput = ({
  control,
  name,
  label,
  required = false,
  containerProps = {},
  labelProps = {},
  inputProps = {},
}: {
  control: Control
  name: string
  label: string
  required?: boolean
  containerProps?: React.ComponentProps<typeof Box>
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps?: InputBaseProps["inputProps"]
}): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? "Phone number is required" : false,
        validate: (value) => {
          if (!value) return true
          return value.trim() !== "" || "Phone number is invalid"
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <Box className='relative w-full' {...containerProps}>
          <label
            className='absolute -top-6 mb-[7px] block text-sm font-thin text-gray-800'
            htmlFor={field.name}
            {...labelProps}
          >
            {label} {required && <span className='text-red-600'>*</span>}
          </label>
          <MuiTelInput
            id={field.name}
            {...field}
            forceCallingCode
            defaultCountry='US'
            error={!!error}
            helperText={error?.message}
            fullWidth
            inputProps={{
              ...inputProps,
              className: twMerge(
                "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                inputProps.className ?? ""
              ),
            }}
            onChange={field.onChange}
          />
        </Box>
      )}
    />
  )
}
