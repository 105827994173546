import { z } from "zod"

import type { CompanyListFilter, ContactQueryableFields, LocationListFilter } from "@/graphql/codegen/graphql.ts"
import {
  CompanyListFilterSchema,
  CompanySortField,
  ContactQueryableFieldsSchema,
  ContactSortField,
  LocationListFilterSchema,
  LocationSortField,
  SortingOrder,
} from "@/graphql/codegen/graphql.ts"
import { SearchCategory } from "@/screens/Companies/types/enums.ts"

export const SearchParamsSchema = z.object({
  ...ContactQueryableFieldsSchema().shape,
  ...LocationListFilterSchema().shape,
  ...CompanyListFilterSchema().shape,
  field: z
    .union([z.nativeEnum(CompanySortField), z.nativeEnum(ContactSortField), z.nativeEnum(LocationSortField)] as const)
    .optional()
    .default(CompanySortField.Name),
  category: z.nativeEnum(SearchCategory).default(SearchCategory.COMPANY),
  order: z.nativeEnum(SortingOrder).default(SortingOrder.Asc),
  query: z.string().optional().default(""),
})

export type SearchParams = z.infer<typeof SearchParamsSchema>

export type CompanySearchParams = {
  category: SearchCategory
  field?: CompanySortField
  order?: SortingOrder
} & { [key in keyof CompanyListFilter]?: CompanyListFilter[key] }

export type ContactSearchParams = {
  category: SearchCategory
  field?: ContactSortField
  order?: SortingOrder
} & { [key in keyof ContactQueryableFields]?: ContactQueryableFields[key] }

export type LocationSearchParams = {
  category: SearchCategory
  field?: LocationSortField
  order?: SortingOrder
} & { [key in keyof LocationListFilter]?: LocationListFilter[key] }
