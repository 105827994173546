import { Box, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"

import type { CompanyDepartment, ContactQueryableFields, ContactSubscription } from "@/graphql/codegen/graphql"
import { DepartmentChips } from "@/screens/Companies/components/filters/contact/DepartmentChip.tsx"
import { StatusChip } from "@/screens/Companies/components/filters/contact/StatusChip.tsx"
import { SubscriptionChips } from "@/screens/Companies/components/filters/SubscriptionChip.tsx"
import { useContactSubscriptionsStore } from "@/screens/Companies/stores/useContactSubscriptionsStore"

export const ContactSearchFilterChips: React.FC = () => {
  const navigate = useNavigate({ from: "/companies/" })
  const { isActive, companyDepartments, contactSubscriptions } = useSearch({
    from: "/companies/",
    strict: true,
  })

  const subscriptions: ContactSubscription[] = useContactSubscriptionsStore((state) => state.contactSubscriptions)

  const handleRemoveFilter = (key: keyof ContactQueryableFields, value: string | CompanyDepartment | boolean) => {
    void navigate({
      search: (prev: Record<string, unknown>) => {
        const newFilter = { ...prev }
        if (Array.isArray(newFilter[key])) {
          newFilter[key] = (newFilter[key] as Array<string | CompanyDepartment | boolean>).filter(
            (item) => item !== value
          )
          if ((newFilter[key] as Array<string | CompanyDepartment | boolean>).length === 0) {
            delete newFilter[key] // Remove the filter entirely if it's empty
          }
        } else {
          delete newFilter[key]
        }
        return newFilter
      },
      replace: true,
    })
  }

  if (isActive === undefined && !companyDepartments && !contactSubscriptions) {
    return null
  }

  return (
    <Box data-testid='contact-search-filter-chips' display='flex' flexWrap='wrap' alignItems='center' gap={2}>
      <Typography variant='body1'>Filters:</Typography>
      <StatusChip isActive={isActive} onRemove={(_, value: boolean) => handleRemoveFilter("isActive", value)} />
      <DepartmentChips
        companyDepartments={companyDepartments}
        onRemove={(_, value: CompanyDepartment) => handleRemoveFilter("companyDepartments", value)}
      />
      <SubscriptionChips
        contactSubscriptions={contactSubscriptions}
        subscriptions={subscriptions}
        onRemove={(_, value: string) => handleRemoveFilter("contactSubscriptions", value)}
      />
    </Box>
  )
}
