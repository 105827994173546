import { useCallback } from "react"
import type { CombinedError, OperationContext, OperationResult } from "urql"
import { useMutation, useQuery } from "urql"

import type { CommentMutationCreateArgs, CommentMutationUpdateArgs, Mutation, Query } from "@/graphql/codegen/graphql"
import {
  CommentListQueryDocument,
  CommentParentTableType,
  CreateCommentMutationDocument,
  UpdateCommentMutationDocument,
} from "@/graphql/codegen/graphql"

export const useGetComments = (
  referenceId: string,
  type?: CommentParentTableType
): {
  commentDetails: Pick<Query, "comment"> | undefined
  commentDetailsFetching: boolean
  commentDetailsError: CombinedError | undefined
  createComment: (
    variables: CommentMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "comment">, CommentMutationCreateArgs>>
  reexecuteCommentList: () => void
  updateComment: (
    variables: CommentMutationUpdateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "comment">, CommentMutationUpdateArgs>>
} => {
  const [
    { data: commentDetails, fetching: commentDetailsFetching, error: commentDetailsError },
    reexecuteCommentListQuery,
  ] = useQuery<Pick<Query, "comment">>({
    query: CommentListQueryDocument,
    variables: { referenceId, parentTableType: type ?? CommentParentTableType.Company },
    pause: !referenceId,
  })

  const reexecuteCommentList = useCallback(() => {
    reexecuteCommentListQuery({ requestPolicy: "network-only" })
  }, [reexecuteCommentListQuery])

  const [, createComment] = useMutation<Pick<Mutation, "comment">, CommentMutationCreateArgs>(
    CreateCommentMutationDocument
  )

  const [, updateComment] = useMutation<Pick<Mutation, "comment">, CommentMutationUpdateArgs>(
    UpdateCommentMutationDocument
  )

  return {
    commentDetails,
    commentDetailsFetching,
    commentDetailsError,
    createComment,
    updateComment,
    reexecuteCommentList,
  }
}
