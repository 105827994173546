import { Badge, Box, Typography } from "@mui/material"
import { type ReactElement } from "react"
import { useForm } from "react-hook-form"

import { PurchaseOrderSection } from "../../types"

import { ProductAddedTable } from "./ProductAddedTable"

import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

export const ProductAddedContainer = (): ReactElement => {
  const productValues = usePurchaseOrderStore((state) => state.formValues[PurchaseOrderSection.ORDER_LINES])

  // React Hook Form setup
  const { watch } = useForm({
    defaultValues: {
      orderLines: productValues || [],
    },
  })

  // Sync form values with the store when the form value changes
  const orderLines = watch("orderLines")

  return (
    <Box className='mt-4 gap-8'>
      <Badge
        badgeContent={orderLines.length}
        className='text-gray-900'
        sx={{
          "& .MuiBadge-badge": {
            right: -15,
            top: 12,
            padding: "0 4px",
            backgroundColor: "#E6FDEE",
          },
        }}
      >
        <Typography variant='h5' className='bg-white text-gray-700'>
          Products Added
        </Typography>
      </Badge>
      <ProductAddedTable />
    </Box>
  )
}
