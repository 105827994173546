import type {
  Company,
  CompanyLocationAssociation,
  Country,
  GetFileInfoInput,
  Location,
  Product,
  SignedUrl,
  UnitOfMeasurement,
} from "@/graphql/codegen/graphql"
import {
  FieldGroupEnum,
  ProductCommpanyLocationAssociationsDocument,
  ProductCompanyInformationDocument,
  ProductCompanyLocationsDocument,
  ProductFormDetailsDocument,
  ReferenceTableName,
} from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { useCountriesStore } from "@/stores/countriesStore"
import { useUnitOfMeasurementsStore } from "@/stores/unitOfMeasurmentsStore"

const getProductCompanyInformation = async (productId: number) => {
  return queryClient.query(ProductCompanyInformationDocument, { productId })
}

const getProductCompanyLocationAssociations = async (companyId: string) => {
  return queryClient.query(ProductCommpanyLocationAssociationsDocument, { companyId })
}

const getCompanyLocations = async (companyId: string) => {
  return queryClient.query(ProductCompanyLocationsDocument, { companyId })
}

const getProductFormDetails = async (productId: number) => {
  const fileVariables: GetFileInfoInput = {
    referenceId: productId.toString(),
    referenceTableName: ReferenceTableName.Product,
    fieldGroups: [FieldGroupEnum.Product, FieldGroupEnum.Package, FieldGroupEnum.Pallet],
  }
  return queryClient.query(ProductFormDetailsDocument, { productId, fileInfo: fileVariables })
}

export const getLatestSignedUrls = (signedUrls: SignedUrl[]): SignedUrl[] => {
  return signedUrls.reduce((latestUrls, currentUrl) => {
    // Get the field group for the current URL
    const fieldGroup = currentUrl.file?.fieldGroup
    if (!fieldGroup) return latestUrls

    // Check if we already have a URL for this field group
    const existingUrl = latestUrls.find((url) => url.file?.fieldGroup === fieldGroup)
    if (!existingUrl) {
      // If no existing URL for this field group, add the current one
      return [...latestUrls, currentUrl]
    }

    // Convert creation dates to Date objects, defaulting to epoch if missing
    const currentDate = currentUrl.file?.createdOn ? new Date(currentUrl.file.createdOn) : new Date(0)
    const existingDate = existingUrl.file?.createdOn ? new Date(existingUrl.file.createdOn) : new Date(0)

    // If current URL is newer, replace the existing one for this field group
    if (currentDate > existingDate) {
      return [...latestUrls.filter((url) => url.file?.fieldGroup !== fieldGroup), currentUrl]
    }

    // Keep existing URL if it's newer
    return latestUrls
  }, [] as SignedUrl[])
}

// Sort in following order: Product, Package, Pallet
export const sortProductFormSignedUrls = (signedUrls: SignedUrl[]): SignedUrl[] => {
  return signedUrls.sort((a, b) => {
    const order = [FieldGroupEnum.Product, FieldGroupEnum.Package, FieldGroupEnum.Pallet]
    return (
      order.indexOf(a.file?.fieldGroup ?? FieldGroupEnum.Product) -
      order.indexOf(b.file?.fieldGroup ?? FieldGroupEnum.Product)
    )
  })
}

export const aggregateProductFormSignedUrls = (signedUrls: SignedUrl[]): SignedUrl[] => {
  return sortProductFormSignedUrls(getLatestSignedUrls(signedUrls))
}

export interface ProductInformationLoaderResult {
  productId: number
  company: Company | null
  units: UnitOfMeasurement[]
  shippingLocations: CompanyLocationAssociation[]
  companyLocations: Location[]
  countries: Country[]
  productForm: Pick<Product, "productFormInfo" | "uomId"> & { signedUrls: SignedUrl[] }
}

export const productInformationLoader = async ({
  params,
}: {
  params: { productId: string }
}): Promise<ProductInformationLoaderResult> => {
  const { productId } = params
  const [units, countries, productCompanyIdInfo, productFormDetails] = await Promise.all([
    useUnitOfMeasurementsStore.getState().getUnits(),
    useCountriesStore.getState().getCountries(),
    getProductCompanyInformation(Number(productId)),
    getProductFormDetails(Number(productId)),
  ])

  const company =
    productCompanyIdInfo.data?.product.get.__typename === "ProductGetSuccess"
      ? (productCompanyIdInfo.data.product.get.product.company as Company | null)
      : null

  const signedUrls =
    productFormDetails.data?.fileUpload.getFileInfo.__typename === "GetFileInfoSuccess"
      ? aggregateProductFormSignedUrls(productFormDetails.data.fileUpload.getFileInfo.signedUrls)
      : []

  const productForm = {
    ...(productFormDetails.data?.product.get.__typename === "ProductGetSuccess"
      ? productFormDetails.data.product.get.product
      : null),
    signedUrls,
  }

  let shippingLocations: CompanyLocationAssociation[] = []
  let companyLocations: Location[] = []

  if (company) {
    const [locationAssociations, locationsResponse] = await Promise.all([
      getProductCompanyLocationAssociations(company.companyId),
      getCompanyLocations(company.companyId),
    ])

    shippingLocations =
      locationAssociations.data?.company.get.__typename === "CompanyGetSuccess"
        ? (locationAssociations.data.company.get.company.locationsAssociations as CompanyLocationAssociation[])
        : []

    companyLocations =
      locationsResponse.data?.location.list.__typename === "LocationListSuccess"
        ? ((locationsResponse.data.location.list.locations as Location[]) ?? [])
        : []
  }

  return {
    productId: parseInt(productId, 10),
    company,
    units,
    shippingLocations,
    companyLocations,
    countries,
    productForm,
  }
}
