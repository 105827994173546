import { type ReactElement } from "react"

import { FileIconTemplate } from "./FileIconTemplate"

interface GIFIconProps {
  className?: string
}

export const GIFIcon = ({ className }: GIFIconProps): ReactElement => (
  <FileIconTemplate
    className={className}
    text='GIF'
    stripeColor='#9C27B0' // Purple
  />
)
