import { Box, Divider, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import type { UseFormProps } from "react-hook-form"

import { StatusFilter } from "@/components/common/Filters/StatusFilter"
import type { LocationListFilter } from "@/graphql/codegen/graphql.ts"
import { CompanyStatus } from "@/graphql/codegen/graphql.ts"

const statusList: CompanyStatus[] = [CompanyStatus.Active, CompanyStatus.Inactive]
const statusKey: keyof LocationListFilter = "isLocationActive"

export type FormValues = LocationListFilter & {
  isLocationActive: CompanyStatus | CompanyStatus[]
}

export const LocationSearchFilter: FC = () => {
  const navigate = useNavigate({ from: "/companies/" })
  const search = useSearch({ from: "/companies/", strict: true })

  const { isLocationActive } = search

  const defaultValues = useMemo(
    () => ({
      isLocationActive:
        isLocationActive === undefined ? [] : isLocationActive ? CompanyStatus.Active : CompanyStatus.Inactive,
    }),
    [isLocationActive]
  )

  const methods = useForm<FormValues>({
    values: defaultValues,
  } as UseFormProps<FormValues>)

  const handleFilterChange = useCallback(
    (name: keyof LocationListFilter, value: unknown) => {
      if (name === "isLocationActive") {
        const isActiveValue =
          (value as CompanyStatus[]).length === 1 ? (value as CompanyStatus[])[0] === CompanyStatus.Active : undefined
        navigate({
          search: (prev: Record<string, any>) => ({
            ...prev,
            isLocationActive: isActiveValue,
          }),
          replace: true,
        })
        return
      }

      navigate({
        search: (prev: Record<string, any>) => ({
          ...prev,
          [name]: value,
        }),
        replace: true,
      })
    },
    [navigate]
  )

  return (
    <FormProvider {...methods}>
      <Box className='flex h-full flex-col gap-y-2'>
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Location Status
          </Typography>
          <Divider />
          <StatusFilter
            name={statusKey}
            data={statusList}
            control={methods.control}
            onFilterChange={handleFilterChange}
          />
        </Box>
      </Box>
    </FormProvider>
  )
}
