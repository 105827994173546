import { type ReactElement, type ReactNode } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

interface DragDropProviderProps {
  children: ReactNode
}

export const DragDropProvider = ({ children }: DragDropProviderProps): ReactElement => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
}
