import type { SelectChangeEvent } from "@mui/material"
import { Box, Chip, Select } from "@mui/material"
import { KeyboardArrowDownOutlined, MoreHorizOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent, ReactNode } from "react"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"

type MultiSelectProps = {
  name: string
  label: string
  placeholder?: string
  control: Control
  children?: ReactNode
  required?: boolean
}

// TODO: Move this component to storybook and refactor the changes
const MultiSelect: FunctionComponent<MultiSelectProps> = (props: MultiSelectProps) => {
  const { name, control, children, label, placeholder, required } = props
  const {
    field,
    fieldState: { error },
  } = useController({ name, control })

  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)
  const [visibleChips, setVisibleChips] = useState<string[]>([])
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const updateVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 40 // 32 is the width of the MoreHorizOutlined icon
      let totalWidth = 0
      const visibleChipsArray: string[] = []

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(field.value[index])
          totalWidth += chipWidth
        }
      })
      setVisibleChips(visibleChipsArray)
      setIsOverflowing(visibleChipsArray.length < field.value.length)
    }
  }, [field.value])

  useEffect(() => {
    updateVisibleChips()
  }, [field.value])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[]
    field.onChange(selectedIds)
  }

  return (
    <Box>
      <label className='mb-2 block text-sm font-normal text-gray-700'>
        {label}
        {required && <span className='text-red-600'>*</span>}
      </label>
      <Select
        id={name}
        multiple
        fullWidth
        placeholder={placeholder}
        IconComponent={KeyboardArrowDownOutlined}
        renderValue={() => (
          <Box className='flex overflow-hidden align-middle' ref={containerRef}>
            <Box className='flex flex-nowrap gap-1'>
              {visibleChips.map((value) => {
                return value ? <Chip key={value} label={value} className='rounded-sm' /> : null
              })}
            </Box>
            {isOverflowing && (
              <Box className='mx-1 flex size-8 items-center justify-center rounded bg-gray-300'>
                <MoreHorizOutlined className='ml-0 shrink-0' />
              </Box>
            )}
          </Box>
        )}
        className='mb-2 h-10 border-none hover:border-gray-400'
        value={field.value.map((value) => value)}
        onChange={handleChange}
      >
        {children}
      </Select>
      {error && <span className='text-sm text-red-600'>{error.message}</span>}
      <Box className='invisible absolute' ref={measureRef}>
        {field.value.map((value) => (
          <Chip key={value} label={value} />
        ))}
      </Box>
    </Box>
  )
}

export default memo(MultiSelect)
