import type { AnyRoute } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"

import WorkInProgressAlert from "@/components/common/Alerts/WorkInProgressAlert.tsx"

const queryLocationById = (locationId: string) => ({ locationId })

export const Route: AnyRoute = createFileRoute("/companies/$companyId/edit/_layout/locations/$locationId")({
  loader:
    () =>
    ({ params: { locationId } }: { params: { locationId: string } }) =>
      queryLocationById(locationId),
  component: () => <WorkInProgressAlert title='Location Information. Work in Progress' />,
})
