import { Chip } from "@mui/material"
import type { Maybe } from "graphql/jsutils/Maybe"
import type React from "react"
import { twMerge } from "tailwind-merge"

import { OrderStatus } from "@/graphql/codegen/graphql"

export interface GetStatusDisplayPropertiesParams {
  status?: Maybe<OrderStatus>
}

export type StatusChipColor = "warning" | "success" | "error" | "default" | "primary" | "secondary" | "info" | undefined

export interface StatusDisplayProperties {
  color: StatusChipColor
  label: string | null
}

export type GetStatusDisplayProperties = (params: GetStatusDisplayPropertiesParams) => StatusDisplayProperties

const getStatusDisplayProperties: GetStatusDisplayProperties = ({ status }) => {
  switch (status) {
    case OrderStatus.Draft:
      return {
        color: "warning",
        label: "Draft",
      }
    case OrderStatus.Sent:
      return {
        color: "success",
        label: "Sent",
      }
    case OrderStatus.Confirmed:
      return {
        color: "primary",
        label: "Confirmed",
      }
    case OrderStatus.Closed:
      return {
        color: "error",
        label: "Closed",
      }
    default:
      return {
        color: undefined,
        label: null,
      }
  }
}

interface OrderStatusChipProps {
  status?: Maybe<OrderStatus>
  className?: string
}

export const OrderStatusChip: React.FC<OrderStatusChipProps> = ({ status, className, ...rest }) => {
  const { color, label } = getStatusDisplayProperties({ status })

  return (
    !!label && (
      <Chip
        {...rest}
        size='small'
        className={twMerge("bg-transparent text-xs text-gray-800", className)}
        variant='outlined'
        color={color}
        label={label}
      />
    )
  )
}
