import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Fade } from "@mui/material"
import { useParams } from "@tanstack/react-router"
import { useState } from "react"
import type { FunctionComponent } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { z } from "zod"

import EditAssociatedLocations from "../../edit/components/EditAssociatedLocations"
import { useGetAssociatedLocations } from "../../hooks/useGetAssociatedLocations"

import AssociatedLocation from "./AssociatedLocation"
import AssociatedLocationsTable from "./AssociatedLocationsTable"

import type {
  CompanyLocationAssociationCreateInput,
  CompanyLocationAssociationOutput,
  CompanyLocationAssociationUpdateInput,
} from "@/graphql/codegen/graphql"

const AssociatedLocationSchema = z.object({
  shipping: z.string().min(1, "Select shipping"),
  billing: z.string().min(1, "Select billing"),
  order: z.string().min(1, "Select order"),
  office: z.string().optional(),
  shipVia: z.string().optional(),
})

type AssociatedLocationContainerProps = {
  associatedLocationList: CompanyLocationAssociationOutput[]
  showFields: boolean
  onAddFields: (showFields: boolean) => void
  reexecuteAssociatedLocationQuery: () => void
}
const AssociatedLocationContainer: FunctionComponent<AssociatedLocationContainerProps> = ({
  associatedLocationList,
  showFields,
  onAddFields,
  reexecuteAssociatedLocationQuery,
}) => {
  const { companyId } = useParams({ from: "/companies/$companyId/edit" })
  const [showEditLocationDialog, setShowEditLocationDialog] = useState<boolean>(false)
  const [activeEditLocation, setActiveEditLocation] = useState<CompanyLocationAssociationOutput | undefined>()
  const methods = useForm({
    resolver: zodResolver(AssociatedLocationSchema),
    defaultValues: {
      shipping: "",
      billing: "",
      order: "",
      office: "",
      shipVia: "",
    },
    mode: "all",
  })

  const {
    createCompanyLocationAssociation,
    locations,
    companyNameList,
    loadMore,
    updateCompanyLocationAssociation,
    fetching,
  } = useGetAssociatedLocations({
    companyId,
  })

  const { setError, handleSubmit } = methods

  const onSubmit = handleSubmit(async (data) => {
    const formattedData: CompanyLocationAssociationCreateInput = {
      companyId,
      shipping: {
        locationId: data.shipping,
      },
      billing: {
        locationId: data.billing,
      },
      order: {
        locationId: data.order,
      },
      ...(data.shipVia && {
        shippingVia: {
          companyId: data.shipVia,
        },
      }),
      ...(data.office && {
        office: {
          locationId: data.office,
        },
      }),
    }

    const { error } = await createCompanyLocationAssociation({ input: formattedData })
    onAddFields(false)
    if (error) {
      // Handle any errors that might occur during the associating a location with a company
      setError("shipping", {
        type: "server",
        message: error.message,
      })
      onAddFields(false)
    }
    methods.reset()
    reexecuteAssociatedLocationQuery()
  })

  const onEditSubmit = handleSubmit(async (data) => {
    const formattedData: CompanyLocationAssociationUpdateInput = {
      companyId,
      companyLocationAssociationId: activeEditLocation?.companyLocationAssociationId ?? "",
      shipping: {
        locationId: data.shipping,
      },
      order: {
        locationId: data.order,
      },
      billing: {
        locationId: data.billing,
      },
      ...(data.shipVia && {
        shippingVia: {
          companyId: data.shipVia,
        },
      }),
      ...(data.office && {
        office: {
          locationId: data.office,
        },
      }),
    }

    const { error } = await updateCompanyLocationAssociation({ input: formattedData })
    onAddFields(false)
    if (error) {
      // Handle any errors that might occur during the associating a location with a company
      setError("shipping", {
        type: "server",
        message: error.message,
      })
      onAddFields(false)
    }
    setShowEditLocationDialog(false)
    methods.reset()
    setActiveEditLocation(undefined)
    reexecuteAssociatedLocationQuery()
  })

  return (
    <FormProvider {...methods}>
      <Box className='h-full overflow-y-auto'>
        <Fade in={!fetching} timeout={500}>
          <div>
            {showFields && (
              <AssociatedLocation
                loadMore={loadMore}
                locations={locations}
                companyNameList={companyNameList}
                onSubmit={onSubmit}
              />
            )}
            <AssociatedLocationsTable
              locations={locations}
              companyNameList={companyNameList}
              associatedLocationList={associatedLocationList}
              onEditClick={(associatedLocation) => {
                methods.reset({
                  shipping: associatedLocation?.shipping.locationId,
                  billing: associatedLocation?.billing.locationId,
                  order: associatedLocation?.order?.locationId,
                  office: associatedLocation?.office?.locationId,
                  shipVia: associatedLocation?.shippingVia?.companyId,
                })
                setActiveEditLocation(associatedLocation)
                setShowEditLocationDialog(true)
              }}
            />
            <EditAssociatedLocations
              open={showEditLocationDialog}
              onClose={() => {
                methods.reset()
                setActiveEditLocation(undefined)
                setShowEditLocationDialog(false)
              }}
              locations={locations}
              companyNameList={companyNameList}
              loadMore={loadMore}
              onSubmit={onEditSubmit}
            />
          </div>
        </Fade>
      </Box>
    </FormProvider>
  )
}

export default AssociatedLocationContainer
