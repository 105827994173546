import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { ProductInstruction, ProductInstructionListSuccess, Query } from "@/graphql/codegen/graphql"
import { ProductInstructionsListDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20

export const useGetInstructions = (): {
  instructionsListError: CombinedError | undefined
  instructionsList: ProductInstruction[]
  instructionsListFetching: boolean
  triggerQuery: () => void
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [instructionsList, setInstructionsList] = useState<ProductInstruction[]>([])
  const queryVars = {
    input: {
      filter: { query: "" },
      offset,
      limit: LIMIT,
    },
  }

  const [
    { data: instructionDetails, fetching: instructionDetailsFetching, error: instructionDetailsError },
    queryCategories,
  ] = useQuery<Pick<Query, "product">>({
    query: ProductInstructionsListDocument,
    variables: queryVars,
  })

  useEffect(() => {
    if (instructionDetails) {
      const instructions = (instructionDetails.product.listProductInstruction as ProductInstructionListSuccess)
        .productInstructions
      setInstructionsList((prev) => (offset === 0 ? instructions : [...prev, ...instructions]))
    }
  }, [instructionDetails, offset])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const triggerQuery = useCallback(() => queryCategories(), [queryCategories])

  return {
    instructionsList,
    instructionsListFetching: instructionDetailsFetching,
    instructionsListError: instructionDetailsError,
    loadMore,
    triggerQuery,
  }
}
