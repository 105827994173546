import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { ListPricingPackagesSuccess, PricingPackage, Query } from "@/graphql/codegen/graphql"
import { PricingPackageListQueryDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20

export const useGetPricingPackage = ({
  productId,
  companyId,
  sourceId,
}: {
  productId: string
  companyId: string
  sourceId: string
}): {
  pricingListError: CombinedError | undefined
  pricingList: PricingPackage[]
  pricingListFetching: boolean
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [pricingList, setPricingList] = useState<PricingPackage[]>([])

  const [{ data: pricingListDetails, fetching: pricingListFetching, error: pricingListError }] = useQuery<
    Pick<Query, "pricingPackage">
  >({
    query: PricingPackageListQueryDocument,
    variables: {
      input: {
        filter: {
          productId,
          companyId,
          sourceCompanyLocationAssociationId: sourceId,
          destinationCompanyLocationAssociationId: null,
        },
        limit: LIMIT,
        offset,
      },
    },
  })

  useEffect(() => {
    if (pricingListDetails) {
      const pricingPackagesList = (pricingListDetails?.pricingPackage?.list as ListPricingPackagesSuccess)
        .pricingPackages
      setPricingList((prev) => (offset === 0 ? pricingPackagesList : [...prev, ...pricingPackagesList]))
    }
  }, [pricingList, offset, pricingListDetails])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  return {
    pricingList,
    pricingListFetching,
    pricingListError,
    loadMore,
  }
}
