import { Box, Typography } from "@mui/material"
import type { FC } from "react"
import { useFormContext } from "react-hook-form"

import type { ContactFields } from "../types/dialogProps"

import { PhoneNumberInput } from "@/components/common/Phone/PhoneInput"

export const LocationContactMethodForm: FC = () => {
  const { control } = useFormContext<ContactFields>()

  return (
    <form className='space-y-6'>
      <Typography variant='body1' fontWeight={700} className='pb-10 leading-6 tracking-[0.15px] text-gray-700'>
        Contact Method
      </Typography>
      <Box className='space-y-10'>
        <PhoneNumberInput
          inputProps={{ "data-testid": "phone-input" }}
          control={control}
          label='Phone'
          name='phoneNumber'
        />
        <PhoneNumberInput
          inputProps={{ "data-testid": "alt-phone-input" }}
          control={control}
          label='Alt Phone'
          name='altPhoneNumber'
        />
        <PhoneNumberInput inputProps={{ "data-testid": "fax-input" }} control={control} label='Fax' name='faxNumber' />
      </Box>
    </form>
  )
}
