import type { AvatarProps as MaterialAvatarProps } from "@mui/material"
import { Avatar as AvatarComponent } from "@mui/material"
import type { ReactElement } from "react"
import { z } from "zod"

const sizeSchema = z.union([z.literal(18), z.literal(24), z.literal(32), z.literal(40)])
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avatarPropsSchema = z.object({
  size: sizeSchema.optional().default(32),
  variant: z.enum(["circular", "rounded", "square"]).optional(),
})
type AvatarProps = z.infer<typeof avatarPropsSchema> & MaterialAvatarProps

export const Avatar = ({ size = 32, children, sx, ...restProps }: AvatarProps): ReactElement => {
  return (
    <AvatarComponent sx={{ ...sx, width: `${size}px`, height: `${size}px` }} {...restProps}>
      {children}
    </AvatarComponent>
  )
}
