import { useCallback, useRef } from "react"

export const useThrottle = <T extends (...args: any[]) => any>(callback: T, delay: number): [T] => {
  const lastExecuted = useRef<number>(0)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const throttledCallback = useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now()
      if (now - lastExecuted.current >= delay) {
        lastExecuted.current = now
        callback(...args)
      } else {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(
          () => {
            lastExecuted.current = Date.now()
            callback(...args)
          },
          delay - (now - lastExecuted.current)
        )
      }
    },
    [callback, delay]
  ) as T

  return [throttledCallback]
}
