import React, { useMemo, useState } from "react"

import { type Country, type Location, type Region } from "@/graphql/codegen/graphql"
import CancelLocationModal from "@/screens/Companies/components/modals/CancelLocationModal"
import LocationAddedModal from "@/screens/Companies/create/components/LocationAddedModal"
import { AddAddressDialog } from "@/screens/Companies/edit/components/locations/dialogs/AddAddressDialog"
import { AddContactDialog } from "@/screens/Companies/edit/components/locations/dialogs/AddContactDialog"
import { AddNotesDialog } from "@/screens/Companies/edit/components/locations/dialogs/AddNotesDialog"
import { EditAddressDialog } from "@/screens/Companies/edit/components/locations/dialogs/EditAddressDialog"
import { EditContactDialog } from "@/screens/Companies/edit/components/locations/dialogs/EditContactDialog"
import { EditNotesDialog } from "@/screens/Companies/edit/components/locations/dialogs/EditNotesDialog"
import {
  LocationConfirmationDialogs,
  LocationMenuDialogs,
} from "@/screens/Companies/edit/components/locations/types/dialogs"
import type {
  AddAddressQueryProps,
  AddContactQueryProps,
  AddNotesQueryProps,
  AddressFields,
  ContactFields,
  DialogCloseAction,
  EditAddressQueryProps,
  EditContactQueryProps,
  EditNotesQueryProps,
  NotesSchema,
} from "@/screens/Companies/types/dialogProps"

interface LocationTableDialogsProps {
  openedDialog: LocationMenuDialogs | LocationConfirmationDialogs | null
  activeEditLocation: Location | undefined
  countryList: Country[]
  regionList: Region[]
  setOpenedDialog: (dialog: LocationMenuDialogs | LocationConfirmationDialogs | null) => void
  handleCreateSubmit: (data: AddAddressQueryProps) => void
  handleUpdateSubmit: (
    data:
      | EditContactQueryProps
      | EditAddressQueryProps
      | EditNotesQueryProps
      | AddContactQueryProps
      | AddNotesQueryProps
  ) => void
  prevOpenedDialog: LocationMenuDialogs | LocationConfirmationDialogs | null
}

const cancelMessages = {
  [LocationMenuDialogs.ADD_ADDRESS]: {
    title: "Are you sure you want to cancel adding a location?",
    description:
      "By cancelling now this location will not be added to the company. Click on “Go Back” if this action was a mistake.",
  },
  [LocationMenuDialogs.ADD_CONTACT]: {
    title: "Are you sure you want to cancel adding a contact?",
    description:
      "By cancelling now this contact will not be added to the company. Click on “Go Back” if this action was a mistake.",
  },
  [LocationMenuDialogs.ADD_NOTES]: {
    title: "Are you sure you want to cancel adding a note?",
    description:
      "By cancelling now this note will not be added to the company. Click on “Go Back” if this action was a mistake.",
  },
  [LocationMenuDialogs.EDIT_ADDRESS]: {
    title: "Are you sure you want to cancel editing a location?",
    description:
      "By cancelling now this location will not be updated. Click on “Go Back” if this action was a mistake.",
  },
  [LocationMenuDialogs.EDIT_CONTACT]: {
    title: "Are you sure you want to cancel editing a contact?",
    description: "By cancelling now this contact will not be updated. Click on “Go Back” if this action was a mistake.",
  },
  [LocationMenuDialogs.EDIT_NOTES]: {
    title: "Are you sure you want to cancel editing a note?",
    description: "By cancelling now this note will not be updated. Click on “Go Back” if this action was a mistake.",
  },
}

export const LocationTableDialogs: React.FC<LocationTableDialogsProps> = ({
  openedDialog,
  activeEditLocation,
  countryList,
  regionList,
  handleCreateSubmit,
  handleUpdateSubmit,
  setOpenedDialog,
  prevOpenedDialog,
}) => {
  const [dialogData, setDialogData] = useState<AddressFields | ContactFields | NotesSchema | Record<string, any>>({})

  const handleSuccessDialog = (action: "addMore" | DialogCloseAction) => {
    if (action === "addMore") {
      setOpenedDialog(LocationMenuDialogs.ADD_ADDRESS)
      return
    }

    setOpenedDialog(null)
  }

  const handleEditDialogClose = (reason: DialogCloseAction) => {
    if (reason === "success" || reason === "close") {
      setOpenedDialog(null)
      return
    }

    if (reason === "cancel") {
      setOpenedDialog(LocationConfirmationDialogs.CANCEL)
    }
  }

  const handleAddDialogClose = ({
    data,
    reason,
  }: {
    data: AddressFields | ContactFields | NotesSchema | Record<string, any>
    reason: DialogCloseAction
  }) => {
    if (reason === "success" || reason === "close") {
      setOpenedDialog(null)
      return
    }

    if (reason === "cancel") {
      setDialogData(data)
      setOpenedDialog(LocationConfirmationDialogs.CANCEL)
    }
  }

  const handleCancelDialogClose = (reason: DialogCloseAction) => {
    if (reason === "cancel") {
      setOpenedDialog(prevOpenedDialog)
      return
    }

    setOpenedDialog(null)
    setDialogData({})
  }

  const messages = useMemo(
    () => cancelMessages[prevOpenedDialog as LocationMenuDialogs] ?? { title: "", description: "" },
    [prevOpenedDialog]
  )

  return (
    <>
      <LocationAddedModal onClose={handleSuccessDialog} open={openedDialog === LocationConfirmationDialogs.SUCCESS} />
      <CancelLocationModal
        messages={messages}
        onClose={handleCancelDialogClose}
        open={openedDialog === LocationConfirmationDialogs.CANCEL}
      />
      <AddAddressDialog
        data={dialogData as AddressFields}
        open={openedDialog === LocationMenuDialogs.ADD_ADDRESS}
        onClose={handleAddDialogClose}
        onSubmit={handleCreateSubmit}
      />
      <AddContactDialog
        data={dialogData as ContactFields}
        open={openedDialog === LocationMenuDialogs.ADD_CONTACT}
        onClose={handleAddDialogClose}
        onSubmit={handleUpdateSubmit}
        addressDetails={activeEditLocation as Location}
        countryList={countryList}
        regionList={regionList}
      />
      <AddNotesDialog
        data={dialogData as NotesSchema}
        open={openedDialog === LocationMenuDialogs.ADD_NOTES}
        onClose={handleAddDialogClose}
        onSubmit={handleUpdateSubmit}
      />
      <EditAddressDialog
        open={openedDialog === LocationMenuDialogs.EDIT_ADDRESS}
        activeEditLocation={activeEditLocation}
        openedDialog={openedDialog}
        onClose={handleEditDialogClose}
        onSubmit={handleUpdateSubmit}
      />
      <EditContactDialog
        open={openedDialog === LocationMenuDialogs.EDIT_CONTACT}
        activeEditLocation={activeEditLocation}
        openedDialog={openedDialog}
        onClose={handleEditDialogClose}
        onSubmit={handleUpdateSubmit}
      />
      <EditNotesDialog
        open={openedDialog === LocationMenuDialogs.EDIT_NOTES}
        activeEditLocation={activeEditLocation}
        openedDialog={openedDialog}
        onClose={handleEditDialogClose}
        onSubmit={handleUpdateSubmit}
      />
    </>
  )
}
