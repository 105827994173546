import type { SelectChangeEvent } from "@mui/material"
import { Box, FormControl, InputAdornment, MenuItem, Select, TextField } from "@mui/material"
import { FilterAltOutlined, KeyboardArrowDownOutlined, SearchOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ChangeEvent, ReactElement, ReactNode } from "react"
import React, { memo, useMemo } from "react"
import { twMerge } from "tailwind-merge"

import { Button } from "@/components/common/Button"
import { CompanySortField, ContactSortField, LocationSortField } from "@/graphql/codegen/graphql.ts"
import { SearchCategory } from "@/screens/Companies/types/enums.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"

type CompanySearchBarProps = {
  onShowFilter: () => void
}

const SelectIcon = memo(({ className }: { className: string }) => (
  <KeyboardArrowDownOutlined className={`text-gray-500 ${className}`} />
))

const hintMessageMap = new Map<SearchCategory, string>([
  [SearchCategory.COMPANY, "Search by Company Name or ID"],
  [SearchCategory.CONTACT, "Search by Contact’s Name"],
  [SearchCategory.LOCATION, "Search by Location name, Address, Company name"],
])

export const CompaniesSearchBar = ({ onShowFilter }: CompanySearchBarProps): ReactElement => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/companies/" })
  const searchHint = useMemo(() => hintMessageMap.get(currentSearch.category), [currentSearch.category])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const queryValue = e.target.value

    const updatedSearchParams = getRefinedSearchParams({
      ...currentSearch,
      query: queryValue,
    })

    void navigate(updatedSearchParams)
  }

  const handleSearchCategoryDropdownChange = (event: SelectChangeEvent<{ value: SearchCategory }>, _: ReactNode) => {
    const category = event.target.value as SearchCategory
    let field: CompanySortField | ContactSortField | LocationSortField = CompanySortField.Name
    switch (category) {
      case SearchCategory.COMPANY:
        field = CompanySortField.Name
        break
      case SearchCategory.CONTACT:
        field = ContactSortField.CompanyName
        break
      case SearchCategory.LOCATION:
        field = LocationSortField.LocationName
        break
      default:
        break
    }
    const updatedSearchParams = getRefinedSearchParams({
      category,
      field,
      order: currentSearch.order,
    })
    void navigate(updatedSearchParams)
  }

  return (
    <>
      <Box className='flex space-x-4'>
        <div className='flex w-full max-w-lg items-center overflow-hidden rounded-lg border border-gray-400'>
          <FormControl className='border-r border-gray-300'>
            <Select
              data-testid='search-category-select'
              value={currentSearch?.category ?? SearchCategory.COMPANY}
              className='!min-h-[inherit] rounded-r-none border-r border-gray-400 !bg-white !px-3 !font-normal !text-gray-600'
              inputProps={{
                className: twMerge("py-2 pr-6 pl-2", "bg-transparent text-gray-700 font-thin"),
              }}
              IconComponent={SelectIcon}
              variant='outlined'
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              onChange={handleSearchCategoryDropdownChange}
            >
              <MenuItem data-testid='company-option' value={SearchCategory.COMPANY}>
                Company
              </MenuItem>
              <MenuItem data-testid='location-option' value={SearchCategory.LOCATION}>
                Location
              </MenuItem>
              <MenuItem data-testid='contact-option' value={SearchCategory.CONTACT}>
                Contact
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            data-testid='search-input'
            defaultValue={currentSearch?.query}
            variant='outlined'
            placeholder={searchHint}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchOutlined className='text-gray-600' />
                </InputAdornment>
              ),
              classes: {
                notchedOutline: "border-0",
                root: "flex items-center justify-center gap-2 rounded border-none px-4 font-normal text-black hover:outline-none",
                input:
                  "border-none outline-none hover:border-none hover:outline-none focus:border-none focus:outline-none focus:ring-0 px-0 font-thin text-ellipsis",
              },
              onChange: handleInputChange,
            }}
            className='flex-1'
          />
        </div>
        <Button aria-label='filter' appearance='outlined' onClick={onShowFilter} startIcon={<FilterAltOutlined />}>
          Filter
        </Button>
      </Box>
    </>
  )
}

export default CompaniesSearchBar
