import { useRouter } from "@tanstack/react-router"
import { useEffect, useMemo, useState } from "react"
import { useShallow } from "zustand/shallow"

import { useUpdatePO } from "../DeliveryTermsAndShippingLegs/hooks/useUpdatePO"
import { PurchaseOrderAction } from "../types"

import type { Order } from "@/graphql/codegen/graphql"
import { OrderStatus, OrderUpdateInputSchema } from "@/graphql/codegen/graphql"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

export const usePurchaseOrderActions = (): {
  status: OrderStatus
  updatedPOQueryData: Partial<Order>
  fetching: boolean
  handleSaveDraft: () => OrderStatus
  handleSaveAndExit: () => OrderStatus
  handleCancel: () => OrderStatus
  handleSend: () => OrderStatus
} => {
  const router = useRouter()
  const [lastAction, setLastAction] = useState<PurchaseOrderAction | null>(null)
  const { updatePO, updatedPO: updatedPOQueryData, fetching } = useUpdatePO()
  const currentPO = usePurchaseOrderStore(useShallow((state) => state.currentPO))
  const modifiedPO = usePurchaseOrderStore(useShallow((state) => state.modifiedFields))
  const { orderId, status, sentDate } = currentPO!

  const updatedPO = useMemo(() => {
    const rawInput = {
      orderId: Number(orderId),
      status,
      sentDate,
      deliveryLocationId: modifiedPO.DELIVERY_TERMS?.deliveryLocationId ?? currentPO?.destinationLocationAssociationId,
      deliveryTerms: modifiedPO.DELIVERY_TERMS?.deliveryTerms ?? currentPO?.deliveryTerms,
      freightTerms: modifiedPO.DELIVERY_TERMS?.freightTerms ?? currentPO?.freightTerms,
      shipViaCompanyId: modifiedPO.DELIVERY_TERMS?.shipViaCompanyId ?? currentPO?.shipViaCompanyId,
      shipStatus: modifiedPO.DELIVERY_TERMS?.shipStatus ?? currentPO?.shipStatus,
      shippingLegs: modifiedPO.SHIPPING_LEGS?.shippingLegs ?? currentPO?.shippingLegs,
      note: modifiedPO.NOTES?.note ?? currentPO?.note,
      orderLines: modifiedPO.ORDER_LINES?.orderLines ?? currentPO?.orderLines,
    }

    return OrderUpdateInputSchema().parse(rawInput)
  }, [modifiedPO, orderId, status])

  const handleSaveDraft = (): OrderStatus => {
    const input = OrderUpdateInputSchema().parse({
      ...updatedPO,
      status: OrderStatus.Draft,
    })
    updatePO({ input })
    setLastAction(PurchaseOrderAction.SAVE_DRAFT)
    return OrderStatus.Draft
  }

  const handleSaveAndExit = () => {
    const input = OrderUpdateInputSchema().parse(updatedPO)
    updatePO({ input })
    setLastAction(PurchaseOrderAction.SAVE_AND_EXIT)
    return OrderStatus.Draft
  }

  const handleCancel = () => {
    const input = OrderUpdateInputSchema().parse(updatedPO)
    updatePO({ input }) // FIXME: status need to be updated on BE and has to be "Cancelled"
    setLastAction(PurchaseOrderAction.CANCEL)
    return OrderStatus.Cancelled
  }

  const handleSend = () => {
    const input = OrderUpdateInputSchema().parse({
      ...updatedPO,
      status: OrderStatus.Sent,
      sentDate: new Date().toISOString(),
    })
    updatePO({ input })
    setLastAction(PurchaseOrderAction.SEND)
    return OrderStatus.Sent
  }

  useEffect(() => {
    if (
      lastAction &&
      [PurchaseOrderAction.SAVE_AND_EXIT, PurchaseOrderAction.CANCEL].includes(lastAction) &&
      !fetching
    ) {
      router.history.back()
    }
  }, [updatedPOQueryData, lastAction, fetching])

  return {
    lastAction,
    status,
    updatedPOQueryData,
    fetching,
    handleSaveDraft,
    handleSaveAndExit,
    handleCancel,
    handleSend,
    router,
  }
}
