import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { ChangeEvent, ReactElement } from "react"
import { useMemo } from "react"
import { Controller, useForm } from "react-hook-form"

import { LabelSizeUploader } from "./LabelSizeUploader"
import {
  type LabelInformation,
  type LabelInformationFormData,
  createLabelByPattern,
  mapPatternUrlsToSizes,
} from "./types"

import { Button } from "@/components/common/Button"
import { SingleFileUpload } from "@/components/upload/SupplierFileUpload"
import type { SignedUrl } from "@/graphql/codegen/graphql"
import type { PDFSize } from "@/hooks/usePDFUpload"

interface DialogProps {
  open: boolean
  onClose: () => void
  onSave: (data: LabelInformationFormData) => void
  pdfFiles: Record<string, SignedUrl>
  imageFile: SignedUrl | null
  labelInfo: LabelInformation
}

export const LabelInformationDialog = ({
  open,
  onClose,
  onSave,
  pdfFiles,
  imageFile,
  labelInfo,
}: DialogProps): ReactElement => {
  const methods = useForm<LabelInformationFormData>({
    values: {
      isPrivateLabel: labelInfo.isPrivateLabel,
      isPrintedBySupplier: labelInfo.isPrintedBySupplier,
      isAppliedBySupplier: labelInfo.isAppliedBySupplier,
      pdfs: [],
      image: null,
    },
  })

  const handlePDFFileChange = (size: PDFSize, file: File) => {
    const pattern = createLabelByPattern(
      methods.getValues("isPrivateLabel"),
      methods.getValues("isPrintedBySupplier"),
      methods.getValues("isAppliedBySupplier"),
      size
    )
    methods.setValue("pdfs", { ...methods.getValues("pdfs"), [pattern]: file })
  }
  const handleSupplierFileChange = (file: File) => {
    methods.setValue("image", file)
  }

  const handlePreviewClick = (url: string) => {
    window.open(url, "_blank")
  }

  const handleSave = (data: LabelInformationFormData) => {
    onSave(data)
  }

  const hasPDFs = useMemo(() => Object.values(pdfFiles).some((file) => file), [pdfFiles])
  const hasImage = useMemo(() => imageFile, [imageFile])

  const handleCheckLabelTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    methods.setValue("isPrivateLabel", event.target.value === "true")
    methods.setValue("isPrintedBySupplier", null)
    methods.setValue("isAppliedBySupplier", null)
  }

  const handlePrintedBySupplierChange = (event: ChangeEvent<HTMLInputElement>) => {
    methods.setValue("isPrintedBySupplier", event.target.value === "true")
    methods.setValue("pdfs", []) // Reset uploaded files when printing option changes
  }
  const handleAppliedBySupplierChange = (event: ChangeEvent<HTMLInputElement>) => {
    methods.setValue("isAppliedBySupplier", event.target.value === "true")
    methods.setValue("pdfs", []) // Reset uploaded files when application option changes
  }

  const handleOnClose = () => {
    methods.reset()
    onClose()
  }

  const privateLabel = methods.watch("isPrivateLabel")
  const printedBySupplier = methods.watch("isPrintedBySupplier")
  const appliedBySupplier = methods.watch("isAppliedBySupplier")

  const checkboxValues = useMemo(
    () => ({
      isPrivateLabel: privateLabel,
      isPrintedBySupplier: printedBySupplier,
      isAppliedBySupplier: appliedBySupplier,
    }),
    [privateLabel, printedBySupplier, appliedBySupplier]
  )

  const pdfFilesBySize = useMemo(() => mapPatternUrlsToSizes(pdfFiles, checkboxValues), [pdfFiles, checkboxValues])

  const previewUrls = useMemo(() => {
    const pdfs = methods.watch("pdfs")
    if (!pdfs) return {}

    return Object.entries(pdfs).reduce((acc, [pattern, file]) => {
      if (!file) return acc
      return {
        ...acc,
        [pattern.split("_").pop() || ""]: URL.createObjectURL(file as unknown as File),
      }
    }, {})
  }, [methods.watch("pdfs")])

  const showPDFUploader = useMemo(() => {
    return (
      (methods.watch("isPrivateLabel") === true && methods.watch("isPrintedBySupplier") === true) ||
      (methods.watch("isPrivateLabel") === true &&
        methods.watch("isPrintedBySupplier") === false &&
        methods.watch("isAppliedBySupplier") !== null)
    )
  }, [methods.watch("isPrivateLabel"), methods.watch("isPrintedBySupplier"), methods.watch("isAppliedBySupplier")])

  const showSupplierUploader = useMemo(() => {
    return methods.watch("isPrivateLabel") === false
  }, [methods.watch("isPrivateLabel")])

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth='sm' fullWidth data-testid='label-info-dialog'>
      <DialogTitle className='pb-3 font-normal text-primary'>
        {hasPDFs || hasImage ? "Edit Label Information" : "Add Label Information"}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleOnClose}
        className='absolute right-4 top-4 text-primary'
        data-testid='close-button'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <form onSubmit={methods.handleSubmit(handleSave)} className='flex flex-1 flex-col'>
        <DialogContent classes={{ root: "p-10" }}>
          <Box className='flex flex-col gap-10'>
            <Controller
              name='isPrivateLabel'
              control={methods.control}
              render={({ field }) => (
                <FormControl component='fieldset'>
                  <Typography variant='body2' className='mb-4'>
                    Check labeling type
                    <Box component='span' className='text-red-500'>
                      *
                    </Box>
                  </Typography>
                  <RadioGroup
                    {...field}
                    value={String(field.value)}
                    row
                    className='gap-8'
                    onChange={handleCheckLabelTypeChange}
                  >
                    <FormControlLabel value='false' control={<Radio />} label="Supplier's Labeling" />
                    <FormControlLabel value='true' control={<Radio />} label="Akrochem's Private Labeling" />
                  </RadioGroup>
                </FormControl>
              )}
            />

            {methods.watch("isPrivateLabel") === true && (
              <>
                <Controller
                  name='isPrintedBySupplier'
                  control={methods.control}
                  render={({ field }) => (
                    <FormControl component='fieldset'>
                      <Typography variant='body1' className='mb-4'>
                        Printing Options
                      </Typography>
                      <RadioGroup
                        {...field}
                        value={field.value === null ? "" : field.value}
                        row
                        className='gap-8'
                        onChange={handlePrintedBySupplierChange}
                      >
                        <FormControlLabel value={true} control={<Radio />} label='Printed by Supplier' />
                        <FormControlLabel value={false} control={<Radio />} label='Printed by Akrochem' />
                      </RadioGroup>
                    </FormControl>
                  )}
                />

                <Controller
                  name='isAppliedBySupplier'
                  control={methods.control}
                  render={({ field }) => (
                    <FormControl component='fieldset'>
                      <Typography variant='body1' className='mb-4'>
                        Application Options
                      </Typography>
                      <RadioGroup
                        {...field}
                        value={field.value === null ? "" : field.value}
                        row
                        className='gap-8'
                        onChange={handleAppliedBySupplierChange}
                      >
                        <FormControlLabel value={true} control={<Radio />} label='Applied by Supplier' />
                        <FormControlLabel value={false} control={<Radio />} label='Applied by Akrochem' />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </>
            )}

            {showPDFUploader ? (
              <Box>
                <Typography variant='body1' className='mb-4'>
                  Upload Labels
                </Typography>
                <LabelSizeUploader
                  onFileChange={handlePDFFileChange}
                  fileUrls={pdfFilesBySize}
                  previewUrls={previewUrls}
                  uploading={false}
                  onPreviewClick={handlePreviewClick}
                />
              </Box>
            ) : showSupplierUploader ? (
              <Box>
                <Typography variant='body2' className='mb-2'>
                  Please upload supplier's label (Optional)
                </Typography>
                <SingleFileUpload onChange={handleSupplierFileChange} fileUrl={imageFile?.url || null} />
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions className='justify-between px-10 pb-10'>
          <Button appearance='outlined' onClick={handleOnClose} type='button'>
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={methods.formState.isSubmitting}>
            {hasPDFs || hasImage ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
