import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { type ReactElement, useMemo } from "react"

import { IMAGE_ORDER } from "./types"

import { Button } from "@/components/common/Button"
import { FieldGroupEnum } from "@/graphql/codegen/graphql"

interface ProductFormPicturesPreviewProps {
  open: boolean
  onClose: () => void
  onEdit: () => void
  imageUrls: string[]
}

export const ProductFormPicturesPreview = ({
  open,
  onClose,
  onEdit,
  imageUrls,
}: ProductFormPicturesPreviewProps): ReactElement => {
  const urls = useMemo(() => {
    const urlMap: Record<FieldGroupEnum, string | null> = {
      [FieldGroupEnum.Product]: null,
      [FieldGroupEnum.Package]: null,
      [FieldGroupEnum.Pallet]: null,
    }

    IMAGE_ORDER.forEach((fieldGroup, index) => {
      urlMap[fieldGroup] = imageUrls[index] || null
    })

    return urlMap
  }, [imageUrls])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Product Pictures Preview</DialogTitle>
      <DialogContent>
        <Box className='flex flex-col gap-6'>
          {/* Product Self Image */}
          <Box className='w-full'>
            <Box className='mb-2 text-sm font-medium text-gray-700'>Product</Box>
            <Box className='relative h-[300px] w-full rounded-md border border-gray-300'>
              {urls[FieldGroupEnum.Product] ? (
                <img
                  src={urls[FieldGroupEnum.Product]}
                  alt='Product'
                  className='size-full rounded-md object-cover'
                  loading='lazy'
                />
              ) : (
                <Box className='flex size-full items-center justify-center text-gray-400'>No image</Box>
              )}
            </Box>
          </Box>

          {/* Packaging and Pallet Images */}
          <Box className='grid grid-cols-2 gap-4'>
            <Box>
              <Box className='mb-2 text-sm font-medium text-gray-700'>In Packaging</Box>
              <Box className='relative h-[200px] w-full rounded-md border border-gray-300'>
                {urls[FieldGroupEnum.Package] ? (
                  <img
                    src={urls[FieldGroupEnum.Package]}
                    alt='Package'
                    className='size-full rounded-md object-cover'
                    loading='lazy'
                  />
                ) : (
                  <Box className='flex size-full items-center justify-center text-gray-400'>No image</Box>
                )}
              </Box>
            </Box>

            <Box>
              <Box className='mb-2 text-sm font-medium text-gray-700'>On Pallet</Box>
              <Box className='relative h-[200px] w-full rounded-md border border-gray-300'>
                {urls[FieldGroupEnum.Pallet] ? (
                  <img
                    src={urls[FieldGroupEnum.Pallet]}
                    alt='Pallet'
                    className='size-full rounded-md object-cover'
                    loading='lazy'
                  />
                ) : (
                  <Box className='flex size-full items-center justify-center text-gray-400'>No image</Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className='flex justify-between px-6 pb-4'>
        <Button onClick={onClose} appearance='outlined'>
          Go Back
        </Button>
        <Button onClick={onEdit} appearance='contained'>
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
