import { Box, IconButton, Paper, Typography } from "@mui/material"
import {
  ChatOutlined,
  ChevronRightOutlined,
  HomeWorkOutlined,
  MailOutlined,
  PersonAddOutlined,
  PhoneIphoneOutlined,
  VisibilityOutlined,
} from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { useMemo } from "react"

import Chip from "@/components/common/Chip/Chip.tsx"
import type { Contact, ContactMethod, Maybe } from "@/graphql/codegen/graphql"

const ContactCard = ({
  contact,
  isEmpty = false,
  onCardClick,
  onCommentClick,
  onSubscriptionClick,
  onLocationClick,
}: {
  contact?: Contact
  isEmpty?: boolean
  onCardClick: () => void
  onCommentClick?: () => void
  onSubscriptionClick?: () => void
  onLocationClick?: () => void
}): ReactElement => {
  const { firstName, lastName, companyDepartment, physicalLocation, contactMethod = [] } = contact ?? {}

  const getPrimaryContactMethods = (contactMethod: Maybe<ContactMethod>[]) => {
    let primaryEmail = null
    let primaryPhone = null

    for (const method of contactMethod.filter((m) => m != null)) {
      if (!primaryEmail && method.contactChannel === "EMAIL") {
        primaryEmail = method.value
      }
      if (!primaryPhone && method.contactChannel === "PHONE" && method.sortOrder === 1) {
        primaryPhone = method.value
      }

      if (primaryEmail && primaryPhone) {
        break
      }
    }

    return {
      email: primaryEmail,
      phone: primaryPhone,
    }
  }

  const { email, phone } = useMemo(() => getPrimaryContactMethods(contactMethod ?? []), [contactMethod])

  return (
    <Paper
      elevation={isEmpty ? 0 : 6}
      className={`h-auto w-full rounded hover:cursor-pointer hover:border hover:border-primary-400 sm:h-[234px] sm:w-[341px] ${
        isEmpty ? "border border-dashed border-gray-400 p-4 sm:p-[80px]" : "p-4 sm:p-[16px]"
      }`}
      onClick={() => onCardClick()}
    >
      {isEmpty ? (
        <Box className='flex flex-col items-center'>
          <PersonAddOutlined className='size-[46px] text-primary-400' />
          <Typography variant='subtitle2' className='gray-700 text-center' fontWeight='normal'>
            Add another contact
          </Typography>
        </Box>
      ) : (
        <Box className='flex h-full flex-col'>
          <Box className='mb-2 flex items-center justify-between'>
            <Typography variant='subtitle1' className='gray-900 max-w-[80%] truncate' fontWeight='normal'>
              {firstName} {lastName}
            </Typography>
            <IconButton color='primary' size='medium' className='p-0'>
              <VisibilityOutlined />
            </IconButton>
          </Box>
          <Typography className='gray-700 mb-3 h-5 truncate' fontWeight='normal'>
            {companyDepartment}
          </Typography>
          <Box className='mb-4 grow'>
            {email && (
              <Box className='mb-1 flex items-center gap-2'>
                <MailOutlined fontSize='small' className='text-gray-600' />
                <Typography variant='body2' className='gray-700 max-w-[calc(100%-24px)] truncate' fontWeight='normal'>
                  {email}
                </Typography>
              </Box>
            )}
            {phone && (
              <Box className='mb-1 flex items-center gap-2'>
                <PhoneIphoneOutlined fontSize='small' className='text-gray-600' />
                <Typography variant='body2' className='gray-700 max-w-[calc(100%-24px)] truncate' fontWeight='normal'>
                  {phone}
                </Typography>
              </Box>
            )}
            {physicalLocation && (
              <Box className='flex items-center gap-2'>
                <HomeWorkOutlined fontSize='small' className='text-gray-600' />
                <Typography variant='body2' className='gray-700 max-w-[calc(100%-24px)] truncate' fontWeight='normal'>
                  {physicalLocation.address1}, {physicalLocation.city}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className='flex flex-wrap items-center gap-2'>
            <Chip
              label='Locations'
              className='rounded-sm font-[12px] text-gray-900'
              onDelete={() => {}}
              deleteIcon={<ChevronRightOutlined className='text-gray-800' />}
              onClick={(event) => {
                event.stopPropagation()
                onLocationClick?.()
              }}
            />
            <Chip
              label='Subscriptions'
              className='rounded-sm bg-[#FFF0B0] font-[12px] text-gray-900'
              onDelete={() => {}}
              deleteIcon={<ChevronRightOutlined className='text-gray-800' />}
              onClick={(event) => {
                event.stopPropagation()
                onSubscriptionClick?.()
              }}
            />
            <IconButton
              type='button'
              className='size-8 rounded bg-gray-100 text-primary hover:bg-primary-100'
              aria-label='comments'
              onClick={(event) => {
                event.stopPropagation()
                onCommentClick?.()
              }}
            >
              <ChatOutlined />
            </IconButton>
          </Box>
        </Box>
      )}
    </Paper>
  )
}

export default ContactCard
