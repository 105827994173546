import { notFound } from "@tanstack/react-router"

import { PurchaseOrderSection } from "./types"

import { FieldGroupEnum, GetPurchaseOrderDocument, ReferenceTableName } from "@/graphql/codegen/graphql"
import type { OrderGetSuccess } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

const getPurchaseOrder = async ({ params }: { params: { purchaseOrderId: string } }) => {
  const { data, error } = await queryClient.query(GetPurchaseOrderDocument, {
    input: { orderId: Number(params.purchaseOrderId) },
    fileInfo: {
      referenceId: params.purchaseOrderId,
      referenceTableName: ReferenceTableName.Order,
      fieldGroups: [FieldGroupEnum.PurchaseOrder],
    },
  })

  if (
    data?.order.get.__typename === "OrderGetFailure" ||
    data?.fileUpload?.getFileInfo.__typename === "GetFileInfoFailure" ||
    error
  ) {
    console.error(error)
    throw notFound()
  }

  const purchaseOrder = (data?.order.get as OrderGetSuccess).order
  const purchaseOrderPDFUrl = data?.fileUpload?.getFileInfo?.signedUrls?.[0]?.url ?? null

  return { purchaseOrder, purchaseOrderPDFUrl }
}

// todo: refactor to use this functions to preload data
// const getComments = async ({ params }: { params: { purchaseOrderId: string } }) => {
// todo
//   const { data, error } = await queryClient.query(GetPurchaseOrderCommentsDocument, {
//     input: { orderId: Number(params.purchaseOrderId) },
//   })
// }

// todo: refactor to use this functions to preload data
// const getNote = async ({ params }: { params: { purchaseOrderId: string } }) => {
// todo
//   const { data, error } = await queryClient.query(GetPurchaseOrderNoteDocument, {
//     input: { orderId: Number(params.purchaseOrderId) },
//   })
// }

export const routeDataPreload = async ({ params }: { params: { purchaseOrderId: string } }): Promise<void> => {
  const { purchaseOrder, purchaseOrderPDFUrl } = await getPurchaseOrder({ params })

  const store = usePurchaseOrderStore.getState()

  store.setCurrentPO(purchaseOrder)
  store.setPurchaseOrderPDFUrl(purchaseOrderPDFUrl)

  /**
   * Update the header section
   */
  store.updateSectionFields(PurchaseOrderSection.HEADER, {
    orderId: purchaseOrder.orderId,
    createdByUser: purchaseOrder.createdByUser!,
    sourceLocationAssociationId: purchaseOrder.sourceLocationAssociationId!,
    destinationLocationAssociationId: purchaseOrder.destinationLocationAssociationId!,
    requestedDeliveryDate: purchaseOrder.requestedDeliveryDate!,
    expectedDeliveryDate: purchaseOrder.expectedDeliveryDate!,
    sentDate: purchaseOrder.sentDate!,
  })

  /**
   * Update the delivery terms section
   */
  store.updateSectionFields(PurchaseOrderSection.DELIVERY_TERMS, {
    incoterms: purchaseOrder.deliveryTerms ?? "",
    incotermsLocation: purchaseOrder.deliveryLocationId ?? "",
    freightTerms: purchaseOrder.freightTerms ?? "",
    shipVia: purchaseOrder.shipViaCompanyId ?? "",
    shipmentStatus: purchaseOrder.shipStatus ?? "",
  })

  /**
   * Update the shipping legs section
   */
  store.updateSectionFields(
    PurchaseOrderSection.SHIPPING_LEGS,
    purchaseOrder.shippingLegs?.map((leg) => ({
      ...leg,
      shippingLegId: leg.shippingLegId ?? "",
    })) ?? []
  )

  /**
   * Update the items section
   */
  store.updateSectionFields(
    PurchaseOrderSection.ORDER_LINES,
    purchaseOrder.orderLines?.map((orderLine) => ({
      ...orderLine,
      numberOfPallets: orderLine.numberOfPallets ?? 0,
      pricePerUom: orderLine.pricePerUom ?? 0,
      quantityInUom: orderLine.quantityInUom ?? 0,
      productId: orderLine.productId ?? "",
      uomId: orderLine.uomId ?? "",
    })) ?? []
  )

  /**
   * Update the notes section
   */
  store.updateSectionFields(PurchaseOrderSection.NOTES, purchaseOrder.note ?? "")

  /**
   * Update the comments section
   */
  store.updateSectionFields(PurchaseOrderSection.COMMENTS, {
    currentComment: {}, // todo: implement
    comments: [], // todo: implement
  })
}
