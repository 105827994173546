import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material"
import {
  AddOutlined,
  EditOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import { type ReactElement, useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "urql"

import { useProductTechnicalInformation } from "../hooks/useProductTechnicalnformation"

import AddTechnicalInformationModal from "./AddTechnicalInformationModal"
import { ShelfLifeUom } from "./TechnicalInformationContent"
import { TechnicalInformationList } from "./TechnicalInformationList"
import type { TechnicalInformationFormData } from "./TechnicalInformationSchema"
import { TechnicalInformationSchema } from "./TechnicalInformationSchema"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import {
  type Mutation,
  type ProductMutationUpdateArgs,
  type ProductUpdateFailure,
  UpdateProductMutationDocument,
} from "@/graphql/codegen/graphql"
import { calculateInDays } from "@/screens/Products/utils"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const ProductTechnicalInformationContainer = (): ReactElement => {
  const [expanded, setExpanded] = useState(true)
  const [addTechnicalModal, setAddTechnicalModal] = useState(false)
  const { enqueueNotification } = useNotificationsStore()
  const methods = useForm({
    defaultValues: {
      specificGravity: 0,
      shelfLifeDays: 0,
      cassRegistryId: "",
      harmonizedTariffSchedule: "",
      usmcaClass: false,
      unNumber: "",
      shelfLifeUom: ShelfLifeUom.Weeks,
    },
    resolver: zodResolver(TechnicalInformationSchema),
    mode: "onChange",
  })
  const { productId: queryCompanyId } = useParams({
    from: "/product/$productId/edit",
  })

  const { data: technicalInformation, fetch: fetchTechnicalInformation } = useProductTechnicalInformation({
    productId: Number(queryCompanyId),
  })
  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )
  const { setError } = methods

  const showTechnicalInformation =
    technicalInformation?.specificGravity ||
    technicalInformation?.shelfLifeDays ||
    technicalInformation?.cassRegistryId ||
    technicalInformation?.harmonizedTariffSchedule ||
    technicalInformation?.usmcaClass ||
    technicalInformation?.unNumber

  const handleSubmit = useCallback(
    async (data: TechnicalInformationFormData) => {
      const { shelfLifeUom, shelfLifeDays, ...restData } = data
      const shelfLifeDaysValue = calculateInDays(Number(data.shelfLifeDays), shelfLifeUom)

      try {
        const result = await updateProduct({
          input: {
            ...restData,
            productId: Number(queryCompanyId),
            specificGravity: Number(data.specificGravity) || null,
            shelfLifeDays: shelfLifeDaysValue || null,
          },
        })

        if (result.error || (result.data?.product.update as ProductUpdateFailure)?.error) {
          throw new Error(
            result.error?.message || (result.data?.product.update as ProductUpdateFailure)?.error?.message
          )
        }

        enqueueNotification({
          type: "success",
          silent: false,
          content: (
            <p>Product technical specification successfully {showTechnicalInformation ? "updated" : "added"}.</p>
          ),
        })
        methods.reset()
        setAddTechnicalModal(false)
        fetchTechnicalInformation({ productId: Number(queryCompanyId) })
      } catch (error) {
        setError("root", {
          type: "server",
          message: (error as Error).message,
        })

        enqueueNotification({
          type: "error",
          silent: false,
          content: <p>Failed to {showTechnicalInformation ? "update" : "add"} the product technical specification</p>,
        })
      }
    },
    [
      enqueueNotification,
      methods,
      queryCompanyId,
      setError,
      showTechnicalInformation,
      updateProduct,
      fetchTechnicalInformation,
    ]
  )

  const onEditHandleClick = useCallback(() => {
    if (!technicalInformation) return // Handle possible null value
    const { divisible, format } = getTimeFormat(technicalInformation.shelfLifeDays || 0)
    methods.reset({
      specificGravity: technicalInformation.specificGravity || 0,
      shelfLifeDays: (technicalInformation.shelfLifeDays || 0) / divisible,
      cassRegistryId: technicalInformation.cassRegistryId || "",
      harmonizedTariffSchedule: technicalInformation.harmonizedTariffSchedule || "",
      usmcaClass: Boolean(technicalInformation.usmcaClass),
      unNumber: technicalInformation.unNumber || "",
      shelfLifeUom: format as ShelfLifeUom,
    })
    methods.trigger()
    setAddTechnicalModal(true)
  }, [methods, technicalInformation])

  return (
    <Paper className='flex flex-1 flex-col border border-gray-300 p-6' elevation={0}>
      <Box className={`flex justify-between ${expanded ? "mb-6" : ""}`}>
        <Typography variant='h6'>Technical Information</Typography>
        <Box>
          {showTechnicalInformation && expanded && (
            <IconButton color='primary' aria-label='edit product conditions' onClick={onEditHandleClick}>
              <EditOutlined />
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label='show more'>
            {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded}>
        {showTechnicalInformation ? (
          <TechnicalInformationList productDetails={technicalInformation} />
        ) : (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Add Technical Information'
            description={""}
          >
            <Button
              color='inherit'
              size='small'
              appearance='outlined'
              className='text-primary-500 self-start'
              startIcon={<AddOutlined />}
              onClick={() => setAddTechnicalModal(true)}
            >
              Technical Details
            </Button>
          </InformationMissingAlert>
        )}
        <FormProvider {...methods}>
          <AddTechnicalInformationModal
            open={addTechnicalModal}
            onClose={() => {
              methods.reset()
              setAddTechnicalModal(false)
            }}
            onSubmit={handleSubmit}
          />
        </FormProvider>
      </Collapse>
    </Paper>
  )
}

export const getTimeFormat = (value: number): { divisible: number; format: string } => {
  if (value % 365 === 0) {
    return { divisible: 365, format: "Years" }
  } else if (value % 30 === 0) {
    return { divisible: 30, format: "Months" }
  } else if (value % 7 === 0) {
    return { divisible: 7, format: "Weeks" }
  } else {
    return { divisible: 1, format: "Days" }
  }
}
