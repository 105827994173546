import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material"
import { HelpOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { TextFieldComponent } from "../TechnicalInformation/TechnicalInformationContent"

import { type ProductCreateInput } from "@/graphql/codegen/graphql"

export enum LeadTimeUoms {
  Days = "Days",
  Weeks = "Weeks",
}

export const ShippingDetailsContent: FC = () => {
  const { control } = useFormContext<ProductCreateInput>()

  return (
    <form>
      <Box>
        <TextFieldComponent
          control={control}
          name='weightPerTruckload'
          label='Pounds (lb) per Truckload'
          placeholder='000'
          type='number'
        />
        <Controller
          name='leadTimeInDays'
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Lead Time</label>
              <TextField
                {...field}
                type='number'
                value={field.value}
                InputProps={{
                  endAdornment: (
                    <Box className='flex items-center gap-4'>
                      <HelpOutlined />
                      <Controller
                        name='leadTimeUom'
                        control={control}
                        render={({ field }) => (
                          <FormControl variant='outlined' size='small'>
                            <Select
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                minWidth: "60px",
                                "& .MuiSelect-select": {
                                  padding: "2px 4px",
                                },
                              }}
                              {...field}
                              displayEmpty
                              IconComponent={KeyboardArrowDownOutlined}
                              MenuProps={{
                                PaperProps: {
                                  style: { maxHeight: 48 * 4.5 + 8, width: 60 },
                                },
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {Object.keys(LeadTimeUoms).map((leadTimeUom) => (
                                <MenuItem key={leadTimeUom} value={leadTimeUom}>
                                  {leadTimeUom}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Box>
                  ),
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                  inputProps: {
                    className: "hide-stepper",
                  },
                }}
                placeholder='00'
                fullWidth
                variant='outlined'
              />
            </Box>
          )}
        />
        <TextFieldComponent
          control={control}
          name='minOrderQuantity'
          label='Min Order Qty (lb)'
          placeholder='00'
          type='number'
        />
      </Box>
    </form>
  )
}
