import { Box, IconButton, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { EditOutlined } from "@mui-symbols-material/w300"
import dayjs from "dayjs"
import type { Maybe } from "graphql/jsutils/Maybe"
import { useCallback, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { useGetPurchaseOrderShipDetails } from "../hooks/useGetPurchaseOrderShipLocation"
import { PurchaseOrderSection } from "../types"

import EditPurchaseOrderHeaderDialog from "./EditPurchaseOrderHeaderDialog"
import { OrderStatusChip } from "./OrderStatusChip"
import { ShipStatusChip } from "./ShipStatusChip"

import { Form, FormField } from "@/components/Forms"
import { type Location, OrderStatus } from "@/graphql/codegen/graphql"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

interface DateFieldProps {
  field: any
  fieldState: any
  disabled?: boolean
}

const DateField = ({ field, fieldState, disabled = false, ...rest }: DateFieldProps) => {
  return (
    <DatePicker
      {...rest}
      {...field}
      disabled={disabled}
      className='max-w-[180px]'
      value={field.value ? dayjs(field.value) : null}
      error={!!fieldState?.error}
      onChange={(date) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD")
        field.onChange(formattedDate)
      }}
    />
  )
}

const DataRow = ({ children }: { children: React.ReactNode }) => (
  <Box className='flex flex-wrap items-center gap-x-6 gap-y-4'>{children}</Box>
)

const DataField = ({ children }: { children: React.ReactNode }) => (
  <Box className='flex items-center gap-1'>{children}</Box>
)

const DataLabel = ({ children }: { children: React.ReactNode }) => (
  <Typography variant='body2' color='textSecondary'>
    {children}
  </Typography>
)

const DataValue = ({
  children,
  className,
  fontWeight = 500,
}: {
  children: React.ReactNode
  className?: string
  fontWeight?: number
}) => (
  <Typography variant='body2' color='textPrimary' fontWeight={fontWeight} className={className}>
    {children}
  </Typography>
)

type GetShipLocationDisplayValue = (location?: Maybe<Location>) => string

const getShipLocationDisplayValue: GetShipLocationDisplayValue = (location) => {
  if (!location) return "-"

  let displayValue = ""

  if (location.address1) displayValue += ` ${location.address1}`
  if (location.address2) displayValue += ` ${location.address2}`
  if (location.city) displayValue += `, ${location.city}`
  if (location.postalCode) displayValue += `, ${location.postalCode}`

  // Remove leading comma and whitespace pairs, if any
  displayValue = displayValue.replace(/^(?:,\s)+/g, "")

  return displayValue.trim()
}

export const PurchaseOrderHeader: React.FC = () => {
  const [open, setOpen] = useState(false)
  const { formValues, updateSectionFields, updateModifiedFields } = usePurchaseOrderStore()
  const header = formValues[PurchaseOrderSection.HEADER]
  const order = usePurchaseOrderStore((state) => state.currentPO)

  const form = useForm({
    defaultValues: {
      ...header,
    },
  })

  const { purchaseOrderShipLocation: purchaseOrderShipFromLocation, purchaseOrderShipCompany: purchaseOrderCompany } =
    useGetPurchaseOrderShipDetails(order?.destinationLocationAssociationId)

  const { purchaseOrderShipLocation: purchaseOrderShipToLocation } = useGetPurchaseOrderShipDetails(
    order?.destinationLocationAssociationId
  )

  const handleSubmit = useCallback(
    (destinationLocationAssociationId: string) => {
      updateSectionFields(PurchaseOrderSection.HEADER, {
        ...form.getValues(),
        destinationLocationAssociationId,
      })
      updateModifiedFields(PurchaseOrderSection.HEADER, { destinationLocationAssociationId })
      setOpen(false)
    },
    [form, updateModifiedFields, updateSectionFields]
  )

  // watch Delivery Requested by change and updat store, use subscribe/unsubscribe in effect
  useEffect(() => {
    const subscription = form.watch((value) => {
      if (value.requestedDeliveryDate && order?.orderId) {
        updateSectionFields(PurchaseOrderSection.HEADER, {
          ...form.getValues(),
          requestedDeliveryDate: value.requestedDeliveryDate,
        })
        updateModifiedFields(PurchaseOrderSection.HEADER, { requestedDeliveryDate: value.requestedDeliveryDate })
      }

      if (value && order?.orderId) {
        updateSectionFields(PurchaseOrderSection.HEADER, {
          ...form.getValues(),
          expectedDeliveryDate: value.expectedDeliveryDate,
        })
        updateModifiedFields(PurchaseOrderSection.HEADER, { expectedDeliveryDate: value.expectedDeliveryDate })
      }
    })
    return () => subscription.unsubscribe()
  }, [form, updateSectionFields, updateModifiedFields])

  return (
    <Box className='flex flex-col gap-5 rounded-md border border-solid border-gray-300 p-6'>
      {/* Order ID, status chips, and edit button */}
      <Box className='flex justify-between gap-4'>
        <Box className='flex items-center gap-4'>
          <Typography variant='h5' className='text-gray-800'>
            Order: {order?.orderId ?? "-"}
          </Typography>
          <OrderStatusChip status={order?.status} />
          <ShipStatusChip shipStatus={order?.shipStatus} />
        </Box>
        <IconButton
          aria-label='edit purchase order'
          className='text-primary-500 rounded-md'
          onClick={() => setOpen(true)}
        >
          <EditOutlined color='primary' />
        </IconButton>
      </Box>

      <Box className='flex flex-col flex-wrap gap-4'>
        {/* Created by, Supplier name, and Company ID */}
        <DataRow>
          <DataField>
            <DataLabel>Created by</DataLabel>
            <DataValue className='bg-primary-100 px-6 py-1 text-xs' fontWeight={400}>
              {order?.createdByUser?.details?.name ?? "-"}
            </DataValue>
          </DataField>
          <DataField>
            <DataLabel>Supplier:</DataLabel>
            <DataValue>{purchaseOrderCompany?.company?.name ?? "-"}</DataValue>
          </DataField>
          <DataField>
            <DataLabel>Company ID:</DataLabel>
            <DataValue>{purchaseOrderCompany?.company?.externalSystemId ?? "-"}</DataValue>
          </DataField>
        </DataRow>

        {/* Ship From/To */}
        <DataRow>
          <DataField>
            <DataLabel>Ship From:</DataLabel>
            <DataValue>{purchaseOrderShipFromLocation?.location?.name ?? ""}</DataValue>
            &#45;
            <Typography variant='body2' color='textPrimary'>
              {getShipLocationDisplayValue(purchaseOrderShipFromLocation?.location)}
            </Typography>
          </DataField>
          <DataField>
            <DataLabel>Ship To:</DataLabel>
            <DataValue>{purchaseOrderShipToLocation?.location?.name ?? ""}</DataValue>
            &#45;
            <Typography variant='body2' color='textPrimary'>
              {getShipLocationDisplayValue(purchaseOrderShipToLocation?.location)}
            </Typography>
          </DataField>
        </DataRow>
      </Box>

      <FormProvider {...form}>
        <Form>
          {/* Order dates */}
          <Box className='flex grow-0 flex-wrap gap-4'>
            <FormField
              name='sentDate'
              label='Order Sent'
              className='grow-0'
              render={({ field, fieldState }) => <DateField field={field} fieldState={fieldState} disabled />}
            />
            <FormField
              name='requestedDeliveryDate'
              label='Delivery Requested by'
              className='grow-0'
              render={({ field, fieldState }) => <DateField field={field} fieldState={fieldState} />}
            />
            <FormField
              name='expectedDeliveryDate'
              label='ETA'
              className='grow-0'
              render={({ field, fieldState }) => (
                <DateField
                  field={field}
                  fieldState={fieldState}
                  disabled={formValues.HEADER.status === OrderStatus.Confirmed}
                />
              )}
            />
          </Box>
          <EditPurchaseOrderHeaderDialog
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            supplierName={purchaseOrderCompany?.company?.name || ""}
            onSubmit={handleSubmit}
          />
        </Form>
      </FormProvider>
    </Box>
  )
}
