import { Box, IconButton, Typography } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

interface ProductOriginGridProps {
  shippingOrigin: {
    address: string
    country: string
    city: string
  }
  manufacturingOrigin: {
    country: string
    city: string
  }
  onEdit: () => void
}

export const ProductOriginGrid = ({
  shippingOrigin,
  manufacturingOrigin,
  onEdit,
}: ProductOriginGridProps): ReactElement => {
  return (
    <Box
      className='grid grid-cols-4 divide-x divide-y divide-gray-200 border border-gray-200'
      data-testid='product-origin-grid'
    >
      <Box className='col-span-1 flex items-center bg-gray-300 p-4' data-testid='shipping-origin-header'>
        <Typography variant='subtitle1' className='font-medium text-gray-700'>
          Shipping Origin
        </Typography>
      </Box>
      <Box className='col-span-3 flex items-center bg-gray-100 p-4' data-testid='manufacturing-origin-header'>
        <Typography variant='subtitle1' className='font-medium text-gray-700'>
          Manufacturing Origin
        </Typography>
      </Box>
      <Box className='flex items-center p-4' data-testid='ship-from-label'>
        <Typography variant='body2' className='text-gray-700'>
          Ship From
        </Typography>
      </Box>
      <Box className='flex items-center px-2 py-4' data-testid='country-label'>
        <Typography variant='body2' className='text-gray-700'>
          Country
        </Typography>
      </Box>
      <Box className='flex items-center px-2 py-4' data-testid='city-label'>
        <Typography variant='body2' className='text-gray-700'>
          City
        </Typography>
      </Box>
      <Box
        className='flex items-center justify-end p-4'
        sx={{ borderLeft: "none" }}
        data-testid='edit-button-container'
      >
        <IconButton onClick={onEdit} color='primary' data-testid='edit-button' aria-label='Edit product origin'>
          <EditOutlined />
        </IconButton>
      </Box>

      <Box className='flex items-center p-4' data-testid='shipping-origin-address'>
        <Typography variant='body2'>{shippingOrigin.address || "-"}</Typography>
      </Box>
      <Box className='flex items-center p-4' data-testid='manufacturing-origin-country'>
        <Typography variant='body2'>{manufacturingOrigin.country || "-"}</Typography>
      </Box>
      <Box className='flex items-center p-4' data-testid='manufacturing-origin-city'>
        <Typography variant='body2'>{manufacturingOrigin.city || "-"}</Typography>
      </Box>
    </Box>
  )
}
