import { Box, IconButton, TextareaAutosize, Typography } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useState } from "react"
import type { UseMutationExecute } from "urql"

import { Button } from "@/components/common/Button"
import { CommentParentTableType } from "@/graphql/codegen/graphql.ts"
import type {
  Comment,
  CommentCreateFailure,
  CommentMutationCreateArgs,
  CommentMutationUpdateArgs,
  CommentUpdateFailure,
  Mutation,
} from "@/graphql/codegen/graphql.ts"
import { useAuthStore } from "@/stores/authStore.ts"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface CommentProps extends Comment {
  companyId: string
  onCreate: UseMutationExecute<Pick<Mutation, "comment">, CommentMutationCreateArgs>
  onUpdate: UseMutationExecute<Pick<Mutation, "comment">, CommentMutationUpdateArgs>
  reexecuteCommentList: () => void
}

const CompanyComments: FunctionComponent<CommentProps> = ({
  companyId,
  commentId,
  updatedAt,
  updatedBy,
  value,
  onCreate,
  onUpdate,
  reexecuteCommentList,
}) => {
  const { enqueueNotification } = useNotificationsStore()
  const [onEdit, setOnEdit] = useState(false)
  const [commentValue, setCommentValue] = useState<string | undefined>(value ?? "")
  const { user } = useAuthStore.getState()

  const handleEditClick = useCallback(() => {
    setCommentValue(value ?? "")
    setOnEdit(true)
  }, [value])

  const notify = (type: "success" | "error", message: string) =>
    enqueueNotification({ type, silent: false, content: <p>{message}</p> })

  const handleCommentAction = useCallback(async () => {
    const { error, data: result } = commentId
      ? await onUpdate({
          input: { commentId, value: commentValue ?? "" },
        })
      : await onCreate({
          input: {
            createdBy: user?.id ?? "",
            referenceId: companyId,
            type: CommentParentTableType.Company,
            value: commentValue ?? "",
          },
        })

    const failure = commentId
      ? (result?.comment?.update as CommentUpdateFailure)?.error
      : (result?.comment?.create as CommentCreateFailure)?.error
    const actionType = commentId ? "update" : "add"

    if (error || failure) {
      notify("error", `Failed to ${actionType} comment`)
    } else {
      const notificationMessage =
        actionType === "update" ? "Successfully updated comment" : "Successfully added comment"
      notify("success", notificationMessage)
      setCommentValue("")
      reexecuteCommentList()
    }
    setOnEdit(false)
  }, [commentId, onUpdate, commentValue, onCreate, user?.id, companyId, notify, reexecuteCommentList])

  return (
    <>
      {commentId && !onEdit ? (
        <Box className='flex items-center justify-between border border-solid border-gray-300 p-6'>
          <Box>
            <Typography variant='caption' className='text-gray-600'>
              Posted: {updatedAt} By {updatedBy}
            </Typography>
            <Typography variant='body2' className='mt-2'>
              {value}
            </Typography>
          </Box>
          <IconButton color='primary' aria-label='edit company details' onClick={handleEditClick}>
            <EditOutlined />
          </IconButton>
        </Box>
      ) : (
        <div className='relative w-full pt-2'>
          <TextareaAutosize
            placeholder='Add a comment...'
            minRows={1.5}
            maxRows={1.5}
            className='overflowY-auto w-full rounded-md border border-solid border-gray-300 p-4 pr-[120px] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#BFDBFE]'
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
          />
          {commentValue && (
            <Button onClick={handleCommentAction} className='absolute right-8 top-6'>
              {commentId ? "Update" : "Add"}
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default CompanyComments
