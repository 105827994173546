import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import type { ReactElement } from "react"
import { Controller, useFormContext } from "react-hook-form"

import AddPaymentRuleFields from "./AddPaymentRuleFields"

import { MaturityDateTypeGql, PayPatternGql, type PaymentTermCreateInput } from "@/graphql/codegen/graphql"

const AddPayRuleSchedule = (): ReactElement => {
  const { control, watch, setValue } = useFormContext<PaymentTermCreateInput>()

  const handleResetRuleValues = () => {
    setValue("payRunSchedule.payPattern", PayPatternGql.Monthly)
    setValue("payRunSchedule.startDate", "")
    setValue("payRunSchedule.payDays", "")
    setValue("payRunSchedule.invoiceMaturity.intervalBetweenPayRuns", MaturityDateTypeGql.PayInterval)
    setValue("payRunSchedule.invoiceMaturity.offsetInDays", "")
  }

  return (
    <Box className='mb-6 rounded-md border border-solid border-gray-300 bg-white p-6'>
      <Box className='flex items-center justify-between gap-3'>
        <Typography variant='subtitle1' className='text-lg text-gray-900'>
          Pay Run Schedule
        </Typography>
        <Box className='flex'>
          <Controller
            name='payRunSchedule.isEnabled'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={watch("payRunSchedule.isEnabled") ?? false}
                    name={"payRunSchedule.isEnabled"}
                    role='checkbox'
                    onChange={(e) => {
                      if (!e.target.checked) {
                        handleResetRuleValues()
                      }
                      field.onChange(e.target.checked)
                    }}
                  />
                }
                className='mb-1'
                label='Use Pay Run Schedule'
              />
            )}
          />
        </Box>
      </Box>
      {watch("payRunSchedule.isEnabled") && <AddPaymentRuleFields />}
    </Box>
  )
}

export default AddPayRuleSchedule
