import { Box, Typography } from "@mui/material"
import type { ColumnDef, ExpandedState } from "@tanstack/react-table"
import React, { useEffect, useMemo, useRef, useState } from "react"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { Country, Location, Region } from "@/graphql/codegen/graphql"
import ExpandedLocationRow from "@/screens/Companies/edit/components/locations/ExpandedLocationRow"
import { useLocationTableColumns } from "@/screens/Companies/edit/components/locations/hooks/useLocationTableColumns"
import type {
  LocationConfirmationDialogs,
  LocationMenuDialogs,
} from "@/screens/Companies/edit/components/locations/types/dialogs"

const LIMIT = 20

interface LocationsTableProps {
  locations: Location[]
  loadMore: () => void
  isLocationsFetching: boolean
  error?: { message: string }
  countryList: Country[]
  regionList?: Region[]
  setOpenedDialog: (dialog: LocationMenuDialogs | LocationConfirmationDialogs | null) => void
  setActiveEditLocation: (location: Location | undefined) => void
}

const LocationsTable: React.FC<LocationsTableProps> = ({
  locations,
  loadMore,
  isLocationsFetching,
  error,
  countryList,
  setOpenedDialog,
  setActiveEditLocation,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [expandedRow, setExpandedRow] = useState<ExpandedState>({})

  const memoizedCountryOptions = useMemo(
    () => countryList.map((country) => ({ value: country.isoCode, label: country.name })),
    [countryList]
  )

  const columns = useLocationTableColumns(memoizedCountryOptions, setOpenedDialog, setActiveEditLocation)

  useEffect(() => {
    const handleScroll = () => {
      const container = tableContainerRef.current
      if (!container) return

      const { scrollHeight, scrollTop, clientHeight } = container
      if (scrollHeight - scrollTop <= clientHeight * 2 && !isLocationsFetching && locations.length % LIMIT === 0) {
        loadMore()
      }
    }

    const container = tableContainerRef.current
    if (container) {
      container.addEventListener("scroll", handleScroll)
      return () => container.removeEventListener("scroll", handleScroll)
    }
  }, [loadMore, isLocationsFetching, locations.length])

  return (
    <Box className='flex h-full flex-col' ref={tableContainerRef}>
      <AerosBaseTable<Location>
        slotProps={{
          bodyCellExpanded: { className: "bg-primary-100" },
          expandedCell: {
            className: "p-0 w-full",
            sx: {
              position: "relative",
              "& > *": { width: "100%" },
            },
          },
        }}
        features={{
          table: {
            stickyHeader: true,
          },
          tanstackOptions: {
            data: locations,
            columns: columns as ColumnDef<Location>[],
            state: {
              expanded: expandedRow,
            },
            onExpandedChange: setExpandedRow,
            getRowCanExpand: () => true,
            renderExpandedContent: (row) => (
              <Box className='w-full'>
                <ExpandedLocationRow row={row} />
              </Box>
            ),
          },
        }}
        error={error}
        noDataMessage='No locations found'
      />
      {error && <Typography color='error'>{error.message}</Typography>}
    </Box>
  )
}

export default LocationsTable
