import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { Category, CategoryListSuccess, Query } from "@/graphql/codegen/graphql"
import { CategoryListDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20

export const useGetCategoryList = (): {
  categoryListError: CombinedError | undefined
  categoryList: Category[]
  categoryListFetching: boolean
  triggerQuery: () => void
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [categoryList, setCategoryList] = useState<Category[]>([])

  const [{ data: categoryDetails, fetching: categoryDetailsFetching, error: categoryDetailsError }, queryCategories] =
    useQuery<Pick<Query, "category">>({
      query: CategoryListDocument,
      variables: { input: { limit: LIMIT, offset } },
    })

  useEffect(() => {
    if (categoryDetails) {
      const locations = (categoryDetails.category?.list as CategoryListSuccess).categories
      const formattedClassificationList = formatClassificationList(locations)
      setCategoryList((prev) =>
        offset === 0 ? formattedClassificationList : [...prev, ...formattedClassificationList]
      )
    }
  }, [categoryDetails, offset])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const triggerQuery = useCallback(() => queryCategories(), [queryCategories])

  return {
    categoryList,
    categoryListFetching: categoryDetailsFetching,
    categoryListError: categoryDetailsError,
    loadMore,
    triggerQuery,
  }
}

const formatClassificationList = (categoryList = []) =>
  categoryList.map(({ id, description, classifications = [] }) => {
    return {
      id,
      name: description,
      children: classifications.length > 0 ? formatClassificationList(classifications) : [],
    }
  })
