import { useEffect, useMemo } from "react"
import { useMutation } from "urql"
import { useShallow } from "zustand/shallow"

import { PurchaseOrderSection } from "../../types"

import { UpdatePurchaseOrderDocument } from "@/graphql/codegen/graphql"
import type { Order, OrderMutationUpdateArgs, UpdatePurchaseOrderMutation } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

type UseUpdatePOReturn = {
  updatedPO: Partial<Order> | undefined
  fetching: boolean
  updatePO: (data: OrderMutationUpdateArgs) => void
}

export const useUpdatePO = (): UseUpdatePOReturn => {
  const notify = useNotificationsStore(useShallow((s) => s.enqueueNotification))
  const { currentPO, formValues, setCurrentPO, updateSectionFields } = usePurchaseOrderStore(useShallow((s) => s))
  const [{ data, fetching }, updatePO] = useMutation<UpdatePurchaseOrderMutation, OrderMutationUpdateArgs>(
    UpdatePurchaseOrderDocument
  )

  const updatedPO = useMemo(() => {
    if (data?.order.update.__typename === "OrderUpdateSuccess") {
      notify({ message: "Purchase order updated", type: "success" })
      return data?.order.update.order as Partial<Order>
    }

    if (data?.order.update.__typename === "OrderUpdateFailure") {
      notify({ message: "Failed to update purchase order", type: "error" })
    }

    return undefined
  }, [data])

  // update store with the updated PO
  useEffect(() => {
    if (updatedPO) {
      setCurrentPO({ ...currentPO, ...updatedPO })

      updateSectionFields(PurchaseOrderSection.HEADER, {
        ...formValues.HEADER,
        sentDate: updatedPO.sentDate,
      })
    }
  }, [updatedPO])

  return { updatedPO, updatePO, fetching }
}
