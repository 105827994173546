import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useState } from "react"
import { useFormContext } from "react-hook-form"

import { AddContactGeneralInformation } from "./AddContactGeneralInformation"
import { AddContactMethods } from "./AddContactMethods"
import { AddLocationRoles } from "./AddLocationRoles"

import { Button } from "@/components/common/Button"
import type { ContactCreateInput } from "@/graphql/codegen/graphql"

type ContactModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: ContactCreateInput) => void
}

const AddContactDialog: FunctionComponent<ContactModalProps> = ({ open, onClose, onSubmit }) => {
  const methods = useFormContext<ContactCreateInput>()
  const [step, setStep] = useState(0)

  const onNextStep = useCallback(async () => {
    const isValid = await methods.trigger()
    if (isValid) {
      setStep((prevState) => prevState + 1)
    }
  }, [methods])

  const handleSubmit = useCallback(
    (value: ContactCreateInput) => {
      onSubmit(value)
      onClose("success")
      setStep(0)
    },
    [onClose]
  )

  const isGeneralStepDisabled =
    !methods.getFieldState("firstName").invalid &&
    !methods.getFieldState("lastName").invalid &&
    methods.formState.touchedFields.firstName &&
    methods.formState.touchedFields.lastName

  const handleResetClick = useCallback(() => {
    methods.reset()
    onClose("cancel")
    setStep(0)
  }, [onClose])

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={(_, reason) => {
            methods.reset()
            setStep(0)
            onClose(reason)
          }}
          fullWidth
        >
          <DialogTitle className='pl-10 font-normal text-primary'>Add Contact</DialogTitle>
          <IconButton
            aria-label='close'
            onClick={() => onClose("cancel")}
            className='absolute right-4 top-4 text-primary'
          >
            <CloseOutlined />
          </IconButton>
          <Divider />
          <DialogContent classes={{ root: "p-10" }}>
            {step === 0 && <AddContactGeneralInformation />}
            {step === 1 && <AddContactMethods />}
            {step === 2 && <AddLocationRoles />}
          </DialogContent>
          <DialogActions className={"justify-between px-10 pb-10"}>
            <Button appearance='outlined' onClick={handleResetClick}>
              Cancel
            </Button>
            {step === 0 && (
              <Button onClick={onNextStep} disabled={!isGeneralStepDisabled}>
                Continue
              </Button>
            )}
            {step === 1 && (
              <Box>
                <Button
                  variant='primary'
                  appearance='text'
                  className='mr-6'
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Save And Exit
                </Button>
                <Button onClick={onNextStep}>Continue</Button>
              </Box>
            )}
            {step === 2 && (
              <Box>
                <Button
                  variant='primary'
                  appearance='text'
                  className='mr-6'
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Save And Exit
                </Button>
                <Button onClick={methods.handleSubmit(handleSubmit)} disabled={!methods.formState.isValid}>
                  Save
                </Button>
              </Box>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default AddContactDialog
