import { zodResolver } from "@hookform/resolvers/zod"
import { Box, CircularProgress } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useShallow } from "zustand/react/shallow"

import { Button } from "@/components/common/Button"
import { InfoTable } from "@/components/InfoTable/InfoTable"
import { formattedTierData } from "@/constants/pricingTiers"
import type { CreatePricingPackageFailure, CreatePricingPackageInput, Product } from "@/graphql/codegen/graphql"
import { AddPricingSchema } from "@/screens/Products/components/PricingTiers/AddPricingSchema"
import AddPricingTiersModal from "@/screens/Products/components/PricingTiers/AddPricingTiersModal"
import { useCreatePricingTier } from "@/screens/Products/hooks/useCreatePricingTier"
import { useGetPricingPackage } from "@/screens/Products/hooks/useGetPricingPackage"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface ExpandedContentProps {
  row: Product
  productId: string
  companyId: string
  sourceId: string
  onContentLoad: (height: number) => void
}

export const ExpandedContent: React.FC<ExpandedContentProps> = ({ productId, companyId, sourceId, onContentLoad }) => {
  const { pricingList = [], pricingListFetching } = useGetPricingPackage({ productId, companyId, sourceId })
  const [open, setOpen] = useState(false)
  const notify = useNotificationsStore(useShallow(({ enqueueNotification }) => enqueueNotification))
  const { createPricingTier } = useCreatePricingTier()
  const contentRef = useRef<HTMLDivElement>(null)

  const methods = useForm({
    defaultValues: {
      pricingTiersToCreate: [
        { unitOfMeasurement: { id: "", name: "" }, numberOfUnits: "", pricePerUnit: "" },
        { unitOfMeasurement: { id: "", name: "" }, numberOfUnits: "", pricePerUnit: "" },
        { unitOfMeasurement: { id: "", name: "" }, numberOfUnits: "", pricePerUnit: "" },
      ],
      effectiveDate: "",
      expirationDate: "",
      freightTerms: "",
      incoterms: "",
      incotermsLocationId: "",
      destinationCompanyLocationAssociationId: "",
      sourceCompanyLocationAssociationId: "",
    },
    resolver: zodResolver(AddPricingSchema),
    mode: "all",
  })

  const { setError } = methods

  useEffect(() => {
    if (contentRef.current) {
      onContentLoad(contentRef.current.clientHeight)
    }
  }, [onContentLoad]) // Added hideDetails as dependency, updates height when hideDetails changes

  const handleSubmit = useCallback(
    async (data: CreatePricingPackageInput) => {
      const formattedData = formattedTierData({
        ...data,
        companyId,
        productId,
      })
      const { error, data: result } = await createPricingTier({
        input: formattedData,
      })
      if (error || (result?.pricingPackage?.create as CreatePricingPackageFailure)?.error) {
        setError("root", {
          // Keep this as "name"
          type: "server",
          message: error?.message || (result?.pricingPackage?.create as CreatePricingPackageFailure)?.error?.message,
        })

        notify({
          type: "error",
          message: "Pricing and Shipping terms creation failed",
        })
      } else {
        notify({
          type: "success",
          message: "Pricing and Shipping terms successfully added",
        })
      }
      methods.reset()
      setOpen(false)
    },
    [companyId, createPricingTier, methods, notify, productId, setError]
  )

  if (pricingListFetching) {
    return (
      <div ref={contentRef} className='overflow-auto pb-4'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div ref={contentRef} className='w-full overflow-auto pb-4'>
      {pricingList.length === 0 ? (
        <div className='flex justify-end'>
          <Button variant='primary' appearance='text' startIcon={<AddOutlined />} onClick={() => setOpen(true)}>
            Pricing and Shipping terms
          </Button>
        </div>
      ) : (
        <Box className='h-full p-4'>
          {/* TODO: Added mock data which will be replaced with actual data once API integration is completed*/}
          <InfoTable
            title='Current Pricing'
            chipData={[
              { label: "Effective", value: "05/15/2024" },
              { label: "Valid Until", value: "05/15/2024" },
            ]}
            rows={[
              { label: "500 QTY", value: "$90,000 / Ton" },
              { label: "1000 QTY", value: "$80,000 / Ton" },
            ]}
            extraInfo={[
              { label: "Freight", value: "Prepaid and Add" },
              { label: "Incoterms", value: "CIP - Carriage and Insurance Paid to" },
            ]}
            location='Boston, MA'
          />
          <div className='flex justify-end pt-2'>
            <Button variant='primary' appearance='text' startIcon={<AddOutlined />}>
              Future Pricing
            </Button>
          </div>
        </Box>
      )}
      <FormProvider {...methods}>
        <AddPricingTiersModal
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </div>
  )
}
