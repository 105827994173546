import { useNavigate, useSearch } from "@tanstack/react-router"
import type { OnChangeFn, SortingState } from "@tanstack/react-table"
import { getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { FunctionComponent } from "react"
import { useEffect, useMemo, useRef } from "react"

import { TableWrapper } from "../../../../shared/components/Tables/TableWrappper"

import type { Contact, ContactSortField } from "@/graphql/codegen/graphql"
import { SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { useThrottle } from "@/hooks/useThrottle"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import {
  accessorKeyToContactSortField,
  columns,
  contactSortFieldToAccessoryKey,
} from "@/screens/Companies/components/tables/ContactsTableUtils.tsx"
import type { SortableAccessorKey } from "@/screens/Companies/components/tables/ContactsTableUtils.tsx"
import { useContactSearchStore } from "@/screens/Companies/stores/useContactSearchStore.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"

export const ContactsTable: FunctionComponent = () => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/companies/" })
  const { category, query, field, order, ...restSearchParams } = currentSearch

  const data = useContactSearchStore(({ data }) => data)
  const fetching = useContactSearchStore(({ fetching }) => fetching)
  const error = useContactSearchStore(({ error }) => error)
  const executeSearch = useContactSearchStore(({ executeSearch }) => executeSearch)
  const loadMore = useContactSearchStore(({ loadMore }) => loadMore)

  useEffect(() => {
    executeSearch(queryClient, currentSearch)
  }, [executeSearch, queryClient, currentSearch])

  const sorting = useMemo(() => {
    const tableSortField = contactSortFieldToAccessoryKey.get(field as ContactSortField) as string
    const tableSortOrder = order || SortingOrder.Asc
    return [
      {
        id: tableSortField,
        asc: tableSortOrder === SortingOrder.Asc,
        desc: tableSortOrder === SortingOrder.Desc,
      },
    ]
  }, [field, order])

  const handleSortingChange: OnChangeFn<SortingState> = (updaterFn) => {
    // @ts-expect-error - updaterFn is known fn
    const newSorting = updaterFn(sorting)

    if (newSorting.length > 0) {
      const sort = newSorting[0]
      const key = sort.id as SortableAccessorKey
      const field = accessorKeyToContactSortField.get(key)
      const params = {
        ...restSearchParams,
        query,
        category,
        field,
        order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
      }

      const refinedParams = getRefinedSearchParams(params)
      void navigate({
        to: "/companies/",
        search: refinedParams.search,
        replace: true,
      })
    }
  }

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const [throttledLoadMore] = useThrottle(() => loadMore(queryClient, currentSearch), 200)
  const onScroll = useScrollLoadMore({ fetching, loadMore: throttledLoadMore, scrollContainerRef: tableContainerRef })

  const table = useReactTable<Contact>({
    data,
    columns,
    state: { sorting },
    onSortingChange: handleSortingChange,
    manualSorting: true,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableWrapper
      table={table}
      data={data}
      error={error}
      fetching={fetching}
      onScroll={onScroll}
      tableContainerRef={tableContainerRef}
    />
  )
}
