import { Box, CircularProgress, Typography } from "@mui/material"
import { AddPhotoAlternateOutlined } from "@mui-symbols-material/w300"
import { type ReactElement, memo } from "react"

import { ImageHover } from "@/components/upload/components/ImageHover"
import { FileUpload } from "@/components/upload/FileUpload"
import type { FieldGroupEnum } from "@/graphql/codegen/graphql"
import { useDragAndDrop } from "@/hooks/useDragAndDrop"

export interface FileUploadSectionProps {
  type: FieldGroupEnum
  label: string
  fileUrl: string | null
  onFileSelect: (type: FieldGroupEnum, file: File) => void
  uploading: Record<FieldGroupEnum, boolean>
  accept?: string
  maxSize?: number
  icon?: ReactElement
  emptyStateText?: string
  dropStateText?: string
}

export const FileUploadSection = memo(
  ({
    type,
    label,
    fileUrl,
    onFileSelect,
    uploading,
    accept = ".jpg,.png,.gif,.jpeg",
    maxSize = 10 * 1024 * 1024, // 10MB default
    icon = <AddPhotoAlternateOutlined fontSize='large' className='text-primary-400' />,
    emptyStateText = "Upload file",
    dropStateText = "Drop to upload",
  }: FileUploadSectionProps): ReactElement => {
    const { dropRef, isOver, canDrop } = useDragAndDrop({
      onDrop: (file) => onFileSelect(type, file),
      accept,
      maxSize,
      enabled: true,
    })

    return (
      <Box className='flex flex-col gap-2' ref={dropRef}>
        <FileUpload
          onChange={(file) => onFileSelect(type, file)}
          fileUrl={fileUrl}
          accept={accept}
          maxSize={maxSize}
          templates={{
            content: ({ uploading: isUploading, fileUrl: currentFileUrl }) => (
              <Box className='flex h-[124px] w-[150px] flex-col items-center justify-center'>
                {isUploading ? (
                  <CircularProgress size={48} />
                ) : currentFileUrl ? (
                  <Box className='relative size-full'>
                    <Box className='relative size-full overflow-hidden rounded-md'>
                      <img src={currentFileUrl} alt={label} className='absolute inset-0 size-full object-cover' />
                    </Box>
                  </Box>
                ) : (
                  <Box className='flex flex-col items-center gap-2'>
                    {icon}
                    <Typography variant='caption' className='text-center text-gray-600'>
                      {label}
                    </Typography>
                    <Typography variant='caption' className='text-center text-gray-400'>
                      {emptyStateText}
                    </Typography>
                  </Box>
                )}
              </Box>
            ),
            hover: ({ fileUrl }) => <ImageHover fileUrl={fileUrl} />,
            overlay: () => (
              <Box
                className={`pointer-events-none absolute inset-0 z-10 flex size-full items-center justify-center rounded-lg border-2 border-dashed transition-all duration-200 ${
                  isOver && canDrop ? "border-primary-500 bg-white/90" : "border-transparent bg-transparent"
                }`}
              >
                <Box
                  className={`flex flex-col items-center gap-4 transition-opacity duration-200 ${
                    isOver && canDrop ? "opacity-100" : "opacity-0"
                  }`}
                >
                  {icon}
                  <Typography variant='caption' className='text-primary'>
                    {dropStateText}
                  </Typography>
                </Box>
              </Box>
            ),
          }}
          slotProps={{
            content: {
              uploading: uploading[type],
            },
            uploadBox: {
              className: "w-[150px] h-[124px] overflow-hidden",
            },
          }}
        />
      </Box>
    )
  }
)

FileUploadSection.displayName = "FileUploadSection"
