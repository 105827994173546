import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import { type ReactElement, useMemo } from "react"
import { useForm } from "react-hook-form"

import { LabelSizeUploader } from "./LabelSizeUploader"
import type { LabelInformation, LabelInformationFormData } from "./types"
import { mapPatternUrlsToSizes } from "./types"

import { FilePreview } from "@/components/upload/SupplierFileUpload"
import type { SignedUrl } from "@/graphql/codegen/graphql"

interface ViewLabelsDialogProps {
  open: boolean
  onClose: () => void
  onSave: (data: Pick<LabelInformationFormData, "pdfs">) => void
  pdfFiles: Record<string, SignedUrl>
  imageFile: SignedUrl | null
  labelInfo: LabelInformation
}

export const ViewLabelsDialog = ({
  open,
  onClose,
  onSave,
  pdfFiles,
  imageFile,
  labelInfo,
}: ViewLabelsDialogProps): ReactElement => {
  const methods = useForm({
    defaultValues: {
      pdfs: {},
    },
  })
  const pdfFilesBySize = useMemo(() => mapPatternUrlsToSizes(pdfFiles, labelInfo), [pdfFiles, labelInfo])

  const previewUrls = useMemo(() => {
    const pdfs = methods.watch("pdfs")
    if (!pdfs) return {}

    return Object.entries(pdfs).reduce((acc, [pattern, file]) => {
      if (!file) return acc
      return {
        ...acc,
        [pattern.split("_").pop() || ""]: URL.createObjectURL(file as unknown as File),
      }
    }, {})
  }, [methods.watch("pdfs")])

  const handlePreviewClick = (url: string) => {
    window.open(url, "_blank")
  }

  const renderContent = () => {
    if (!labelInfo.isPrivateLabel) {
      if (!imageFile?.url) return null

      return (
        // <Box>
        //   <SingleFileUpload onChange={() => {}} fileUrl={imageFile.url} file={imageFile} accept='image/*' />
        // </Box>
        <Box
          className='flex w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-md border-2 border-gray-300 p-4'
          onClick={() => handlePreviewClick(imageFile.url)}
        >
          <FilePreview file={imageFile.file} readOnly />
        </Box>
      )
    }

    const hasPDFs = Object.values(pdfFiles).some((file) => file !== null)
    if (!hasPDFs) return null

    return (
      <Box>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <Typography variant='body1' className='mb-4'>
            Labels
          </Typography>
          <LabelSizeUploader
            onFileChange={() => {}}
            fileUrls={pdfFilesBySize}
            previewUrls={previewUrls}
            uploading={false}
            onPreviewClick={handlePreviewClick}
          />
        </form>
      </Box>
    )
  }

  const content = renderContent()
  if (!content) return <></>

  return (
    <Dialog open={open} onClose={onClose} maxWidth={labelInfo.isPrivateLabel ? "md" : "sm"} fullWidth>
      <DialogTitle className='pb-3 font-normal text-primary'>
        Label File{labelInfo.isPrivateLabel ? "s" : ""}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        className='absolute right-4 top-4 text-primary'
        data-testid='close-button'
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent classes={{ root: "p-10" }}>{content}</DialogContent>
    </Dialog>
  )
}
