import { useParams } from "@tanstack/react-router"
import { useCallback, useEffect, useMemo, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { Country, Location, Region } from "@/graphql/codegen/graphql"
import { CountryListDocument, LocationListQueryDocument, RegionListQueryDocument } from "@/graphql/codegen/graphql.ts"
import type { LocationQueryListArgs, Query } from "@/graphql/codegen/graphql.ts"

const LIMIT = 20 // Define the limit for pagination

export const useLocations = ({ countryIsoCode }: { countryIsoCode?: string } = {}): {
  regionListFetching: boolean
  regionList: Array<Region> | any[]
  countryListFetching: boolean
  countryList: Array<Country> | any[]
  setSelectedCountry: (value: ((prevState: string) => string) | string) => void
  companyLocations: {
    loadMoreLocations: () => void
    reexecuteQueryWithReset: () => void
    locations: Location[]
    fetching: boolean
    error: CombinedError | undefined
    resetLocationList: () => void
  }
} => {
  const { companyId } = useParams({ from: "/companies/$companyId/edit" })
  const [selectedCountry, setSelectedCountry] = useState(countryIsoCode ?? "")
  const [locationListOffset, setLocationListOffset] = useState(0)
  const [locationList, setLocationList] = useState<Location[]>([])
  const [hasMore, setHasMore] = useState(true)

  const [{ data: countryListData, fetching: countryListFetching }] = useQuery<Pick<Query, "country">>({
    query: CountryListDocument,
    variables: { input: { limit: 250 } },
  })

  const [{ data: regionListData, fetching: regionListFetching }] = useQuery<Pick<Query, "region">>({
    query: RegionListQueryDocument,
    variables: { input: { filter: { countryIsoCode: selectedCountry }, limit: 60 } },
  })

  const [
    { data: companyLocationsData, fetching: companyLocationsFetching, error: companyLocationsError },
    reexecuteCompanyLocationsQuery,
  ] = useQuery<Pick<Query, "location">, LocationQueryListArgs>({
    pause: !companyId,
    query: LocationListQueryDocument,
    variables: { input: { filter: { companyId }, limit: LIMIT, offset: locationListOffset } },
    requestPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (companyLocationsData?.location.list.__typename === "LocationListSuccess") {
      const locations = companyLocationsData.location.list.locations
      setLocationList((prev: Location[]) => (locationListOffset === 0 ? locations : [...prev, ...locations]))
      if (locations.length < LIMIT) {
        setHasMore(false)
      }
    }
  }, [companyLocationsData, locationListOffset])

  const loadMoreLocations = useCallback(() => {
    if (hasMore) {
      setLocationListOffset((prevOffset) => prevOffset + LIMIT)
    }
  }, [hasMore])

  const resetLocationList = useCallback(() => {
    setLocationListOffset(0)
    setLocationList([])
    setHasMore(true)
  }, [])

  const reexecuteQueryWithReset = useCallback(() => {
    resetLocationList()
    reexecuteCompanyLocationsQuery({ requestPolicy: "network-only" })
  }, [resetLocationList, reexecuteCompanyLocationsQuery])

  const countryList = useMemo(() => {
    if (countryListData?.country.list.__typename === "CountryListSuccess") {
      return countryListData.country.list.countries
    }

    return []
  }, [countryListData])

  const regionList = useMemo(() => {
    if (regionListData?.region.list.__typename === "RegionListSuccess") {
      return regionListData.region.list.regions
    }

    return []
  }, [regionListData])

  return {
    companyLocations: {
      locations: locationList,
      fetching: companyLocationsFetching,
      error: companyLocationsError,
      loadMoreLocations,
      reexecuteQueryWithReset,
      resetLocationList,
    },
    countryList,
    countryListFetching,
    regionList,
    regionListFetching,
    setSelectedCountry,
  }
}
