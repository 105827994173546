import { Box, Paper } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import type { ReactElement, ReactNode } from "react"
import { z } from "zod"

import { FilterHeader } from "@/screens/Companies/components/filters/FilterHeader.tsx"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchFilterDrawerPropsSchema = z.object({
  open: z.boolean(),
  toggleDrawer: z.function(),
  children: z.custom<ReactNode>(),
  clear: z.function(),
})

type SearchFilterDrawerProps = z.input<typeof SearchFilterDrawerPropsSchema>

export const SearchFilterDrawer = ({ open, toggleDrawer, children, clear }: SearchFilterDrawerProps): ReactElement => {
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={toggleDrawer}
      PaperProps={{
        className: "rounded-l-[1.25rem] h-full max-h-screen",
      }}
      slotProps={{
        backdrop: {
          classes: {
            root: "bg-transparent",
          },
        },
      }}
      elevation={4}
    >
      <Box
        className='flex h-full w-80 flex-col overflow-hidden' // Keep the drawer fixed in height and prevent it from scrolling
        role='presentation'
        component={Paper}
        elevation={0}
      >
        <div className='shrink-0 p-4'>
          <FilterHeader onClear={clear} onClose={toggleDrawer} />
        </div>

        {/* Filter content: the section before the "Account Manager" filter */}
        <div className='flex grow flex-col overflow-hidden'>
          {children} {/* All filters will go here */}
        </div>

        <div className='shrink-0 border-t border-gray-300 p-4'>{/* Footer, if needed */}</div>
      </Box>
    </Drawer>
  )
}
