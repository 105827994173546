import type { SelectChangeEvent } from "@mui/material"
import { Box, Checkbox, Chip, MenuItem, Select, Typography } from "@mui/material"
import { CloseFilledOutlined, KeyboardArrowDownOutlined, MoreHorizOutlined } from "@mui-symbols-material/w300"
import { useCallback, useEffect, useRef, useState } from "react"
import { useController, useFormContext } from "react-hook-form"

import type { ContactSubscription } from "@/graphql/codegen/graphql.ts"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const ContactSubscriptionContent = ({
  subscriptionList,
}: {
  subscriptionList: ContactSubscription[]
}): React.ReactElement => {
  const { control } = useFormContext()
  const { field } = useController({
    name: "batchContactSubscriptionsToCreate",
    control,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)
  const [visibleChips, setVisibleChips] = useState<string[]>([])
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const updateVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 40 // 32 is the width of the MoreHorizOutlined icon
      let totalWidth = 0
      const visibleChipsArray: string[] = []

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(field.value[index].contactSubscriptionId)
          totalWidth += chipWidth
        }
      })
      setVisibleChips(visibleChipsArray)
      setIsOverflowing(visibleChipsArray.length < field.value.length)
    }
  }, [field.value])

  useEffect(() => {
    updateVisibleChips()
  }, [field.value])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[]
    const selectedManagers = selectedIds
      .map((id) => subscriptionList.find((subscription) => subscription.contactSubscriptionId === id))
      .filter(Boolean)
    field.onChange(selectedManagers)
  }

  const handleDelete = (contactSubscriptionId: string) => {
    field.onChange(
      field.value.filter(
        (subscription: ContactSubscription) => subscription.contactSubscriptionId !== contactSubscriptionId
      )
    )
  }

  return (
    <form>
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        Subscriptions
      </Typography>
      <Box className='mt-4'>
        {field.value.map((subscription: ContactSubscription) => (
          <Chip
            key={subscription.contactSubscriptionId}
            label={subscription.name}
            className='mb-2 mr-1 rounded-sm bg-[#FFF0B0] font-[12px] text-gray-900'
            onDelete={() => handleDelete(subscription.contactSubscriptionId)}
            deleteIcon={
              <CloseFilledOutlined
                className='text-gray-900'
                onClick={() => handleDelete(subscription.contactSubscriptionId)}
              />
            }
          />
        ))}
      </Box>
      <Box className='mt-8'>
        <label className='mb-2 block text-sm font-normal text-gray-700'>
          Add all known subscriptions for this contact
        </label>
        <Select
          id='contact-subscription-select'
          multiple
          fullWidth
          MenuProps={MenuProps}
          IconComponent={KeyboardArrowDownOutlined}
          renderValue={() => (
            <Box className='flex items-center overflow-hidden' ref={containerRef}>
              <Box className='flex flex-nowrap gap-1'>
                {visibleChips.map((contactSubscriptionId) => {
                  const subscription = subscriptionList.find(
                    ({ contactSubscriptionId: id }) => id === contactSubscriptionId
                  )
                  return subscription ? (
                    <Chip
                      key={contactSubscriptionId}
                      label={subscription.name}
                      className='mr-1 rounded-sm bg-[#FFF0B0] font-[12px] text-gray-900'
                    />
                  ) : null
                })}
              </Box>
              {isOverflowing && (
                <Box className='mx-1 flex size-6 items-center justify-center rounded bg-[#FFF0B0]'>
                  <MoreHorizOutlined className='ml-0 shrink-0' />
                </Box>
              )}
            </Box>
          )}
          className='mb-2 h-10 border-none hover:border-gray-400'
          value={field.value.map(({ contactSubscriptionId }: ContactSubscription) => contactSubscriptionId)}
          onChange={handleChange}
        >
          {subscriptionList.map(({ contactSubscriptionId, name }) => (
            <MenuItem key={contactSubscriptionId} value={contactSubscriptionId}>
              <Checkbox
                checked={field.value.some(
                  (subscription: ContactSubscription) => subscription.contactSubscriptionId === contactSubscriptionId
                )}
              />
              {name}
            </MenuItem>
          ))}
        </Select>
        <Box className='invisible absolute' ref={measureRef}>
          {field.value.map(({ contactSubscriptionId, name }: ContactSubscription) => (
            <Chip
              key={contactSubscriptionId}
              label={name}
              className='mb-2 mr-1 rounded-sm bg-[#FFF0B0] font-[12px] text-gray-900'
            />
          ))}
        </Box>
      </Box>
    </form>
  )
}
