// AccountManagerChips.tsx
import React, { useMemo } from "react"

import { FilterChip } from "@/screens/Companies/components/filters/FilterChip"
import { useAccountManagerStore } from "@/stores/accountManagerStore"

interface AccountManagerChipsProps {
  accountManagers: string[] | undefined
  onRemove: (key: keyof any, value: string) => void
}

export const AccountManagerChips: React.FC<AccountManagerChipsProps> = ({ accountManagers, onRemove }) => {
  const accountManagerOptions = useAccountManagerStore((state) => state.visibleManagers)

  const accountManagerNames = useMemo(
    () =>
      accountManagers
        ?.map((id: string) => {
          const manager = accountManagerOptions.find((m) => m.accountManagerId === id)
          return manager ? `${manager.firstName} ${manager.lastName}` : ""
        })
        .filter(Boolean) || [],
    [accountManagers, accountManagerOptions]
  )

  const chips = useMemo(
    () =>
      accountManagerNames.map((name, idx) => (
        <FilterChip
          key={`${name}-${idx}`}
          label={`Manager: ${name}`}
          onDelete={() => onRemove("accountManagers", accountManagers![idx])}
        />
      )),
    [accountManagerNames, accountManagers, onRemove]
  )

  return <>{chips}</>
}
