import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material"
import { AddOutlined, CloseOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"

import { useLocations } from "../../../../Contacts/edit/hooks/useLocations"

import { Button } from "@/components/common/Button"
import type { ContactLocationCreateInput } from "@/graphql/codegen/graphql"
import { ContactRole } from "@/graphql/codegen/graphql"
import { AddContactLocationRoleSchema } from "@/screens/Companies/create/components/AddContactSchema"
import { useCreateNewContactModal } from "@/screens/Companies/hooks/useCreateNewContact"

type AddContactLocationRolesProps = {
  open: boolean
  name: string
  contactId: string
  companyId: string
  companyName?: string
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: () => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const AddContactLocationRoles: FunctionComponent<AddContactLocationRolesProps> = ({
  open,
  name,
  contactId,
  companyId,
  companyName,
  onClose,
  onSubmit,
}) => {
  const { createContactLocation } = useCreateNewContactModal()
  const { locations } = useLocations({ companyId })

  const { control, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      locationRoles: [{ locationId: "", role: "" }],
    },
    resolver: zodResolver(AddContactLocationRoleSchema),
    mode: "all",
  })
  const { fields, append } = useFieldArray({
    control,
    name: "locationRoles",
  })
  const formValues = watch("locationRoles")

  const isLastFieldComplete = formValues.some((field) => field.locationId && field.role)

  const handleOnSubmit = useCallback(
    async (data) => {
      const uniqueItems = data.locationRoles.filter(
        (item, index, self) => index === self.findIndex((t) => t.locationId === item.locationId && t.role === item.role)
      )

      await uniqueItems.forEach(({ locationId, role }) => {
        const formattedContactLocation: ContactLocationCreateInput = {
          contactId,
          locationId: locationId,
          contactRole: ContactRole[role as keyof typeof ContactRole],
        }
        createContactLocation({ input: formattedContactLocation })
      })
      onSubmit()
      reset()
      onClose("success")
    },
    [contactId, createContactLocation, onSubmit, onClose]
  )

  const handleResetClick = useCallback(() => {
    onClose("cancel")
    reset()
  }, [onClose])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        onClose(reason)
        reset()
      }}
      fullWidth
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle className='pb-3 pl-10 font-normal text-primary'>
          {name}
          {companyName && (
            <Typography variant='subtitle1' className='text-gray-700'>
              {companyName}
            </Typography>
          )}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => {
            reset()
            onClose("cancel")
          }}
          className='absolute right-4 top-4 text-primary'
        >
          <CloseOutlined />
        </IconButton>
        <Divider />
        <DialogContent classes={{ root: "p-10" }}>
          <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
            Locations Roles
          </Typography>
          <Box className='space-y-10'>
            {fields.map((field, index) => (
              <div key={field.id}>
                <Controller
                  name={`locationRoles.${index}.locationId`}
                  id={`locationRoles.${index}.locationId`}
                  control={control}
                  render={({ field }) => (
                    <Box className='relative'>
                      <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Location</label>
                      <Select
                        {...field}
                        displayEmpty
                        input={<OutlinedInput />}
                        SelectDisplayProps={{
                          className:
                            "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                        }}
                        classes={{
                          icon: "text-gray-600",
                        }}
                        IconComponent={KeyboardArrowDownOutlined}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        variant='outlined'
                        fullWidth
                      >
                        {locations.map((location) => (
                          <MenuItem key={location.locationId} value={location.locationId}>
                            {`${location.address1} - ${location.city} ${location.regionIsoCode}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                />
                <Controller
                  name={`locationRoles.${index}.role`}
                  id={`locationRoles.${index}.role`}
                  control={control}
                  render={({ field }) => (
                    <Box className='relative'>
                      <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Role</label>
                      <Select
                        {...field}
                        displayEmpty
                        input={<OutlinedInput />}
                        SelectDisplayProps={{
                          className:
                            "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                        }}
                        classes={{
                          icon: "text-gray-600",
                        }}
                        IconComponent={KeyboardArrowDownOutlined}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        variant='outlined'
                        fullWidth
                      >
                        {Object.keys(ContactRole).map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                />
              </div>
            ))}
            <Box className='flex w-full justify-end'>
              <Button
                variant='primary'
                appearance='text'
                startIcon={<AddOutlined />}
                onClick={() => append({ locationId: "", role: "" })}
              >
                Role At Location
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={"justify-between px-10 pb-10"}>
          <Button appearance='outlined' onClick={handleResetClick}>
            Cancel
          </Button>
          <Button type='submit' disabled={!isLastFieldComplete}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddContactLocationRoles
