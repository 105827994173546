import { useCallback, useEffect, useState } from "react"
import { useQuery } from "urql"

import type {
  Company,
  CompanyListDetailsQuery,
  CompanyListDetailsQueryVariables,
  CompanyListSuccess,
} from "@/graphql/codegen/graphql"
import { CompanyListDetailsDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20

type UseGetShipViaQueryReturn = {
  data: Company[]
  fetching: boolean
  loadMore: () => void
  triggerQuery: () => void
}

export const useGetShipViaQuery = (): UseGetShipViaQueryReturn => {
  const [offset, setOffset] = useState(0)
  const [intermediaryLocations, setIntermediaryLocations] = useState<Company[]>([])

  const [{ data, fetching }, queryCompanies] = useQuery<CompanyListDetailsQuery, CompanyListDetailsQueryVariables>({
    query: CompanyListDetailsDocument,
    variables: {
      input: {
        filter: { types: ["TRANSPORTATION"] },
        limit: LIMIT,
        offset,
      },
    },
  })

  useEffect(() => {
    if (data) {
      const companyList = (data?.company.list as CompanyListSuccess)?.companies || []

      setIntermediaryLocations((prev) => (offset === 0 ? companyList : [...prev, ...companyList]))
    }
  }, [data])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const triggerQuery = useCallback(() => queryCompanies(), [queryCompanies])

  return {
    data: intermediaryLocations,
    fetching,
    loadMore,
    triggerQuery,
  }
}
