import type { OperationContext, OperationResult } from "urql"
import { useMutation } from "urql"

import type {
  CompanyPaymentTermMutationCreateArgs,
  CompanyPaymentTermMutationUpdateArgs,
  Mutation,
} from "@/graphql/codegen/graphql"
import {
  CreateCompanyPaymentTermMutationDocument,
  UpdateCompanyPaymentTermMutationDocument,
} from "@/graphql/codegen/graphql"

export const useCreateCompanyPaymentTerm = (): {
  createCompanyPayment: (
    variables: CompanyPaymentTermMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "companyPaymentTerm">, CompanyPaymentTermMutationCreateArgs>>
  updateCompanyPayment: (
    variables: CompanyPaymentTermMutationUpdateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "companyPaymentTerm">, CompanyPaymentTermMutationUpdateArgs>>
} => {
  const [, createCompanyPayment] = useMutation<
    Pick<Mutation, "companyPaymentTerm">,
    CompanyPaymentTermMutationCreateArgs
  >(CreateCompanyPaymentTermMutationDocument)

  const [, updateCompanyPayment] = useMutation<
    Pick<Mutation, "companyPaymentTerm">,
    CompanyPaymentTermMutationUpdateArgs
  >(UpdateCompanyPaymentTermMutationDocument)

  return {
    createCompanyPayment,
    updateCompanyPayment,
  }
}
