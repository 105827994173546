import type { OperationContext, OperationResult } from "urql"
import { useMutation } from "urql"

import type {
  ContactMutationAddContactLocationArgs,
  ContactMutationCreateArgs,
  ContactMutationDeleteContactLocationArgs,
  Mutation,
} from "@/graphql/codegen/graphql"
import {
  CreateNewContactLocationMutationDocument,
  CreateNewContactMutationDocument,
  DeleteContactLocationMutationDocument,
} from "@/graphql/codegen/graphql"

export const useCreateNewContactModal = (): {
  createContact: (
    variables: ContactMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "contact">, ContactMutationCreateArgs>>
  createContactLocation: (
    variables: ContactMutationAddContactLocationArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "contact">, ContactMutationAddContactLocationArgs>>
  deleteContactLocation: (
    variables: ContactMutationDeleteContactLocationArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "contact">, ContactMutationDeleteContactLocationArgs>>
} => {
  // Mutation to create a new contact
  const [, createContact] = useMutation<Pick<Mutation, "contact">, ContactMutationCreateArgs>(
    CreateNewContactMutationDocument
  )

  // Mutation to add location and role to a contact
  const [, createContactLocation] = useMutation<Pick<Mutation, "contact">, ContactMutationAddContactLocationArgs>(
    CreateNewContactLocationMutationDocument
  )

  // Mutation to delete location and role to a contact
  const [, deleteContactLocation] = useMutation<Pick<Mutation, "contact">, ContactMutationDeleteContactLocationArgs>(
    DeleteContactLocationMutationDocument
  )

  return {
    createContact,
    createContactLocation,
    deleteContactLocation,
  }
}
