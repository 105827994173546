import { Box, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material"
import { DeleteOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { Controller, useFormContext } from "react-hook-form"

import AddPaymentDiscount from "./AddPaymentDiscount"
import AddPaymentRule from "./AddPaymentRule"

import type { PaymentTermCreateInput } from "@/graphql/codegen/graphql"

const AddPaymentParts = ({
  partsIndex = 0,
  optionsIndex = 0,
  onRemove,
}: {
  partsIndex: number
  optionsIndex: number
  onRemove: (index: number) => void
}): ReactElement => {
  const { control, watch, setValue } = useFormContext<PaymentTermCreateInput>()

  const handleResetRuleValues = () => {
    setValue(`options.${optionsIndex}.parts.${partsIndex}.discount.percentage`, "")
    setValue(`options.${optionsIndex}.parts.${partsIndex}.discount.applicableIfWithinDays`, "")
  }

  return (
    <Box className='rounded-md border border-solid border-gray-300 bg-white p-6'>
      <Box className='flex items-center justify-between gap-3 pb-3'>
        <Typography variant='subtitle1' className='text-lg text-gray-900'>
          Part {partsIndex + 1}
        </Typography>
        <Box className='flex'>
          <Controller
            name={`options.${optionsIndex}.parts.${partsIndex}.includeSalesDiscount`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={watch(`options.${optionsIndex}.parts.${partsIndex}.includeSalesDiscount`) ?? false}
                    name={`options.${optionsIndex}.parts.${partsIndex}.includeSalesDiscount`}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        handleResetRuleValues()
                      }
                      field.onChange(e.target.checked)
                    }}
                  />
                }
                className='mb-1'
                label='Include Sales Discount'
              />
            )}
          />
          <IconButton aria-label='delete parts' onClick={() => onRemove(partsIndex)}>
            <DeleteOutlined />
          </IconButton>
        </Box>
      </Box>
      <AddPaymentRule partsIndex={partsIndex} optionsIndex={optionsIndex} />
      {watch(`options.${optionsIndex}.parts.${partsIndex}.includeSalesDiscount`) && (
        <AddPaymentDiscount partsIndex={partsIndex} optionsIndex={optionsIndex} />
      )}
    </Box>
  )
}

export default AddPaymentParts
