import React, { useMemo } from "react"

import { FilterChip } from "@/screens/Companies/components/filters/FilterChip.tsx"
import type { TreeNode } from "@/types/tree.ts"

interface TypeChipsProps {
  companyTypes: TreeNode<string>[]
  selectedTypes: string[] | undefined
  onRemove: (key: keyof any, value: string) => void
}

export const TypeChips: React.FC<TypeChipsProps> = ({ companyTypes, selectedTypes, onRemove }) => {
  const flattenTree = <T,>(nodes: TreeNode<T>[]): TreeNode<T>[] => {
    if (nodes?.length === 0) {
      return []
    }
    return nodes.reduce((acc: TreeNode<T>[], node) => {
      acc.push(node)
      if (node.children && node.children.length > 0) {
        acc = acc.concat(flattenTree(node.children))
      }
      return acc
    }, [])
  }

  const chips = useMemo(() => {
    const flattenedCompanyTypes = flattenTree(companyTypes)
    const companyTypesWithNames = flattenedCompanyTypes.filter((type) => selectedTypes?.includes(type.id))

    return companyTypesWithNames.map((type) => (
      <FilterChip key={type.id} label={`Type: ${type.name}`} onDelete={() => onRemove("types", type.id)} />
    ))
  }, [companyTypes, selectedTypes, onRemove])

  return <>{chips}</>
}
