import { useMemo } from "react"
import { useQuery } from "urql"
import { useShallow } from "zustand/shallow"

import { GetIncotermsDocument } from "@/graphql/codegen/graphql"
import type {
  GetIncotermsQuery,
  GetIncotermsQueryVariables,
  IncotermsDefinitionMap,
  IncotermsListFailure,
} from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

type UseGetIncotermsQueryResult = {
  data: IncotermsDefinitionMap[]
  fetching: boolean
  loadMore: () => void
}

export const useGetIncotermsQuery = (): UseGetIncotermsQueryResult => {
  const notify = useNotificationsStore(useShallow((s) => s.enqueueNotification))
  const [{ data, fetching, error }, fetch] = useQuery<GetIncotermsQuery, GetIncotermsQueryVariables>({
    query: GetIncotermsDocument,
  })

  const incoterms = useMemo(() => {
    if (fetching) return []
    if (!data || error || data.incoterms.listIncoterms.__typename === "IncotermsListFailure") {
      notify({
        message: "An error occurred",
        type: "error",
      })
      console.error(error || (data?.incoterms?.listIncoterms as IncotermsListFailure).error)

      return []
    }

    return data?.incoterms?.listIncoterms.incoterms.map((incoterm: IncotermsDefinitionMap) => ({
      label: incoterm.value?.name,
      value: incoterm.key,
    })) as IncotermsDefinitionMap[]
  }, [data])

  return {
    data: incoterms,
    fetching,
    loadMore: fetch,
  }
}
