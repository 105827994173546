import { z } from "zod"

import type { File as AerosFile, SignedUrl } from "@/graphql/codegen/graphql"
import { PDFSize } from "@/hooks/usePDFUpload"

export type LabelFileBySize = Record<PDFSize, SignedUrl | null>

export const LABEL_SIZES = [
  { size: PDFSize.Small, label: "Small" },
  { size: PDFSize.Medium, label: "Medium" },
  { size: PDFSize.Large, label: "Large" },
  { size: PDFSize.ExtraLarge, label: "Extra Large" },
] as const

export type LabelInformationFormData = {
  isPrivateLabel: boolean
  isPrintedBySupplier: boolean | null
  isAppliedBySupplier: boolean | null
  pdfs: File[]
  image: File | null
}

// Define literal types for radio values
const RadioValue = z.enum(["true", "false"])
type RadioValue = z.infer<typeof RadioValue>

// Define transformers for consistent boolean conversion
const booleanTransformer = (val: boolean | RadioValue) => (typeof val === "boolean" ? val : val === "true")

const nullableBooleanTransformer = (val: boolean | RadioValue | null) =>
  val === null ? null : typeof val === "boolean" ? val : val === "true"

export const schema = z.object({
  isPrivateLabel: z.union([z.boolean(), RadioValue]).transform(booleanTransformer),
  isPrintedBySupplier: z.union([z.boolean(), RadioValue, z.null()]).transform(nullableBooleanTransformer),
  isAppliedBySupplier: z.union([z.boolean(), RadioValue, z.null()]).transform(nullableBooleanTransformer),
  files: z.array(z.instanceof(File)),
})

// Infer the type with transformed values
export type FormData = {
  isPrivateLabel: boolean
  isPrintedBySupplier: boolean | null
  isAppliedBySupplier: boolean | null
  files: File[]
}

// !!! When updating this function, make sure to update the possible patterns in mapPatternUrlsToSizes
export const createLabelByPattern = (
  isPrivateLabel: boolean,
  isPrintedBySupplier: boolean | null,
  isAppliedBySupplier: boolean | null,
  size: PDFSize
): string => {
  if (!isPrivateLabel) return ""

  if (isPrintedBySupplier === null) {
    console.warn("Missing supplier values:", { isPrintedBySupplier })
    return ""
  }

  const printedBy = isPrintedBySupplier ? "supplier" : "akrochem"
  const appliedBy = isAppliedBySupplier === null ? "null" : isAppliedBySupplier ? "supplier" : "akrochem"

  const pattern = `private_${printedBy}_${appliedBy}_size_${size}`
  return pattern
}

export type LabelInformation = {
  isPrivateLabel: boolean
  isPrintedBySupplier: boolean | null
  isAppliedBySupplier: boolean | null
}

export type LabelFile = {
  type: "pdf" | "image"
  size: PDFSize
  file?: File
  savedFileData: AerosFile | null
}

export const mapStoredFilesToLabelFiles = (files: AerosFile[]): LabelFile[] => {
  return files.map((file) => ({
    type: file.label?.includes(".pdf") ? "pdf" : "image",
    size: file.label?.split("_")[3] as PDFSize,
    savedFileData: file,
  }))
}

/*
  This function is used to map BE returned SignedUrls[] to LabelFiles[]
  @example:
    BE returns [
      { url: "https://example.com/private_supplier_supplier_size_small.pdf", file: { label: "private_supplier_supplier_size_small.pdf" } },
      { url: "https://example.com/private_supplier_supplier_size_medium.pdf", file: { label: "private_supplier_supplier_size_medium.pdf" } },
    ]
    This will return {
      small: { url: "https://example.com/private_supplier_supplier_size_small.pdf", file: { label: "private_supplier_supplier_size_small.pdf" } },
      medium: { url: "https://example.com/private_supplier_supplier_size_medium.pdf", file: { label: "private_supplier_supplier_size_medium.pdf" } },
    }
*/
export const mapSignedUrlsToLabelFiles = (signedUrls: SignedUrl[]): LabelFileBySize => {
  // First group files by size
  const urlsBySize = signedUrls.reduce(
    (acc, item) => {
      if (!item.file?.label) return acc
      const pattern = item.file.label.split("/").pop() || ""
      const size = pattern.split("_")[4] as PDFSize // Extract size from pattern

      if (!acc[size]) {
        acc[size] = []
      }
      acc[size].push(item)
      return acc
    },
    {} as Record<PDFSize, SignedUrl[]>
  )

  // Then get latest file for each size
  const getLatestFile = (files: SignedUrl[]): SignedUrl | null => {
    if (!files?.length) return null
    return files.reduce((latest, current) => {
      if (!latest.file?.createdOn || !current.file?.createdOn) return latest
      return new Date(current.file.createdOn) > new Date(latest.file.createdOn) ? current : latest
    })
  }

  return {
    [PDFSize.Small]: getLatestFile(urlsBySize[PDFSize.Small]) || null,
    [PDFSize.Medium]: getLatestFile(urlsBySize[PDFSize.Medium]) || null,
    [PDFSize.Large]: getLatestFile(urlsBySize[PDFSize.Large]) || null,
    [PDFSize.ExtraLarge]: getLatestFile(urlsBySize[PDFSize.ExtraLarge]) || null,
  }
}
/**
 * Maps signed URLs to a record keyed by their full pattern name
 * @param signedUrls Array of signed URLs to map
 * @returns Record mapping pattern names to signed URLs
 * @example
 * Input: [
 *   {url: "...", file: {label: "private_supplier_supplier_size_small.pdf"}},
 *   {url: "...", file: {label: "private_supplier_supplier_size_medium.pdf"}}
 * ]
 * Returns: {
 *   "private_supplier_supplier_size_small": SignedUrl,
 *   "private_supplier_supplier_size_medium": SignedUrl
 * }
 */
export const mapSignedUrlsToPDFSizePattern = (signedUrls: SignedUrl[]): Record<string, SignedUrl> => {
  const possiblePatterns = [
    "private_supplier_supplier_size_small",
    "private_supplier_supplier_size_medium",
    "private_supplier_supplier_size_large",
    "private_supplier_supplier_size_extraLarge",
    "private_supplier_akrochem_size_small",
    "private_supplier_akrochem_size_medium",
    "private_supplier_akrochem_size_large",
    "private_supplier_akrochem_size_extraLarge",
    "private_akrochem_supplier_size_small",
    "private_akrochem_supplier_size_medium",
    "private_akrochem_supplier_size_large",
    "private_akrochem_supplier_size_extraLarge",
    "private_akrochem_akrochem_size_small",
    "private_akrochem_akrochem_size_medium",
    "private_akrochem_akrochem_size_large",
    "private_akrochem_akrochem_size_extraLarge",
    "private_supplier_null_size_small",
    "private_supplier_null_size_medium",
    "private_supplier_null_size_large",
    "private_supplier_null_size_extraLarge",
    "private_akrochem_null_size_small",
    "private_akrochem_null_size_medium",
    "private_akrochem_null_size_large",
    "private_akrochem_null_size_extraLarge",
  ]

  // Group files by pattern and get latest for each
  return signedUrls.reduce(
    (acc, signedUrl) => {
      if (!signedUrl.file?.label) return acc

      const pattern = signedUrl.file.label.split("/").pop() || ""

      // Only process if pattern is in our allowed list
      if (!possiblePatterns.includes(pattern)) return acc

      // If we already have this pattern, compare dates
      if (acc[pattern]) {
        const existingDate = new Date(acc[pattern].file?.createdOn || 0)
        const currentDate = new Date(signedUrl.file.createdOn || 0)
        if (currentDate > existingDate) {
          acc[pattern] = signedUrl
        }
      } else {
        acc[pattern] = signedUrl
      }

      return acc
    },
    {} as Record<string, SignedUrl>
  )
}

/**
 * Maps pattern URLs to PDF sizes based on label information
 * @param patternUrls Record of pattern URLs mapped by pattern name
 * @param labelInfo Label information containing printing and application preferences
 * @returns Record mapping PDF sizes to signed URLs
 */
export const mapPatternUrlsToSizes = (
  patternUrls: Record<string, SignedUrl>,
  labelInfo: LabelInformation
): Record<PDFSize, SignedUrl | null> => {
  const result: Record<PDFSize, SignedUrl | null> = {
    [PDFSize.Small]: null,
    [PDFSize.Medium]: null,
    [PDFSize.Large]: null,
    [PDFSize.ExtraLarge]: null,
  }

  // Return empty result if not private label
  if (!labelInfo.isPrivateLabel) return result

  // Return empty result if printed by supplier is not set
  if (labelInfo.isPrintedBySupplier === null) return result

  Object.entries(patternUrls).forEach(([pattern, signedUrl]) => {
    // Split pattern into components
    const [type, printedBy, appliedBy, , sizeStr] = pattern.split("_")

    // Skip if not a private label pattern
    if (type !== "private" || printedBy === "null") return

    // Match printed by preference
    const printedByMatches = labelInfo.isPrintedBySupplier ? printedBy === "supplier" : printedBy === "akrochem"

    // Match applied by preference based on null state
    const appliedByMatches =
      labelInfo.isAppliedBySupplier === null
        ? appliedBy === "null"
        : labelInfo.isAppliedBySupplier
          ? appliedBy === "supplier"
          : appliedBy === "akrochem"

    // Only process if both preferences match
    if (printedByMatches && appliedByMatches) {
      // Map size string to PDFSize enum
      const size = sizeStr === "extraLarge" ? PDFSize.ExtraLarge : (sizeStr as PDFSize)
      result[size] = signedUrl
    }
  })

  return result
}
