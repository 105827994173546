import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useForm } from "react-hook-form"

import { useProductSearchStore } from "../stores/useProductsSearchStore"

import type { ProductCreateFailure, ProductCreateSuccess } from "@/graphql/codegen/graphql"
import { ProductFormInfoItem } from "@/graphql/codegen/graphql"
import { AddProductModalSchema } from "@/screens/Products/components/AddProductSchema"
import { useCreateNewProduct } from "@/screens/Products/hooks/useCreateNewProduct"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseAddProductProps {
  companyId: string
}

interface UseAddProductReturn {
  open: boolean
  setOpen: (open: boolean) => void
  methods: UseFormReturn<typeof AddProductModalSchema>
  handleSubmit: (data: any) => void
}

export const useAddProduct = ({ companyId }: UseAddProductProps): UseAddProductReturn => {
  const [open, setOpen] = useState(false)
  const { createProduct } = useCreateNewProduct()
  const { enqueueNotification } = useNotificationsStore()
  const appendData = useProductSearchStore((state) => state.appendData)

  const methods = useForm({
    defaultValues: {
      companyId,
      vendorSku: "",
      vendorDescription: "",
      companyProductDescription: "",
      productManager: { id: "", name: "" },
      locationsAssociations: [{ address1: "", city: "", regionIsoCode: "", locationAssociationId: "" }],
      classification: { id: "", name: "" },
      productFormInfo: "",
      uomId: "",
    },
    resolver: zodResolver(AddProductModalSchema),
    mode: "all",
  })

  const { setError } = methods

  const handleSubmit = async (data: any) => {
    const { productManager, locations, locationsAssociations = [], classification, ...rest } = data
    const isLocationsAssociationsPresent = locationsAssociations.some(
      ({ locationAssociationId }) => !!locationAssociationId
    )
    const product = {
      ...rest,
      companyId,
      classificationId: classification.id,
      productFormInfo: ProductFormInfoItem[data.productFormInfo as keyof typeof ProductFormInfoItem],
      productManagerId: productManager.id,
      locationsAssociations: isLocationsAssociationsPresent
        ? locationsAssociations.map(({ locationAssociationId }) => {
            return { locationAssociationId }
          })
        : null,
      uomId: data.uomId || null,
    }
    const { error, data: result } = await createProduct({
      input: product,
    })
    if (error || (result?.product?.create as ProductCreateFailure)?.error) {
      setError("root", {
        // Keep this as "name"
        type: "server",
        message: error?.message || (result?.product?.create as ProductCreateFailure)?.error?.message,
      })

      enqueueNotification({
        type: "error",
        silent: false,
        content: <p>Product creation failed</p>,
      })
    } else {
      const newProduct = (result?.product?.create as ProductCreateSuccess).product
      appendData([newProduct])
      enqueueNotification({
        type: "success",
        silent: false,
        content: <p>Product successfully created</p>,
      })
    }
    methods.reset()
    setOpen(false)
  }

  return { open, setOpen, methods, handleSubmit }
}
