import type { SelectChangeEvent } from "@mui/material"
import { Box, Checkbox, Chip, Divider, ListSubheader, MenuItem, Select } from "@mui/material"
import { KeyboardArrowDownOutlined, MoreHorizOutlined } from "@mui-symbols-material/w300"
import { memo, useCallback, useRef } from "react"
import { useController } from "react-hook-form"
import type { Control } from "react-hook-form"

import type { TreeNode } from "@/types/tree.ts"

type CompanyTypeSelectProps = {
  companyTypeTree: TreeNode<string>[]
  name: string
  control: Control<any>
}

const CompanyTypeSelect = ({ companyTypeTree, name, control }: CompanyTypeSelectProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)

  const getVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 40
      let totalWidth = 0
      const visibleChipsArray: string[] = []

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(field.value[index])
          totalWidth += chipWidth
        }
      })
      return {
        visibleChips: visibleChipsArray,
        isOverflowing: visibleChipsArray.length < field.value.length,
      }
    }
    return { visibleChips: [], isOverflowing: false }
  }, [field.value])

  const { visibleChips, isOverflowing } = getVisibleChips()

  const isLeafNode = (node: TreeNode<string>) => !node.children || node.children.length === 0

  const handleDelete = useCallback(
    (valueToDelete: string) => {
      const newValue = field.value.filter((value: string) => value !== valueToDelete)
      field.onChange(newValue)
    },
    [field]
  )

  return (
    <>
      <label className='mb-2 block text-sm font-normal text-gray-700'>
        Company Type<span className='text-red-600'>*</span>
      </label>
      <Select
        id='company-type-select'
        multiple
        fullWidth
        IconComponent={KeyboardArrowDownOutlined}
        renderValue={() => (
          <Box className='flex overflow-hidden align-middle' ref={containerRef}>
            <Box className='flex flex-nowrap gap-1'>
              {visibleChips.map((value) => (
                <Chip key={value} label={value} className='rounded-sm' onDelete={() => handleDelete(value)} />
              ))}
            </Box>
            {isOverflowing && (
              <Box className='mx-1 flex size-8 items-center justify-center rounded bg-gray-300'>
                <MoreHorizOutlined className='ml-0 shrink-0' />
              </Box>
            )}
          </Box>
        )}
        className='mb-2 h-10 border-none hover:border-gray-400'
        {...field}
        onChange={(event: SelectChangeEvent<string[]>) => {
          const selectedValues = event.target.value as string[]
          field.onChange(selectedValues)
        }}
      >
        {companyTypeTree.map((category, index) => [
          index > 0 && <Divider key={`divider-${category.id}`} />,
          isLeafNode(category) ? (
            <MenuItem key={category.id} value={category.id}>
              <Checkbox checked={field.value.includes(category.id)} />
              {category.name}
            </MenuItem>
          ) : (
            <ListSubheader key={`${category.id}-subheader`}>{category.name}</ListSubheader>
          ),
          category.children?.map((subCategory) => (
            <MenuItem key={subCategory.id} value={subCategory.id}>
              <Checkbox checked={field.value.includes(subCategory.id)} />
              {subCategory.name}
            </MenuItem>
          )),
        ])}
      </Select>
      {error && <span className='text-sm text-red-600'>{error.message}</span>}
      <Box className='invisible absolute' ref={measureRef}>
        {field.value.map((id: string) => (
          <Chip key={id} label={id} />
        ))}
      </Box>
    </>
  )
}

export default memo(CompanyTypeSelect)
