import { Paper, Typography } from "@mui/material"
import type { ReactElement } from "react"

import { DeliveryTermsContainer } from "./components/DeliveryTermsContainer"
import { ShippingLegsContainer } from "./components/ShippingLegsContainer"
export const DeliveryTermsAndShippingLegsContainer = (): ReactElement => {
  return (
    <Paper className='space-y-7 border border-gray-300 p-6' elevation={0}>
      <Typography variant='subtitle1' fontWeight={400} letterSpacing={0.15}>
        Delivery Terms and Shipping Legs
      </Typography>
      <DeliveryTermsContainer />
      <ShippingLegsContainer />
    </Paper>
  )
}
