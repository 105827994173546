import { Box } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import { type ReactElement } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"

import AddPaymentOption from "./AddPaymentOption"
import AddPayRuleSchedule from "./AddPayRuleSchedule"

import { Button } from "@/components/common/Button"
import { PaymentRuleEventGql, type PaymentTermCreateInput } from "@/graphql/codegen/graphql"

const PaymentTermSection = (): ReactElement => {
  const { control } = useFormContext<PaymentTermCreateInput>()
  const { fields: optionsFields, append: appendOptions } = useFieldArray<PaymentTermCreateInput>({
    control,
    name: "options",
  })

  const handleAddOptions = () => {
    appendOptions({
      parts: [
        {
          paymentRule: {
            daysAfter: "",
            event: PaymentRuleEventGql.PlacingOrder,
            percentage: "",
          },
          includeSalesDiscount: false,
          discount: {
            percentage: "",
            applicableIfWithinDays: "",
          },
        },
      ],
    })
  }

  return (
    <Box className='space-y-10 pb-10'>
      {optionsFields.map((_, index) => (
        <AddPaymentOption optionsIndex={index} key={index} />
      ))}
      <Box className='flex justify-end gap-3'>
        <Button
          color='inherit'
          appearance='outlined'
          className='align-end'
          startIcon={<AddOutlined />}
          disabled={optionsFields.length > 4}
          onClick={handleAddOptions}
        >
          Add Payment Option
        </Button>
      </Box>
      <AddPayRuleSchedule />
    </Box>
  )
}

export default PaymentTermSection
