import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { Location } from "@/graphql/codegen/graphql"
import { LocationListQueryDocument } from "@/graphql/codegen/graphql.ts"
import type { LocationQueryListArgs, Query } from "@/graphql/codegen/graphql.ts"

const LIMIT = 20 // Define the limit for pagination

export const useLocations = ({
  companyId,
}: {
  companyId: string
}): {
  loadMoreLocations: () => void
  reexecuteQueryWithReset: () => void
  locations: Location[]
  fetching: boolean
  error: CombinedError | undefined
  resetLocationList: () => void
} => {
  const [locationListOffset, setLocationListOffset] = useState(0)
  const [locationList, setLocationList] = useState<Location[]>([])
  const [hasMore, setHasMore] = useState(true)

  const [
    { data: companyLocationsData, fetching: companyLocationsFetching, error: companyLocationsError },
    reexecuteCompanyLocationsQuery,
  ] = useQuery<Pick<Query, "location">, LocationQueryListArgs>({
    pause: !companyId,
    query: LocationListQueryDocument,
    variables: { input: { filter: { companyId }, limit: LIMIT, offset: locationListOffset } },
  })

  useEffect(() => {
    if (companyLocationsData?.location.list.__typename === "LocationListSuccess") {
      const locations = companyLocationsData.location.list.locations
      setLocationList((prev: Location[]) => (locationListOffset === 0 ? locations : [...prev, ...locations]))
      if (locations.length < LIMIT) {
        setHasMore(false)
      }
    }
  }, [companyLocationsData, locationListOffset])

  const loadMoreLocations = useCallback(() => {
    if (hasMore) {
      setLocationListOffset((prevOffset) => prevOffset + LIMIT)
    }
  }, [hasMore])

  const resetLocationList = useCallback(() => {
    setLocationListOffset(0)
    setLocationList([])
    setHasMore(true)
  }, [])

  const reexecuteQueryWithReset = useCallback(() => {
    resetLocationList()
    reexecuteCompanyLocationsQuery({ requestPolicy: "network-only" })
  }, [resetLocationList, reexecuteCompanyLocationsQuery])

  return {
    locations: locationList,
    fetching: companyLocationsFetching,
    error: companyLocationsError,
    loadMoreLocations,
    reexecuteQueryWithReset,
    resetLocationList,
  }
}
