import { Box } from "@mui/material"
import type { ReactElement } from "react"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useShallow } from "zustand/shallow"

import { DeliveryTermsForm } from "./DeliveryTermsForm"

import { useDeliveryTerms } from "@/screens/Procurement/PurchaseOrder/DeliveryTermsAndShippingLegs/hooks/deliveryTerms/useDeliveryTerms"
import type { DeliveryTermsFormValues } from "@/screens/Procurement/PurchaseOrder/types"
import { PurchaseOrderSection } from "@/screens/Procurement/PurchaseOrder/types"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

export const DeliveryTermsContainer = (): ReactElement => {
  const deliveryTermsValues = usePurchaseOrderStore((s) => s.formValues[PurchaseOrderSection.DELIVERY_TERMS])

  const methods = useForm<DeliveryTermsFormValues>({
    defaultValues: deliveryTermsValues,
  })

  const {
    incotermsOptions,
    incotermsLocationOptions,
    freightTermsOptions,
    shipViaOptions,
    shipmentStatusOptions,
    loading,
    loadMoreIncoterms,
    loadMoreIncotermsLocation,
    loadMoreShipVia,
  } = useDeliveryTerms({ methods })

  const shippingLegs = usePurchaseOrderStore(useShallow((state) => state.formValues.SHIPPING_LEGS))
  const isShippingLegsFilled = useMemo(() => shippingLegs.length > 0, [shippingLegs])

  return (
    <Box className='flex flex-row gap-4'>
      <DeliveryTermsForm
        methods={methods}
        isShippingLegsFilled={isShippingLegsFilled}
        incotermsOptions={incotermsOptions}
        incotermsLocationOptions={incotermsLocationOptions}
        freightTermsOptions={freightTermsOptions}
        shipViaOptions={shipViaOptions}
        shipmentStatusOptions={shipmentStatusOptions}
        loading={loading}
        loadMoreIncoterms={loadMoreIncoterms}
        loadMoreIncotermsLocation={loadMoreIncotermsLocation}
        loadMoreShipVia={loadMoreShipVia}
      />
    </Box>
  )
}
