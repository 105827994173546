import { Tooltip } from "@mui/material"
import { MoreHorizOutlined } from "@mui-symbols-material/w300"
import type { FC, ReactElement, ReactNode } from "react"
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"

interface ChipData {
  id: string
  name: string
  maxWidth?: number
  icon?: ReactElement
}

interface LimitedSizeChipGroupProps {
  chips: ChipData[]
  maxSize: number
  renderChip: (chip: ChipData, index: number) => ReactNode // Function to render custom chip component
  renderMoreChip?: (hiddenChipsCount: number) => ReactNode // Optional function to render the "more" chip
}

const CHIP_GAP = 4 // Gap between chips
const MORE_ICON_WIDTH = 44 // Width of the "more" icon chip
const CHIP_PADDING = 16 // Combined padding for each chip

const LimitedSizeChipGroup: FC<LimitedSizeChipGroupProps> = ({
  chips,
  maxSize,
  renderChip,
  renderMoreChip, // Allow custom rendering for "more" chip
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [visibleChips, setVisibleChips] = useState<ChipData[]>([])
  const [hiddenChipsCount, setHiddenChipsCount] = useState<number>(0)
  const [chipWidths, setChipWidths] = useState<number[]>([])
  const maxChipWidth = useMemo(() => maxSize - CHIP_GAP - MORE_ICON_WIDTH - CHIP_PADDING, [maxSize])

  const measureChipWidths = useCallback(() => {
    if (!containerRef.current) return

    const chipElements = containerRef.current.querySelectorAll(".chip")
    const widths = Array.from(chipElements).map((chip) => Math.min(chip.getBoundingClientRect().width, maxChipWidth))
    setChipWidths(widths)
  }, [maxChipWidth])

  useLayoutEffect(() => {
    measureChipWidths()
  }, [measureChipWidths])

  const calculateVisibleChips = useCallback(() => {
    if (!containerRef.current) return

    let totalWidth = 0
    let visibleChipCount = 0

    // Iterate over chips and calculate how many can fit
    for (let i = 0; i < chipWidths.length; i++) {
      totalWidth += chipWidths[i] + CHIP_GAP

      if (i === 0) {
        visibleChipCount++
        continue
      }

      if (totalWidth + MORE_ICON_WIDTH > maxSize) {
        break
      }

      visibleChipCount++
    }

    // Edge case: check if two chips can fit with more icon
    if (
      visibleChipCount === 1 &&
      chipWidths.length > 1 &&
      chipWidths[0] + chipWidths[1] + CHIP_GAP * 2 + MORE_ICON_WIDTH <= maxSize
    ) {
      visibleChipCount++
    }

    setVisibleChips(chips.slice(0, visibleChipCount))
    setHiddenChipsCount(chips.length - visibleChipCount)
  }, [chipWidths, chips, maxSize])

  useEffect(() => {
    calculateVisibleChips()

    window.addEventListener("resize", calculateVisibleChips)
    return () => window.removeEventListener("resize", calculateVisibleChips)
  }, [calculateVisibleChips])

  // Default function to render the "more" chip if no custom function is provided
  const defaultRenderMoreChip = (count: number) => (
    <Tooltip title={`${count} more`}>
      <div className='chip'>
        {renderChip(
          { id: "more", name: "More", maxWidth: MORE_ICON_WIDTH, icon: <MoreHorizOutlined /> },
          visibleChips.length
        )}
      </div>
    </Tooltip>
  )

  return (
    <div ref={containerRef} style={{ display: "flex", flexWrap: "nowrap", maxWidth: maxSize }}>
      {chips.length > 0 && (
        <Tooltip key={chips[0].id} title={chips[0].name}>
          <div className='chip'>{renderChip({ ...chips[0], maxWidth: maxChipWidth }, 0)} </div>
        </Tooltip>
      )}
      {visibleChips.slice(1).map((chip, index) => (
        <Tooltip key={chip.id} title={chip.name}>
          <div className='chip'>{renderChip({ ...chip, maxWidth: maxChipWidth }, index)} </div>
        </Tooltip>
      ))}
      {hiddenChipsCount > 0 &&
        (renderMoreChip ? renderMoreChip(hiddenChipsCount) : defaultRenderMoreChip(hiddenChipsCount))}
    </div>
  )
}

export default memo(LimitedSizeChipGroup)
