import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useShallow } from "zustand/react/shallow"

import { useUpdatePO } from "../../../DeliveryTermsAndShippingLegs/hooks/useUpdatePO"
import { PurchaseOrderSection } from "../../../types"
import { KILOGRAM_UOM_ID, POUND_UOM_ID } from "../../ProductAdded/getPurchaseOrderProductTableColumns"

import type { Order, OrderLine } from "@/graphql/codegen/graphql"
import { OrderUpdateInputSchema } from "@/graphql/codegen/graphql"
import { useUnitOfMeasurementsStore } from "@/stores/unitOfMeasurmentsStore"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

export interface ChangePricingUoMFormData {
  uomId: string
  pricePerUom: number
}

interface UseChangePricingUoMFormProps {
  order: Order
  productId: number
  showLoader?: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const useChangePricingUoMForm = ({
  order,
  productId,
  onClose,
  onSuccess,
}: UseChangePricingUoMFormProps): any => {
  const [showLoader, setShowLoader] = useState(false)
  const { updateSectionFields } = usePurchaseOrderStore()
  const orderLine = order.orderLines?.find((line) => line.productId === productId)

  const form = useForm<ChangePricingUoMFormData>({
    values: {
      uomId: orderLine?.uomId ?? POUND_UOM_ID,
      pricePerUom: orderLine?.pricePerUom ?? 0,
    },
  })

  const units = useUnitOfMeasurementsStore(useShallow((s) => s.units))

  // Getting only pounds and kilograms
  const pricingUoMOptions = units
    .filter((unit) => unit.id === POUND_UOM_ID || unit.id === KILOGRAM_UOM_ID)
    .reduce<Record<string, string>>((acc, unit) => {
      acc[unit.id] = unit.name ?? ""
      return acc
    }, {})

  const { updatePO, updatedPO } = useUpdatePO()

  const handleSubmit = (data: ChangePricingUoMFormData) => {
    if (orderLine) {
      setShowLoader(true)
      // api accepts back the whole array of orderLines, we need to update the one we want by finding the one to update and merging the rest
      const restOfProducts = order.orderLines?.filter((line) => line.productId !== productId) ?? []
      const updatedOrderLines = [
        ...restOfProducts,
        {
          ...orderLine,
          ...data,
        },
      ] as OrderLine[]

      const mappedToOrderLineInput = updatedOrderLines.map((line) => ({
        numberOfPallets: line.numberOfPallets ?? 0,
        pricePerUom: line.pricePerUom ?? 0,
        productId: line.productId,
        quantityInUom: line.quantityInUom ?? 0,
        uomId: line.uomId ?? "",
      }))

      const orderUpdateInput = {
        orderId: order.orderId,
        orderLines: mappedToOrderLineInput,
        type: order.type,
        sourceLocationAssociationId: order.sourceLocationAssociationId,
        destinationLocationAssociationId: order.destinationLocationAssociationId,
      }

      const schema = OrderUpdateInputSchema()
      const validatedInput = schema.safeParse(orderUpdateInput)
      if (validatedInput.success) {
        updatePO({
          input: validatedInput.data,
        })
        updateSectionFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines || [])
      } else {
        console.error(validatedInput.error)
      }
    }
  }

  useEffect(() => {
    if (updatedPO) {
      onSuccess?.()
      onClose()
      setShowLoader(false)
    }
  }, [updatedPO])

  return {
    showLoader,
    form,
    pricingUoMOptions,
    handleSubmit: form.handleSubmit(handleSubmit),
  }
}
