import type { TableRowProps } from "@mui/material"
import { TableRow } from "@mui/material"
import { twMerge } from "tailwind-merge"

/**
 * Header row component with light gray background.
 * Should contain AerosTableCell components.
 *
 * @example
 * ```tsx
 * <AerosTableHeadRow>
 *   <AerosTableCell>Header 1</AerosTableCell>
 *   <AerosTableCell>Header 2</AerosTableCell>
 * </AerosTableHeadRow>
 * ```
 *
 * @param props - Extends MUI TableRowProps
 * @param props.className - Additional Tailwind classes for row styling
 */
export const AerosTableHeadRow = ({ className, ...props }: TableRowProps): JSX.Element => {
  return <TableRow className={twMerge(["h-10 border-none"], className)} {...props} />
}
