import { TableBody } from "@mui/material"
import type { Cell } from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import React, { useEffect, useRef } from "react"

import type { Company } from "@/graphql/codegen/graphql"
import { BaseTableCell } from "@/screens/Companies/components/tables/BaseTableCell.tsx"
import { BaseTableVirtualRow } from "@/screens/Companies/components/tables/BaseTableVirtualRow.tsx"
import { EmptyTableRow } from "@/screens/Companies/components/tables/EmptyTableRow.tsx"

export const TableBodyWrapper: React.FC<{
  table: any
  data: any[]
  fetching: boolean
  tableContainerRef: React.RefObject<HTMLDivElement>
}> = ({ table, data, tableContainerRef, fetching }) => {
  const parentRef = useRef<HTMLTableSectionElement>(null)

  const virtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 64,
    overscan: 5,
    measureElement: (element) => element?.getBoundingClientRect().height || 64,
  })

  useEffect(() => {
    virtualizer.measure()
  }, [data, virtualizer])

  if (!data?.length && !fetching)
    return (
      <TableBody>
        <EmptyTableRow />
      </TableBody>
    )

  return (
    <TableBody ref={parentRef} style={{ height: `${virtualizer.getTotalSize()}px`, position: "relative" }}>
      {virtualizer.getVirtualItems().map((virtualRow) => {
        const row = table.getRowModel().rows[virtualRow.index]
        return (
          <BaseTableVirtualRow key={row.id} virtualItem={virtualRow} virtualizer={virtualizer}>
            {row.getVisibleCells().map((cell: Cell<Company, Company>) => (
              <BaseTableCell key={cell.id} {...cell} />
            ))}
          </BaseTableVirtualRow>
        )
      })}
    </TableBody>
  )
}
