import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip, Typography } from "@mui/material"
import { HelpOutlined } from "@mui-symbols-material/w300"
import { type FC, useCallback } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { TextFieldComponent, TooltipLink } from "../Information/TechnicalInformation/TechnicalInformationContent"

import { type ProductCreateInput } from "@/graphql/codegen/graphql"

export const ProductRegulatoryContent: FC = () => {
  const { control, watch, setValue } = useFormContext<ProductCreateInput>()

  const handleChange = useCallback(
    (newValue: boolean, key: string) => {
      setValue(key, newValue)
    },
    [setValue]
  )

  return (
    <form>
      <Box>
        <Box className='flex gap-6'>
          <TextFieldComponent
            control={control}
            name='cassRegistryId'
            label='CAS'
            placeholder='0000-00-0'
            tooltipText={
              <>
                Search CAS registry number® (cas rn®)
                <TooltipLink href='https://commonchemistry.cas.org/detail?cas_rn=58-08-2' text='HERE' />
              </>
            }
          />
          <TextFieldComponent
            control={control}
            name='unNumber'
            label='UN Number'
            placeholder='000'
            tooltipText={
              <>
                Determine whether your goods qualify for USMCA
                <TooltipLink
                  href='https://www.braumillerlaw.com/chemicals-and-the-usmca-its-not-just-how-you-mix-it-to-get-a-reaction-or-not-from-cbp/'
                  text='HERE'
                />
              </>
            }
          />
        </Box>
        <FormControl component='fieldset' variant='standard' className='mt-[27px]'>
          <FormGroup>
            <Controller
              name='isRohsCompliant'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={watch("isRohsCompliant") ?? false}
                      onChange={(event) => handleChange(event.target.checked, "isRohsCompliant")}
                      name='isRohsCompliant'
                    />
                  }
                  className='mb-1'
                  label={
                    <Box className='flex gap-3'>
                      RoHS Compliant
                      <Tooltip
                        title={
                          <Typography className='m-1 w-[155px] text-xs font-medium text-white'>
                            <TooltipLink
                              href='https://en.wikipedia.org/wiki/Restriction_of_Hazardous_Substances_Directive'
                              text='RoHS Compliant'
                            />
                          </Typography>
                        }
                      >
                        <HelpOutlined />
                      </Tooltip>
                    </Box>
                  }
                />
              )}
            />
            <Controller
              name='isReachSvhcCompliant'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={watch("isReachSvhcCompliant") ?? false}
                      onChange={(event) => handleChange(event.target.checked, "isReachSvhcCompliant")}
                      name='isReachSvhcCompliant'
                    />
                  }
                  className='mb-1'
                  label={
                    <Box className='flex gap-3'>
                      REACH SVHS Compliant
                      <Tooltip
                        title={
                          <Typography className='m-1 w-[155px] text-xs font-medium text-white'>
                            <TooltipLink
                              href='https://environment.ec.europa.eu/topics/chemicals/reach-regulation_en'
                              text='REACH SVHS Compliant'
                            />
                          </Typography>
                        }
                      >
                        <HelpOutlined />
                      </Tooltip>
                    </Box>
                  }
                />
              )}
            />
          </FormGroup>
        </FormControl>
      </Box>
    </form>
  )
}
