import { Checkbox, FormControlLabel } from "@mui/material"
import type { FC } from "react"

import type { ContactSubscription } from "@/graphql/codegen/graphql.ts"

export const SubscriptionCheckbox: FC<{
  subscription: ContactSubscription
  value: ContactSubscription["contactSubscriptionId"][]
  onChange: (newValue: ContactSubscription["contactSubscriptionId"][]) => void
}> = ({ subscription, value, onChange }) => {
  const isChecked = value.includes(subscription.contactSubscriptionId)

  const handleCheckboxChange = (checked: boolean) => {
    if (checked) {
      // Add subscription ID if checked
      onChange([...value, subscription.contactSubscriptionId])
    } else {
      // Remove subscription ID if unchecked
      onChange(value.filter((id) => id !== subscription.contactSubscriptionId))
    }
  }

  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} onChange={(e) => handleCheckboxChange(e.target.checked)} />}
      label={subscription.name}
    />
  )
}
