import { useEffect, useMemo } from "react"
import { useQuery } from "urql"

import type { Product, ProductRegulatoryQueryVariables } from "@/graphql/codegen/graphql"
import { ProductRegulatoryDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseRegulatoryProps {
  productId: number
}

type ProductRegulatory = Pick<Product, "cassRegistryId" | "unNumber" | "isRohsCompliant" | "isReachSvhcCompliant">

interface UseRegulatoryResponse {
  data: ProductRegulatory | null
  fetching: boolean
  fetch: (variables: ProductRegulatoryQueryVariables) => void
}

export const useProductRegulatory = ({ productId }: UseRegulatoryProps): UseRegulatoryResponse => {
  const [{ data, error, fetching }, fetch] = useQuery({
    query: ProductRegulatoryDocument,
    variables: {
      productId,
    },
  })

  const hasError = error?.message || data?.product.get.__typename === "ProductGetFailure"
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  useEffect(() => {
    if (hasError) {
      notify({
        message: "Error fetching product regulatory",
        type: "error",
        silent: false,
      })
    }
  }, [hasError, notify])

  const conditions = useMemo(() => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return data.product.get.product
    }
    return null
  }, [data])

  return {
    data: conditions as ProductRegulatory | null,
    fetching,
    fetch,
  }
}
