import { Alert, AlertTitle, Box, Divider, IconButton, TextareaAutosize, Typography } from "@mui/material"
import { AddOutlined, EditOutlined, VisibilityOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent, ReactNode } from "react"
import { Fragment, useState } from "react"

import AddContactComment from "./AddContactComment"

import { Button } from "@/components/common/Button"
import type { Comment } from "@/graphql/codegen/graphql"

type ContactCommentsProps = {
  commentList: Comment[]
  name: string
  contactId: string
  companyName?: string
  onEditComment: (commentId: string, value: string) => void
  reexecuteCommentList: () => void
}

const ContactComments: FunctionComponent<ContactCommentsProps> = (props: ContactCommentsProps) => {
  const { commentList, name, companyName, contactId, onEditComment, reexecuteCommentList } = props
  const [showAddComment, setShowAddComment] = useState(false)
  const [editCommentId, setEditCommentId] = useState<string>("")

  const handleEditComment = async (commentId: string, value: string) => {
    setEditCommentId("")
    await onEditComment(commentId, value)
    reexecuteCommentList()
  }

  return (
    <Box className='gap-2 rounded-md border border-solid border-gray-300 p-6'>
      <Box className='flex justify-between gap-2'>
        <Typography variant='subtitle1' className='text-gray-900'>
          Comments
        </Typography>
      </Box>
      {commentList.length === 0 ? (
        <Box my={2}>
          <Alert
            icon={<WarningOutlined color='primary' />}
            severity='info'
            variant='filled'
            classes={{
              message: "w-full flex flex-row justify-between",
            }}
          >
            <Box>
              <AlertTitle className='font-normal leading-7 text-gray-900'>Start by adding comment(s)</AlertTitle>
              <Box className='w-full font-thin text-blue-gray-600'>
                No comments have been added yet. Once you add some, you will be able to see them here.
              </Box>
            </Box>
            <Box className='flex items-center'>
              <Button size='small' appearance='outlined' onClick={() => setShowAddComment(true)}>
                <AddOutlined />
                New Comment
              </Button>
            </Box>
          </Alert>
        </Box>
      ) : (
        <>
          <Box className='flex items-start gap-4 px-0'>
            <Button
              variant='primary'
              appearance='text'
              className='pl-0'
              startIcon={<AddOutlined />}
              onClick={() => setShowAddComment(true)}
            >
              New Comment
            </Button>
            <Button variant='primary' appearance='text' startIcon={<VisibilityOutlined />}>
              Archived Comments
            </Button>
          </Box>
          <Box className='w-full items-center justify-between py-3'>
            {commentList.map(({ updatedAt, updatedBy, value, commentId }) =>
              value ? (
                <Fragment key={updatedAt}>
                  <Divider />
                  {editCommentId === commentId ? (
                    <EditComment value={value} commentId={commentId} onEditComment={handleEditComment} />
                  ) : (
                    <Box className='group relative pl-3 pt-3 hover:bg-primary-100'>
                      <Box className='group flex w-full justify-between'>
                        <Typography variant='caption' className='text-gray-600'>
                          Posted: {updatedAt} By {updatedBy}
                        </Typography>
                        <IconButton
                          color='primary'
                          aria-label='edit comment'
                          size='small'
                          className='absolute right-2.5 top-0.5 hidden rounded-none group-hover:block'
                          onClick={() => setEditCommentId(commentId ?? "")}
                        >
                          <EditOutlined className='mr-1' />
                          <Typography variant='caption' className='text-xs'>
                            Edit
                          </Typography>
                        </IconButton>
                      </Box>
                      <Box>
                        <Typography variant='body2' className='mt-2 truncate pb-3 text-gray-800'>
                          {value}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Fragment>
              ) : null
            )}
          </Box>
        </>
      )}
      <AddContactComment
        open={showAddComment}
        name={name}
        contactId={contactId}
        companyName={companyName}
        onClose={() => {
          setShowAddComment(false)
        }}
        onSubmit={() => {
          setShowAddComment(false)
          reexecuteCommentList()
        }}
      />
    </Box>
  )
}

export default ContactComments

export const EditComment = ({
  value,
  commentId,
  onEditComment,
}: {
  value: string
  commentId: string
  onEditComment: (commentId: string, value: string) => void
}): ReactNode => {
  const [updatedValue, setUpdatedValue] = useState<string>(value)

  return (
    <div className='relative w-full pt-2'>
      <TextareaAutosize
        minRows={1.5}
        maxRows={1.5}
        className='overflowY-auto w-full rounded-md border border-solid border-gray-300 p-4 pr-[120px] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#BFDBFE]'
        value={updatedValue}
        onChange={(e) => setUpdatedValue(e.target.value)}
      />
      <Button
        onClick={() => onEditComment(commentId, updatedValue)}
        className='absolute right-8 top-6'
        disabled={updatedValue === value}
      >
        Update
      </Button>
    </div>
  )
}
