import { Box, Typography } from "@mui/material"
import { type ReactElement } from "react"

import { ImagePreview } from "./ImagePreview"

interface ImagePreviewGridProps {
  urls: string[]
  onImageClick?: () => void
}

export const ImagePreviewGrid = ({ urls, onImageClick }: ImagePreviewGridProps): ReactElement => {
  if (!urls?.length) {
    return <Typography variant='body2'>No pictures</Typography>
  }

  return (
    <Box className='flex items-center gap-x-4'>
      {urls.map((url) => (
        <ImagePreview key={url} url={url} onClick={onImageClick} />
      ))}
    </Box>
  )
}
