import { jwtDecode } from "jwt-decode"
import { create } from "zustand"

import type { AuthTokens } from "@/graphql/codegen/graphql.ts"
import { redirectToSignIn as handleSignIn, redirectToSignOut } from "@/utils/auth/auth.ts"
import { exchangeTokenWithAPI, refreshTokenWithAPI } from "@/utils/auth/exchangeToken.ts"
import { isTokenExpired } from "@/utils/auth/isTokenExpired.ts"

export interface User {
  id: string
  fullName: string
  oldId: string
  role: string
  email: string
  avatarUrl?: string
}

export interface AuthState {
  idToken: string | null
  accessToken: string | null
  refreshToken: string | null
  isAuthenticated: boolean
  isLoading: boolean
  user: User | null
  setIdToken: (token: string) => void
  setAuthTokens: (tokens: AuthTokens) => void
  clearAuth: () => void
  autoLogout: <T>(fn: () => Promise<T>) => Promise<T>
  checkAuth: () => Promise<void>
  completeAuthentication: () => Promise<void>
  signIn: () => void
  signOut: () => void
  getUser: () => User | null
}

const LOCAL_STORAGE_KEYS = {
  idToken: "id_token",
  accessToken: "access_token",
  refreshToken: "refresh_token",
} as const

export const useAuthStore = create<AuthState>((set, get) => ({
  idToken: localStorage.getItem(LOCAL_STORAGE_KEYS.idToken),
  accessToken: localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken),
  refreshToken: localStorage.getItem(LOCAL_STORAGE_KEYS.refreshToken),
  isAuthenticated: false,
  isLoading: false,
  user: null,

  setIdToken: (token) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.idToken, token)
    set({ idToken: token, isLoading: true })
  },

  setAuthTokens: (tokens) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.accessToken, tokens.accessToken)
    localStorage.setItem(LOCAL_STORAGE_KEYS.refreshToken, tokens.refreshToken)
    set({
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
      isAuthenticated: true,
      isLoading: false,
      user: get().getUser(),
    })
  },

  clearAuth: () => {
    Object.values(LOCAL_STORAGE_KEYS).forEach((key) => localStorage.removeItem(key))
    set({
      idToken: null,
      accessToken: null,
      refreshToken: null,
      isAuthenticated: false,
      user: null,
      isLoading: false,
    })
  },

  autoLogout: async (fn) => {
    try {
      return await fn()
    } catch (err) {
      console.error(err)
      if ((err as Error)?.message === "Unauthorized") {
        get().signOut()
      }
      throw err
    }
  },

  checkAuth: async () => {
    const { idToken, accessToken, refreshToken } = get()
    set({ isLoading: true })

    if (idToken && (!accessToken || isTokenExpired(accessToken))) {
      if (refreshToken) {
        try {
          const tokens = await refreshTokenWithAPI({ accessToken: accessToken!, refreshToken })
          get().setAuthTokens(tokens)
        } catch {
          get().clearAuth()
        }
      } else {
        await get().completeAuthentication()
      }
    } else if (idToken && accessToken && !isTokenExpired(accessToken)) {
      set({ isAuthenticated: true, user: get().getUser() })
    } else {
      get().clearAuth()
    }

    set({ isLoading: false })
  },

  completeAuthentication: async () => {
    const { idToken } = get()
    if (!idToken) {
      set({ isLoading: false, isAuthenticated: false })
      return
    }

    try {
      const tokens = await exchangeTokenWithAPI(idToken)
      get().setAuthTokens(tokens)
    } catch (error) {
      console.error("Authentication failed:", error)
      get().clearAuth()
    }
  },

  signIn: () => handleSignIn(),

  signOut() {
    console.trace("signOut")
    get().clearAuth()
    redirectToSignOut()
  },

  getUser: () => {
    const { idToken } = get()
    if (!idToken) return null

    try {
      const decodedToken = jwtDecode<{
        "cognito:username": string
        "custom:as400_id": string
        "custom:user_role": string
        "custom:avatar_url": string
        name: string
        email: string
      }>(idToken)

      return {
        id: decodedToken["cognito:username"],
        oldId: decodedToken["custom:as400_id"],
        role: decodedToken["custom:user_role"],
        avatarUrl: decodedToken["custom:avatar_url"] ?? undefined,
        fullName: decodedToken.name,
        email: decodedToken.email,
      }
    } catch (error) {
      console.error("Failed to decode ID token:", error)
      return null
    }
  },
}))
