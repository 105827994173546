import type { ButtonProps as BaseButtonProps } from "@mui/base"
import type { ReactNode } from "react"
import { z } from "zod"

export const buttonSchema = z.object({
  size: z.enum(["small", "medium", "large"]).optional().default("medium"),
  variant: z.enum(["primary", "secondary", "error"]).optional().default("primary"),
  appearance: z.enum(["contained", "outlined", "text"]).optional().default("contained"),
  disabled: z.boolean().default(false).optional(),
  startIcon: z.custom<ReactNode>().optional(),
  endIcon: z.custom<ReactNode>().optional(),
})

export type ButtonProps = z.input<typeof buttonSchema> & BaseButtonProps
