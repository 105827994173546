import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material"
import { MoreVertOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { Row } from "@tanstack/react-table"
import type { ReactElement } from "react"
import React, { useCallback, useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import ExpandedLocationRowDropdownMenu from "./ExpandedLocationRowDropdownMenu"

import { Button } from "@/components/common/Button"
import { InputField } from "@/components/common/InputField"
import type { Location, LocationMutationUpdateArgs } from "@/graphql/codegen/graphql.ts"
import { UpdateLocationMutationDocument } from "@/graphql/codegen/graphql.ts"
import ExpandedLocationRowContacts from "@/screens/Companies/edit/components/locations/ExpandedLocationRowContacts.tsx"
import Notes from "@/screens/Companies/view/components/Notes.tsx"

interface VirtualRowProps<Location> {
  row: Row<Location>
}

const visibleCells: Array<keyof Pick<Location, "address1" | "name" | "notes">> = ["address1", "name", "notes"]

const ExpandedLocationRow = ({ row }: VirtualRowProps<Location>): ReactElement => {
  const { companyId } = useParams({ from: "/companies/$companyId/edit" })

  const [notes, setNotes] = useState(row.original?.notes ?? "")
  const [isEditing, setIsEditing] = useState(notes.length === 0)

  const [, updateNote] = useMutation<Pick<Location, "locationId" | "notes">, LocationMutationUpdateArgs>(
    UpdateLocationMutationDocument
  )

  const handleSaveNote = useCallback(async () => {
    if (notes.trim().length > 1) {
      await updateNote({ input: { locationId: row.original.locationId, notes } })
      setIsEditing(false)
    }
  }, [notes])

  useEffect(() => {
    setNotes(row.original?.notes ?? "")
  }, [row])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleViewDetails = () => {
    handleMenuClose()
    alert("View details")
  }

  const handleEditAssociation = () => {
    handleMenuClose()
    alert("Edit association")
  }

  const handleRemoveFromLocation = () => {
    handleMenuClose()
    alert("Remove from location")
  }

  return (
    <TableRow className='flex w-full'>
      {row.getVisibleCells().map((cell) => (
        <TableCell
          key={cell.id}
          className={twMerge([
            cell.column.getSize() === 150 ? "grow" : "grow-0",
            "flex items-start justify-start border-b-gray-300 p-6 text-gray-700",
          ])}
          width={cell.column.getSize()}
        >
          {!visibleCells.includes(cell?.column.id as keyof Pick<Location, "address1" | "name" | "notes">) && null}
          {cell.column.id === "name" && (
            <Typography variant='body2' className='font-light leading-normal'>
              <span className='block'>
                <span className='capitalize text-gray-900'>phone&nbsp;</span>
                <span>{row.original?.phoneNumber || ""}</span>
              </span>
              <span className='block'>
                <span className='capitalize text-gray-900'>alt phone&nbsp;</span>
                <span>{row.original?.altPhoneNumber || ""}</span>
              </span>
              <span className='block'>
                <span className='capitalize text-gray-900'>fax&nbsp;</span>
                <span>{row.original?.faxNumber || ""}</span>
              </span>
            </Typography>
          )}
          {cell.column.id === "address1" && (
            <ExpandedLocationRowContacts companyId={companyId} locationId={row.original.locationId} />
          )}
          {cell.column.id === "notes" && (
            <>
              {!isEditing ? (
                <Box>
                  <Notes>{notes || "No notes added."}</Notes>
                </Box>
              ) : (
                <Box className='flex flex-col'>
                  <InputField
                    label=''
                    placeholder='Add a note...'
                    multiline
                    className='p-4'
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    state='regular'
                    endAdornment={
                      notes.trim().length > 1 && (
                        <Button size='small' appearance='contained' onClick={handleSaveNote}>
                          Add
                        </Button>
                      )
                    }
                  />
                </Box>
              )}
            </>
          )}

          {cell.column.id === "actions" && (
            <>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertOutlined />
              </IconButton>
              <ExpandedLocationRowDropdownMenu
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onViewDetails={handleViewDetails}
                onEditAssociation={handleEditAssociation}
                onRemoveFromLocation={handleRemoveFromLocation}
              />
            </>
          )}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default ExpandedLocationRow
