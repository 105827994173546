import { z } from "zod"

import type { LocationConfirmationDialogs, LocationMenuDialogs } from "../edit/components/locations/types/dialogs"

import type { Country, Location, Region } from "@/graphql/codegen/graphql"
import { LocationCreateInputSchema, LocationUpdateInputSchema } from "@/graphql/codegen/graphql"

// ===== Common Types =====

export type DialogCloseAction = "close" | "cancel" | "success" | "backdropClick" | "escapeKeyDown"

type DialogBaseProps = {
  open: boolean
}

// ===== Location Update and Create Schemas =====

export const LocationUpdateSchema = LocationUpdateInputSchema()
export const LocationCreateSchema = LocationCreateInputSchema()

// ===== Shared Schema Picks =====

const AddressSchema = LocationUpdateSchema.pick({
  address1: true,
  address2: true,
  city: true,
  countryIsoCode: true,
  regionIsoCode: true,
  postalCode: true,
  name: true,
}).extend({
  address1: z.string().min(1, "Address is required"),
  address2: z.string().optional().default(""),
  city: z.string().min(1, "City is required"),
  countryIsoCode: z.string().min(1, "Country is required"),
  regionIsoCode: z.string().nullish(),
  postalCode: z.string().nullish(),
  name: z.string().min(1, "Name is required"),
})

const ContactSchema = LocationUpdateSchema.pick({
  phoneNumber: true,
  altPhoneNumber: true,
  faxNumber: true,
}).extend({
  phoneNumber: z.string().nullish(),
  altPhoneNumber: z.string().nullish(),
  faxNumber: z.string().nullish(),
})

const NotesSchema = LocationUpdateSchema.pick({
  notes: true,
})

// ===== Shared Types ====

export type AddressFields = z.infer<typeof AddressSchema>
export type ContactFields = z.infer<typeof ContactSchema>
export type NotesSchema = z.infer<typeof NotesSchema>
export type LocationFormFields = AddressFields & ContactFields & NotesSchema

// ===== Location Update Types =====

// Schema and types for editing location
export const EditAddressQuerySchema = AddressSchema
export type EditAddressQueryProps = z.infer<typeof EditAddressQuerySchema>
export interface EditAddressDialogProps extends DialogBaseProps {
  onSubmit: (data: EditAddressQueryProps) => void
  activeEditLocation: Location | undefined
  openedDialog: LocationMenuDialogs | LocationConfirmationDialogs | null
  onClose: (reason: DialogCloseAction) => void
}

// Schema and types for editing contact
export const EditContactQuerySchema = ContactSchema
export type EditContactQueryProps = z.infer<typeof EditContactQuerySchema>
export interface EditContactDialogProps extends DialogBaseProps {
  onSubmit: (data: EditContactQueryProps) => void
  activeEditLocation: Location | undefined
  openedDialog: LocationMenuDialogs | LocationConfirmationDialogs | null
  onClose: (reason: DialogCloseAction) => void
}

// Schema and types for editing notes
export const EditNotesQuerySchema = NotesSchema
export type EditNotesQueryProps = z.infer<typeof EditNotesQuerySchema>
export interface EditNotesDialogProps extends DialogBaseProps {
  onSubmit: (data: EditNotesQueryProps) => void
  activeEditLocation: Location | undefined
  openedDialog: LocationMenuDialogs | LocationConfirmationDialogs | null
  onClose: (reason: DialogCloseAction) => void
}

// ===== Location Create Types =====

// Schema and types for adding address
export const AddAddressQuerySchema = AddressSchema
export type AddAddressQueryProps = z.infer<typeof AddAddressQuerySchema>
export interface AddAddressDialogProps extends DialogBaseProps {
  data: AddressFields
  onSubmit: (data: AddAddressQueryProps) => void
  onClose: ({ data, reason }: { data: AddressFields | Record<string, any>; reason: DialogCloseAction }) => void
}

// Schema and types for adding contact
export const AddContactQuerySchema = ContactSchema
export type AddContactQueryProps = z.infer<typeof AddContactQuerySchema>
export interface AddContactDialogProps extends DialogBaseProps {
  data: ContactFields
  addressDetails: Location
  countryList: Country[]
  regionList: Region[]
  onSubmit: (data: AddContactQueryProps) => void
  onClose: ({ data, reason }: { data: ContactFields | Record<string, any>; reason: DialogCloseAction }) => void
}

// Schema and types for adding notes
export const AddNotesQuerySchema = NotesSchema
export type AddNotesQueryProps = z.infer<typeof AddNotesQuerySchema>
export interface AddNotesDialogProps extends DialogBaseProps {
  data: NotesSchema
  onSubmit: (data: AddNotesQueryProps) => void
  onClose: ({ data, reason }: { data: NotesSchema | Record<string, any>; reason: DialogCloseAction }) => void
}
