import { useEffect, useMemo } from "react"
import { useQuery } from "urql"

import type { Product, ProductOriginInformationQueryVariables } from "@/graphql/codegen/graphql"
import { ProductOriginInformationDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseOriginProps {
  productId: number
}

type ProductOrigin = Pick<Product, "cityOfOrigin" | "countryOfOrigin" | "locationsAssociations" | "locationOriginId">

interface UseOriginResponse {
  data: ProductOrigin | null
  fetching: boolean
  fetch: (variables: ProductOriginInformationQueryVariables) => void
}

export const useProductOrigin = ({ productId }: UseOriginProps): UseOriginResponse => {
  const [{ data, error, fetching }, fetch] = useQuery({
    query: ProductOriginInformationDocument,
    variables: {
      productId,
    },
  })

  const hasError = error?.message || data?.product.get.__typename === "ProductGetFailure"
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  useEffect(() => {
    if (hasError) {
      notify({
        message: "Error fetching product origin information",
        type: "error",
      })
    }
  }, [hasError, notify])

  const origin = useMemo(() => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return data.product.get.product
    }
    return null
  }, [data])

  return {
    data: origin as ProductOrigin | null,
    fetching,
    fetch,
  }
}
