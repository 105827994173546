import type { OperationContext, OperationResult } from "urql"
import { useMutation } from "urql"

import type { Mutation, PricingPackageMutationCreateArgs } from "@/graphql/codegen/graphql"
import { CreatePricingTierMutationDocument } from "@/graphql/codegen/graphql"

export const useCreatePricingTier = (): {
  createPricingTier: (
    variables: PricingPackageMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "pricingPackage">, PricingPackageMutationCreateArgs>>
} => {
  const [, createPricingTier] = useMutation<Pick<Mutation, "pricingPackage">, PricingPackageMutationCreateArgs>(
    CreatePricingTierMutationDocument
  )

  return {
    createPricingTier,
  }
}
