import { useCallback, useEffect, useState } from "react"

import type { ProductSelectionItem, PurchaseOrderFormData } from "../Supplier/types/types"

import { usePurchaseOrder } from "./usePurchaseOrder"

type UsePurchaseOrderDialogReturn = {
  open: boolean
  fetching: boolean
  handleOpen: () => void
  handleClose: () => void
  handleSubmit: (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => void
}

export const usePurchaseOrderDialog = (): UsePurchaseOrderDialogReturn => {
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { handleCreatePO, createdPO, fetching } = usePurchaseOrder()

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const handleSubmit = useCallback(
    (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => {
      setIsSubmitting(true)
      handleCreatePO(data, selectedProducts)
    },
    [handleCreatePO, handleClose]
  )

  useEffect(() => {
    if (!fetching && isSubmitting && createdPO) {
      setIsSubmitting(false)
      handleClose()
    }
  }, [fetching, isSubmitting, handleClose, createdPO])

  return { open, fetching, handleOpen, handleClose, handleSubmit }
}
