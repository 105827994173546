import { cva } from "class-variance-authority"

const buttonVariants = cva(
  "inline-flex cursor-pointer items-center justify-center gap-2 text-nowrap rounded-md font-medium transition-colors duration-150 focus:outline-primary-300", // Base styles
  {
    variants: {
      size: {
        small: "h-8 px-2.5 py-1 text-sm",
        medium: "h-10 px-4 py-2 text-base",
        large: "h-12 px-[22px] py-2 text-lg",
      },
      variant: {
        primary: "",
        secondary: "",
        error: "",
      },
      appearance: {
        contained: "",
        outlined: "",
        text: "",
      },
      disabled: {
        true: "cursor-not-allowed opacity-50",
        false: "",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        appearance: "contained",
        class: "border-primary bg-primary text-white hover:border-primary-700 hover:bg-primary-700",
      },
      {
        variant: "primary",
        appearance: "outlined",
        class: "border border-primary-400 bg-transparent text-primary hover:bg-primary-100",
      },
      {
        variant: "primary",
        appearance: "text",
        class: "border-0 bg-transparent text-primary hover:bg-primary-100",
      },
      {
        variant: "secondary",
        appearance: "contained",
        class: "border-gray-900 bg-gray-900 text-white hover:border-black hover:bg-black",
      },
      {
        variant: "secondary",
        appearance: "outlined",
        class: "border border-gray-700 bg-transparent text-gray-900 hover:bg-gray-200",
      },
      {
        variant: "error",
        appearance: "contained",
        class: "border-secondary-600 bg-secondary-600 text-white hover:border-secondary-700 hover:bg-secondary-700",
      },
      {
        variant: "error",
        appearance: "outlined",
        class: "border border-secondary-600 bg-transparent text-secondary-600 hover:bg-secondary-100",
      },
      {
        disabled: true,
        appearance: "contained",
        class: "border-none bg-gray-300 text-gray-500",
      },
      {
        disabled: true,
        appearance: "outlined",
        class: "border border-gray-400 bg-white text-gray-500",
      },
      {
        disabled: true,
        appearance: "text",
        class: "border-0 bg-white text-gray-500",
      },
    ],
    defaultVariants: {
      size: "medium",
      variant: "primary",
      appearance: "contained",
      disabled: false,
    },
  }
)

export default buttonVariants
