import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material"
import { AddOutlined, InfoOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import { Link } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { FormProvider, useForm } from "react-hook-form"

import type { PurchaseOrderDialogFormValues, PurchaseOrderDialogProps } from "../types"
import { PurchaseOrderDialogSchema } from "../types"

import { ProductsAdded } from "./ProductsAdded"

import { Button } from "@/components/common/Button"
import { FieldRow, Form, FormField, renderSelectPlaceholder } from "@/components/Forms"
import type { Product } from "@/graphql/codegen/graphql"

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"
const selectSlotProps = { input: { className: InputFieldClasses } }

export const PurchaseOrderDialog = ({
  open,
  fetching,
  onClose,
  products,
  shipToLocations,
  onSubmit,
  company,
}: PurchaseOrderDialogProps): ReactElement => {
  const methods = useForm<PurchaseOrderDialogFormValues>({
    resolver: zodResolver(PurchaseOrderDialogSchema),
    defaultValues: {
      shipTo: "",
    },
  })

  const handleClose = () => {
    onClose()
    methods.reset()
  }

  const handleFormSubmit = (data: PurchaseOrderDialogFormValues) => {
    onSubmit(
      {
        ...data,
        freightTerms: products[0]?.freightItem,
        incoterms: null, // TODO: Add Incoterms; Currently blocked by Feature Rework & BE
        deliveryTerms: null, // TODO: Add Delivery Terms ; Currently blocked by Feature Rework & BE
      },
      products
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle className='px-[38px] font-normal text-primary'>Start Purchase Order</DialogTitle>
      <DialogContent className='px-[38px]'>
        <Box className='space-y-4 rounded-md'>
          <ProductsAdded products={products as unknown as Product[]} />
          <Box className='text-sm text-gray-600'>
            <Box component='p' className='flex h-8 items-center'>
              <Box component='span' className='text-gray-900'>
                Supplier:
              </Box>
              &nbsp;
              <Box component='span' className='text-gray-700'>
                {company?.name || "-"}
              </Box>
            </Box>
            <Box component='p' className='flex h-8 items-center'>
              <Box component='span' className='text-gray-900'>
                Ship From:
              </Box>
              &nbsp;
              <Box component='span' className='text-gray-700'>
                {company?.locationsAssociations?.[0]?.shipping?.name || "-"}
              </Box>
            </Box>
            <Box className='flex h-8 items-center'>
              <Box component='span' className='text-gray-900'>
                Incoterms:
              </Box>
              &nbsp;
              <Box component='span' className='text-gray-700'>
                {/* TODO: Add Incoterms */}-
              </Box>
              <Tooltip title='More info about Incoterms' arrow>
                <IconButton size='small' className='ml-1'>
                  <InfoOutlined fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className='flex h-8 items-center'>
              <Box component='span' className='text-gray-900'>
                Freight Terms:
              </Box>
              &nbsp;
              <Box component='span' className='text-gray-700'>
                {/* TODO: Add Freight Terms */}-
              </Box>
              <Tooltip title='Terms can be changed later' arrow>
                <IconButton size='small' className='ml-1'>
                  <InfoOutlined fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Divider />
          <Typography variant='body1' fontWeight={700} className='text-gray-700'>
            Shipping Information
          </Typography>
          <FormProvider {...methods}>
            <Form className='space-y-4'>
              <FieldRow>
                <FormField
                  name='shipTo'
                  label='Ship To'
                  required
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      {...field}
                      slotProps={selectSlotProps}
                      type='text'
                      error={!!error}
                      fullWidth
                      variant='outlined'
                      displayEmpty
                      IconComponent={KeyboardArrowDownOutlined}
                      renderValue={renderSelectPlaceholder({ field, placeholderText: "Select Location" })}
                    >
                      {field.value === "" && <MenuItem value=''>Select Location</MenuItem>}
                      {shipToLocations.map((location) => (
                        <MenuItem
                          key={location.companyLocationAssociationId}
                          value={location.companyLocationAssociationId}
                        >
                          {location.shipping?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FieldRow>
            </Form>
          </FormProvider>
        </Box>
      </DialogContent>
      <DialogActions className='justify-between p-10'>
        <Button onClick={handleClose} appearance='outlined'>
          Cancel
        </Button>
        <Box className='flex items-center space-x-4'>
          <Button appearance='outlined' startIcon={<AddOutlined />}>
            <Link to={`/companies/${company?.companyId}/edit/products`}>Add More Products</Link>
          </Button>
          <Button
            type='submit'
            onClick={methods.handleSubmit(handleFormSubmit)}
            variant='primary'
            disabled={!methods.formState.isValid || fetching}
            endIcon={
              fetching ? (
                <Box className='flex items-center'>
                  <CircularProgress size={16} className={fetching ? "text-white disabled:text-primary" : ""} />
                  &nbsp;
                </Box>
              ) : null
            }
          >
            Continue
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
