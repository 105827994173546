import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { AddCircleOutlined, EditOutlined, MoreVertOutlined, PauseOutlined } from "@mui-symbols-material/w300"
import type { Row } from "@tanstack/react-table"
import type { ReactNode } from "react"
import { useCallback, useRef, useState } from "react"

import type { Product } from "@/graphql/codegen/graphql"
import { useProductActionsStore } from "@/screens/Companies/products/stores/useProductsActions"
import { ProductActions, ProductActionsDialogs } from "@/screens/Companies/products/types/schema"

export const ProductActionMenu = ({ original: product }: Row<Product>): ReactNode => {
  const anchorElRef = useRef<HTMLButtonElement>(null)
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)
  const onAction = useProductActionsStore((state) => state.onAction)
  const setProductAtAction = useProductActionsStore((state) => state.setProductAtAction)

  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
    setProductAtAction(product)
  }, [product, setProductAtAction])
  const handleClose = useCallback(() => {
    setOpen(false)
    setProductAtAction(null)
  }, [setProductAtAction])

  return (
    <Box className='flex items-center justify-center'>
      <IconButton onClick={handleOpen} ref={anchorElRef}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorElRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => setOpenedDialog(ProductActionsDialogs.EDIT_PRODUCT)}>
          <ListItemIcon>
            <EditOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
          </ListItemIcon>
          <ListItemText primary='Edit Product' />
        </MenuItem>
        <MenuItem onClick={() => onAction(ProductActions.EDIT_PRODUCT_DETAILS)}>
          <ListItemIcon>
            <EditOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
          </ListItemIcon>
          <ListItemText primary='Edit Product Details' />
        </MenuItem>
        {product.isActive ? (
          <MenuItem onClick={() => onAction(ProductActions.DEACTIVATE_PRODUCT)}>
            <ListItemIcon>
              <PauseOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
            </ListItemIcon>
            <ListItemText primary='Deactivate Product' />
          </MenuItem>
        ) : (
          <MenuItem onClick={() => onAction(ProductActions.ACTIVATE_PRODUCT)}>
            <ListItemIcon>
              <AddCircleOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
            </ListItemIcon>
            <ListItemText primary='Activate' />
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
