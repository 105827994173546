import { useMemo } from "react"
import type { OperationContext, OperationResult } from "urql"
import { useMutation, useQuery } from "urql"

import type {
  AccountManagerListSuccess,
  CompanyListSuccess,
  CompanyMutationCreateArgs,
  CompanyMutationUpdateArgs,
  CompanyTypeTreeSuccess,
  Mutation,
  Query,
} from "@/graphql/codegen/graphql"
import {
  AccountManagerListQueryDocument,
  CompanyListBasicDocument,
  CompanyTypeTreeDocument,
  CreateNewCompanyMutationDocument,
  UpdateNewCompanyMutationDocument,
} from "@/graphql/codegen/graphql"
import useDebounce from "@/utils/useDebounce.tsx"

export const useCreateNewCompanyModal = (
  modalNameInput = ""
): {
  companyNameListFetching: boolean
  reexecuteCompanyNameQuery: (opts?: Partial<OperationContext>) => void
  accountsManagerList: any[]
  companyTypeFetching: boolean
  companyNameList: string[]
  updateCompany: (
    variables: CompanyMutationUpdateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "company">, CompanyMutationUpdateArgs>>
  companyTypeList: "JSON" | any[]
  accountsManagerListFetching: boolean
  createCompany: (
    variables: CompanyMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "company">, CompanyMutationCreateArgs>>
} => {
  // Query for company name list
  const debouncedModalNameInput = useDebounce(modalNameInput, 300)
  const [{ data: companyNameListData, fetching: companyNameListFetching }, reexecuteCompanyNameQuery] = useQuery<
    Pick<Query, "company">
  >({
    query: CompanyListBasicDocument,
    variables: { filter: { query: debouncedModalNameInput }, limit: 50, offset: 0 },
    pause: debouncedModalNameInput.length < 3,
  })

  // Query for company type list
  const [{ data: companyTypeData, fetching: companyTypeFetching }] = useQuery<Pick<Query, "companyType">>({
    query: CompanyTypeTreeDocument,
  })

  // Query for accounts manager list
  const [{ data: accountsManagerListData, fetching: accountsManagerListFetching }] = useQuery<
    Pick<Query, "accountManager">
  >({
    query: AccountManagerListQueryDocument,
    variables: { input: { limit: 0 } },
  })

  // Mutation to create a new company
  const [, createCompany] = useMutation<Pick<Mutation, "company">, CompanyMutationCreateArgs>(
    CreateNewCompanyMutationDocument
  )

  // Mutation to update a company
  const [, updateCompany] = useMutation<Pick<Mutation, "company">, CompanyMutationUpdateArgs>(
    UpdateNewCompanyMutationDocument
  )

  // Memoize the company name list
  const companyNameList = useMemo(() => {
    return ((companyNameListData?.company.list as CompanyListSuccess)?.companies || []).map((company) => company.name)
  }, [companyNameListData])

  // Memoize the company type list
  const companyTypeList = useMemo(() => {
    return (companyTypeData?.companyType?.tree as CompanyTypeTreeSuccess)?.tree || []
  }, [companyTypeData])

  // Memoize the accounts manager list
  const accountsManagerList = useMemo(() => {
    return (accountsManagerListData?.accountManager?.list as AccountManagerListSuccess)?.accountManagers || []
  }, [accountsManagerListData])

  return {
    companyNameList,
    companyNameListFetching,
    reexecuteCompanyNameQuery,
    companyTypeList,
    companyTypeFetching,
    accountsManagerList,
    accountsManagerListFetching,
    createCompany,
    updateCompany,
  }
}
