import { Box, CircularProgress, Typography } from "@mui/material"
import { SearchOutlined } from "@mui-symbols-material/w300"
import React, { useCallback, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"

import { AccountManagerCheckbox } from "./AccountManagerCheckbox"

import { InputField } from "@/components/common/InputField"
import type { CompanyListFilter } from "@/graphql/codegen/graphql.ts"
import { useAccountManagers } from "@/hooks/useAccountManagers"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll.ts"

export const AccountManagerFilter = React.memo<{
  onFilterChange: (name: keyof CompanyListFilter, value: any) => void
}>(({ onFilterChange }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { control, watch } = useFormContext()
  const accountManagersValue = watch("accountManagers") || []

  const { visibleManagers, fetching, loadMore } = useAccountManagers()

  const filteredManagers = useMemo(() => {
    const normalizedSearchTerm = searchTerm.trim().toLocaleLowerCase()
    const seen = new Set<string>()

    return visibleManagers.filter((manager) => {
      const fullName = `${manager.firstName} ${manager.lastName}`.toLocaleLowerCase()

      if (seen.has(manager.accountManagerId)) {
        return false
      }

      const isMatch = fullName.includes(normalizedSearchTerm)

      if (isMatch) {
        seen.add(manager.accountManagerId)
        return true
      }

      return false
    })
  }, [searchTerm, visibleManagers])

  const { containerRef } = useInfiniteScroll(loadMore, {
    isLoading: fetching,
    hasMore: visibleManagers.length % 20 === 0, // Assuming 20 is the page size
    threshold: 200,
  })

  const handleFilterChange = useCallback(
    (newValue: string[]) => {
      onFilterChange("accountManagers", newValue) // Update the selected accountManager IDs in the filter
    },
    [onFilterChange]
  )

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
        Account Manager
      </Typography>

      <Box className='flex-none p-4'>
        <InputField
          placeholder='Search by Name'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={<SearchOutlined className='text-gray-600' />}
          endAdornment={fetching && <CircularProgress size={20} />}
        />
      </Box>

      <Box className='flex grow flex-col overflow-hidden'>
        <Box className='grow overflow-y-auto px-8 py-2' ref={containerRef}>
          {filteredManagers.map((manager, index) => (
            <AccountManagerCheckbox
              key={manager.accountManagerId}
              manager={manager}
              control={control}
              name='accountManagers'
              accountManagersValue={accountManagersValue}
              onFilterChange={handleFilterChange}
              className={index === filteredManagers.length - 1 && visibleManagers.length % 20 !== 0 ? "grow" : ""}
            />
          ))}
          {fetching && (
            <Box display='flex' justifyContent='center' py={2}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
})
