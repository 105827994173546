import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { DeleteOutlined, EditOutlined, VisibilityOutlined } from "@mui-symbols-material/w300"

interface ContactRowDropdownMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  onViewDetails: () => void
  onEditAssociation: () => void
  onRemoveFromLocation: () => void
}

const ExpandedLocationRowDropdownMenu: React.FC<ContactRowDropdownMenuProps> = ({
  anchorEl,
  onClose,
  onViewDetails,
  onEditAssociation,
  onRemoveFromLocation,
}) => {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem onClick={onViewDetails}>
        <ListItemIcon>
          <VisibilityOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Contact Details' />
      </MenuItem>
      <MenuItem onClick={onEditAssociation}>
        <ListItemIcon>
          <EditOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Edit Association' />
      </MenuItem>
      <MenuItem onClick={onRemoveFromLocation}>
        <ListItemIcon>
          <DeleteOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Remove from Location' />
      </MenuItem>
    </Menu>
  )
}

export default ExpandedLocationRowDropdownMenu
