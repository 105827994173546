import { CompanySortField, CompanyStatus } from "@/graphql/codegen/graphql.ts"
import { createStatusMap } from "@/utils/statusMap.ts"

export const supplierStatusMap = createStatusMap<
  CompanyStatus,
  "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"
>([
  [CompanyStatus.Active, "success"],
  [CompanyStatus.Pending, "warning"],
  [CompanyStatus.Rejected, "error"],
  [CompanyStatus.Inactive, "secondary"],
])

export const supplierProductStatusMap = createStatusMap<
  boolean,
  "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"
>([
  [true, "success"],
  [false, "secondary"],
])

export type SortableAccessorKey = "accountManagers" | "types" | "name"

export const accessorKeyToSupplierSortField: ReadonlyMap<SortableAccessorKey, CompanySortField> = new Map([
  ["name", CompanySortField.Name],
  // todo: companyId
  // todo: shipFrom
  // todo: stockLevel
])

export const supplierSortFieldToAccessoryKey: ReadonlyMap<CompanySortField, SortableAccessorKey> = new Map([
  [CompanySortField.Name, "name"],
  // todo: companyId
  // todo: shipFrom
  // todo: stockLevel
])

export const SUPPLIER_QUERY_TYPES = ["COMMODITY_VENDOR"]
