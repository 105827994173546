import { Box } from "@mui/material"
import { useMemo, useState } from "react"
import type { ReactElement } from "react"
import { useShallow } from "zustand/shallow"

import { ChangePricingUoMDialog } from "../Dialogs/ChangePricingUoMDialog"

import { getPurchaseOrderProductTableColumns } from "./getPurchaseOrderProductTableColumns"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { Product } from "@/graphql/codegen/graphql"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

interface SelectedProduct {
  isOpen: boolean
  product: Product | null
}

export const ProductAddedTable = (): ReactElement => {
  const currentPO = usePurchaseOrderStore(useShallow((state) => state.currentPO))
  const orderLines = usePurchaseOrderStore(useShallow((state) => state.formValues.ORDER_LINES))
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct>({
    isOpen: false,
    product: null,
  })

  const updatedOrderDetails = useMemo(() => {
    return {
      ...currentPO,
      orderLines,
    }
  }, [currentPO, orderLines])

  const handleOpenDialog = (product: Product) => {
    setSelectedProduct({ isOpen: true, product })
  }

  const handleCloseDialog = () => {
    setSelectedProduct({ isOpen: false, product: null })
  }

  const columns = useMemo(() => getPurchaseOrderProductTableColumns(handleOpenDialog), [])

  return (
    <>
      <Box className='mt-5 flex size-full flex-col shadow-none'>
        <AerosBaseTable
          slotProps={{
            bodyRow: { className: "h-[122px] text-gray-700 first:px-3 " },
            bodyCell: { className: "border-b border-gray-300" },
            headerRow: { className: "h-10 border-b border-gray-500 bg-gray-100 text-gray-700" },
          }}
          features={{
            tanstackOptions: {
              data: orderLines || [],
              columns,
            },
          }}
          noDataMessage='No products added'
        />
      </Box>
      <ChangePricingUoMDialog
        open={selectedProduct.isOpen}
        onClose={handleCloseDialog}
        order={updatedOrderDetails}
        productId={selectedProduct.product?.productId ?? 0}
      />
    </>
  )
}
