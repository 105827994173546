import { TableCell, TableRow } from "@mui/material"
import type { ReactElement } from "react"

export const EmptyTableRow = (): ReactElement => (
  <TableRow className='flex w-full'>
    <TableCell
      colSpan={3}
      className='flex h-[64px] items-center justify-start border-none p-4 text-gray-700 first:pr-0'
    >
      No results found according to your search criteria
    </TableCell>
  </TableRow>
)
