import { Box } from "@mui/material"
import React from "react"
import { twMerge } from "tailwind-merge"

interface FieldRowProps {
  children: React.ReactNode
  className?: string
}

/**
 * Displays a row of evenly distributed form fields
 *
 * @param props The component's props
 * @param props.children The children to render (the form fields)
 * @param props.className The class name to apply to the row. Optional
 *
 * @example
 * ```tsx
 * <FieldRow>
 *   <FormField
 *     name="my-field-1"
 *     label="My First Field"
 *     render={({ field, fieldState: { error } }) => (
 *       <TextField
 *         {...field}
 *         placeholder="Enter some text"
 *         error={!!error}
 *         fullWidth
 *         variant="outlined"
 *       />
 *     )}
 *   />
 *   <FormField
 *     name="my-field-2"
 *     label="My Second Field"
 *     render={({ field, fieldState: { error } }) => (
 *       <TextField
 *         {...field}
 *         placeholder="Enter some text"
 *         error={!!error}
 *         fullWidth
 *         variant="outlined"
 *       />
 *     )}
 *   />
 * </FieldRow>
 * ```
 */
const FieldRow = ({ children, className, ...rest }: FieldRowProps): React.ReactElement => {
  return (
    <Box className={twMerge("grid-auto-fit grid grow items-center gap-4", className)} {...rest}>
      {children}
    </Box>
  )
}

export default FieldRow
export { FieldRow }
export type { FieldRowProps }
