import { Box, ListSubheader, MenuItem, Select, Typography } from "@mui/material"
import { CheckCircleFilledRounded } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import { type FC, useEffect, useMemo } from "react"
import { useFormContext } from "react-hook-form"

import { useGetLocationAssociation } from "../Product/Information/hooks/useGetLocationAssociation"

import { FieldGroup, FieldRow, Form, FormField } from "@/components/Forms"
import {
  formatAnyModeTransportIncoterms,
  formatSeaAndInlandWaterwayIncoterms,
  formattedPricingTiers,
} from "@/constants/pricingTiers"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll"
import { useGetIncotermsLocations } from "@/screens/Products/hooks/useGetIncotermsLocations"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"
const selectSlotProps = { input: { className: InputFieldClasses } }

export const AddShippingTerms: FC = () => {
  const { getValues, setValue } = useFormContext()
  const { companyId } = useParams({ from: "/companies/$companyId/edit" })
  const {
    akrochemLocationListFetching,
    akrochemShippingLocations,
    intermediaryLocationListFetching,
    intermediaryLocations,
    loadMore,
  } = useGetIncotermsLocations()

  const { locationsAssociationsList } = useGetLocationAssociation({
    companyId,
  })

  const formData = useMemo(() => getValues(), [getValues])

  const { containerRef: shippingLocationRef } = useInfiniteScroll(loadMore, {
    isLoading: akrochemLocationListFetching,
    hasMore: akrochemShippingLocations.length % 20 === 0,
    threshold: 200,
  })

  const { containerRef: incotermLocationRef } = useInfiniteScroll(loadMore, {
    isLoading: intermediaryLocationListFetching,
    hasMore: intermediaryLocations.length % 20 === 0,
    threshold: 200,
  })

  useEffect(() => {
    if (locationsAssociationsList.length === 0) return
    const sourceCompanyLocationAssociationId = locationsAssociationsList.filter(
      (location) => location.companyLocationAssociationId
    )[0]?.companyLocationAssociationId
    setValue("sourceCompanyLocationAssociationId", sourceCompanyLocationAssociationId)
  }, [locationsAssociationsList, setValue])

  return (
    <Form className='gap-6'>
      <PriceBreakdown formData={formData} />
      <FieldGroup legend='2. Shipping Terms'>
        <FieldRow>
          <FormField
            name='freightTerms'
            label='Freight Terms'
            required
            slotProps={{
              label: {
                className: "text-gray-800 text-[12px] font-normal",
              },
            }}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                slotProps={selectSlotProps}
                type='text'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                MenuProps={MenuProps}
                displayEmpty
              >
                {formattedPricingTiers.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FieldRow>
        <FieldRow>
          <FormField
            name='incoterms'
            label='Incoterms'
            required
            slotProps={{
              label: {
                className: "text-gray-800 text-[12px] font-normal",
              },
            }}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                slotProps={selectSlotProps}
                type='text'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                MenuProps={MenuProps}
                displayEmpty
              >
                <ListSubheader className='text-base font-bold text-black'>Any mode of Transport:</ListSubheader>
                {formatAnyModeTransportIncoterms.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
                <ListSubheader className='text-base font-bold text-black'>
                  Sea and Inland Waterway Transport:
                </ListSubheader>
                {formatSeaAndInlandWaterwayIncoterms.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FieldRow>
        <FieldRow>
          <FormField
            name='incotermsLocationId'
            label='Incoterm Locations'
            required
            slotProps={{
              label: {
                className: "text-gray-800 text-[12px] font-normal",
              },
            }}
            render={({ field, fieldState }) => (
              <Select
                ref={incotermLocationRef}
                {...field}
                slotProps={selectSlotProps}
                type='text'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                MenuProps={MenuProps}
                displayEmpty
              >
                {intermediaryLocations.map((location) => (
                  <MenuItem key={location.locationId} value={location.locationId}>
                    {[location?.address1, location?.city, location?.regionIsoCode].filter(Boolean).join(", ")}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FieldRow>
        <FieldRow>
          <FormField
            name='destinationCompanyLocationAssociationId'
            label='Shipping Location'
            required
            slotProps={{
              label: {
                className: "text-gray-800 text-[12px] font-normal",
              },
            }}
            render={({ field, fieldState }) => (
              <Select
                ref={shippingLocationRef}
                {...field}
                slotProps={selectSlotProps}
                type='text'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                MenuProps={MenuProps}
                displayEmpty
              >
                {akrochemShippingLocations.map((location) => (
                  <MenuItem key={location.companyLocationAssociationId} value={location.companyLocationAssociationId}>
                    {[location?.address1, location?.city, location?.regionIsoCode].filter(Boolean).join(", ")}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FieldRow>
      </FieldGroup>
    </Form>
  )
}

const PriceBreakdown: FC = ({ formData }) => (
  <Box className='space-y-4'>
    <Box className='mb-2' display='flex' alignItems='center'>
      <Typography
        variant='body1'
        fontWeight={700}
        className='leading-6 tracking-[0.15px] text-gray-700'
        display='flex'
        alignItems='center'
      >
        1. Price Breakdown
        <CheckCircleFilledRounded className='ml-1 text-success-500' />
      </Typography>
    </Box>
    <Box className='px-6'>
      <Box className='mb-3 w-1/2'>
        <Box className='flex justify-between'>
          <Typography variant='body2' className='font-bold text-gray-700'>
            Quantity
          </Typography>
          <Typography variant='body2' className='w-1/2 font-bold text-gray-700'>
            Price/UoM
          </Typography>
        </Box>
        {formData.pricingTiersToCreate.map(
          ({ numberOfUnits, pricePerUnit, unitOfMeasurement }, index) =>
            numberOfUnits &&
            pricePerUnit &&
            unitOfMeasurement.name && (
              <Box key={index} className='flex justify-between'>
                <Typography variant='body2' className='text-gray-700'>
                  {numberOfUnits}
                </Typography>
                <Typography variant='body2' className='w-1/2 text-gray-700'>
                  {`$${pricePerUnit} / ${unitOfMeasurement.name}`}
                </Typography>
              </Box>
            )
        )}
      </Box>
      <p className='mb-3 py-0 text-sm text-gray-900'>Effective Date: {formData.effectiveDate}</p>
      <p className='py-0 text-sm text-gray-900'>Expiration Date: {formData.expirationDate || "-"}</p>
    </Box>
  </Box>
)
