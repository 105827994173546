import { twMerge } from "tailwind-merge"

interface FieldLabelProps {
  children: React.ReactNode
  htmlFor?: string
  required?: boolean
  className?: string
}

/**
 * Displays a label for a form field
 *
 * @param props The component's props
 * @param props.children The children to render (the label text)
 * @param props.htmlFor The id of the input field this label is for. Optional, but recommended
 * @param props.className The class name to apply to the label. Optional
 *
 * @example
 * ```tsx
 * <FieldLabel htmlFor="my-field-id">
 *   My Field Label
 * </FieldLabel>
 * ```
 */
const FieldLabel = ({
  children,
  htmlFor,
  required = false,
  className,
  ...rest
}: FieldLabelProps): React.ReactElement => {
  return (
    <label htmlFor={htmlFor} className={twMerge("block text-sm font-thin text-gray-700", className)} {...rest}>
      {children}
      {required && <span className='text-red-600'>&nbsp;*</span>}
    </label>
  )
}

export default FieldLabel
export { FieldLabel }
export type { FieldLabelProps }
