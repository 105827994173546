import { useCallback, useEffect, useRef, useState } from "react"

function useDebounce<T>(value: T, delay: number): T
function useDebounce<T extends (...args: any[]) => any>(callback: T, delay: number): T
function useDebounce<T>(valueOrCallback: T | ((...args: any[]) => any), delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(valueOrCallback as T)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (typeof valueOrCallback !== "function") {
      timeoutRef.current = setTimeout(() => {
        setDebouncedValue(valueOrCallback as T)
      }, delay)

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }
  }, [valueOrCallback, delay])

  const debouncedFunction = useCallback(
    (...args: any[]) => {
      return new Promise<T extends (...args: any[]) => any ? ReturnType<T> : T>((resolve) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
          if (typeof valueOrCallback === "function") {
            resolve((valueOrCallback as (...args: any[]) => any)(...args))
          } else {
            resolve(valueOrCallback as T extends (...args: any[]) => any ? ReturnType<T> : T)
          }
        }, delay)
      })
    },
    [valueOrCallback, delay]
  )

  return typeof valueOrCallback === "function" ? (debouncedFunction as T) : debouncedValue
}

export default useDebounce
