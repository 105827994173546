import { create } from "zustand"

import type {
  ContactSubscription,
  ContactSubscriptionsQuery,
  ContactSubscriptionsQueryVariables,
} from "@/graphql/codegen/graphql"
import { ContactSubscriptionsDocument } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"

type ContactSubscriptionsStore = {
  contactSubscriptions: ContactSubscription[]
  fetching: boolean
  error: string | null
  fetchContactSubscriptions: () => Promise<void>
}

export const useContactSubscriptionsStore = create<ContactSubscriptionsStore>((set) => ({
  contactSubscriptions: [],
  fetching: false,
  error: null,
  fetchContactSubscriptions: async () => {
    set({ fetching: true })
    try {
      const { data, error } = await queryClient
        .query<ContactSubscriptionsQuery, ContactSubscriptionsQueryVariables>(ContactSubscriptionsDocument, {})
        .toPromise()

      if (error) {
        set({ error: error.message, fetching: false })
      } else if (data?.contactSubscription.listSubscriptions.__typename === "ContactSubscriptionListSuccess") {
        set({
          contactSubscriptions: data.contactSubscription.listSubscriptions.contactSubscriptions,
          fetching: false,
          error: null,
        })
      } else {
        set({ contactSubscriptions: [], fetching: false, error: "Failed to fetch contact subscriptions" })
      }
    } catch (error) {
      set({ error: (error as Error).message, fetching: false })
    }
  },
}))
