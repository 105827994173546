import type { StateCreator } from "zustand"
import { create } from "zustand"
import { persist, subscribeWithSelector } from "zustand/middleware"

import { UnitOfMeasurementListDocument } from "@/graphql/codegen/graphql"
import type {
  UnitOfMeasurement,
  UnitOfMeasurementListOutputFailure,
  UnitOfMeasurementListQuery,
  UnitOfMeasurementListQueryVariables,
} from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"

interface UnitOfMeasurementsStore {
  units: UnitOfMeasurement[]
  fetching: boolean
  error: string | null
  getUnits: () => Promise<UnitOfMeasurement[]>
}

const store: StateCreator<UnitOfMeasurementsStore> = (set, get) => ({
  units: [],
  fetching: false,
  error: null,
  getUnits: async () => {
    if (get().units.length === 0 && !get().fetching) {
      set({ fetching: true })
      try {
        const { data, error } = await queryClient
          .query<UnitOfMeasurementListQuery, UnitOfMeasurementListQueryVariables>(UnitOfMeasurementListDocument, {})
          .toPromise()

        if (error || data?.unitOfMeasurement.list.__typename === "UnitOfMeasurementListOutputFailure") {
          const message =
            error?.message || (data?.unitOfMeasurement.list as UnitOfMeasurementListOutputFailure).error.message
          set({ error: message, fetching: false })
        } else if (data?.unitOfMeasurement.list.__typename === "UnitOfMeasurementListOutputSuccess") {
          set({
            units: data?.unitOfMeasurement.list.unitOfMeasurements,
            fetching: false,
            error: null,
          })
        } else {
          set({ units: [], fetching: false, error: "Failed to fetch units of measurements" })
        }
      } catch (error) {
        set({ error: (error as Error).message, fetching: false })
      }
    }
    return get().units
  },
})

export const useUnitOfMeasurementsStore = create(
  persist(
    subscribeWithSelector<UnitOfMeasurementsStore>((set, get, val) => {
      const storeWithMiddleware = store(set, get, val)

      return {
        ...storeWithMiddleware,
        getUnits: async () => {
          await storeWithMiddleware.getUnits()
          return get().units
        },
      }
    }),
    {
      name: "unit-of-measurements-storage",
    }
  )
)
