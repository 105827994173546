import { alertClasses, createTheme } from "@mui/material"

import colors from "@/styles/colors.ts"

const rootElement = document.getElementById("root")

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          [`&.${alertClasses.filled}.${alertClasses.colorInfo}`]: {
            backgroundColor: colors.primary["100"],
            color: colors.blue.grey["600"],
          },
          [`&.${alertClasses.filled}.${alertClasses.colorWarning}`]: {
            backgroundColor: colors.tertiary.yellow["300"],
            color: colors.grey["700"],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .PrivateSwitchBase-input": {
            width: "100%",
            height: "100%",
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          background: colors.grey["300"],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.grey["400"],
          },
          "&.Mui-focused, &&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.primary["200"],
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          position: "relative",
          width: "100%",
          display: "grid",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: colors.grey["100"],
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  palette: {
    ...colors,
  },
})

export default theme
