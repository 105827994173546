import { Box, IconButton } from "@mui/material"
import {
  AddOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpFilledOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { Link } from "@tanstack/react-router"
import type { CellContext, ColumnDef } from "@tanstack/react-table"
import type { ReactElement } from "react"

import { ProductActionMenu } from "./ProductActionsMenu"
import { useProductActionsStore } from "./stores/useProductsActions"
import { ProductActionsDialogs } from "./types/schema"
import { ProductStatusMap } from "./utils/productStatusMap"

import { Button } from "@/components/common/Button"
import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import type { Product } from "@/graphql/codegen/graphql"

const ActionsCell = ({ cell }: CellContext<Product, unknown>): ReactElement => {
  const { row } = cell.getContext()

  return (
    <Box className='flex items-center justify-center space-x-4'>
      <Link to='/product/$productId/edit' params={{ productId: row.original.productId }}>
        <Button appearance='outlined' variant='primary' startIcon={<AddOutlined />}>
          Product Details
        </Button>
      </Link>
      <ProductActionMenu {...row} />
      <IconButton aria-label='expand row' size='small' onClick={() => row.toggleExpanded()}>
        {row.getIsExpanded() ? <KeyboardArrowUpFilledOutlined /> : <KeyboardArrowDownOutlined />}
      </IconButton>
    </Box>
  )
}

const SKUCell = ({ getValue }: CellContext<Product, unknown>): ReactElement => {
  const value = getValue() as string
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)

  if (!value) {
    return (
      <Button
        appearance='text'
        variant='primary'
        startIcon={<WarningOutlined />}
        onClick={() => setOpenedDialog(ProductActionsDialogs.ADD_SKU)}
      >
        Add SKU
      </Button>
    )
  }
  return <>{value}</>
}

const LocationCell = ({ row }: CellContext<Product, unknown>): ReactElement => {
  const value = row.original.locationsAssociations[0]?.shipping?.name
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)

  if (!value) {
    return (
      <Button
        appearance='text'
        variant='primary'
        startIcon={<WarningOutlined />}
        onClick={() => setOpenedDialog(ProductActionsDialogs.ADD_LOCATION)}
      >
        Add Location
      </Button>
    )
  }
  return <>{value}</>
}

export const columns: ColumnDef<Product>[] = [
  {
    accessorKey: "activityStatusReason",
    header: "",
    cell: ({ getValue, cell }: CellContext<Product, unknown>): ReactElement => (
      <StatusIndicator data-testid={`${cell.id}-${getValue()}`} status={getValue() as string} map={ProductStatusMap} />
    ),
    size: 44,
    enableSorting: false,
  },
  {
    accessorKey: "productId",
    header: "Product Number",
  },
  {
    accessorKey: "vendorSku",
    header: "Vendor SKU",
    cell: SKUCell,
  },
  {
    accessorKey: "companyProductDescription",
    header: "Akrochem Description",
  },
  {
    accessorKey: "vendorDescription",
    header: "Vendor Description",
  },
  {
    accessorKey: "shipFromLocation",
    header: "Ship From",
    cell: LocationCell,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ActionsCell,
    size: 325,
  },
]
