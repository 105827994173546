import { type ReactElement } from "react"

import { FileIconTemplate } from "./FileIconTemplate"

interface PDFIconProps {
  className?: string
}

export const PDFIcon = ({ className }: PDFIconProps): ReactElement => (
  <FileIconTemplate className={className} text='PDF' stripeColor='#E53935' />
)
