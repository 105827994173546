import { zodResolver } from "@hookform/resolvers/zod"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { useCallback, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { LocationNotesForm } from "@/screens/Companies/edit/components/locations/LocationNotesForm"
import {
  type EditNotesDialogProps,
  type EditNotesQueryProps,
  EditNotesQuerySchema,
} from "@/screens/Companies/types/dialogProps"

export const EditNotesDialog: FC<EditNotesDialogProps> = ({
  open,
  onClose,
  onSubmit,
  activeEditLocation,
  openedDialog,
}) => {
  const methods = useForm<EditNotesQueryProps>({
    values: (activeEditLocation as EditNotesQueryProps) ?? {},
    resolver: zodResolver(EditNotesQuerySchema),
    mode: "onBlur",
  })

  useEffect(() => {
    if (activeEditLocation && openedDialog === null) {
      methods.reset(activeEditLocation as EditNotesQueryProps)
    }
  }, [activeEditLocation, openedDialog, methods])

  const handleSave = useCallback(
    async (value: EditNotesQueryProps) => {
      const isValid = await methods.trigger()
      if (isValid) {
        onSubmit(value)
        onClose("success")
      }
    },
    [onSubmit, onClose, methods]
  )

  return (
    <Dialog open={open} onClose={(_, reason) => onClose(reason)} fullWidth>
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>Edit Location Notes</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <FormProvider {...methods}>
          <LocationNotesForm />
        </FormProvider>
      </DialogContent>
      <DialogActions className='justify-between px-10 pb-10'>
        <Button variant='primary' appearance='outlined' onClick={() => onClose("cancel")}>
          Cancel
        </Button>
        <Button variant='primary' onClick={methods.handleSubmit(handleSave)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
