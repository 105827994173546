import type { Maybe } from "graphql/jsutils/Maybe"
import { useMemo } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import { type LocationGetSuccess, PurchaseOrderLocationDocument, type Query } from "@/graphql/codegen/graphql"

export interface UsePurchaseOrderLocationData {
  purchaseOrderLocation: Maybe<LocationGetSuccess>
  purchaseOrderLocationFetching: boolean
  purchaseOrderLocationError: CombinedError | undefined
}

export type UsePurchaseOrderLocation = (locationId?: Maybe<string>) => UsePurchaseOrderLocationData

export const useGetPurchaseOrderLocation: UsePurchaseOrderLocation = (locationId) => {
  const [
    { data: purchaseOrderLocationData, fetching: purchaseOrderLocationFetching, error: purchaseOrderLocationError },
  ] = useQuery<Pick<Query, "location">>({
    query: PurchaseOrderLocationDocument,
    variables: { locationId },
    pause: !locationId,
  })

  const purchaseOrderLocation = useMemo(
    () =>
      purchaseOrderLocationData?.location?.get.__typename === "LocationGetSuccess"
        ? purchaseOrderLocationData?.location?.get
        : null,
    [purchaseOrderLocationData?.location?.get]
  )

  return {
    purchaseOrderLocation,
    purchaseOrderLocationFetching,
    purchaseOrderLocationError,
  }
}
