import { MenuItem, Select } from "@mui/material"
import { HelpOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { useId, useRef } from "react"
import type { ControllerFieldState } from "react-hook-form"

import { renderSelectPlaceholder } from "@/components/Forms"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"

const InputFieldClasses =
  "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2 pr-14 w-full text-ellipsis"
const selectSlotProps = { input: { className: InputFieldClasses }, root: { className: "pr-0" } }

interface SelectFieldProps {
  field: any
  fieldState: ControllerFieldState
  options: any[]
  optionValueKey?: string
  optionLabelKey?: string
  loadMore?: () => void
  fetching?: boolean
  disabled?: boolean
}

export const SelectField = ({
  field,
  fieldState,
  options,
  optionValueKey = "value",
  optionLabelKey = "label",
  disabled = false,
  loadMore = () => {},
  fetching = false,
}: SelectFieldProps): ReactElement => {
  const id = useId()
  const selectRef = useRef<HTMLDivElement>(null)

  const onScroll = useScrollLoadMore({
    fetching,
    loadMore,
    scrollContainerRef: selectRef,
  })

  return (
    <Select
      {...field}
      slotProps={selectSlotProps}
      type='text'
      error={!!fieldState.error}
      fullWidth
      variant='outlined'
      displayEmpty
      IconComponent={KeyboardArrowDownOutlined}
      renderValue={renderSelectPlaceholder({ field })}
      endAdornment={<HelpOutlined fontSize='small' className='absolute right-8' />}
      disabled={disabled}
      onScrollCapture={onScroll}
      MenuProps={{
        PaperProps: {
          ref: selectRef,
          style: {
            maxHeight: 200,
          },
        },
      }}
    >
      <MenuItem key={id + "-" + field.name + "-select-one"} value='' divider>
        Select One
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={id + "-" + field.name + "-" + option[optionValueKey]} value={option[optionValueKey]} divider>
          {option[optionLabelKey]}
        </MenuItem>
      ))}
    </Select>
  )
}
