import { z } from "zod"

export const UserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  avatar: z
    .string()
    .nullish()
    .transform((value) => value ?? undefined),
})

export type User = z.infer<typeof UserSchema>
