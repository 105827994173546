/**
 * This function is used to calculate the number of days based on the format (Days, Weeks, Months, Years) selected
 */
export const calculateInDays = (value: number, format: string): number => {
  switch (format) {
    case "Days":
      return value
    case "Weeks":
      return value * 7
    case "Months":
      return value * 30
    case "Years":
      return value * 365
    default:
      return value
  }
}
