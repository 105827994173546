import type { InputInputSlotProps, InputSlots } from "@mui/base"
import { Box, InputAdornment, TextField } from "@mui/material"
import type { ComponentPropsWithoutRef, FC } from "react"
import { twMerge } from "tailwind-merge"

import inputVariants from "@/components/common/InputField/inputFieldVariant"
import type { InputVariantsProps } from "@/components/common/InputField/inputFieldVariant"

type CustomInputSlots = InputInputSlotProps & {
  label?: ComponentPropsWithoutRef<"label">
}

type InputFieldProps = {
  label?: string
  placeholder?: string
  error?: string
  slots?: InputSlots
  slotProps?: CustomInputSlots
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  multiline?: boolean
  size?: "medium" | "large"
} & InputVariantsProps &
  ComponentPropsWithoutRef<"input">

const InputField: FC<InputFieldProps> = ({
  label,
  placeholder,
  error,
  size,
  state,
  disabled,
  color,
  startAdornment,
  endAdornment,
  slots,
  slotProps,
  multiline,
  ...props
}) => {
  return (
    <Box className='relative'>
      {label && (
        <label
          {...slotProps?.label}
          className={twMerge([
            "absolute -top-6 mb-[7px] block text-sm font-thin text-gray-700",
            slotProps?.label?.className,
          ])}
        >
          {label}
          {props.required && <span className='text-red-600'>*</span>}
        </label>
      )}
      <TextField
        {...props}
        multiline={multiline}
        placeholder={placeholder}
        error={!!error}
        className='p-0'
        fullWidth
        variant='outlined'
        InputProps={{
          slots,
          classes: {
            root: twMerge(inputVariants({ size, state, disabled }), props.className),
            input:
              "border-none outline-none hover:border-none hover:outline-none focus:border-none focus:outline-none focus:ring-0 px-0",
          },
          startAdornment: startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>,
          endAdornment: endAdornment && <InputAdornment position='end'>{endAdornment}</InputAdornment>,
        }}
      />
      {error && <span className='text-sm text-red-600'>{error}</span>}
    </Box>
  )
}

export default InputField
