import { Box, Paper, Typography } from "@mui/material"
import type { AnyRoute } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"
import { FormProvider, useForm } from "react-hook-form"

import { CommentsContainer } from "@/screens/Procurement/PurchaseOrder/components/CommentsContainer"
import { NotesContainer } from "@/screens/Procurement/PurchaseOrder/components/NotesContainer"
import { OrderPackagingSummary } from "@/screens/Procurement/PurchaseOrder/components/OrderPackagingSummary"
import { ProductAddedContainer } from "@/screens/Procurement/PurchaseOrder/components/ProductAdded/ProductAddedContainer"
import { PurchaseOrderHeader } from "@/screens/Procurement/PurchaseOrder/components/PurchaseOrderHeader"
import { PurchaseOrderHeaderActionsContainer } from "@/screens/Procurement/PurchaseOrder/components/PurchaseOrderHeaderActionsContainer"
import { DeliveryTermsAndShippingLegsContainer } from "@/screens/Procurement/PurchaseOrder/DeliveryTermsAndShippingLegs/DeliveryTermsAndShippingLegsContainer"
import { routeDataPreload } from "@/screens/Procurement/PurchaseOrder/routeDataPreload"
import { PurchaseOrderSection } from "@/screens/Procurement/PurchaseOrder/types"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

const PurchaseOrderLayout = () => {
  const form = useForm()

  const order = usePurchaseOrderStore((state) => state.formValues[PurchaseOrderSection.HEADER])

  const loading = false

  return loading ? (
    <Typography variant='h5'>Loading...</Typography>
  ) : order ? (
    <Box className='size-full px-6 pb-6'>
      <Box className='flex h-full flex-col gap-4'>
        <Paper
          elevation={0}
          className='flex min-h-0 grow flex-col space-y-8 overflow-y-auto overflow-x-hidden bg-white p-6'
        >
          <FormProvider {...form}>
            {/* Page header and controls */}
            <PurchaseOrderHeaderActionsContainer />

            {/* Order summary and Delivery terms */}
            <Box className='flex flex-col gap-2'>
              <PurchaseOrderHeader />
              <DeliveryTermsAndShippingLegsContainer />
            </Box>

            {/* Order packaging summary */}
            <OrderPackagingSummary />

            {/* Products list */}
            <ProductAddedContainer />

            {/* PO Note, Comments, and More Products */}
            <Box className='flex flex-col gap-16'>
              <Box className='flex flex-col gap-5'>
                <NotesContainer />
                <CommentsContainer />
              </Box>
            </Box>
          </FormProvider>
        </Paper>
      </Box>
    </Box>
  ) : (
    <Typography variant='h5'>Purchase Order Not Found</Typography>
  )
}

export const Route: AnyRoute = createFileRoute("/purchase-order/$purchaseOrderId/")({
  loader: routeDataPreload,
  component: PurchaseOrderLayout,
})
