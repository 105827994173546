import type { Client, CombinedError } from "urql"
import { create } from "zustand"

import type { Company, CompanySortField, SortingOrder } from "@/graphql/codegen/graphql.ts"
import { CompanyListDetailsDocument } from "@/graphql/codegen/graphql.ts"

type CompanySearchFilters = {
  query?: string
  category?: string
  field?: CompanySortField
  order?: SortingOrder
  [key: string]: unknown
}

export type CompanySearchState = {
  data: Company[]
  fetching: boolean
  error: CombinedError | Error | undefined
  offset: number
  limit: number
  hasMore: boolean
  lastExecutedFilters: CompanySearchFilters
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  resetSearch: () => void
  setData: (data: Company[]) => void
  appendData: (data: Company[]) => void
  executeSearch: (client: Client, filters: CompanySearchFilters) => Promise<void>
  loadMore: (client: Client, filters: CompanySearchFilters) => Promise<void>
}

const deduplicateData = (newData: Company[], existingData: Company[]): Company[] => {
  const uniqueIds = new Set(existingData.map((company) => company.companyId))
  return [...existingData, ...newData.filter((company) => !uniqueIds.has(company.companyId))]
}

const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: undefined,
  offset: 0,
  limit: 20,
  hasMore: true,
  lastExecutedFilters: {},
}

export const useCompanySearchStore = create<CompanySearchState>((set, get) => ({
  ...INITIAL_STATE,

  setFetching: (fetching) => set({ fetching }),
  setError: (error) => set({ error }),
  resetSearch: () => set({ ...INITIAL_STATE }),
  setData: (data) => set({ data, error: undefined }),
  appendData: (data) =>
    set((state) => ({
      data: deduplicateData(data, state.data),
      error: undefined,
    })),

  executeSearch: async (client: Client, filters: CompanySearchFilters) => {
    const state = get()
    if (state.fetching) return

    const { query, ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) === JSON.stringify(state.lastExecutedFilters)) return

    const shouldExecuteSearch = validQuery || Object.keys(restFilters).length > 0
    if (!shouldExecuteSearch) {
      set({ data: [], hasMore: false, lastExecutedFilters: {} })
      return
    }

    set({ fetching: true })

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: otherFilters,
        offset: 0,
        limit: state.limit,
        sort: { field, order },
      }

      const result = await client.query(CompanyListDetailsDocument, { input: queryInput }).toPromise()

      if (result.data?.company?.list.__typename === "CompanyListSuccess") {
        const companies = result.data.company.list.companies as Company[]
        set({
          data: companies,
          offset: 0,
          hasMore: companies.length === state.limit,
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  loadMore: async (client: Client, filters: CompanySearchFilters) => {
    const state = get()
    if (state.fetching || !state.hasMore) return

    // Ensure filters is defined before destructuring
    const { query, ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) !== JSON.stringify(state.lastExecutedFilters)) {
      await state.executeSearch(client, filters)
      return
    }

    set({ fetching: true })
    const newOffset = state.offset + state.limit

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: otherFilters,
        offset: newOffset,
        limit: state.limit,
        sort: { field, order },
      }

      const result = await client.query(CompanyListDetailsDocument, { input: queryInput }).toPromise()

      if (result.data?.company?.list.__typename === "CompanyListSuccess") {
        const companies = result.data.company.list.companies as Company[]
        set((prevState) => ({
          data: deduplicateData(companies, prevState.data),
          offset: newOffset,
          hasMore: companies.length === state.limit,
        }))
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },
}))
