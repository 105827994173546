import { Box, Typography } from "@mui/material"
import { type ReactElement } from "react"

import type { LabelFileBySize } from "./types"
import { LABEL_SIZES } from "./types"

import { FileUpload } from "@/components/upload/FileUpload"
import { PDFTemplates } from "@/components/upload/templates/PDFUploadTemplate"
import type { PDFSize } from "@/hooks/usePDFUpload"

interface LabelSizeUploaderProps {
  onFileChange: (size: PDFSize, file: File) => void
  fileUrls: LabelFileBySize
  previewUrls: Record<PDFSize, File | null>
  uploading?: boolean
  onPreviewClick?: (url: string) => void
  readOnly?: boolean
}

export const LabelSizeUploader = ({
  onFileChange,
  fileUrls,
  previewUrls,
  uploading = false,
  onPreviewClick,
  readOnly = false,
}: LabelSizeUploaderProps): ReactElement => (
  <Box className='flex justify-between'>
    {LABEL_SIZES.map(({ size, label }) => {
      const fileUrl = fileUrls[size]?.url || previewUrls[size] || null

      return (
        <Box key={size} className='flex flex-col gap-2'>
          <FileUpload
            onChange={(file) => !readOnly && onFileChange(size, file)}
            accept='.pdf'
            maxSize={10 * 1024 * 1024}
            fileUrl={fileUrl}
            enableDragAndDrop={!fileUrl && !readOnly}
            readOnly={readOnly}
            templates={PDFTemplates}
            slotProps={{
              content: {
                uploading,
                label: `${label} Label`,
              },
              uploadBox: {
                className: "w-[100px] h-[100px]",
              },
            }}
          />
          {fileUrl && (
            <Typography
              variant='caption'
              className='cursor-pointer text-gray-600'
              onClick={() => onPreviewClick?.(fileUrl)}
            >
              {label}{" "}
              <Box component='span' className='text-primary underline'>
                Preview
              </Box>
            </Typography>
          )}
        </Box>
      )
    })}
  </Box>
)
