import { Box, Divider, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback } from "react"
import { FormProvider, useForm } from "react-hook-form"
import type { UseFormProps } from "react-hook-form"

import { StatusFilter } from "@/components/common/Filters/StatusFilter"
import type { ContactQueryableFields } from "@/graphql/codegen/graphql.ts"
import { CompanyStatus } from "@/graphql/codegen/graphql.ts"
import { DepartmentFilter } from "@/screens/Companies/components/filters/contact/DepartmentFilter.tsx"
import { ContactSubscriptionFilter } from "@/screens/Companies/components/filters/contact/SubscriptionSearchFilter.tsx"
import { useContactSubscriptionsStore } from "@/screens/Companies/stores/useContactSubscriptionsStore"
import { getRefinedSearchParams } from "@/screens/Companies/utils"

const statusList: CompanyStatus[] = [CompanyStatus.Active, CompanyStatus.Inactive]
const statusKey: keyof ContactQueryableFields = "isActive"

type FormValues = ContactQueryableFields & {
  isActive: CompanyStatus | CompanyStatus[]
  companyDepartments: string[]
  contactSubscriptions: string[]
}

export const ContactSearchFilter: FC = () => {
  const navigate = useNavigate({ from: "/companies/" })
  const search = useSearch({ from: "/companies/", strict: true })

  const subscriptions = useContactSubscriptionsStore((state) => state.contactSubscriptions)

  const { isActive, companyDepartments, contactSubscriptions } = search

  const methods = useForm<FormValues>({
    values: {
      isActive: isActive === undefined ? [] : isActive ? CompanyStatus.Active : CompanyStatus.Inactive,
      companyDepartments: companyDepartments ?? [],
      contactSubscriptions: contactSubscriptions ?? [],
    },
  } as UseFormProps<FormValues>)

  const handleFilterChange = useCallback(
    (name: keyof ContactQueryableFields, value: unknown) => {
      if (name === "isActive") {
        const isActiveValue =
          (value as CompanyStatus[]).length === 1 ? (value as CompanyStatus[])[0] === CompanyStatus.Active : undefined
        navigate({
          search: (prev: Record<string, any>) => ({
            ...prev,
            isActive: isActiveValue,
          }),
          replace: true,
        })
        return
      }

      const params = getRefinedSearchParams(search, {
        ...search,
        [name]: value,
      })

      navigate(params)
    },
    [navigate]
  )

  return (
    <FormProvider {...methods}>
      <Box className='flex h-full flex-col gap-y-2'>
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Contact Status
          </Typography>
          <Divider />
          <StatusFilter
            name={statusKey}
            data={statusList}
            control={methods.control}
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className='grow overflow-hidden'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Department
          </Typography>
          <Divider />
          <DepartmentFilter onFilterChange={handleFilterChange} />
        </Box>

        <Box className='grow overflow-hidden'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Subscriptions
          </Typography>
          <Divider />
          <ContactSubscriptionFilter subscriptions={subscriptions} onFilterChange={handleFilterChange} />
        </Box>
      </Box>
    </FormProvider>
  )
}
