import { Box, IconButton, Typography } from "@mui/material"
import { CheckOutlined, CloseOutlined, EditOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { memo, useMemo } from "react"

import { stockType } from "./ProductConditionsContent"

import type { Product } from "@/graphql/codegen/graphql"

type ProductConditions = Pick<
  Product,
  "stockType" | "excludedFromCombinedPricing" | "isConsignmentItem" | "isOnAllocation"
>

interface ProductConditionsTableProps {
  productDetails: ProductConditions
  onEditConditionClick: (productDetails: Product) => void
}

interface ColumnContentProps {
  content?: string | boolean | null
  children?: string
}

const ColumnContent: FC<ColumnContentProps> = ({ content, children }) => (
  <Box gap={1.5} className='flex items-center'>
    {content ? <CheckOutlined className='text-green-700' /> : <CloseOutlined className='text-secondary-600' />}
    <Typography variant='body2' className='text-gray-700'>
      {children || String(content)}
    </Typography>
  </Box>
)

const ProductConditionsTable: FC<ProductConditionsTableProps> = ({ productDetails, onEditConditionClick }) => {
  const stockTypeLabel = useMemo(
    () => stockType.find((type) => type.value === productDetails.stockType)?.label,
    [productDetails.stockType]
  )

  const headerItems = [
    { label: "Stock" },
    { label: "Combined Pricing" },
    { label: "Consignment" },
    { label: "Allocation" },
    { label: "" },
  ]

  const contentItems = [
    { content: stockTypeLabel, testId: "stock-type" },
    { content: productDetails.excludedFromCombinedPricing, testId: "combined-pricing", label: "Excluded" },
    { content: productDetails.isConsignmentItem, testId: "consignment", label: "Consignment Item" },
    { content: productDetails.isOnAllocation, testId: "allocation", label: "On Allocation" },
  ]

  return (
    <Box className='mt-2 grid grid-cols-5 overflow-hidden border border-gray-100' data-testid='product-conditions-grid'>
      {headerItems.map(({ label }, index) => (
        <Box
          key={label}
          className={`flex items-center bg-gray-100 p-4 ${index !== 0 ? "border-l border-gray-100" : ""}`}
        >
          <Typography variant='subtitle2' className='font-medium text-gray-700'>
            {label}
          </Typography>
        </Box>
      ))}

      {contentItems.map(({ content, testId, label }, index) => (
        <Box
          key={testId}
          className={`flex items-center p-4 ${index !== 0 ? "border-l border-gray-100" : ""} border-t border-gray-100`}
          data-testid={testId}
        >
          <ColumnContent content={content}>{label}</ColumnContent>
        </Box>
      ))}

      <Box className='flex items-center justify-end border-l border-t border-gray-100 p-4' data-testid='actions'>
        <IconButton
          color='primary'
          aria-label='edit product conditions'
          onClick={() => onEditConditionClick(productDetails as Product)}
        >
          <EditOutlined />
        </IconButton>
      </Box>
    </Box>
  )
}

export default memo(ProductConditionsTable)
