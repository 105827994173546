import type { AnyRouter } from "@tanstack/react-router"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { Client, Provider, fetchExchange } from "urql"

import { routeTree } from "../../routeTree.gen.ts"

import { useAuthStore } from "@/stores/authStore.ts"
import { createAuthMiddleware } from "@/utils/auth/middleware.ts"
import env from "@/utils/env.ts"

export const queryClient = new Client({
  url: env.VITE_API_ORIGIN,
  exchanges: [createAuthMiddleware(), fetchExchange],
  requestPolicy: "network-only",
})

// Router setup
const router: AnyRouter = createRouter({
  routeTree,
  defaultErrorComponent: ({ error }) => <>{error.message}</>,
  context: {
    auth: undefined!, // We'll inject this when we render
    queryClient,
  },
  defaultPreload: "intent",
  defaultPreloadDelay: 0,
})

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

// Auth and GraphQL providers component
const GraphqlRouterProvider = (): ReactElement => {
  return (
    <Provider value={queryClient}>
      <RouterProvider router={router} context={{ auth: useAuthStore.getState(), queryClient }} />
    </Provider>
  )
}

export default GraphqlRouterProvider
