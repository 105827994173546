import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import type { ColumnDef } from "@tanstack/react-table"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { FunctionComponent } from "react"
import { memo, useMemo } from "react"

import type { Product } from "@/graphql/codegen/graphql"

type RegulatoryDetailsTableProps = {
  productDetails: Pick<Product, "cassRegistryId" | "unNumber" | "isRohsCompliant" | "isReachSvhcCompliant">
}

type ColumnContentProps = {
  content: string
}
const ColumnContent: FunctionComponent<ColumnContentProps> = (props: ColumnContentProps) => {
  const { content } = props

  return (
    <Typography variant='body2' className='mt-0.5 text-gray-700'>
      {content}
    </Typography>
  )
}

const RegulatoryDetailsTable: FunctionComponent<RegulatoryDetailsTableProps> = (props: RegulatoryDetailsTableProps) => {
  const { productDetails } = props
  const columns = useMemo<ColumnDef<Product>[]>(
    () => [
      {
        accessorKey: "cassRegistryId",
        header: "CAS",
        cell: ({ getValue }) => <ColumnContent content={getValue<string>()} />,
      },
      {
        accessorKey: "unNumber",
        header: "UN Number",
        cell: ({ getValue }) => <ColumnContent content={getValue<string>()} />,
      },
      {
        accessorKey: "isRohsCompliant",
        header: "Compliances",
        cell: ({ row }) => (
          <Box className='flex gap-2'>
            {row.original.isRohsCompliant || row.original.isReachSvhcCompliant
              ? [
                  {
                    label: "RoHS Compliant",
                    value: row.original.isRohsCompliant,
                  },
                  {
                    label: "REACH SVHC Compliant",
                    value: row.original.isReachSvhcCompliant,
                  },
                ].map(({ label, value }) => {
                  return value && <Chip label={label} className='rounded-sm' />
                })
              : "-"}
          </Box>
        ),
      },
    ],
    []
  )

  const table = useReactTable({
    data: [productDetails],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableContainer component={Paper} className='mt-2 shadow-none'>
      <Table>
        <TableHead className='h-10 border-b border-gray-500'>
          <TableRow>
            {table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <TableCell
                  key={header.id}
                  className='h-[64px] w-2/6 px-2 text-white'
                  data-testid={header.id}
                  padding='none'
                >
                  <Typography variant='subtitle2' className='text-gray-900'>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Typography>
                </TableCell>
              ))
            )}
          </TableRow>
        </TableHead>
        <TableBody data-testid='tableBody' className='table-row-group'>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  data-testid={`${row.id}-${cell.id}`}
                  padding='none'
                  className='h-[64px] px-2 text-gray-700 first:px-3'
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default memo(RegulatoryDetailsTable)
