import {
  CompanyListFilterSchema,
  ContactQueryableFieldsSchema,
  LocationListFilterSchema,
} from "@/graphql/codegen/graphql.ts"

export const CompanyFilterSchema = CompanyListFilterSchema().extend({
  accountManagers: CompanyListFilterSchema().shape.accountManagers.default([]),
  statuses: CompanyListFilterSchema().shape.statuses.default([]),
  query: CompanyListFilterSchema().shape.query.default(""),
})
export const LocationFilterSchema = LocationListFilterSchema().extend({
  isLocationActive: LocationListFilterSchema()
    .shape.isLocationActive.default(null)
    .transform((value) => value ?? undefined),
})
export const ContactFilterSchema = ContactQueryableFieldsSchema().extend({
  companyDepartments: ContactQueryableFieldsSchema().shape.companyDepartments.default([]),
  isActive: ContactQueryableFieldsSchema()
    .shape.isActive.default(null)
    .transform((value) => value ?? undefined),
  contactSubscriptions: ContactQueryableFieldsSchema().shape.contactSubscriptions.default([]),
})
