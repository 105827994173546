import type { IconMapping, RouteNavObject } from "@/types/routes.ts"

const addIconToRoute = (route: RouteNavObject, iconMapping: IconMapping): RouteNavObject => {
  return {
    ...route,
    icon: iconMapping[route.pathname],
    children: route.children?.map((child) => addIconToRoute(child, iconMapping)),
  }
}

export const addIcons = (routes: RouteNavObject[], iconMapping: IconMapping): RouteNavObject[] => {
  return routes.map((route) => addIconToRoute(route, iconMapping))
}
