import { ProductStatusReason } from "@/graphql/codegen/graphql"
import { createStatusMap } from "@/utils/statusMap"

export const ProductStatusMap = createStatusMap<
  ProductStatusReason,
  "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"
>([
  [ProductStatusReason.Active, "success"],
  [ProductStatusReason.PendingApproval, "warning"],
  [ProductStatusReason.Rejected, "error"],
  [ProductStatusReason.Discontinued, "secondary"],
])
