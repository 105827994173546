import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { HelpOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FC, ReactElement, ReactNode } from "react"
import { Controller, useFormContext } from "react-hook-form"

import type { TechnicalInformationFormData } from "./TechnicalInformationSchema"

export enum ShelfLifeUom {
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months",
  Years = "Years",
}

export const TechnicalInformationContent: FC = () => {
  const { control, watch, setValue } = useFormContext<TechnicalInformationFormData>()

  return (
    <form>
      <Box>
        <Controller
          name='specificGravity'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Specific Gravity</label>
              <TextField
                {...field}
                type='number'
                value={field.value}
                slotProps={{
                  input: {
                    endAdornment: <HelpOutlined />,
                    classes: {
                      input: InputFieldClasses,
                    },
                    inputProps: {
                      className: "hide-stepper",
                      min: "0",
                      max: "999999.9999",
                    },
                  },
                }}
                placeholder='0.0000'
                fullWidth
                variant='outlined'
                error={!!error}
                helperText={error?.message}
                onChange={(e) => field.onChange(parseFloat(e.target.value) || 0)} // Ensure value is a number
              />
            </Box>
          )}
        />
        <Controller
          name='shelfLifeDays'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Shelf Life</label>
              <TextField
                {...field}
                type='number'
                value={field.value}
                slotProps={{
                  input: {
                    endAdornment: (
                      <Box className='flex items-center gap-4'>
                        <HelpOutlined />
                        <Controller
                          name='shelfLifeUom'
                          control={control}
                          render={({ field }) => (
                            <FormControl variant='outlined' size='small'>
                              <Select
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  minWidth: "60px",
                                  "& .MuiSelect-select": {
                                    padding: "2px 4px",
                                  },
                                }}
                                {...field}
                                displayEmpty
                                IconComponent={KeyboardArrowDownOutlined}
                                MenuProps={{
                                  PaperProps: {
                                    style: { maxHeight: 48 * 4.5 + 8, width: 60 },
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                {Object.keys(ShelfLifeUom).map((uom) => (
                                  <MenuItem key={uom} value={uom}>
                                    {uom}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Box>
                    ),
                    classes: {
                      input:
                        "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                    },
                    inputProps: {
                      className: "hide-stepper",
                    },
                  },
                }}
                placeholder='00'
                fullWidth
                variant='outlined'
                error={!!error}
                helperText={error?.message}
                onChange={(e) => field.onChange(parseFloat(e.target.value) || 0)} // Ensure value is a number
              />
            </Box>
          )}
        />
        <TextFieldComponent
          control={control}
          name='cassRegistryId'
          label='CAS'
          placeholder='0000-00-0'
          tooltipText={
            <>
              Search CAS registry number® (cas rn®)
              <TooltipLink href='https://commonchemistry.cas.org/detail?cas_rn=58-08-2' text='HERE' />
            </>
          }
        />
        <TextFieldComponent
          control={control}
          name='harmonizedTariffSchedule'
          label='HTS'
          placeholder='000'
          tooltipText={
            <>
              HTS sets out the tariff rates and statistical categories for merchandise imported into the United States.
              <TooltipLink href='https://hts.usitc.gov/' text='More Info' />
            </>
          }
        />
        <TextFieldComponent
          control={control}
          name='unNumber'
          label='UN Number'
          placeholder='0000'
          tooltipText={
            <>
              A four-digit number that identifies
              <TooltipLink href='https://en.wikipedia.org/wiki/UN_number' text='hazardous materials' />
            </>
          }
        />
        <Controller
          name='usmcaClass'
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={Boolean(watch("usmcaClass"))}
                  role='checkbox'
                  onChange={(event) => setValue("usmcaClass", Boolean(event.target.checked))}
                  name='usmcaClass'
                />
              }
              className='mb-1 mt-[27px]'
              label={
                <Box className='flex gap-3'>
                  USMCA (NAFTA) Class
                  <Tooltip
                    title={
                      <>
                        Determine whether your goods qualify for USMCA
                        <TooltipLink
                          href='https://www.braumillerlaw.com/chemicals-and-the-usmca-its-not-just-how-you-mix-it-to-get-a-reaction-or-not-from-cbp/'
                          text='HERE'
                        />
                      </>
                    }
                  >
                    <HelpOutlined />
                  </Tooltip>
                </Box>
              }
            />
          )}
        />
      </Box>
    </form>
  )
}

export const TooltipLink = ({ text, href }: { text: string; href: string }): ReactElement => (
  <Link
    href={href}
    target='_blank'
    rel='noopener'
    className='ml-1 text-[#ACE1FF] decoration-[#ACE1FF]'
    underline='always'
  >
    {text}
  </Link>
)

type TextFieldComponentProps = {
  name: string
  type?: string
  label: string
  placeholder?: string
  control: any
  tooltipText?: ReactNode
  required?: boolean
  onChange?: (e: any, field: any) => void
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"

export const TextFieldComponent: FC<TextFieldComponentProps> = (props: TextFieldComponentProps) => {
  const { name, type = "text", control, placeholder, label, tooltipText, onChange } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box className='relative'>
          <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>{label}</label>
          <TextField
            {...field}
            type={type}
            value={field.value}
            slotProps={{
              input: {
                endAdornment: tooltipText ? (
                  <Tooltip
                    title={
                      <Typography className='m-1 w-[155px] text-xs font-medium text-white'>{tooltipText}</Typography>
                    }
                    className=''
                  >
                    <HelpOutlined />
                  </Tooltip>
                ) : (
                  <HelpOutlined />
                ),
                classes: {
                  input: InputFieldClasses,
                },
                inputProps: {
                  className: `${type === "number" ? "hide-stepper" : ""}`,
                },
              },
            }}
            placeholder={placeholder}
            fullWidth
            variant='outlined'
            error={!!error}
            helperText={error?.message}
            onChange={(e) => {
              field.onChange(onChange ? onChange(e, field) : e.target.value)
            }}
          />
        </Box>
      )}
    />
  )
}
