import Badge from "@mui/material/Badge"
import type { ReactElement } from "react"

export type StatusKey = string | boolean

export type StatusColor = "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"

export type StatusMap = Map<StatusKey, StatusColor>

export const StatusIndicator = ({ status, map }: { status: StatusKey; map: StatusMap }): ReactElement => (
  <Badge
    data-testid={`statusIndicator-${status}`}
    color={map.get(status)}
    badgeContent=''
    classes={{
      root: "block w-fit",
      standard: "min-w-2 h-2 p-0",
      colorSuccess: "bg-tertiary-green-500",
      colorSecondary: "bg-gray-500",
    }}
  />
)
