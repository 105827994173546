import { Box, Chip, IconButton, Paper, Typography } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { ReactNode } from "react"

interface ChipData {
  label: string
  value: string
}

interface RowData {
  label: string
  value: string
}

interface InfoTableProps {
  title: string
  chipData?: ChipData[]
  rows?: RowData[]
  extraInfo?: RowData[]
  location?: string
  onEdit?: () => void
}

/**
 * InfoTable - A reusable component for displaying structured information.
 *
 * @param props Component props
 * @param props.title Title displayed in the header
 * @param props.chipData Array of ChipData objects displayed as chips in the header. Optional
 * @param props.rows Array of RowData objects displayed as label-value pairs in the table. Optional
 * @param props.extraInfo Additional information displayed as labeled text items. Optional
 * @param props.location Location or other final text item displayed at the end of the table. Optional
 * @param props.onEdit Optional edit button click handler
 */
export const InfoTable = ({
  title,
  chipData = [],
  rows = [],
  extraInfo = [],
  location,
  onEdit,
}: InfoTableProps): ReactNode => (
  <Box className='h-full'>
    <Box className='flex h-[60px] items-center justify-between bg-primary-100 p-4'>
      <Box className='flex gap-4'>
        <Typography variant='h6' className='content-center text-base text-gray-700'>
          {title}
        </Typography>
        {chipData.map(({ label, value }, index) => (
          <Chip
            key={label + index}
            label={
              <>
                <span className='font-bold'>{label}</span> {value}
              </>
            }
            className='rounded bg-primary-200 font-[12px] text-gray-900'
          />
        ))}
      </Box>
      <IconButton color='primary' aria-label='edit' className='size-10 p-4' onClick={onEdit}>
        <EditOutlined />
      </IconButton>
    </Box>
    <Paper className='border border-t-0 border-gray-300 p-6' elevation={0}>
      <Box className='flex gap-4'>
        <Box className='w-1/2'>
          {rows.map((row, index) => (
            <Box key={index} className='mb-3 flex justify-between'>
              <Typography variant='body2' className='text-gray-700'>
                {row.label}
              </Typography>
              <Typography variant='body2' className='w-1/2 text-gray-700'>
                {row.value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className='w-1/2'>
          {extraInfo.map((info, index) => (
            <Typography key={index} variant='body2' className='text-gray-700'>
              <span className='font-bold'>{info.label}:</span> {info.value}
            </Typography>
          ))}
          {location && (
            <Typography variant='body2' className='mt-2 text-gray-700'>
              {location}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  </Box>
)
