import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { PaymentTerm, PaymentTermListSuccess, Query } from "@/graphql/codegen/graphql"
import { PaymentTermsListDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20

export const useGetPaymentTerms = (): {
  paymentTermListError: CombinedError | undefined
  paymentTermList: PaymentTerm[]
  paymentTermListFetching: boolean
  triggerQuery: () => void
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [paymentTermList, setPaymentTermList] = useState<PaymentTerm[]>([])

  const [
    { data: paymentTermDetails, fetching: paymentTermDetailsFetching, error: paymentTermDetailsError },
    queryPaymentTerms,
  ] = useQuery<Pick<Query, "paymentTerm">>({
    query: PaymentTermsListDocument,
    variables: { input: { limit: LIMIT, offset } },
  })

  useEffect(() => {
    if (paymentTermDetails) {
      const paymentTerm = (paymentTermDetails?.paymentTerm.list as PaymentTermListSuccess).paymentTerms
      setPaymentTermList((prev) => (offset === 0 ? paymentTerm : [...prev, ...paymentTerm]))
    }
  }, [paymentTermDetails, offset])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  return {
    paymentTermList,
    paymentTermListFetching: paymentTermDetailsFetching,
    paymentTermListError: paymentTermDetailsError,
    loadMore,
    triggerQuery: queryPaymentTerms,
  }
}
