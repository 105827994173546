import { Box, Checkbox, FormControlLabel } from "@mui/material"
import type { ReactElement } from "react"
import { Controller } from "react-hook-form"

import { ProductStatusReason } from "@/graphql/codegen/graphql.ts"

export const ProductStatusFilter = <T,>({
  data = [],
  labelMap = {
    [ProductStatusReason.Active]: "Active",
    [ProductStatusReason.Approved]: "Approved",
    [ProductStatusReason.Discontinued]: "Discontinued",
    [ProductStatusReason.ItemDataComplete]: "Item Data Complete",
    [ProductStatusReason.ItemDetailsAdded]: "Item Details Added",
    [ProductStatusReason.PendingApproval]: "Pending Approval",
    [ProductStatusReason.Rejected]: "Rejected",
  },
  control,
  name,
  onFilterChange,
}: {
  data: ProductStatusReason[]
  labelMap?: Record<ProductStatusReason, string>
  name: string
  control: any
  onFilterChange: (name: keyof T, value: ProductStatusReason | null) => void
}): ReactElement => (
  <Box className='flex flex-col px-8 py-4'>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {data.map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={field.value === status}
                  onChange={(e) => {
                    const newValue = e.target.checked ? status : null
                    field.onChange(newValue)
                    onFilterChange(name as keyof T, newValue)
                  }}
                />
              }
              label={labelMap[status] || status}
            />
          ))}
        </>
      )}
    />
  </Box>
)
