import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Modal, Typography } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { memo, useCallback, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { CancelTemplate } from "@/components/common/Modals/CancelTemplate.tsx"
import type {
  Company,
  CompanyCreateFailure,
  CompanyCreateInput,
  CompanyCreateSuccess,
} from "@/graphql/codegen/graphql.ts"
import { CompanyStatus } from "@/graphql/codegen/graphql.ts"
import { CreateNewCompanyForm } from "@/screens/Companies/create/components/CreateNewCompanyForm.tsx"
import { CompanyModalSchema } from "@/screens/Companies/create/components/CreateNewCompanySchema.tsx"
import { useCreateNewCompanyModal } from "@/screens/Companies/hooks/useCreateNewCompanyModal.tsx"
import { useNotificationsStore } from "@/stores/useNotificationsStore"
import type { TreeNode } from "@/types/tree.ts"

const NewCompanySuccessMessage = () => (
  <>
    <p>A new company has been successfully created.</p>
    <p>Add required information to complete company’s setup or save and come back later.</p>
  </>
)

type CreateNewCompanyModalContainerProps = {
  isOpen: boolean
  onClose: (data?: CompanyCreateInput, reason?: "backdropClick" | "escapeKeyDown") => void
  onSuccess?: (value: ((prevState: boolean) => boolean) | boolean | Company) => void
}

// extend COmpanyCreateInput to have accountManagers to have at least one accountManagerId that is not empty
type CompanyCreateInputWithAccountManagers = CompanyCreateInput & {
  accountManagers: { accountManagerId: string }[]
}

const CreateNewCompanyModal: FunctionComponent<CreateNewCompanyModalContainerProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [modalInput, setModalInput] = useState("")
  const { companyNameList, companyTypeList, accountsManagerList, createCompany } = useCreateNewCompanyModal(modalInput)
  const methods = useForm<CompanyCreateInputWithAccountManagers>({
    defaultValues: {
      name: "",
      types: [],
      accountManagers: [],
      status: CompanyStatus.Active,
    },
    resolver: zodResolver(CompanyModalSchema),
    mode: "onChange",
  })

  const notify = useNotificationsStore((state) => state.enqueueNotification)

  const { setError, clearErrors, handleSubmit } = methods

  const [isCancelConfirmationOpen, setCancelConfirmationOpen] = useState(false)
  const [step, setStep] = useState(1)

  const handleNextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1)
  }, [])

  const handleCancel = useCallback(() => {
    setCancelConfirmationOpen(true)
  }, [])

  const confirmCancel = useCallback(() => {
    setCancelConfirmationOpen(false)
    onClose()
  }, [onClose])

  useEffect(() => {
    if (!isOpen) {
      setStep(1)
      setCancelConfirmationOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (modalInput) {
      const isDuplicate = companyNameList.some((name) => name.toLowerCase().trim() === modalInput.toLowerCase().trim())

      if (isDuplicate) {
        setError("name", {
          type: "manual",
          message: "Company with this name already exists.",
        })
      }
    }
  }, [modalInput, companyNameList, setError, clearErrors])

  const onSubmit = handleSubmit(async (data) => {
    const filteredAccountsManagerList = accountsManagerList
      .filter((accountManager) =>
        data.accountManagers?.some((selected) => selected.accountManagerId === accountManager.accountManagerId)
      )
      .map(({ accountManagerId }) => ({ accountManagerId }))
    const { error, data: company } = await createCompany({
      input: {
        accountManagers: filteredAccountsManagerList,
        name: data.name,
        status: CompanyStatus.Active,
        types: data.types.map((company) => company),
      },
    })

    const hasError =
      error?.message ||
      (company?.company.create.__typename === "CompanyCreateFailure" && Boolean(company?.company.create.error))

    if (hasError) {
      // Handle any errors that might occur during the creation of the company
      setError("name", {
        type: "server",
        message: error?.message || (company?.company.create as CompanyCreateFailure).error?.message,
      })
      notify({
        type: "error",
        silent: false,
        message: error?.message || "Failed to create company",
      })
    } else if (onSuccess) {
      methods.reset()
      onSuccess((company?.company.create as CompanyCreateSuccess).company)
      notify({
        type: "success",
        silent: false,
        content: <NewCompanySuccessMessage />,
      })
    }
    methods.reset()

    onClose(data)
  })

  return (
    <>
      <Modal open={isOpen} onClose={handleCancel}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            width: 576,
            borderRadius: 2,
          }}
        >
          {isCancelConfirmationOpen ? (
            <>
              <CancelTemplate onClose={setCancelConfirmationOpen} confirmCancel={confirmCancel} />
            </>
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, pl: 5 }}>
                <Typography variant='h6' color='primary'>
                  New Company
                </Typography>
                <Button size='small' appearance='text' onClick={handleCancel}>
                  <CloseOutlined />
                </Button>
              </Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
              <FormProvider {...methods}>
                <CreateNewCompanyForm
                  companyNameList={companyNameList}
                  companyTypeList={companyTypeList as unknown as TreeNode<string>[]}
                  accountsManagerList={accountsManagerList}
                  step={step}
                  onChange={setModalInput}
                  handleCancel={handleCancel}
                  handleNextStep={handleNextStep}
                  onSubmit={onSubmit}
                />
              </FormProvider>
            </>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default memo(CreateNewCompanyModal)
