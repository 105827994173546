import { Box } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import Toolbar from "@mui/material/Toolbar"
import type { AnyRoute } from "@tanstack/react-router"
import { Outlet, createRootRoute, useLocation, useRouter } from "@tanstack/react-router"
import { useEffect, useMemo } from "react"
import { twMerge } from "tailwind-merge"

import AppHeader from "@/components/common/Header/AppHeader.tsx"
import { Notifications } from "@/components/common/Notifications/Notifications.tsx"
import SideNav from "@/components/SideNav/SideNav.tsx"
import { useAuthStore } from "@/stores/authStore.ts"
import theme from "@/styles/mui-theme.ts"
import type { User } from "@/types/user/user.schema.tsx"
import { UserSchema } from "@/types/user/user.schema.tsx"
import { addLabels } from "@/utils/breadcrumbs/addLabelToRoute.ts"
import { getRoutes } from "@/utils/breadcrumbs/availableRoutes.ts"
import { generateBreadcrumbItems } from "@/utils/breadcrumbs/generateBreadcrumItems.ts"
import { labelMapping } from "@/utils/constant/navigation.tsx"
const Component = () => {
  const location = useLocation() // Triggered location change event
  const currentUser = useAuthStore((state) => state.user)
  const checkAuth = useAuthStore((state) => state.checkAuth)

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  const router = useRouter()
  const user: User = useMemo(() => {
    return UserSchema.parse({
      id: currentUser?.id ?? "",
      name: currentUser?.fullName ?? "",
      email: currentUser?.fullName ?? "",
      avatar: currentUser?.avatarUrl ?? "",
    })
  }, [currentUser])

  const drawerWidth = 272

  const routes = useMemo(
    () =>
      getRoutes({
        routeTree: router.routeTree,
        options: {
          excludePatterns: ["**/auth/**"],
        },
      }),
    [router.routeTree]
  )

  const routesWithLabels = useMemo(() => addLabels(routes, labelMapping), [routes])
  const breadcrumbItems = useMemo(
    () => generateBreadcrumbItems(routesWithLabels, location.pathname),
    [routesWithLabels, location.pathname]
  )

  return (
    <div className='flex h-screen bg-gray-100'>
      <AppHeader
        className={twMerge([`z-[${theme.zIndex.drawer + 1}]`, "bg-transparent p-6"])}
        user={user}
        notifications={0}
        breadcrumbs={breadcrumbItems}
        pathname={location.pathname}
      />
      <Drawer
        classes={{ paperAnchorLeft: "border-0 pl-6 pr-4'", paper: "bg-transparent" }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant='permanent'
        anchor='left'
      >
        <Toolbar className='h-28 py-6' />
        <SideNav />
      </Drawer>
      <main className='flex grow flex-col overflow-hidden bg-transparent'>
        <Toolbar className='h-28 py-6' />
        <Box className='flex flex-1 overflow-y-auto'>
          <Outlet />
        </Box>
      </main>
      <Notifications />
    </div>
  )
}

export const Route: AnyRoute = createRootRoute({
  component: Component,
})
