import type { CreatePricingPackageInput } from "@/graphql/codegen/graphql.ts"
import { FreightTerms, Incoterms } from "@/graphql/codegen/graphql.ts"

export const pricingTiers = Object.values(FreightTerms)
export const incoterms = Object.values(Incoterms)
const incoTermsLabels = {
  CIP: "CIP: Carriage and Insurance Paid To",
  CPT: "CPT: Carriage Paid To",
  DAP: "DAP: Delivered At Place",
  DPU: "DPU: Delivered At Place Unloaded",
  DDP: "DDP: Delivered Duty Paid",
  EXW: "EXW: Ex Works",
  FCA: "FAC: Free Carrier",
  FAS: "FAS: Free Alongside Ship",
  FOB: "FOB: Free On Board",
  CFR: "CFR: Cost and Freight",
  CIF: "CIF: Cost, Insurance and Freight",
}

function formatEnumValue(enumValue: string): string {
  return enumValue
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const formattedPricingTiers = pricingTiers.map((key) => ({
  value: key,
  label: formatEnumValue(key),
}))

// Utility function to format incoterms with labels
function getFormattedIncoterms(includeSeaTerms: boolean) {
  return incoterms
    .filter((term) =>
      includeSeaTerms
        ? ["CFR", "CIF", "FAS", "FOB"].some((seaTerm) => term.includes(seaTerm))
        : !["CFR", "CIF", "FAS", "FOB"].some((seaTerm) => term.includes(seaTerm))
    )
    .map((key) => ({ value: key, label: incoTermsLabels[key] }))
}

export const formatAnyModeTransportIncoterms = getFormattedIncoterms(false)
export const formatSeaAndInlandWaterwayIncoterms = getFormattedIncoterms(true)

export const formattedTierData = (data: any): CreatePricingPackageInput => {
  return {
    pricingPackageToCreate: {
      productId: data.productId,
      companyId: data.companyId,
      sourceCompanyLocationAssociationId: data.sourceCompanyLocationAssociationId,
      destinationCompanyLocationAssociationId: data.destinationCompanyLocationAssociationId,
      isBuySide: false,
      incoterms: data.incoterms,
      incotermsLocationId: data.incotermsLocationId,
      freightTerms: data.freightTerms,
      effectiveDate: data.effectiveDate,
      expirationDate: data.expirationDate || null,
      unitOfMeasurementId: data.pricingTiersToCreate[0]?.unitOfMeasurement?.id,
    },
    pricingTiersToCreate: data.pricingTiersToCreate
      .map(({ numberOfUnits, pricePerUnit }) =>
        numberOfUnits && pricePerUnit
          ? {
              numberOfUnits: parseInt(numberOfUnits),
              pricePerUnit: parseFloat(pricePerUnit),
            }
          : undefined
      )
      .filter(Boolean),
  }
}
