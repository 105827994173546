import type { AuthTokens } from "@/graphql/codegen/graphql.ts"
import env from "@/utils/env.ts"

const makeGraphQLRequest = async (query: string, variables: any, token: string): Promise<any> => {
  const result = await fetch(env.VITE_API_ORIGIN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ query, variables }),
  })

  const json = await result.json()

  if (!json.data || json.errors) {
    throw new Error(json.errors?.[0]?.message || "Request failed")
  }

  return json.data
}

export const exchangeTokenWithAPI = async (idToken: string): Promise<AuthTokens> => {
  const query = `
    mutation authorization($input: AuthExchangeTokensInput!) {
      auth {
        exchangeToken(input: $input) {
          __typename
          ... on AuthExchangeTokenSuccess {
            tokens {
              accessToken
              refreshToken
            }
          }
          ... on AuthExchangeTokenFailure {
            error {
              message
            }
          }
        }
      }
    }
  `

  const variables = { input: { identityToken: idToken } }

  const data = await makeGraphQLRequest(query, variables, idToken)
  if (data.auth.exchangeToken.__typename === "AuthExchangeTokenSuccess") {
    return data.auth.exchangeToken.tokens
  }

  throw new Error(data.auth.exchangeToken.error.message || "Token exchange failed")
}

export const refreshTokenWithAPI = async ({ accessToken, refreshToken }: AuthTokens): Promise<AuthTokens> => {
  const query = `
    mutation authorization($input: AuthRefreshTokensInput!) {
      auth {
        refreshToken(input: $input) {
          __typename
          ... on AuthRefreshTokenSuccess {
            tokens {
              accessToken
              refreshToken
            }
          }
          ... on AuthRefreshTokenFailure {
            error {
              message
            }
          }
        }
      }
    }
  `

  const variables = { input: { refreshToken } }

  const data = await makeGraphQLRequest(query, variables, accessToken)
  if (data.auth.refreshToken.__typename === "AuthRefreshTokenSuccess") {
    return data.auth.refreshToken.tokens
  }

  throw new Error(data.auth.refreshToken.error.message || "Token refresh failed")
}
