import { useEffect, useMemo } from "react"
import { useQuery } from "urql"

import type { Product, ProductTechnicalInformationQueryVariables } from "@/graphql/codegen/graphql"
import { ProductTechnicalInformationDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseTechnicalInformationProps {
  productId: number
}

type ProductTechnicalInformation = Pick<
  Product,
  "specificGravity" | "shelfLifeDays" | "cassRegistryId" | "harmonizedTariffSchedule" | "usmcaClass" | "unNumber"
>

interface UseTechnicalInformationResponse {
  data: ProductTechnicalInformation | null
  fetching: boolean
  fetch: (variables: ProductTechnicalInformationQueryVariables) => void
}

export const useProductTechnicalInformation = ({
  productId,
}: UseTechnicalInformationProps): UseTechnicalInformationResponse => {
  const [{ data, error, fetching }, fetch] = useQuery({
    query: ProductTechnicalInformationDocument,
    variables: {
      productId,
    },
  })

  const hasError = error?.message || data?.product.get.__typename === "ProductGetFailure"
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  useEffect(() => {
    if (hasError) {
      notify({
        message: "Error fetching product technical information",
        type: "error",
        silent: false,
      })
    }
  }, [hasError, notify])

  const technicalInformation = useMemo(() => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return data.product.get.product
    }
    return null
  }, [data])

  return {
    data: technicalInformation as ProductTechnicalInformation | null,
    fetching,
    fetch,
  }
}
