import { Box, Divider, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { AccountManagerFilter } from "@/components/common/Filters/AccountManagerFilter"
import { StatusFilter } from "@/components/common/Filters/StatusFilter"
import type { CompanyListFilter } from "@/graphql/codegen/graphql.ts"
import { CompanyStatus } from "@/graphql/codegen/graphql.ts"
import { useAccountManagers } from "@/hooks/useAccountManagers.ts"
import { CompanyFilterSchema } from "@/screens/Companies/types/filters.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"

const statusList = Object.values(CompanyStatus)
const statusKey = "statuses"

export const CompanySearchFilter: FC = () => {
  const navigate = useNavigate({ from: "/companies/" })
  const currentSearch = useSearch({
    from: "/companies/",
    strict: true,
  })

  const { statuses, accountManagers, types } = currentSearch

  const methods = useForm<CompanyListFilter>({
    values: CompanyFilterSchema.parse({
      statuses: statuses ?? [],
      accountManagers: accountManagers ?? [],
      types: types ?? [],
    }),
  })

  const { visibleManagers, loadMore, fetching } = useAccountManagers()

  const handleFilterChange = useCallback(
    (name: keyof CompanyListFilter, value: any) => {
      const params = getRefinedSearchParams(currentSearch, {
        ...currentSearch,
        [name]: value,
      })

      void navigate(params)
    },
    [currentSearch, navigate]
  )

  return (
    <FormProvider {...methods}>
      <Box className='flex h-full flex-col gap-y-2'>
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Company Status
          </Typography>
          <Divider />
          <StatusFilter
            name={statusKey}
            data={statusList}
            control={methods.control}
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className='grow overflow-hidden'>
          <AccountManagerFilter
            managers={visibleManagers}
            onFilterChange={handleFilterChange}
            fetching={fetching}
            loadMore={loadMore}
          />
        </Box>
      </Box>
    </FormProvider>
  )
}
