import { useCallback, useEffect } from "react"

type ScrollLoadMoreProps = {
  fetching: boolean
  loadMore: () => void
  scrollContainerRef: React.RefObject<HTMLElement>
}

export const useScrollLoadMore = ({ fetching, loadMore, scrollContainerRef }: ScrollLoadMoreProps): (() => void) => {
  // Check if we should trigger loadMore based on scroll position
  const shouldLoadMore = useCallback((): boolean => {
    const container = scrollContainerRef.current
    if (!container) {
      console.warn("scrollContainerRef is null or undefined.")
      return false
    }

    const { scrollTop, clientHeight, scrollHeight } = container
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight

    // Check if we're scrolling near the bottom and not fetching
    return scrollPercentage >= 0.8 && !fetching && scrollTop > 0
  }, [fetching, scrollContainerRef])

  // Scroll handler to trigger loadMore
  const handleScroll = useCallback(() => {
    if (shouldLoadMore()) {
      loadMore()
    }
  }, [shouldLoadMore, loadMore])

  // Attach and cleanup scroll event listener
  useEffect(() => {
    const container = scrollContainerRef.current
    if (container) {
      container.addEventListener("scroll", handleScroll)

      return () => {
        container.removeEventListener("scroll", handleScroll)
      }
    } else {
      console.warn("scrollContainerRef.current is null; event listener not added.")
    }
  }, [handleScroll, scrollContainerRef])

  // Return the handleScroll function for manual triggering if needed
  return handleScroll
}
