import { useCallback, useEffect, useRef } from "react"

interface UseInfiniteScrollOptions {
  threshold?: number
  isLoading: boolean
  hasMore: boolean
}

export const useInfiniteScroll = (
  onLoadMore: () => void,
  options: UseInfiniteScrollOptions
): { containerRef: React.RefObject<HTMLDivElement> } => {
  const { threshold = 100, isLoading, hasMore } = options
  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = useCallback(() => {
    if (!containerRef.current || isLoading || !hasMore) return

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current
    if (scrollHeight - (scrollTop + clientHeight) < threshold) {
      onLoadMore()
    }
  }, [isLoading, hasMore, onLoadMore, threshold])

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  return { containerRef }
}
