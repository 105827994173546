import type { TableRowProps } from "@mui/material"
import { TableRow } from "@mui/material"
import { twMerge } from "tailwind-merge"

/**
 * Table row component for the body section.
 * Should contain AerosTableCell components.
 *
 * @example
 * ```tsx
 * <AerosTableRow>
 *   <AerosTableCell>Data 1</AerosTableCell>
 *   <AerosTableCell>Data 2</AerosTableCell>
 * </AerosTableRow>
 * ```
 *
 * @param props - Extends MUI TableRowProps
 * @param props.className - Additional Tailwind classes for row styling
 */
export const AerosTableRow = ({ className, ...props }: TableRowProps): JSX.Element => {
  return <TableRow className={twMerge(["h-16 border-none text-gray-700"], className)} {...props} />
}
