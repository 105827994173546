import { Box, IconButton, Paper, TextareaAutosize, Typography } from "@mui/material"
import { AddOutlined, EditOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { PurchaseOrderSection } from "../types"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

export const NotesContainer = (): ReactElement => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { updateSectionFields, updateModifiedFields } = usePurchaseOrderStore()
  const noteValues = usePurchaseOrderStore((state) => state.formValues[PurchaseOrderSection.NOTES])

  // React Hook Form setup
  const { control, watch } = useForm({
    defaultValues: {
      currentNote: noteValues || "",
    },
  })
  // Sync form values with the store when the form value changes
  const noteValue = watch("currentNote")

  useEffect(() => {
    if (!noteValue) return

    updateSectionFields(PurchaseOrderSection.NOTES, noteValue)
    updateModifiedFields(PurchaseOrderSection.NOTES, { note: noteValue })
  }, [noteValue, updateSectionFields, updateModifiedFields])

  return (
    <Paper elevation={0}>
      <Typography variant='subtitle1' className='mb-3 text-lg'>
        PO Note
      </Typography>
      <Box>
        {isEdit ? (
          <Box className='relative w-full items-center justify-between py-3'>
            <Controller
              name='currentNote'
              control={control}
              render={({ field }) => (
                <TextareaAutosize
                  {...field}
                  minRows={1.5}
                  maxRows={1.5}
                  className='overflowY-auto w-full rounded-md border border-solid border-gray-300 p-4 pr-[120px] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#BFDBFE]'
                />
              )}
            />
          </Box>
        ) : noteValue ? (
          <Paper
            elevation={0}
            className='group relative h-[70px] w-full items-center justify-between border border-gray-300 p-6'
          >
            <Controller
              name='currentNote'
              control={control}
              render={() => (
                <Typography variant='body2' className='align-center h-full truncate pr-[120px] text-gray-800'>
                  {noteValue || ""}
                </Typography>
              )}
            />

            <IconButton
              color='primary'
              aria-label='edit comment'
              size='small'
              className='group absolute right-3 top-3 hidden rounded-none group-hover:block'
              onClick={() => setIsEdit(true)}
            >
              <EditOutlined className='mr-1' />
            </IconButton>
          </Paper>
        ) : (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Add note to this PO'
            action={
              <Button
                color='inherit'
                size='small'
                appearance='outlined'
                className='text-primary-500'
                startIcon={<AddOutlined />}
                onClick={() => setIsEdit(true)}
              >
                New Note
              </Button>
            }
            description={""}
          />
        )}
      </Box>
    </Paper>
  )
}
