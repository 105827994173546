import { useEffect, useRef, useState } from "react"
import { useQuery } from "urql"

import { FieldGroupEnum, GetPurchaseOrderFileDocument, ReferenceTableName } from "@/graphql/codegen/graphql"

export const usePurchaseOrderFile = (orderId?: number | null): string | null => {
  const [hasPdf, setHasPdf] = useState(false)
  const pollInterval = useRef<NodeJS.Timeout | undefined>()

  const [{ data: purchaseOrderFileResult }, getPurchaseOrderFile] = useQuery({
    query: GetPurchaseOrderFileDocument,
    variables: {
      input: {
        referenceId: `${orderId ?? ""}`,
        referenceTableName: ReferenceTableName.Order,
        fieldGroups: [FieldGroupEnum.PurchaseOrder],
      },
    },
    pause: !orderId || hasPdf,
  })

  if (
    !hasPdf &&
    purchaseOrderFileResult?.fileUpload.getFileInfo.__typename === "GetFileInfoSuccess" &&
    purchaseOrderFileResult.fileUpload.getFileInfo.signedUrls.length > 0
  ) {
    // File has been generated for this order, stop polling
    setHasPdf(true)
  }

  useEffect(() => {
    if (!hasPdf) {
      pollInterval.current = setInterval(() => {
        getPurchaseOrderFile()
      }, 3_000)
    } else {
      clearInterval(pollInterval.current)
    }

    return () => {
      clearInterval(pollInterval.current)
    }
  }, [getPurchaseOrderFile, hasPdf])

  return purchaseOrderFileResult?.fileUpload.getFileInfo.__typename === "GetFileInfoSuccess"
    ? purchaseOrderFileResult?.fileUpload.getFileInfo.signedUrls[0]?.url
    : null
}
