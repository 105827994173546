import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { Query, UnitOfMeasurement, UnitOfMeasurementListOutputSuccess } from "@/graphql/codegen/graphql"
import { UnitOfMeasurementListDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20

export const useGetUnitOfMeasurementList = (): {
  unitOfMeasurementListError: CombinedError | undefined
  unitOfMeasurementList: UnitOfMeasurement[]
  unitOfMeasurementListFetching: boolean
  triggerQuery: () => void
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [unitOfMeasurementList, setUnitOfMeasurementList] = useState<UnitOfMeasurement[]>([])

  const [
    {
      data: unitOfMeasurementDetails,
      fetching: unitOfMeasurementDetailsFetching,
      error: unitOfMeasurementDetailsError,
    },
    queryUnitOfMeasurement,
  ] = useQuery<Pick<Query, "unitOfMeasurement">>({
    query: UnitOfMeasurementListDocument,
  })

  useEffect(() => {
    if (unitOfMeasurementDetails) {
      const locations = (unitOfMeasurementDetails.unitOfMeasurement?.list as UnitOfMeasurementListOutputSuccess)
        ?.unitOfMeasurements
      setUnitOfMeasurementList((prev) => (offset === 0 ? locations : [...prev, ...locations]))
    }
  }, [unitOfMeasurementDetails, offset])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const triggerQuery = useCallback(() => queryUnitOfMeasurement(), [queryUnitOfMeasurement])

  return {
    unitOfMeasurementList,
    unitOfMeasurementListFetching: unitOfMeasurementDetailsFetching,
    unitOfMeasurementListError: unitOfMeasurementDetailsError,
    loadMore,
    triggerQuery,
  }
}
