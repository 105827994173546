import { Collapse, Paper } from "@mui/material"
import { AddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useCallback, useMemo, useState } from "react"
import { useMutation, useQuery } from "urql"

import type { SignedUrl } from "../ProductForm/types"

import { Header } from "./components/Header"
import { LabelInformationContent } from "./LabelInformationContent"
import { LabelInformationDialog } from "./LabelInformationDialog"
import type { LabelInformation, LabelInformationFormData } from "./types"
import { mapSignedUrlsToPDFSizePattern } from "./types"
import { ViewLabelsDialog } from "./ViewLabelsDialog"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import type { GetFileInfoQuery, GetFileInfoQueryVariables } from "@/graphql/codegen/graphql"
import {
  FieldGroupEnum,
  GetFileInfoDocument,
  ProductLabelInfoDocument,
  ProductUpdateDocument,
  ReferenceTableName,
} from "@/graphql/codegen/graphql"
import { useImageUpload } from "@/hooks/useImageUpload"
import { usePDFUpload } from "@/hooks/usePDFUpload"
import { getLatestSignedUrls } from "@/screens/Products/routeLoader"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const getLatestImageSignedUrl = (signedUrls: SignedUrl[]): SignedUrl => {
  return getLatestSignedUrls(
    signedUrls.filter(
      (url: SignedUrl) =>
        url.url?.includes(".jpg") ||
        url.url?.includes(".png") ||
        url.url?.includes(".jpeg") ||
        url.url?.includes(".gif")
    )
  ).sort((a, b) => {
    const aCreatedOn = Number(a.file?.createdOn ?? 0)
    const bCreatedOn = Number(b.file?.createdOn ?? 0)
    return aCreatedOn - bCreatedOn
  })[0] as SignedUrl
}

export const LabelInformationContainer = (): ReactElement => {
  const { productId } = useParams({ from: "/product/$productId/edit/_layout/information" })

  const [{ data }, getProductLabelInfo] = useQuery({
    query: ProductLabelInfoDocument,
    variables: { productId: Number(productId) },
  })

  const [{ data: fileUploadData }, getFileInfo] = useQuery<GetFileInfoQuery, GetFileInfoQueryVariables>({
    query: GetFileInfoDocument,
    variables: {
      fileInfoInput: {
        referenceTableName: ReferenceTableName.Product,
        referenceId: productId.toString(),
        fieldGroups: [FieldGroupEnum.ProductLabel],
      },
    },
  })

  const { handleGetSignedUrl: handleGetPDFSignedUrl, handleUploadToS3: handleUploadPDFToS3 } = usePDFUpload({
    referenceTableName: ReferenceTableName.Product,
    getReferenceId: () => productId.toString(),
  })
  const { handleGetSignedUrl: handleGetImageSignedUrl, handleUploadToS3: handleUploadImageToS3 } = useImageUpload({
    referenceTableName: ReferenceTableName.Product,
    getReferenceId: () => productId.toString(),
    type: FieldGroupEnum.ProductLabel,
  })
  const [{ data: updateProductData }, updateProduct] = useMutation(ProductUpdateDocument)

  const notify = useNotificationsStore((state) => state.enqueueNotification)

  const fileData = useMemo(() => {
    if (!fileUploadData) return []

    if (fileUploadData.fileUpload.getFileInfo.__typename === "GetFileInfoFailure") {
      return []
    }

    return fileUploadData.fileUpload.getFileInfo.signedUrls
  }, [fileUploadData])

  const labelInfo = useMemo((): LabelInformation => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return {
        isPrivateLabel: data.product.get.product.privateLabel ?? false,
        isPrintedBySupplier: null, // TODO: API pending
        isAppliedBySupplier: null, // TODO: API pending
      }
    }
    return {
      isPrivateLabel: false,
      isPrintedBySupplier: null,
      isAppliedBySupplier: null,
    }
  }, [data, updateProductData])

  const [expanded, setExpanded] = useState(true)
  const [openUpdateLabelInfo, setOpenUpdateLabelInfo] = useState(false)
  const [openViewLabels, setOpenViewLabels] = useState(false)
  const files = useMemo(
    () => ({
      pdfs: mapSignedUrlsToPDFSizePattern(
        fileData?.filter(({ file }: SignedUrl) => file?.originalName?.includes(".pdf")) ?? [],
        labelInfo
      ),
      image: getLatestImageSignedUrl(fileData ?? []),
    }),
    [fileData, labelInfo]
  )

  const hasFiles = useMemo(() => Boolean(files.pdfs?.length) || Boolean(files.image), [fileData, labelInfo])

  const toggleExpanded = useCallback(() => setExpanded((prev) => !prev), [])
  const handleCloseUpdateLabelInfo = useCallback(() => setOpenUpdateLabelInfo(false), [])
  const handleCloseViewLabels = useCallback(() => setOpenViewLabels(false), [])
  const handleOpenViewLabels = useCallback(() => {
    setOpenViewLabels(true)
  }, [])

  const handleOpenUpdateLabelInfo = useCallback(() => {
    setOpenUpdateLabelInfo(true)
  }, [])

  const handleSubmit = useCallback(
    async (data: LabelInformationFormData) => {
      try {
        await updateProduct({
          input: {
            productId: Number(productId),
            privateLabel: data.isPrivateLabel,
            // TODO: Add  printedBySupplier and appliedBySupplier
          },
        })

        if (data.image) {
          // Upload image
          const signedImageUrl = await handleGetImageSignedUrl(data.image, FieldGroupEnum.ProductLabel)
          if (!signedImageUrl) {
            throw new Error("Failed to get signed image URL")
          }
          await handleUploadImageToS3(signedImageUrl, FieldGroupEnum.ProductLabel, data.image)
        } else {
          // Upload pdfs
          const signedPdfUrls = await Promise.all(
            Object.entries(data.pdfs).map(([label, file]) =>
              handleGetPDFSignedUrl(file, FieldGroupEnum.ProductLabel, label)
            )
          )
          const uploaded = await Promise.all(
            signedPdfUrls
              .filter((signedUrl) => signedUrl !== null)
              .map((signedUrl, index) => handleUploadPDFToS3(signedUrl, FieldGroupEnum.ProductLabel, data.pdfs[index]))
          )

          if (uploaded.some((uploaded) => !uploaded)) {
            throw new Error("Failed to upload some PDFs")
          }
        }

        await new Promise((resolve) => setTimeout(resolve, 150))
        await Promise.all([getProductLabelInfo(), getFileInfo()])

        setOpenUpdateLabelInfo(false)
        notify({
          message: "Label information updated successfully",
          type: "success",
        })
      } catch (error) {
        console.error("Failed to update label information:", error)
        notify({
          message: "Failed to update label information",
          type: "error",
        })
      }
    },
    [updateProduct, productId, handleGetPDFSignedUrl, handleUploadPDFToS3, getProductLabelInfo, getFileInfo]
  )

  return (
    <>
      <Paper className='border border-gray-300 p-6' elevation={0}>
        <Header expanded={expanded} onExpandClick={toggleExpanded} />
        <Collapse in={expanded}>
          {!hasFiles ? (
            <InformationMissingAlert
              icon={<WarningOutlined color='primary' />}
              title='Add Product Label Information'
              action={
                <Button
                  color='inherit'
                  size='small'
                  appearance='outlined'
                  className='text-primary-500'
                  startIcon={<AddOutlined />}
                  onClick={handleOpenUpdateLabelInfo}
                  data-testid='add-label-info-button'
                >
                  Labeling Info
                </Button>
              }
              description={""}
              data-testid='product-label-info-alert'
            />
          ) : (
            <LabelInformationContent
              labelInfo={labelInfo}
              hasFiles={hasFiles}
              onEdit={handleOpenUpdateLabelInfo}
              onViewLabels={handleOpenViewLabels}
            />
          )}
        </Collapse>
      </Paper>

      <LabelInformationDialog
        open={openUpdateLabelInfo}
        onClose={handleCloseUpdateLabelInfo}
        onSave={handleSubmit}
        pdfFiles={files.pdfs}
        imageFile={files.image}
        labelInfo={labelInfo}
      />

      <ViewLabelsDialog
        open={openViewLabels}
        onClose={handleCloseViewLabels}
        onSave={handleSubmit}
        pdfFiles={files.pdfs}
        imageFile={files.image}
        labelInfo={labelInfo}
      />
    </>
  )
}
