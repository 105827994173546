import { Alert, AlertTitle, Box } from "@mui/material"
import { ConstructionOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

const WorkInProgressAlert = ({
  title = "Work in Progress",
  message = "This section is currently under development. Please check back later for updates.",
}: {
  title?: string
  message?: string
}): ReactElement => (
  <Box my={2}>
    <Alert icon={<ConstructionOutlined />} severity='info' variant='filled' color='warning'>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  </Box>
)

export default WorkInProgressAlert
