import { Box } from "@mui/material"
import { SearchOutlined } from "@mui-symbols-material/w300"
import { memo, useCallback, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"

import { InputField } from "@/components/common/InputField"
import { formattedCompanyDepartments } from "@/constants/companyDepartment.ts"
import type { CompanyDepartment, ContactQueryableFields } from "@/graphql/codegen/graphql.ts"
import { DepartmentCheckbox } from "@/screens/Companies/components/filters/contact/DepartmentCheckbox.tsx"

export const DepartmentFilter = memo<{
  onFilterChange: (name: keyof ContactQueryableFields, value: CompanyDepartment[]) => void
}>(({ onFilterChange }) => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const { watch, setValue } = useFormContext()

  // Watch the array of selected departments
  const companyDepartmentsValue = watch("companyDepartments") || []

  // Filter departments based on the search term
  const filteredDepartments = useMemo(() => {
    return formattedCompanyDepartments.filter(({ formatted }) =>
      formatted.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [searchTerm])

  // Update form value on checkbox change
  const handleFilterChange = useCallback(
    (newValue: CompanyDepartment[]) => {
      setValue("companyDepartments", newValue) // Update form state with new department array
      onFilterChange("companyDepartments", newValue)
    },
    [setValue, onFilterChange]
  )

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box className='flex-none p-4'>
        <InputField
          placeholder='Search by Name'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={<SearchOutlined className='text-gray-600' />}
        />
      </Box>

      <Box className='flex max-h-72 grow flex-col overflow-y-auto px-8 py-2'>
        {/* Render filtered department checkboxes */}
        {filteredDepartments.map((department, idx) => (
          <DepartmentCheckbox
            key={department.original + idx}
            department={department}
            value={companyDepartmentsValue} // Pass the array of selected values
            onChange={handleFilterChange} // Handle change for multiple selections
          />
        ))}
      </Box>
    </Box>
  )
})
