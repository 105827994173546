import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, capitalize } from "@mui/material"
import {
  DeleteOutlined,
  EditOutlined,
  KeyboardArrowDownSharp,
  KeyboardArrowUpSharp,
  MoreVertOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import type { ColumnDef, ExpandedState, Row } from "@tanstack/react-table"
import type { FC } from "react"
import { useCallback, useMemo, useState } from "react"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { RelatedProductInstruction } from "@/graphql/codegen/graphql"

const InstructionGroupDropdownContainer: FC<{
  row: Row<RelatedProductInstruction>
  onEdit: (instruction: RelatedProductInstruction) => void
  onDelete: (instruction: RelatedProductInstruction) => void
}> = ({ row, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = useCallback(
    (_instruction: RelatedProductInstruction) => {
      // onEdit(instruction)
      handleClose()
    },
    [onEdit]
  )

  const handleDelete = useCallback(
    (_instruction: RelatedProductInstruction) => {
      // onDelete(instruction)
      handleClose()
    },
    [onDelete]
  )

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertOutlined />
      </IconButton>
      <InstructionGroupsDropdownMenu
        anchorEl={anchorEl}
        row={row}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onClose={handleClose}
      />
    </>
  )
}

const InstructionGroupsDropdownMenu: FC<{
  anchorEl: HTMLElement | null
  row: Row<RelatedProductInstruction>
  onEdit: (instruction: RelatedProductInstruction) => void
  onDelete: (instruction: RelatedProductInstruction) => void
  onClose: () => void
}> = ({ anchorEl, row, onEdit, onDelete, onClose }) => {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {/* todo: enable edit */}
      <MenuItem onClick={() => onEdit(row.original)} disabled>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary='Edit' />
      </MenuItem>
      {/* todo: enable delete */}
      <MenuItem onClick={() => onDelete(row.original)} disabled>
        <ListItemIcon>
          <DeleteOutlined />
        </ListItemIcon>
        <ListItemText primary='Delete' />
      </MenuItem>
    </Menu>
  )
}

const InstructionGroupExpanded: FC<Row<RelatedProductInstruction>> = (row) => {
  return (
    <Box className='space-y-4'>
      <Box className='flex items-center space-x-2 border-b border-gray-200 p-4 pr-6 last:border-0'>
        {row.original.productInstruction?.printableLabel && (
          <Box
            component='img'
            src={row.original.productInstruction.printableLabel}
            alt={`Image for ${row.original.productInstruction.name}`}
            width={48}
            height={48}
            sx={{ marginRight: 2 }}
          />
        )}
        <Box>
          <Typography variant='subtitle2'>{row.original.productInstruction?.name}</Typography>
          <Typography variant='body2' color='text.secondary'>
            <pre className='text-wrap'>{row.original.productInstruction?.description}</pre>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const InstructionGroups: FC<{
  data: RelatedProductInstruction[]
  onEditInstruction: (instruction: RelatedProductInstruction) => void
  onDeleteInstruction: (instruction: RelatedProductInstruction) => void
}> = ({ data = [], onEditInstruction, onDeleteInstruction }) => {
  const columns: ColumnDef<RelatedProductInstruction>[] = useMemo(
    () => [
      {
        header: "Applicable To",
        cell: ({ row }) => capitalize(row.original.applicableTo?.toLowerCase() || ""),
      },
      {
        header: "Transportation Mode",
        cell: ({ row }) => capitalize(row.original.transportationMode?.toLowerCase() || ""),
      },
      {
        header: "Included With",
        cell: ({ row }) => capitalize(row.original.includedWith?.toLowerCase() || ""),
      },
      {
        header: "Hazardous",
        cell: ({ row }) => {
          const isHazardous = row.original.productInstruction?.isHazard
          return isHazardous ? <WarningOutlined color='error' /> : null
        },
      },
      {
        id: "expander",
        cell: ({ row }) => (
          <Box className='flex justify-end'>
            <InstructionGroupDropdownContainer row={row} onEdit={onEditInstruction} onDelete={onDeleteInstruction} />
            <IconButton size='small' onClick={row.getToggleExpandedHandler()} disabled={!row.getCanExpand()}>
              {row.getIsExpanded() ? <KeyboardArrowUpSharp /> : <KeyboardArrowDownSharp />}
            </IconButton>
          </Box>
        ),
        size: 80,
      },
    ],
    [onEditInstruction, onDeleteInstruction]
  )

  const [expandedRow, setExpandedRow] = useState<ExpandedState>({})

  return (
    <Box className='flex size-full flex-col'>
      <AerosBaseTable
        slotProps={{ bodyCellExpanded: { className: "bg-primary-100" } }}
        features={{
          tanstackOptions: {
            data,
            columns,
            state: {
              expanded: expandedRow,
            },
            onExpandedChange: setExpandedRow,
            getRowCanExpand: (row: Row<RelatedProductInstruction>) => Boolean(row.original.productInstruction),
            renderExpandedContent: InstructionGroupExpanded,
          },
        }}
      />
    </Box>
  )
}

export default InstructionGroups
