import { createFileRoute, redirect } from "@tanstack/react-router"

import { useAuthStore } from "@/stores/authStore.ts"
import { getIdTokenFromUrl } from "@/utils/auth/auth.ts"

export const Route = createFileRoute("/auth/callback")({
  beforeLoad: async () => {
    const idToken = getIdTokenFromUrl()
    if (!idToken) {
      return redirect({ to: "/auth/logout", replace: true })
    }

    const { setIdToken, completeAuthentication } = useAuthStore.getState()
    setIdToken(idToken)
    await completeAuthentication()

    const { isAuthenticated } = useAuthStore.getState()
    if (isAuthenticated) {
      return redirect({ to: "/companies", replace: true })
    }

    return redirect({ to: "/auth/logout", replace: true })
  },
  component: AuthCallback,
})

function AuthCallback() {
  const { isLoading } = useAuthStore()

  if (isLoading) {
    return <div>Loading...</div>
  }

  return null
}
