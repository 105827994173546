import { Box, Typography } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"

import AddPaymentParts from "./AddPaymentParts"

import { Button } from "@/components/common/Button"
import { PaymentRuleEventGql, type PaymentTermCreateInput } from "@/graphql/codegen/graphql"

const AddPaymentOption = ({ optionsIndex = 0 }: { optionsIndex: number }): ReactElement => {
  const { control } = useFormContext<PaymentTermCreateInput[]>()
  const {
    fields: partFields,
    append: appendParts,
    remove: removeParts,
  } = useFieldArray<PaymentTermCreateInput[]>({
    control,
    name: `options.${optionsIndex}.parts`,
  })

  const handleAddParts = () => {
    appendParts({
      paymentRule: {
        daysAfter: "",
        event: PaymentRuleEventGql.PlacingOrder,
        percentage: "",
      },
      includeSalesDiscount: false,
      discount: {
        percentage: "",
        applicableIfWithinDays: "",
      },
    })
  }

  const handleRemoveParts = (index: number) => {
    removeParts(index)
  }

  return (
    <Box className='rounded-md border border-solid border-gray-300 bg-gray-100 px-6 py-11'>
      <Box className='flex items-center justify-between gap-3 pb-3'>
        <Typography variant='subtitle1' className='pb-4 text-lg text-gray-900'>
          Option {optionsIndex + 1}
        </Typography>
        <Button
          appearance='outlined'
          startIcon={<AddOutlined />}
          disabled={partFields.length > 4}
          onClick={handleAddParts}
        >
          Add Payment Term Part
        </Button>
      </Box>
      <Box className='space-y-6'>
        {partFields.map((_, index) => (
          <AddPaymentParts
            partsIndex={index}
            optionsIndex={optionsIndex}
            onRemove={handleRemoveParts}
            key={optionsIndex + "" + index}
          />
        ))}
      </Box>
    </Box>
  )
}

export default AddPaymentOption
