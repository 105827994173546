import { Chip } from "@mui/material"

import colors from "@/styles/colors.ts"

const chipStyles = {
  backgroundColor: "transparent",
  borderColor: colors.primary["300"],
  color: colors.grey["800"],
  "& .MuiChip-deleteIcon": {
    color: colors.primary["300"],
  },
}

interface FilterChipProps {
  label: string
  onDelete: () => void
}

export const FilterChip: React.FC<FilterChipProps> = ({ label, onDelete }) => {
  return <Chip label={label} onDelete={onDelete} variant='outlined' color='primary' sx={chipStyles} />
}
