import { useEffect, useState } from "react"
import type { OperationContext } from "urql"
import { useQuery } from "urql"

import { ProductCommpanyLocationAssociationsDocument } from "@/graphql/codegen/graphql.ts"
import type { CompanyGetSuccess, CompanyLocationAssociation, Query } from "@/graphql/codegen/graphql.ts"

export const useGetLocationAssociation = ({
  companyId,
}: {
  companyId: string
}): {
  locationsAssociationsList: CompanyLocationAssociation[]
  locationsAssociationsListFetching: boolean
  reexecuteLocationsAssociationsQuery: (opts?: Partial<OperationContext>) => void
} => {
  const [locationsAssociationsList, setLocationsAssociationsList] = useState<CompanyLocationAssociation[]>([])
  const [
    { data: locationAssociationsListData, fetching: locationsAssociationsListFetching },
    reexecuteLocationsAssociationsQuery,
  ] = useQuery<Pick<Query, "company">>({
    query: ProductCommpanyLocationAssociationsDocument,
    variables: { companyId },
    pause: !companyId,
  })

  useEffect(() => {
    if (locationAssociationsListData) {
      const locationList =
        (locationAssociationsListData?.company.get as CompanyGetSuccess)?.company?.locationsAssociations || []
      setLocationsAssociationsList(locationList)
    }
  }, [locationAssociationsListData])

  return {
    locationsAssociationsList,
    locationsAssociationsListFetching,
    reexecuteLocationsAssociationsQuery,
  }
}
