import { Box, Checkbox, FormControlLabel, capitalize } from "@mui/material"
import type { ReactElement } from "react"
import { Controller } from "react-hook-form"

import type { CompanyStatus } from "@/graphql/codegen/graphql.ts"

export const StatusFilter = <T,>({
  data = [],
  control,
  name,
  onFilterChange,
  multiple = true,
}: {
  data: CompanyStatus[]
  name: string
  control: any
  onFilterChange: (name: keyof T, value: CompanyStatus | CompanyStatus[]) => void
  multiple?: boolean
}): ReactElement => (
  <Box className='flex flex-col px-8 py-4'>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {data.map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={Array.isArray(field.value) ? field.value.includes(status) : field.value === status}
                  onChange={(e) => {
                    let newValue: CompanyStatus | CompanyStatus[]
                    if (multiple) {
                      newValue = e.target.checked
                        ? [...(Array.isArray(field.value) ? field.value : []), status]
                        : (Array.isArray(field.value) ? field.value : []).filter((s: CompanyStatus) => s !== status)
                    } else {
                      newValue = e.target.checked ? status : []
                    }
                    field.onChange(newValue)
                    onFilterChange(name as keyof T, newValue)
                  }}
                />
              }
              label={capitalize(status.toLowerCase())}
            />
          ))}
        </>
      )}
    />
  </Box>
)
