import type { SnackbarCloseReason } from "@mui/material"
import React, { useCallback, useMemo } from "react"

import { NotificationTemplate } from "@/components/common/Notifications/NotificationTemplate.tsx"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const Notifications: React.FC = () => {
  const { notifications, dequeueNotification, markAsRead, getUnreadCount } = useNotificationsStore()
  const nextNotification = useMemo(
    () => notifications.find((notification) => !notification.silent && !notification.read),
    [notifications]
  )

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason !== "clickaway" && nextNotification) {
        markAsRead(nextNotification.id)
        dequeueNotification()
      }
    },
    [nextNotification, markAsRead, dequeueNotification]
  )

  const handleExited = useCallback(() => {
    if (nextNotification) {
      markAsRead(nextNotification.id)
    }
  }, [nextNotification, markAsRead])

  if (!nextNotification) return null

  return (
    <NotificationTemplate
      {...nextNotification}
      open={Boolean(nextNotification)}
      unreadCount={getUnreadCount()}
      handleSnackbarClose={handleClose}
      handleExited={handleExited}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    />
  )
}
