/**
 * Syntax highlights JSON string
 * @param json - JSON string or object to highlight
 * @returns Highlighted HTML string
 */
export const syntaxHighlight = (json: string | any): string => {
  if (typeof json !== "string") {
    json = JSON.stringify(json, undefined, 2)
  }
  json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")

  const regex =
    /"(?:\\u[\da-fA-F]{4}|\\[^u]|[^\\"])*"(?:\s*:)?|\b(?:true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g

  return json.replace(regex, (match: string): string => {
    let cls = "number"
    if (/^"/.test(match)) {
      cls = /:$/.test(match) ? "key" : "string"
    } else if (/true|false/.test(match)) {
      cls = "boolean"
    } else if (/null/.test(match)) {
      cls = "null"
    }
    return `<span class="${cls}">${match}</span>`
  })
}
