import colors from "@/styles/colors.ts"
import type { LabelMapping, RouteNavObject } from "@/types/routes"

export const toCamelCase = (str: string): string => {
  return str.replace(/-./g, (match) => match[1].toUpperCase())
}

export const sortRoutesByLabelMapping = (routes: RouteNavObject[], labelMapping: LabelMapping): RouteNavObject[] => {
  return routes.sort((a, b) => {
    const indexA = Object.keys(labelMapping).indexOf(a.pathname)
    const indexB = Object.keys(labelMapping).indexOf(b.pathname)
    return indexA - indexB
  })
}

export const stringAvatar = (name: string): { sx: { backgroundColor: string; color: string }; children: string } => {
  return {
    sx: {
      backgroundColor: colors.primary["400"],
      color: "white",
    },
    children: name?.length > 0 ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}` : "",
  }
}

export const uniqueArrayOfObjects = (arr: Record<string, any>[], key: string): Record<string, any>[] =>
  arr.reduce((acc, curr) => {
    if (!acc.some((item: Record<string, any>) => item[key] === curr[key])) {
      acc.push(curr)
    }
    return acc
  }, []) as Record<string, any>[]
