import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Modal, Typography } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { EditCompanyForm } from "./EditCompanyForm.tsx"

import { Button } from "@/components/common/Button"
import { CancelTemplate } from "@/components/common/Modals/CancelTemplate.tsx"
import type { AccountManager, Company, CompanyUpdateFailure } from "@/graphql/codegen/graphql.ts"
import { CompanyModalSchema } from "@/screens/Companies/create/components/CreateNewCompanySchema.tsx"
import { useCreateNewCompanyModal } from "@/screens/Companies/hooks/useCreateNewCompanyModal.tsx"
import type { TreeNode } from "@/types/tree.ts"
import { deepEqualIgnoreTypename } from "@/utils/deepEqualIgnoreTypename.ts"

type CompanyContent = {
  name: string
  types: string[]
  accountManagers: AccountManager[]
}

type EditCompanyModalProps = {
  isOpen: boolean
  data: CompanyContent
  companyId: string
  onClose: (data?: Company, reason?: "backdropClick" | "escapeKeyDown") => void
  onSuccess: (data: Company) => void
}

const EditCompanyModal: FunctionComponent<EditCompanyModalProps> = ({
  isOpen,
  companyId,
  data,
  onClose,
  onSuccess,
}) => {
  const [modalInput, setModalInput] = useState("")
  const [isCancelConfirmationOpen, setCancelConfirmationOpen] = useState(false)
  const methods = useForm<CompanyContent>({
    values: {
      name: data.name,
      accountManagers: data.accountManagers,
      types: data.types,
    },
    resolver: zodResolver(CompanyModalSchema),
    mode: "onChange",
  })
  const {
    setError,
    clearErrors,
    handleSubmit,
    formState: { dirtyFields },
  } = methods

  const { companyNameList, companyTypeList, accountsManagerList, updateCompany } = useCreateNewCompanyModal(modalInput)

  const validateCompanyName = useCallback(
    (name: string) => {
      if (name) {
        const isDuplicate = companyNameList.some((companyName) => {
          const isOnTheList = companyName.toLowerCase().trim() === name.toLowerCase().trim()
          const isOriginalName = companyName.toLowerCase().trim() === data.name.toLowerCase().trim()

          return isOnTheList && !isOriginalName
        })

        if (isDuplicate && dirtyFields.name) {
          setError("name", {
            type: "manual",
            message: "Company with this name already exists.",
          })
        } else {
          clearErrors("name")
        }
      }
    },
    [companyNameList, data.name, dirtyFields.name, setError, clearErrors]
  )

  const onSubmit = handleSubmit(async (updatedData) => {
    const hasChanges = !deepEqualIgnoreTypename(updatedData, data)

    if (!hasChanges) {
      onClose(data as Company)
      return
    }

    const { data: updateCompanyData, error: updateCompanyError } = await updateCompany({
      input: {
        companyId,
        ...updatedData,
      },
    })

    const requestError =
      (updateCompanyError ?? updateCompanyData?.company.update.__typename === "CompanyUpdateFailure")
        ? (updateCompanyData?.company.update as CompanyUpdateFailure).error
        : null

    if (requestError) {
      setError("name", {
        type: "server",
        message: requestError?.message || "Failed to update company details",
      })
      console.error(requestError.message)
    } else if (updateCompanyData?.company.update.__typename === "CompanyUpdateSuccess") {
      const companyData = updateCompanyData.company.update.company
      methods.reset({
        name: companyData.name ?? undefined,
        types: companyData.types?.map((type) => type ?? undefined),
        accountManagers: companyData.accountManagers?.map((manager) => ({
          __typename: manager.__typename,
          accountManagerId: manager.accountManagerId,
          department: manager.department ?? undefined,
          firstName: manager.firstName,
          lastName: manager.lastName,
          title: manager.title ?? undefined,
        })),
      })
      onSuccess(companyData)
      onClose(companyData)
    }
  })

  const confirmCancel = useCallback(() => {
    setCancelConfirmationOpen(false)
    methods.reset()
    onClose()
  }, [onClose])

  const handleCancel = useCallback(() => {
    setCancelConfirmationOpen(true)
    methods.reset()
  }, [])

  const handleInputChange = useCallback(
    (value: string) => {
      setModalInput(value)
      validateCompanyName(value)
    },
    [validateCompanyName]
  )

  return (
    <Modal open={isOpen} onClose={handleCancel}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          boxShadow: 24,
          width: 576,
          borderRadius: 2,
        }}
      >
        {isCancelConfirmationOpen ? (
          <CancelTemplate onClose={setCancelConfirmationOpen} confirmCancel={confirmCancel} />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, pl: 5 }}>
              <Typography variant='h6' color='primary'>
                Edit Company profile
              </Typography>
              <Button size='small' appearance='text' onClick={() => onClose()}>
                <CloseOutlined />
              </Button>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
            <FormProvider {...methods}>
              <EditCompanyForm
                companyNameList={companyNameList}
                companyTypeList={companyTypeList as unknown as TreeNode<string>[]}
                accountsManagerList={accountsManagerList}
                onChange={handleInputChange}
                handleCancel={handleCancel}
                onSubmit={onSubmit}
              />
            </FormProvider>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default EditCompanyModal
