import { type ReactElement } from "react"

import { FileIconTemplate } from "./FileIconTemplate"

interface ImageIconProps {
  className?: string
}

export const ImageIcon = ({ className }: ImageIconProps): ReactElement => (
  <FileIconTemplate
    className={className}
    text='IMG'
    stripeColor='#00796B' // Teal
  />
)
