import { Box, Paper, Typography } from "@mui/material"
import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { useCallback, useState } from "react"

import { type Company } from "@/graphql/codegen/graphql.ts"
import { CompaniesActions } from "@/screens/Companies/components/CompaniesActions.tsx"
import { CompaniesSearch } from "@/screens/Companies/components/CompaniesSearch.tsx"
import { FilterDrawer } from "@/screens/Companies/components/filters/FilterDrawer.tsx"
import { TableContainer } from "@/screens/Companies/components/tables/TableContainer.tsx"
import CreateNewCompanyModal from "@/screens/Companies/create/components/CreateNewCompanyModal.tsx"
import { SearchParamsSchema } from "@/screens/Companies/types/searchParams.ts"

const CompanyComponent = () => {
  const navigate = useNavigate()
  const [isVendorModalOpen, setVendorModalOpen] = useState(false)
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)

  const handleCreateCompanySuccess = useCallback(
    ({ companyId }: Company) => {
      void navigate({ from: "/companies", to: `${companyId}/edit` })
    },
    [navigate]
  )

  const toggleFilterDrawer = useCallback(() => setFilterDrawerOpen((prev) => !prev), [])

  return (
    <>
      {/* Filter Drawer */}
      <FilterDrawer showFilterDrawer={isFilterDrawerOpen} toggleDrawer={toggleFilterDrawer} />

      {/* Main Content */}
      <Box className='flex size-full flex-col px-6 pb-6'>
        <Paper elevation={0} className='flex min-h-0 grow flex-col overflow-hidden bg-white p-6'>
          <Box className='flex items-center justify-between py-6'>
            <Typography variant='h5' color='primary' fontWeight='normal'>
              Companies
            </Typography>
            <CompaniesActions />
          </Box>

          {/* Search and Actions */}
          <CompaniesSearch onShowModal={() => setVendorModalOpen(true)} onShowFilter={toggleFilterDrawer} />

          {/* Table Section */}
          <Box className='flex flex-1 flex-col overflow-y-auto'>
            <TableContainer />
          </Box>
        </Paper>
      </Box>

      {/* New Company Modal */}
      <CreateNewCompanyModal
        isOpen={isVendorModalOpen}
        onClose={() => setVendorModalOpen(false)}
        onSuccess={handleCreateCompanySuccess}
      />
    </>
  )
}

// Route definition with search param validation
export const Route = createFileRoute("/companies/")({
  validateSearch: SearchParamsSchema,
  component: CompanyComponent,
})
