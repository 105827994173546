import { Box, IconButton } from "@mui/material"
import { DeleteOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import type { UseFormReturn } from "react-hook-form"
import { FormProvider } from "react-hook-form"

import { SelectField } from "./SelectField"

import { FieldGroup, FieldRow, Form, FormField } from "@/components/Forms"
import type { ShippingLeg, ShippingLegInput } from "@/graphql/codegen/graphql"
import type { SelectOption } from "@/types/form-fields"

type ShippingLegsFormProps = {
  methods: UseFormReturn<{ shippingLegs: (ShippingLeg | ShippingLegInput)[] }>
  index: number
  shipFromOptions: SelectOption[]
  shipViaOptions: SelectOption[]
  shipToOptions: SelectOption[]
  shipmentStatusOptions: SelectOption[]
  isLast: boolean
  loading: boolean
  loadMoreShipFrom: () => void
  loadMoreShipVia: () => void
  loadMoreShipTo: () => void
  onRemove: () => void
}

export const ShippingLegsForm = ({
  methods,
  index,
  shipFromOptions,
  shipViaOptions,
  shipToOptions,
  shipmentStatusOptions,
  isLast,
  loading,
  loadMoreShipFrom,
  loadMoreShipVia,
  loadMoreShipTo,
  onRemove,
}: ShippingLegsFormProps): ReactElement => {
  return (
    <Box className='flex flex-row gap-4'>
      <FormProvider {...methods}>
        <Form className='flex flex-row gap-4'>
          {/* Ship From */}
          <FieldRow className='w-1/5'>
            <FieldGroup>
              <FormField
                name={`shippingLegs.${index}.sourceLocationId`}
                label='Ship From'
                render={({ field, fieldState }) => (
                  <SelectField
                    field={field}
                    fieldState={fieldState}
                    options={shipFromOptions}
                    loadMore={loadMoreShipFrom}
                    disabled={true}
                    fetching={loading}
                  />
                )}
              />
            </FieldGroup>
          </FieldRow>
          {/* Ship Via */}
          <FieldRow className='w-1/5'>
            <FieldGroup>
              <FormField
                name={`shippingLegs.${index}.shipViaCompanyId`}
                label='Ship Via'
                render={({ field, fieldState }) => (
                  <SelectField
                    field={field}
                    fieldState={fieldState}
                    options={shipViaOptions}
                    loadMore={loadMoreShipVia}
                    disabled={!isLast}
                    fetching={loading}
                  />
                )}
              />
            </FieldGroup>
          </FieldRow>
          {/* Ship To */}
          <FieldRow className='w-1/5'>
            <FieldGroup>
              <FormField
                name={`shippingLegs.${index}.destinationLocationId`}
                label='Ship To'
                render={({ field, fieldState }) => (
                  <SelectField
                    field={field}
                    fieldState={fieldState}
                    options={shipToOptions}
                    loadMore={loadMoreShipTo}
                    disabled={!isLast}
                    fetching={loading}
                  />
                )}
              />
            </FieldGroup>
          </FieldRow>
          {/* Shipment Status */}
          <FieldRow className='w-1/5'>
            <FieldGroup>
              <FormField
                name={`shippingLegs.${index}.shipmentStatus`}
                label='Shipment Status'
                render={({ field, fieldState }) => (
                  <SelectField
                    field={field}
                    fieldState={fieldState}
                    options={shipmentStatusOptions}
                    disabled={!isLast}
                  />
                )}
              />
            </FieldGroup>
          </FieldRow>
        </Form>
      </FormProvider>
      <Box className='mt-6 flex items-center justify-center'>
        {isLast && (
          <IconButton onClick={onRemove}>
            <DeleteOutlined />
          </IconButton>
        )}
        {/* Icon Button Space Placeholder */}
        {!isLast && <Box className='size-10' />}
      </Box>
    </Box>
  )
}
