import { useNavigate, useSearch } from "@tanstack/react-router"
import type { OnChangeFn, SortingState } from "@tanstack/react-table"
import { getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { FunctionComponent } from "react"
import { useEffect, useMemo, useRef } from "react"

import { TableWrapper } from "@/components/Tables/TableWrappper"
import type { Location, LocationSortField } from "@/graphql/codegen/graphql"
import { SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore.ts"
import { useThrottle } from "@/hooks/useThrottle.ts"
import { queryClient } from "@/providers/GraphqlRouterProvider.tsx"
import type { SortableAccessorKey } from "@/screens/Companies/components/tables/LocationsTableUtils.tsx"
import {
  accessorKeyToLocationSortField,
  columns,
  locationSortFieldToAccessoryKey,
} from "@/screens/Companies/components/tables/LocationsTableUtils.tsx"
import { useLocationSearchStore } from "@/screens/Companies/stores/useLocationSearchStore.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"

export const LocationsTable: FunctionComponent = () => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/companies/" })
  const { category, query, field, order, ...restSearchParams } = currentSearch

  const data = useLocationSearchStore(({ data }) => data)
  const fetching = useLocationSearchStore(({ fetching }) => fetching)
  const error = useLocationSearchStore(({ error }) => error)
  const executeSearch = useLocationSearchStore(({ executeSearch }) => executeSearch)
  const loadMore = useLocationSearchStore(({ loadMore }) => loadMore)

  const tableContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    executeSearch(queryClient, currentSearch)
  }, [executeSearch, queryClient, currentSearch])

  const sorting = useMemo(() => {
    const tableSortField = locationSortFieldToAccessoryKey.get(field as LocationSortField) as string
    const tableSortOrder = order || SortingOrder.Asc
    return [
      {
        id: tableSortField,
        asc: tableSortOrder === SortingOrder.Asc,
        desc: tableSortOrder === SortingOrder.Desc,
      },
    ]
  }, [field, order])

  const handleSortingChange: OnChangeFn<SortingState> = (updaterFn) => {
    // @ts-expect-error - updaterFn is know fn
    const newSorting = updaterFn(sorting)

    if (newSorting.length > 0) {
      const sort = newSorting[0]
      const key = sort.id as SortableAccessorKey
      const field = accessorKeyToLocationSortField.get(key)
      const params = {
        ...restSearchParams,
        query,
        category,
        field,
        order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
      }

      const refinedParams = getRefinedSearchParams(params)
      void navigate({
        search: refinedParams.search,
        to: "/companies/",
        replace: true,
      })
    }
  }

  const [throttledLoadMore] = useThrottle(() => loadMore(queryClient, currentSearch), 200)
  const onScroll = useScrollLoadMore({ fetching, loadMore: throttledLoadMore, scrollContainerRef: tableContainerRef })

  const table = useReactTable<Location>({
    data,
    columns,
    state: { sorting },
    onSortingChange: handleSortingChange,
    manualSorting: true,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableWrapper
      table={table}
      data={data}
      error={error}
      fetching={fetching}
      onScroll={onScroll}
      tableContainerRef={tableContainerRef}
    />
  )
}
