import type { AnyRoute } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"

import WorkInProgressAlert from "@/components/common/Alerts/WorkInProgressAlert.tsx"

export const Route: AnyRoute = createFileRoute("/sales/sales-orders")({
  component: () => (
    <div className='w-full px-4'>
      <WorkInProgressAlert />
    </div>
  ),
})
