import type { AlertProps } from "@mui/material"
import { Alert, AlertTitle, Box } from "@mui/material"
import React from "react"
import { twMerge } from "tailwind-merge"

interface InformationRequiredAlertProps extends Omit<AlertProps, "title"> {
  title: string
  description: string
}

export const InformationMissingAlert = React.forwardRef<HTMLDivElement, InformationRequiredAlertProps>(
  ({ title, description, children, className, ...alertProps }, ref) => {
    return (
      <Alert
        severity='info'
        className={twMerge("bg-primary-100 px-6 py-4", className)}
        classes={{
          message: "flex flex-col w-full",
        }}
        ref={ref}
        {...alertProps}
      >
        <Box className={twMerge("flex w-full flex-col gap-2", Boolean(children) && "mb-6")}>
          {Boolean(title) && (
            <AlertTitle className='font-inter text-left text-[18px] font-normal leading-[27px] tracking-[0.15px] text-gray-900'>
              {title}
            </AlertTitle>
          )}
          {Boolean(description) && (
            <p className='font-inter text-left text-sm font-normal leading-[17.5px] tracking-[0.17px] text-gray-700'>
              {description}
            </p>
          )}
        </Box>
        {children}
      </Alert>
    )
  }
)
