import { Box, Typography } from "@mui/material"
import { UploadFileFilledOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

import type { OverlayProps } from "../types"

export const DefaultDragOverlay = ({ isOver, canDrop }: OverlayProps): ReactElement => (
  <Box
    className={`pointer-events-none absolute inset-0 z-10 flex size-full items-center justify-center rounded-lg border-2 border-dashed transition-all duration-200 ${isOver && canDrop ? "border-primary-500 bg-white/90" : "border-transparent bg-transparent"}`}
  >
    <Box
      className={`flex flex-col items-center gap-4 transition-opacity duration-200 ${isOver && canDrop ? "opacity-100" : "opacity-0"}`}
    >
      <UploadFileFilledOutlined className='text-6xl text-primary' />
      <Typography variant='body1' className='text-primary'>
        Drop file here
      </Typography>
    </Box>
  </Box>
)
