import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material"
import type { ReactElement } from "react"
import { FormProvider } from "react-hook-form"

import { useChangePricingUoMForm } from "./hooks/useChangePricingUoMForm"

import { Button } from "@/components/common/Button"
import { FieldGroup } from "@/components/Forms"
import { TextFieldWithSelect } from "@/components/TextFieldWithSelect/TextFieldWithSelect"
import type { Order } from "@/graphql/codegen/graphql"

interface ChangePricingUoMDialogProps {
  open: boolean
  onClose: () => void
  order: Order
  productId: number
  onSuccess?: () => void
}

export const ChangePricingUoMDialog = ({
  open,
  onClose,
  order,
  productId,
  onSuccess,
}: ChangePricingUoMDialogProps): ReactElement => {
  const { showLoader, form, pricingUoMOptions, handleSubmit } = useChangePricingUoMForm({
    order,
    productId,
    onClose,
    onSuccess,
  })

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <FormProvider {...form}>
        <Box component='form' onSubmit={handleSubmit} className='flex flex-col gap-6'>
          <DialogTitle className='px-[38px] font-normal text-primary'>Change Price per UoM</DialogTitle>

          <DialogContent className='px-[38px]'>
            <FieldGroup>
              <TextFieldWithSelect
                name='pricePerUom'
                label='Price per Unit'
                selectName='uomId'
                selectOptions={pricingUoMOptions}
                required
              />
            </FieldGroup>
          </DialogContent>

          <Box className='flex justify-between p-10'>
            <Button onClick={onClose} appearance='outlined' type='button'>
              Cancel
            </Button>
            <Button variant='primary' type='submit' onClick={handleSubmit}>
              Change <CircularProgress size={20} className={showLoader ? "ml-2 text-white" : "hidden"} />
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Dialog>
  )
}
