import { z } from "zod"

import type { ProductFormInfoItem, UnitOfMeasurement } from "@/graphql/codegen/graphql"
import { FieldGroupEnum } from "@/graphql/codegen/graphql"

export interface SignedUrl {
  url: string
  file?: {
    fieldGroup?: FieldGroupEnum
    fileName?: string
  }
}

export interface ProductFormData {
  productFormInfo: ProductFormInfoItem
  uomId: string
  signedUrls?: SignedUrl[]
}

export interface ProductFormDetailsDialogProps {
  open: boolean
  formOptions: any[]
  unitOfMeasurementOptions: UnitOfMeasurement[]
  onClose: () => void
  onSubmit: (data: ProductFormData) => Promise<void>
  initialData?: ProductFormData | null
  previewFiles: Partial<Record<FieldGroupEnum, PreviewFile | null>>
  onFileSelect: (type: FieldGroupEnum, file: File) => void
  uploading: Record<FieldGroupEnum, boolean>
}

export interface ProductFormPicturesEditProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: ProductFormData) => Promise<void>
  signedUrls: SignedUrl[] | undefined
  previewFiles: Partial<Record<FieldGroupEnum, PreviewFile | null>>
  onFileSelect: (type: FieldGroupEnum, file: File) => void
  uploading: Record<FieldGroupEnum, boolean>
}

export interface ProductFormPicturesPreviewProps {
  open: boolean
  onClose: () => void
  onEdit: () => void
  imageUrls: string[]
}

export interface PreviewFile {
  file: File
  previewUrl: string
}

export enum ProductFormDialog {
  EditForm = "EDIT_FORM",
  PreviewImages = "PREVIEW_IMAGES",
  EditPictures = "EDIT_PICTURES",
}

export const UPLOAD_TYPES = [
  { type: FieldGroupEnum.Product, label: "Product Self" },
  { type: FieldGroupEnum.Package, label: "In Packaging" },
  { type: FieldGroupEnum.Pallet, label: "On Pallet" },
] as const

export const schema = z.object({
  productFormInfo: z.string().min(1, { message: "Product Form Information is required" }),
  uomId: z.string().min(1, { message: "Unit of Measurement is required" }),
  signedUrls: z
    .array(
      z.object({
        url: z.string(),
        file: z
          .object({
            fieldGroup: z.nativeEnum(FieldGroupEnum).optional(),
            fileName: z.string().optional(),
          })
          .optional(),
      })
    )
    .optional(),
})

export interface ProductForm {
  productForm: ProductFormData
  forms: any[] // Replace with actual form options type
  units: any[] // Replace with actual unit options type
}

export const IMAGE_ORDER = [FieldGroupEnum.Product, FieldGroupEnum.Package, FieldGroupEnum.Pallet] as const
