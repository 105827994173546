import { Box, Typography } from "@mui/material"
import { type ReactElement } from "react"

export const OrderPackagingSummary = (): ReactElement => {
  // TODO: Calculating the summary is still pending
  return (
    <div className='flex justify-between'>
      <Box className='flex gap-2.5'>
        {[
          { header: "Pallets", value: "-" },
          { header: "Pounds (lb)", value: "-" },
          { header: "Kilograms (Kg)", value: "-" },
        ].map(({ header, value }) => (
          <PackagingSummary header={header} value={value} key={value} />
        ))}
      </Box>
      <PackagingSummary header='Total Amount' value='-' prefix='$' />
    </div>
  )
}

const PackagingSummary = ({ header, value, prefix }: { header: string; value: string; prefix?: string }) => {
  return (
    <Box className='h-[80px] w-max rounded-md border border-gray-300 bg-gray-100 px-6 py-1'>
      <Typography variant='caption' className='text-primary'>
        {header}
      </Typography>
      <Typography variant='h4' className='text-gray-700'>
        {prefix} {value}
      </Typography>
    </Box>
  )
}
