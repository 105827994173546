import { useCallback, useEffect, useMemo, useState } from "react"
import { useQuery } from "urql"

import type {
  Company,
  CompanyListDetailsQuery,
  CompanyListDetailsQueryVariables,
  CompanyListSuccess,
  Location,
} from "@/graphql/codegen/graphql"
import { CompanyListDetailsDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20
const OFFSET = 0

type UseGetShipToQueryReturn = {
  data: Location[]
  fetching: boolean
  loadMore: () => void
}

export const useGetShipToQuery = (): UseGetShipToQueryReturn => {
  const [offset, setOffset] = useState(0)
  const [shipToLocations, setShipToLocations] = useState<Company[]>([])

  const [{ data, fetching }] = useQuery<CompanyListDetailsQuery, CompanyListDetailsQueryVariables>({
    query: CompanyListDetailsDocument,
    variables: {
      input: {
        filter: { types: ["INTERMEDIARY_DESTINATION", "AKROCHEM", "AKROCHEM_WAREHOUSE", "CONSIGNMENT_WAREHOUS"] },
        limit: LIMIT,
        offset: OFFSET,
      },
    },
  })

  const associatedShippingLocations = useMemo(() => {
    // return shipping locations array from locationsAssociations of shipToLocations
    return (
      shipToLocations
        .filter((location) => location.locationsAssociations?.some((association) => association.shipping))
        .map((location) => location.locationsAssociations?.find((association) => association.shipping))
        .flat()
        .map((association) => association?.shipping)
        .filter((location): location is NonNullable<typeof location> => location != null) ?? []
    )
  }, [shipToLocations])

  const companyLocations = useMemo(() => shipToLocations.map((company) => company.locations), [shipToLocations])

  const shipToOptions = useMemo(
    () =>
      [...companyLocations, ...associatedShippingLocations]
        .flat()
        .filter((location): location is NonNullable<typeof location> => location != null) ?? [],
    [companyLocations, associatedShippingLocations]
  )

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  useEffect(() => {
    if (data) {
      const companyList = (data?.company.list as CompanyListSuccess)?.companies || []

      setShipToLocations((prev) => (offset === 0 ? companyList : [...prev, ...companyList]))
    }
  }, [data])

  return {
    data: shipToOptions,
    fetching,
    loadMore,
  }
}
