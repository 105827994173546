import type { PaymentTermCreateInput } from "@/graphql/codegen/graphql"
import { MaturityDateTypeGql, PayPatternGql } from "@/graphql/codegen/graphql"

export const formatPaymentTerms = (data: any): PaymentTermCreateInput => {
  return {
    name: data.name,
    options: data.options.map((option) => ({
      parts: option.parts.map((part) => ({
        paymentRule: {
          daysAfter: parseInt(part.paymentRule.daysAfter),
          event: part.paymentRule.event,
          percentage: parseFloat(part.paymentRule.percentage),
        },
        ...(part.includeSalesDiscount && part.discount.percentage && part.discount.applicableIfWithinDays
          ? {
              discount: {
                percentage: parseFloat(part.discount.percentage),
                applicableIfWithinDays: parseInt(part.discount.applicableIfWithinDays),
              },
            }
          : {}),
      })),
    })),
    ...(data.payRunSchedule.isEnabled &&
    data.payRunSchedule.payPattern &&
    data.payRunSchedule.startDate &&
    data.payRunSchedule.payDays &&
    data.payRunSchedule.invoiceMaturity.intervalBetweenPayRuns &&
    data.payRunSchedule.invoiceMaturity.offsetInDays
      ? {
          payRunSchedule: {
            payPattern: PayPatternGql[data.payRunSchedule.payPattern as keyof typeof PayPatternGql],
            startDate: data.payRunSchedule.startDate,
            payDays: parseInt(data.payRunSchedule.payDays),
            invoiceMaturity: {
              intervalBetweenPayRuns:
                MaturityDateTypeGql[
                  data.payRunSchedule.invoiceMaturity.intervalBetweenPayRuns as keyof typeof MaturityDateTypeGql
                ],
              offsetInDays: parseInt(data.payRunSchedule.invoiceMaturity.offsetInDays),
            },
          },
        }
      : {}),
  }
}
