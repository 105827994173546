import { type ReactElement, useCallback, useRef, useState } from "react"

import { DefaultContent } from "./components/DefaultContent"
import { DefaultDragOverlay } from "./components/DefaultDragOverlay"
import { DefaultHover } from "./components/DefaultHover"
import { DefaultUploadBox } from "./templates/DefaultUploadBox"
import type { FileUploadProps, FileUploadTemplates } from "./types"

import { useDragAndDrop } from "@/hooks/useDragAndDrop"

export const FileUpload = ({
  onChange,
  accept,
  maxSize = 10 * 1024 * 1024,
  fileUrl,
  file,
  readOnly = false,
  className,
  enableDragAndDrop = false,
  templates = {},
  slotProps = {},
}: FileUploadProps): ReactElement => {
  const [isHovered, setIsHovered] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const {
    uploadBox: CustomUploadBox,
    content: CustomContent,
    overlay: CustomOverlay,
    hover: CustomHover,
  } = templates as FileUploadTemplates

  const UploadBoxComponent = CustomUploadBox || DefaultUploadBox
  const ContentComponent = CustomContent || DefaultContent
  const OverlayComponent = CustomOverlay || DefaultDragOverlay
  const HoverComponent = CustomHover || DefaultHover

  const { dropRef, isOver, canDrop } = useDragAndDrop({
    onDrop: onChange,
    accept,
    maxSize,
    enabled: enableDragAndDrop && !readOnly,
  })

  const handleClick = useCallback(() => {
    if (!readOnly) {
      fileInputRef.current?.click()
    }
  }, [readOnly])

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (readOnly) return
      const file = event.target.files?.[0]
      if (file) {
        onChange(file)
      }
      // Reset input value to allow selecting the same file again
      event.target.value = ""
    },
    [onChange, readOnly]
  )

  const handleMouseEnter = useCallback(() => {
    if (!readOnly) {
      setIsHovered(true)
    }
  }, [readOnly])

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  return (
    <div
      ref={enableDragAndDrop && !readOnly ? dropRef : undefined}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <input
        ref={fileInputRef}
        type='file'
        accept={accept}
        onChange={handleFileChange}
        style={{ display: "none" }}
        aria-hidden='true'
        disabled={readOnly}
      />
      <div
        role={readOnly ? "presentation" : "button"}
        tabIndex={readOnly ? -1 : 0}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (!readOnly && (e.key === "Enter" || e.key === " ")) {
            e.preventDefault()
            handleClick()
          }
        }}
        className={`${!readOnly ? "focus:ring-primary-500 outline-none focus:ring-2 focus:ring-offset-2" : ""}`}
      >
        <UploadBoxComponent
          fileUrl={fileUrl || null}
          file={file}
          isOver={isOver}
          canDrop={canDrop}
          isHovered={isHovered && !readOnly}
          {...slotProps.uploadBox}
        >
          <ContentComponent fileUrl={fileUrl || null} {...slotProps.content} />
          {enableDragAndDrop && !readOnly && (
            <OverlayComponent isOver={isOver} canDrop={canDrop} {...slotProps.overlay} />
          )}
          {isHovered && !readOnly && HoverComponent && (
            <HoverComponent fileUrl={fileUrl || null} {...slotProps.hover} />
          )}
        </UploadBoxComponent>
      </div>
    </div>
  )
}
