import { TableCell } from "@mui/material"
import type { Cell } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"
import type { ReactElement } from "react"

export const BaseTableCell = <T, Y>(cell: Cell<T, Y>): ReactElement => (
  <TableCell
    padding='none'
    className='flex h-[64px] items-center justify-start border-b-gray-300 p-4 text-gray-700 first:pr-0'
    style={{
      width: cell.column.getSize(),
      flexGrow: cell.column.getSize() === 150 ? 1 : 0,
    }}
  >
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </TableCell>
)
