import { useNavigate, useSearch } from "@tanstack/react-router"
import { getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { FC } from "react"
import { useEffect, useMemo, useRef } from "react"

import { TableWrapper } from "../../../../shared/components/Tables/TableWrappper"

import type { CompanySortField } from "@/graphql/codegen/graphql"
import { SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { useThrottle } from "@/hooks/useThrottle"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import {
  accessorKeyToCompanySortField,
  companySortFieldToAccessoryKey,
  useCompanyColumns,
} from "@/screens/Companies/components/tables/companies.const.tsx"
import { useCompanySearchStore } from "@/screens/Companies/stores/useCompanySearchStore.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"
import useDebounce from "@/utils/useDebounce"

const CompaniesTable: FC = () => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/companies/" })
  const { category, query, field, order, ...restSearchParams } = currentSearch

  const data = useCompanySearchStore(({ data }) => data)
  const fetching = useCompanySearchStore(({ fetching }) => fetching)
  const error = useCompanySearchStore(({ error }) => error)
  const executeSearch = useCompanySearchStore(({ executeSearch }) => executeSearch)
  const loadMore = useCompanySearchStore(({ loadMore }) => loadMore)

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const debouncedExecuteSearch = useDebounce(
    (filters: {
      [key: string]: unknown
      query?: string
      category?: string
      field?: CompanySortField
      order?: SortingOrder
    }) => {
      executeSearch(queryClient, filters)
    },
    300
  )

  useEffect(() => {
    debouncedExecuteSearch(currentSearch)
  }, [debouncedExecuteSearch, currentSearch])

  const sorting = useMemo(() => {
    const tableSortField = companySortFieldToAccessoryKey.get(field) as string
    const tableSortOrder = order || SortingOrder.Asc
    return [
      { id: tableSortField, asc: tableSortOrder === SortingOrder.Asc, desc: tableSortOrder === SortingOrder.Desc },
    ]
  }, [field, order])

  const handleSortingChange = (updaterFn: any) => {
    const newSorting = updaterFn(sorting)
    if (newSorting.length > 0) {
      const sort = newSorting[0]
      const key = sort.id
      const field = accessorKeyToCompanySortField.get(key)
      const params = {
        ...restSearchParams,
        query,
        category,
        field,
        order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
      }
      const refinedParams = getRefinedSearchParams(params)
      void navigate({ search: refinedParams.search, to: "/companies/", replace: true })
    }
  }

  const [throttledLoadMore] = useThrottle(() => loadMore(queryClient, currentSearch), 200)
  const onScroll = useScrollLoadMore({ fetching, loadMore: throttledLoadMore, scrollContainerRef: tableContainerRef })

  const columns = useCompanyColumns()

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: handleSortingChange,
    manualSorting: true,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableWrapper
      table={table}
      data={data}
      error={error}
      fetching={fetching}
      onScroll={onScroll}
      tableContainerRef={tableContainerRef}
    />
  )
}

export default CompaniesTable
