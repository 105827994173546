import { Box, TextField, Typography } from "@mui/material"
import type { ReactElement } from "react"
import { Controller, useFormContext } from "react-hook-form"

import type { PaymentTermCreateInput } from "@/graphql/codegen/graphql"

const AddPaymentDiscount = ({
  partsIndex = 0,
  optionsIndex = 0,
}: {
  partsIndex: number
  optionsIndex: number
}): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PaymentTermCreateInput>()

  return (
    <div>
      <Box className='mt-4 flex gap-3'>
        <Controller
          name={`options.${optionsIndex}.parts.${partsIndex}.discount.percentage`}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='mb-2 block text-xs text-gray-800'>
                Discount<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                value={field.value}
                fullWidth
                variant='outlined'
                placeholder='-'
                InputProps={{
                  endAdornment: <Typography className='text-sm'>%</Typography>,
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                  inputProps: {
                    className: "hide-stepper",
                  },
                }}
              />
            </Box>
          )}
        />
        <Typography variant='body2' className='mt-6 content-center text-gray-700'>
          applicable if paid in
        </Typography>
        <Controller
          name={`options.${optionsIndex}.parts.${partsIndex}.discount.applicableIfWithinDays`}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='mb-2 block text-xs text-gray-800'>
                Days<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                value={field.value}
                fullWidth
                variant='outlined'
                placeholder='-'
                InputProps={{
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                  inputProps: {
                    className: "hide-stepper",
                  },
                }}
              />
            </Box>
          )}
        />
      </Box>
      <div className='text-sm text-red-600'>
        <div>{errors?.options?.[optionsIndex]?.parts?.[partsIndex]?.discount?.percentage?.message}</div>
        <div>{errors?.options?.[optionsIndex]?.parts?.[partsIndex]?.discount?.applicableIfWithinDays?.message}</div>
      </div>
    </div>
  )
}

export default AddPaymentDiscount
