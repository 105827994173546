import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { AddInstructionsContent } from "./AddInstructionsContent"

import { Button } from "@/components/common/Button"
import type { RelatedProductInstruction, RelatedProductInstructionInput } from "@/graphql/codegen/graphql"

type AddInstructionsProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: RelatedProductInstructionInput) => void
  editingInstruction?: RelatedProductInstruction | null
}

const AddInstructionsModal: FunctionComponent<AddInstructionsProps> = ({
  open,
  onClose,
  onSubmit,
  editingInstruction,
}) => {
  const methods = useFormContext<RelatedProductInstructionInput>()

  const handleSubmit = useCallback(
    (value: RelatedProductInstructionInput) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const isGeneralStepDisabled = methods.formState.isValid

  const handleResetClick = useCallback(() => {
    methods.reset()
    onClose("cancel")
  }, [onClose, methods])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        methods.reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>{editingInstruction ? "Edit" : "Add"} Instruction Group</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <AddInstructionsContent />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(handleSubmit)} disabled={!isGeneralStepDisabled}>
          {editingInstruction ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddInstructionsModal
