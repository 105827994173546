import type { Maybe } from "graphql/jsutils/Maybe"
import { useMemo } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import { type CompanyGetSuccess, PurchaseOrderCompanyDocument, type Query } from "@/graphql/codegen/graphql"

export interface UsePurchaseOrderCompanyData {
  purchaseOrderCompany: Maybe<CompanyGetSuccess>
  purchaseOrderCompanyFetching: boolean
  purchaseOrderCompanyError: CombinedError | undefined
}

export type UsePurchaseOrderCompany = (contactId?: Maybe<string>) => UsePurchaseOrderCompanyData

export const useGetPurchaseOrderCompany: UsePurchaseOrderCompany = (companyId) => {
  const [{ data: purchaseOrderCompanyData, fetching: purchaseOrderCompanyFetching, error: purchaseOrderCompanyError }] =
    useQuery<Pick<Query, "company">>({
      query: PurchaseOrderCompanyDocument,
      variables: { companyId },
      pause: !companyId,
    })

  const purchaseOrderCompany = useMemo(
    () =>
      purchaseOrderCompanyData?.company?.get.__typename === "CompanyGetSuccess"
        ? purchaseOrderCompanyData?.company?.get
        : null,
    [purchaseOrderCompanyData?.company?.get]
  )

  return {
    purchaseOrderCompany,
    purchaseOrderCompanyFetching,
    purchaseOrderCompanyError,
  }
}
