import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { AddOutlined, CheckCircleFilledRounded, CloseFilledOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"

import { Button } from "@/components/common/Button"
import type { DialogCloseAction } from "@/screens/Companies/types/dialogProps"

type LocationAddedModalProps = {
  open: boolean
  onClose: (action: "addMore" | DialogCloseAction) => void
}

const LocationAddedModal: FC<LocationAddedModalProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='flex-end'>
          <Button size='small' appearance='text' onClick={() => onClose("close")}>
            <CloseFilledOutlined className='text-blue-gray' />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign='center'>
          <CheckCircleFilledRounded className='mb-4 size-[48px] text-success-500' />
          <Typography variant='h5' color='primary' className='mt-6 font-normal'>
            Location Successfully Added
          </Typography>
          <Typography variant='h6' className='mt-6 px-3 text-base leading-[150%] tracking-[-0.85px] text-gray-700'>
            Continue to save and add more information to the company or Add Another Location.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display='flex' justifyContent='flex-end' width='100%' p={2}>
          <Button
            appearance='text'
            variant='secondary'
            startIcon={<AddOutlined fontSize='small' />}
            className='mr-2'
            onClick={() => onClose("addMore")}
          >
            Add Another Location
          </Button>
          <Button onClick={() => onClose("success")}>Continue</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default LocationAddedModal
