import { ListItemIcon, ListItemText } from "@mui/material"
import ListItemButton from "@mui/material/ListItemButton"
import { Link, useLocation } from "@tanstack/react-router"
import { cva } from "class-variance-authority"
import type { FC } from "react"
import { twMerge } from "tailwind-merge"

import type { RouteNavObject } from "@/types/routes"

interface SideNavItemProps {
  item: RouteNavObject
  nested?: boolean
}

const listItemButtonStyles = cva("rounded", {
  variants: {
    selected: {
      true: "bg-white ",
    },
    nested: {
      true: "pl-10",
    },
  },
})

const listItemIconContainerStyles = cva("flex h-6 min-w-6 items-center justify-center", {
  variants: {
    selected: {
      true: "rounded bg-primary text-white",
    },
  },
})

const SideNavItem: FC<SideNavItemProps> = ({ item, nested = false }) => {
  const location = useLocation()
  const isSelected = location.pathname === item.pathname

  return (
    <>
      <ListItemButton
        component={Link}
        to={item.pathname}
        selected={isSelected}
        data-testid={`sidenav-${item.label?.toLowerCase()}`}
        className={twMerge(listItemButtonStyles({ selected: isSelected, nested }))}
      >
        {item.icon && (
          <ListItemIcon className={twMerge(listItemIconContainerStyles({ selected: isSelected }))}>
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText className='ml-3' classes={{ primary: nested ? "text-sm" : "" }} primary={item.label} />
      </ListItemButton>
      {item.children && (
        <div className='space-y-2'>
          {item.children.map((child) => (
            <SideNavItem key={child.pathname} item={child} nested />
          ))}
        </div>
      )}
    </>
  )
}

export default SideNavItem
