import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type {
  CompanyPaymentTerm,
  CompanyPaymentTermListSuccess,
  PaymentTerm,
  PaymentTermGetSuccess,
  Query,
} from "@/graphql/codegen/graphql"
import { CompanyPaymentTermListDocument, GetPaymentTermsDocument } from "@/graphql/codegen/graphql"

export const useGetCompanyPaymentTerm = (
  companyId: string
): {
  paymentTermDetails: PaymentTerm | undefined
  paymentTermDetailsFetching: boolean
  paymentTermDetailsError: CombinedError | undefined
  companyPaymentListError: CombinedError | undefined
  companyPaymentList: CompanyPaymentTerm[] | undefined
  companyPaymentListFetching: boolean
  reexecuteCompanyPaymentTerm: () => void
} => {
  const [selectedPaymentTermId, setSelectedPaymentTermId] = useState<PaymentTerm["paymentTermId"] | null>(null)
  const [paymentTerm, setPaymentTerm] = useState<PaymentTerm | undefined>()
  const [companyPaymentList, setCompanyPaymentDetails] = useState<CompanyPaymentTerm[]>([])

  const [
    { data: companyPaymentDetails, fetching: companyPaymentListFetching, error: companyPaymentListError },
    reexecuteCompanyPaymentTermQuery,
  ] = useQuery<Pick<Query, "companyPaymentTerm">>({
    query: CompanyPaymentTermListDocument,
    variables: { input: { filter: { companyId } } },
  })

  const [{ data: paymentTermDetails, fetching: paymentTermDetailsFetching, error: paymentTermDetailsError }] = useQuery<
    Pick<Query, "paymentTerm">
  >({
    query: GetPaymentTermsDocument,
    variables: { input: { paymentTermId: selectedPaymentTermId } },
    pause: !selectedPaymentTermId,
  })

  useEffect(() => {
    if (companyPaymentDetails) {
      const paymentTermList = (companyPaymentDetails?.companyPaymentTerm?.list as CompanyPaymentTermListSuccess)
        .companyPaymentTerms
      setCompanyPaymentDetails(paymentTermList)
      if (paymentTermList.length > 0) {
        setSelectedPaymentTermId(paymentTermList[0].paymentTermId)
      }
    }
  }, [companyPaymentDetails])

  useEffect(() => {
    if (paymentTermDetails) {
      setPaymentTerm((paymentTermDetails?.paymentTerm?.get as PaymentTermGetSuccess)?.paymentTerm)
    }
  }, [paymentTermDetails])

  const reexecuteCompanyPaymentTerm = useCallback(() => {
    reexecuteCompanyPaymentTermQuery({ requestPolicy: "network-only" })
  }, [reexecuteCompanyPaymentTermQuery])

  return {
    paymentTermDetails: paymentTerm,
    paymentTermDetailsFetching,
    paymentTermDetailsError,
    companyPaymentList,
    companyPaymentListFetching,
    companyPaymentListError,
    reexecuteCompanyPaymentTerm,
  }
}
