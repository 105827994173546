import type { Client, CombinedError } from "urql"
import { create } from "zustand"

import type { Location, LocationSortField, SortingOrder } from "@/graphql/codegen/graphql.ts"
import { LocationSearchListDocument } from "@/graphql/codegen/graphql.ts"

export type LocationSearchFilters = {
  query?: string
  category?: string
  field?: LocationSortField
  order?: SortingOrder
  [key: string]: unknown
}

export type LocationSearchState = {
  data: Location[]
  fetching: boolean
  error: CombinedError | Error | undefined
  offset: number
  limit: number
  hasMore: boolean
  lastExecutedFilters: LocationSearchFilters
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  resetSearch: () => void
  setData: (data: Location[]) => void
  appendData: (data: Location[]) => void
  executeSearch: (client: Client, filters: LocationSearchFilters) => Promise<void>
  loadMore: (client: Client, filters: LocationSearchFilters) => Promise<void>
}

const deduplicateData = (newData: Location[], existingData: Location[]): Location[] => {
  const uniqueIds = new Set(existingData.map((location) => location.locationId))
  return [...existingData, ...newData.filter((location) => !uniqueIds.has(location.locationId))]
}

const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: undefined,
  offset: 0,
  limit: 20,
  hasMore: true,
  lastExecutedFilters: {},
}

export const useLocationSearchStore = create<LocationSearchState>((set, get) => ({
  ...INITIAL_STATE,

  setFetching: (fetching) => set({ fetching }),
  setError: (error) => set({ error }),
  resetSearch: () => set({ ...INITIAL_STATE }),
  setData: (data) => set({ data, error: undefined }),
  appendData: (data) =>
    set((state) => ({
      data: deduplicateData(data, state.data),
      error: undefined,
    })),

  executeSearch: async (client: Client, filters: LocationSearchFilters) => {
    const state = get()
    if (state.fetching) return

    const { query, ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) === JSON.stringify(state.lastExecutedFilters)) return

    const shouldExecuteSearch = validQuery || Object.keys(restFilters).length > 0
    if (!shouldExecuteSearch) {
      set({ data: [], hasMore: false, lastExecutedFilters: {} })
      return
    }

    set({ fetching: true })

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: otherFilters,
        offset: 0,
        limit: state.limit,
        sort: { field, order },
      }

      const result = await client.query(LocationSearchListDocument, { input: queryInput }).toPromise()

      if (result.data?.location?.list.__typename === "LocationListSuccess") {
        const locations = result.data.location.list.locations as Location[]
        set({
          data: locations,
          offset: 0,
          hasMore: locations.length === state.limit,
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  loadMore: async (client: Client, filters: LocationSearchFilters) => {
    const state = get()
    if (state.fetching || !state.hasMore) return

    const { query, ...restFilters } = filters
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) !== JSON.stringify(state.lastExecutedFilters)) {
      await state.executeSearch(client, filters)
      return
    }

    set({ fetching: true })
    const newOffset = state.offset + state.limit

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: otherFilters,
        offset: newOffset,
        limit: state.limit,
        sort: { field, order },
      }

      const result = await client.query(LocationSearchListDocument, { input: queryInput }).toPromise()

      if (result.data?.location?.list.__typename === "LocationListSuccess") {
        const locations = result.data.location.list.locations as Location[]
        set((prevState) => ({
          data: deduplicateData(locations, prevState.data),
          offset: newOffset,
          hasMore: locations.length === state.limit,
        }))
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },
}))
