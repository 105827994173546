import { Button as BaseButton } from "@mui/base"
import { forwardRef } from "react"
import { twMerge } from "tailwind-merge"

import type { ButtonProps } from "./button.schema"
import { buttonSchema } from "./button.schema"
import buttonVariants from "./buttonVariant"

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { appearance, size, variant, disabled } = buttonSchema.parse(props)
  const { endIcon, startIcon, ...rest } = props

  const classes = buttonVariants({
    size,
    variant,
    appearance,
    disabled,
  })

  return (
    <BaseButton
      {...rest} // Spread the restProps instead of props
      ref={ref}
      className={twMerge(classes, props.className)}
      data-testid={`button-${size}-${variant}-${appearance}`}
    >
      {startIcon && <span>{startIcon}</span>}
      {props.value || props.children}
      {endIcon && <span>{endIcon}</span>}
    </BaseButton>
  )
})

Button.displayName = "Button"

export default Button
