import type { OperationContext, OperationResult } from "urql"
import { useMutation } from "urql"

import type { Mutation, PaymentTermMutationCreateArgs } from "@/graphql/codegen/graphql"
import { AddPaymentTermsMutationDocument } from "@/graphql/codegen/graphql"

export const useCreatePaymentTerms = (): {
  createPaymentTerm: (
    variables: PaymentTermMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "paymentTerm">, PaymentTermMutationCreateArgs>>
} => {
  // Mutation to create a new payment term
  const [, createPaymentTerm] = useMutation<Pick<Mutation, "paymentTerm">, PaymentTermMutationCreateArgs>(
    AddPaymentTermsMutationDocument
  )

  return {
    createPaymentTerm,
  }
}
