import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext } from "react-hook-form"

import { PaymentInformationContent } from "./PaymentInformationContent"

import { Button } from "@/components/common/Button"

type actions = "cancel" | "success" | "backdropClick" | "escapeKeyDown"
type AddPaymentProps = {
  open: boolean
  isUpdate?: boolean
  onClose: (action: actions) => void
  onSubmit: (data) => void
}

const AddPaymentModal: FunctionComponent<AddPaymentProps> = ({ open, isUpdate = false, onClose, onSubmit }) => {
  const methods = useFormContext()

  const handleSubmit = useCallback(
    (value) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose]
  )

  const isSaveButtonEnabled =
    !methods.getFieldState("paymentTermId").invalid && methods.formState.touchedFields.paymentTermId

  const handleResetClick = useCallback(() => {
    methods.reset()
    onClose("cancel")
  }, [onClose])

  const handleClose = useCallback(
    (reason: actions) => {
      methods.reset()
      onClose(reason)
    },
    [methods, onClose]
  )

  return (
    <Dialog open={open} onClose={(_, reason) => handleClose(reason)} fullWidth>
      <DialogTitle className='px-[38px] font-normal text-primary'>
        <span className='font-normal text-primary'>{isUpdate ? "Edit" : "Add"} Payment Information</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent className='px-[38px]'>
        <PaymentInformationContent />
      </DialogContent>
      <DialogActions className={"justify-between p-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(handleSubmit)} disabled={!isSaveButtonEnabled}>
          {isUpdate ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddPaymentModal
