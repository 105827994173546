import React, { useMemo } from "react"

import { formattedCompanyDepartments } from "@/constants/companyDepartment.ts"
import type { CompanyDepartment } from "@/graphql/codegen/graphql"
import { FilterChip } from "@/screens/Companies/components/filters/FilterChip.tsx"

interface DepartmentChipsProps {
  companyDepartments: CompanyDepartment[] | undefined
  onRemove: (key: keyof any, value: CompanyDepartment) => void
}

export const DepartmentChips: React.FC<DepartmentChipsProps> = ({ companyDepartments, onRemove }) => {
  const chips = useMemo(() => {
    if (!companyDepartments) return null
    return formattedCompanyDepartments
      .filter(({ original }) => companyDepartments.includes(original))
      .map(({ original, formatted }) => (
        <FilterChip
          key={`companyDepartments-${original}`}
          keyProp={`companyDepartments-${original}`}
          label={`Department: ${formatted}`}
          onDelete={() => onRemove("companyDepartments", original)}
        />
      ))
  }, [companyDepartments, onRemove])

  return <>{chips}</>
}
