import { Box, Typography } from "@mui/material"
import { UploadFileOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

import type { ContentProps } from "../types"

export const DefaultContent = ({ uploading, fileUrl }: ContentProps): ReactElement => {
  if (uploading) {
    return (
      <Box className='flex size-full flex-col items-center justify-center'>
        <Typography variant='caption'>Uploading...</Typography>
      </Box>
    )
  }

  if (fileUrl) {
    return (
      <Box className='relative size-full'>
        <img src={fileUrl} alt='Preview' className='absolute inset-0 size-full object-cover' />
      </Box>
    )
  }

  return (
    <Box className='flex size-full flex-col items-center justify-center gap-2'>
      <UploadFileOutlined fontSize='large' className='text-primary' />
      <Typography variant='body2' color='textSecondary' className='text-black'>
        <Box component='span' className='text-primary underline'>
          Click to upload
        </Box>{" "}
        or drag and drop
      </Typography>
    </Box>
  )
}
