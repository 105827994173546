import { Box, IconButton, Typography } from "@mui/material"
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { memo } from "react"

interface HeaderProps {
  expanded: boolean
  onExpandClick: () => void
}

export const Header = memo(
  ({ expanded, onExpandClick }: HeaderProps): ReactElement => (
    <Box className='flex items-center justify-between'>
      <Typography variant='h6' className={expanded ? "mb-6" : ""}>
        Product Label Information
      </Typography>
      <IconButton onClick={onExpandClick} aria-expanded={expanded} aria-label='show more'>
        {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </IconButton>
    </Box>
  )
)

Header.displayName = "Header"
