import { useCallback } from "react"

import { useProductFormImagesStore } from "../stores/useProductFormImagesStore"
import type { SignedUrl } from "../types"
import { IMAGE_ORDER } from "../types"

import { FieldGroupEnum, ReferenceTableName } from "@/graphql/codegen/graphql"
import { useImageUpload } from "@/hooks/useImageUpload"

export const useProductFileHandling = (
  productId: string
): {
  previewFiles: Partial<Record<FieldGroupEnum, { file: File; previewUrl: string } | null>>
  uploading: Partial<Record<FieldGroupEnum, boolean>>
  handleFileSelect: (type: FieldGroupEnum, file: File) => void
  handleSaveFiles: () => Promise<SignedUrl[]>
  handleCancel: (fieldGroup?: FieldGroupEnum) => void
} => {
  const { handleGetSignedUrl, handleUploadToS3 } = useImageUpload({
    referenceTableName: ReferenceTableName.Product,
    type: FieldGroupEnum.Product,
    getReferenceId: () => productId,
  })

  const {
    previewFiles,
    setPreviewFile,
    clearUnsavedPreview,
    clearUnsavedPreviews,
    markPreviewAsSaved,
    setUploading,
    uploading,
    signedUrls,
  } = useProductFormImagesStore()

  const handleFileSelect = useCallback(
    (type: FieldGroupEnum, file: File) => {
      if (!file) return
      setPreviewFile(type, file)
    },
    [setPreviewFile]
  )

  const handleCancel = useCallback(
    (fieldGroup?: FieldGroupEnum) => {
      if (fieldGroup) {
        clearUnsavedPreview(fieldGroup)
      } else {
        clearUnsavedPreviews()
      }
    },
    [clearUnsavedPreview, clearUnsavedPreviews]
  )

  const handleSaveFiles = useCallback(async () => {
    try {
      const uploadPromises = Object.entries(previewFiles)
        .filter(([_, preview]) => preview?.file)
        .map(async ([type, preview]) => {
          if (!preview?.file) return null

          setUploading(type as FieldGroupEnum, true)
          const signedUrl = await handleGetSignedUrl(preview.file, type as FieldGroupEnum)
          if (!signedUrl) return null

          const success = await handleUploadToS3(signedUrl, type as FieldGroupEnum, preview.file)
          if (!success) return null

          setUploading(type as FieldGroupEnum, false)
          markPreviewAsSaved(type as FieldGroupEnum)
          return {
            url: signedUrl,
            file: {
              fieldGroup: type as FieldGroupEnum,
              fileName: preview.file.name,
            },
          }
        })

      const results = await Promise.all(uploadPromises)
      const uploadResults = results.filter((result) => result != null)

      // Create a map of new uploads by fieldGroup
      const newUploads = uploadResults.reduce<Record<string, SignedUrl>>((acc, url) => {
        if (url.file?.fieldGroup) {
          acc[url.file.fieldGroup] = url
        }
        return acc
      }, {})

      // Merge existing URLs with new uploads
      const mergedSignedUrls = IMAGE_ORDER.map((fieldGroup) => {
        // If we have a new upload for this field group, use it
        if (newUploads[fieldGroup]) {
          return newUploads[fieldGroup]
        }
        // Otherwise keep the existing signed URL if any
        return signedUrls.find((url) => url.file?.fieldGroup === fieldGroup)
      }).filter((url): url is SignedUrl => !!url)

      return mergedSignedUrls
    } catch (error) {
      console.error("Failed to upload files:", error)
      return []
    }
  }, [handleGetSignedUrl, handleUploadToS3, setUploading, previewFiles, signedUrls, markPreviewAsSaved])

  return {
    previewFiles,
    uploading,
    handleFileSelect,
    handleSaveFiles,
    handleCancel,
  }
}
