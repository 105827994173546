import { CompanyDepartment } from "@/graphql/codegen/graphql.ts"

export const companyDepartments = Object.values(CompanyDepartment)

function formatEnumValue(enumValue: string): string {
  return enumValue
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const formattedCompanyDepartments = companyDepartments.map((key) => ({
  original: key,
  formatted: formatEnumValue(key),
}))

export type FormattedCompanyDepartments = {
  original: keyof CompanyDepartment
  formatted: string
}
