import { z } from "zod"

import { ApplicationToEnumGql, IncludedWithEnumGql, TransportationModeEnumGql } from "@/graphql/codegen/graphql"

export const AddInstructionsSchema = z.object({
  applicableTo: z.array(
    z.nativeEnum(ApplicationToEnumGql, {
      errorMap: () => ({ message: "Select applicable to" }),
    })
  ),
  transportationMode: z.array(
    z.nativeEnum(TransportationModeEnumGql, {
      errorMap: () => ({ message: "Select transportation mode" }),
    })
  ),
  includedWith: z.array(
    z.nativeEnum(IncludedWithEnumGql, {
      errorMap: () => ({ message: "Select included with" }),
    })
  ),
  productInstructionId: z.array(z.string()).min(1, "Select instructions"),
})
