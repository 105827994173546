import type { ZodError } from "zod"

import type { AuthTokens } from "@/graphql/codegen/graphql.ts"
import { idTokenSchema } from "@/types/auth.ts"
import { exchangeTokenWithAPI } from "@/utils/auth/exchangeToken.ts"
import env from "@/utils/env.ts"

const cognitoConfig = {
  UserPoolId: env.VITE_USER_POOL_ID,
  ClientId: env.VITE_APP_CLIENT_ID,
  RedirectUriSignIn: env.VITE_REDIRECT_SIGN_IN,
  AppWebDomain: env.VITE_COGNITO_DOMAIN,
}

export const getSignInUrl =
  (): `https://${string}/login?response_type=token&client_id=${string}&redirect_uri=${string}&scope=openid` => {
    return `https://${cognitoConfig.AppWebDomain}/login?response_type=token&client_id=${cognitoConfig.ClientId}&redirect_uri=${cognitoConfig.RedirectUriSignIn}&scope=openid`
  }

export const getSignOutUrl =
  (): `https://${string}/logout?response_type=token&client_id=${string}&redirect_uri=${string}&scope=openid` => {
    return `https://${cognitoConfig.AppWebDomain}/logout?response_type=token&client_id=${cognitoConfig.ClientId}&redirect_uri=${cognitoConfig.RedirectUriSignIn}&scope=openid`
  }

export const redirectToSignIn = (): void => {
  location.assign(getSignInUrl())
}

export const redirectToSignOut = (): void => {
  location.assign(getSignOutUrl())
}

export const getIdTokenFromUrl = (): string | null => {
  const hash = window.location.hash.substring(1)
  const params = new URLSearchParams(hash)
  return params.get("id_token")
}

export const validateAndSendIdToken = async (idToken: string): Promise<AuthTokens | ZodError<{ id_token: string }>> => {
  const validatedToken = idTokenSchema.safeParse({ id_token: idToken })

  if (validatedToken.error) {
    return validatedToken.error
  }

  return exchangeTokenWithAPI(validatedToken.data.id_token)
}
