import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material"
import { CloseOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import React from "react"

import { Button } from "@/components/common/Button"

type CloseReason = "cancel" | "success" | "backdropClick" | "escapeKeyDown"

type NoContactsSubscribedModalProps = {
  open: boolean
  updatingPO: boolean
  onClose: (reason: CloseReason) => void
  onSendToSelf: () => Promise<void> | void
  onDownload: () => Promise<void> | void
}

export const NoContactsSubscribedModal: React.FC<NoContactsSubscribedModalProps> = ({
  open,
  updatingPO,
  onClose,
  onSendToSelf,
  onDownload,
}) => {
  const close = (_event: any, reason: CloseReason) => {
    onClose(reason)
  }

  const closeWithReason = (reason: CloseReason) => () => {
    close(undefined, reason)
  }

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <IconButton
        aria-label='close'
        onClick={closeWithReason("cancel")}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10 flex flex-col gap-6" }}>
        <Box className='flex justify-center'>
          <WarningOutlined color='error' fontSize='large' />
        </Box>
        <Typography color='primary' variant='h5' align='center'>
          There are no contacts subscribed to receive purchase orders
        </Typography>
        <Typography color='textSecondary' variant='body1' align='center'>
          However, you can send this PO to yourself or download it and send it manually.
        </Typography>
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={closeWithReason("cancel")}>
          Cancel
        </Button>
        <Button appearance='text' onClick={onSendToSelf}>
          Send To Myself
        </Button>
        <Button
          onClick={onDownload}
          disabled={updatingPO}
          endIcon={
            updatingPO ? (
              <Box className='flex items-center'>
                <CircularProgress size={16} />
              </Box>
            ) : undefined
          }
        >
          Download Purchase Order
        </Button>
      </DialogActions>
    </Dialog>
  )
}
