import type { StateCreator } from "zustand"
import { create } from "zustand"
import { persist, subscribeWithSelector } from "zustand/middleware"

import { CountryListDocument } from "@/graphql/codegen/graphql"
import type {
  Country,
  CountryListFailure,
  CountryListQuery,
  CountryListQueryVariables,
} from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"

interface CountriesStore {
  countries: Country[]
  fetching: boolean
  error: string | null
  getCountries: () => Promise<Country[]>
}

const store: StateCreator<CountriesStore> = (set, get) => ({
  countries: [],
  fetching: false,
  error: null,
  getCountries: async () => {
    if (get().countries.length === 0 && !get().fetching) {
      set({ fetching: true })
      try {
        const { data, error } = await queryClient
          .query<CountryListQuery, CountryListQueryVariables>(CountryListDocument, { input: { limit: 250 } })
          .toPromise()

        if (error || data?.country.list.__typename === "CountryListFailure") {
          const message = error?.message || (data?.country.list as CountryListFailure).error.message
          set({ error: message, fetching: false })
        } else if (data?.country.list.__typename === "CountryListSuccess") {
          set({
            countries: data?.country.list.countries,
            fetching: false,
            error: null,
          })
        } else {
          set({ countries: [], fetching: false, error: "Failed to fetch countries" })
        }
      } catch (error) {
        set({ error: (error as Error).message, fetching: false })
      }
    }
    return get().countries
  },
})

export const useCountriesStore = create(
  persist(
    subscribeWithSelector<CountriesStore>((set, get, val) => {
      const storeWithMiddleware = store(set, get, val)

      return {
        ...storeWithMiddleware,
        getCountries: async () => {
          await storeWithMiddleware.getCountries()
          return get().countries
        },
      }
    }),
    {
      name: "countries-storage",
    }
  )
)
