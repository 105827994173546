import { create } from "zustand"

import type { SignedUrl } from "../types"
import { IMAGE_ORDER } from "../types"

import type { FieldGroupEnum } from "@/graphql/codegen/graphql"

interface PreviewFile {
  file: File
  previewUrl: string
}

interface ProductFormImagesState {
  // Original signed URLs from BE
  signedUrls: SignedUrl[]
  // Current preview URLs (blobs) with files
  previewFiles: Partial<Record<FieldGroupEnum, PreviewFile | null>>
  // Loading states
  uploading: Partial<Record<FieldGroupEnum, boolean>>
  savedPreviews: Set<FieldGroupEnum> // Track which previews were saved
  modifiedPreviews: Set<FieldGroupEnum> // Track which previews were modified

  // Actions
  setSignedUrls: (urls: SignedUrl[]) => void
  setPreviewFile: (fieldGroup: FieldGroupEnum, file: File) => void
  removePreviewFile: (fieldGroup: FieldGroupEnum) => void
  clearPreviews: () => void
  setUploading: (fieldGroup: FieldGroupEnum, isUploading: boolean) => void
  markPreviewAsSaved: (fieldGroup: FieldGroupEnum) => void
  clearSavedPreviews: () => void
  clearUnsavedPreviews: () => void // New method to only clear unsaved previews
  markPreviewAsModified: (fieldGroup: FieldGroupEnum) => void
  clearModifiedPreviews: () => void
  clearUnsavedPreview: (fieldGroup: FieldGroupEnum) => void // Clear single preview

  // URL getters
  getImageUrl: (fieldGroup: FieldGroupEnum) => string | null
  getAllImageUrls: () => string[]
}

export const useProductFormImagesStore = create<ProductFormImagesState>((set, get) => ({
  signedUrls: [],
  previewFiles: {},
  uploading: IMAGE_ORDER.reduce(
    (acc, fieldGroup) => {
      acc[fieldGroup] = false
      return acc
    },
    {} as Partial<Record<FieldGroupEnum, boolean>>
  ),
  savedPreviews: new Set(),
  modifiedPreviews: new Set(),

  setSignedUrls: (urls) => set({ signedUrls: urls }),

  setPreviewFile: (fieldGroup, file) =>
    set((state) => ({
      previewFiles: {
        ...state.previewFiles,
        [fieldGroup]: {
          file,
          previewUrl: URL.createObjectURL(file),
        },
      },
      modifiedPreviews: new Set([...state.modifiedPreviews, fieldGroup]),
    })),

  removePreviewFile: (fieldGroup) =>
    set((state) => {
      const { [fieldGroup]: removed, ...rest } = state.previewFiles
      if (removed?.previewUrl) {
        URL.revokeObjectURL(removed.previewUrl)
      }
      return { previewFiles: rest }
    }),

  clearPreviews: () =>
    set((state) => {
      // Cleanup all preview URLs
      Object.values(state.previewFiles).forEach((preview) => {
        if (preview?.previewUrl) {
          URL.revokeObjectURL(preview.previewUrl)
        }
      })
      return { previewFiles: {} }
    }),

  setUploading: (fieldGroup, isUploading) =>
    set((state) => ({
      uploading: { ...state.uploading, [fieldGroup]: isUploading },
    })),

  markPreviewAsSaved: (fieldGroup) =>
    set((state) => ({
      savedPreviews: new Set([...state.savedPreviews, fieldGroup]),
    })),

  clearSavedPreviews: () => set({ savedPreviews: new Set() }),

  markPreviewAsModified: (fieldGroup) =>
    set((state) => ({
      modifiedPreviews: new Set([...state.modifiedPreviews, fieldGroup]),
    })),

  clearModifiedPreviews: () => set({ modifiedPreviews: new Set() }),

  clearUnsavedPreview: (fieldGroup) =>
    set((state) => {
      if (!state.savedPreviews.has(fieldGroup) && state.previewFiles[fieldGroup]?.previewUrl) {
        URL.revokeObjectURL(state.previewFiles[fieldGroup]!.previewUrl)
        const { [fieldGroup]: _, ...rest } = state.previewFiles
        const newModified = new Set(state.modifiedPreviews)
        newModified.delete(fieldGroup)
        return {
          previewFiles: rest,
          modifiedPreviews: newModified,
        }
      }
      return state
    }),

  clearUnsavedPreviews: () =>
    set((state) => {
      const newPreviewFiles = { ...state.previewFiles }
      state.modifiedPreviews.forEach((fieldGroup) => {
        if (!state.savedPreviews.has(fieldGroup) && newPreviewFiles[fieldGroup]?.previewUrl) {
          URL.revokeObjectURL(newPreviewFiles[fieldGroup]!.previewUrl)
          delete newPreviewFiles[fieldGroup]
        }
      })
      return {
        previewFiles: newPreviewFiles,
        modifiedPreviews: new Set(),
      }
    }),

  getImageUrl: (fieldGroup) => {
    const state = get()
    // First check for preview URL
    if (state.previewFiles[fieldGroup]?.previewUrl) {
      return state.previewFiles[fieldGroup]!.previewUrl
    }
    // Then check for signed URL
    return state.signedUrls.find((url) => url.file?.fieldGroup === fieldGroup)?.url || null
  },

  getAllImageUrls: () => {
    const state = get()
    return IMAGE_ORDER.map((fieldGroup) => state.getImageUrl(fieldGroup)).filter((url): url is string => !!url)
  },
}))
