import { type ReactElement } from "react"

import { FileIconTemplate } from "./FileIconTemplate"

interface JPGIconProps {
  className?: string
}

export const JPGIcon = ({ className }: JPGIconProps): ReactElement => (
  <FileIconTemplate
    className={className}
    text='JPG'
    stripeColor='#F57C00' // Orange
  />
)
