import type { OperationContext, OperationResult } from "urql"
import { useMutation } from "urql"

import type { Mutation, ProductMutationCreateArgs } from "@/graphql/codegen/graphql"
import { CreateNewProductMutationDocument } from "@/graphql/codegen/graphql"

export const useCreateNewProduct = (): {
  createProduct: (
    variables: ProductMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "product">, ProductMutationCreateArgs>>
} => {
  const [, createProduct] = useMutation<Pick<Mutation, "product">, ProductMutationCreateArgs>(
    CreateNewProductMutationDocument
  )

  return {
    createProduct,
  }
}
