import { Box } from "@mui/material"
import { AddAPhotoOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

import type { ContentProps } from "../types"

export const ImageHover = ({ fileUrl }: ContentProps): ReactElement => {
  if (!fileUrl) return <></>

  return (
    <Box
      className='absolute inset-0 flex items-center justify-center'
      role='button'
      tabIndex={0}
      aria-label='Edit image'
    >
      <Box className='flex size-10 items-center justify-center rounded-full bg-white/80'>
        <AddAPhotoOutlined className='text-2xl text-black' />
      </Box>
    </Box>
  )
}
