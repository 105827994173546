import { ContentCopyOutlined, DownloadOutlined, MailOutlined, PrintOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

import { Button } from "@/components/common/Button"

const CompaniesActions = (): ReactElement => (
  <div className='flex items-center justify-between'>
    <div className='space-x-2'>
      <Button size='small' startIcon={<MailOutlined />} appearance='text'>
        Email
      </Button>
      <Button size='small' startIcon={<PrintOutlined />} appearance='text'>
        Print
      </Button>
      <Button size='small' startIcon={<DownloadOutlined />} appearance='text'>
        Download
      </Button>
      <Button size='small' startIcon={<ContentCopyOutlined />} appearance='text'>
        Clone
      </Button>
    </div>
  </div>
)

export { CompaniesActions }
