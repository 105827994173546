import { create } from "zustand"

import { NotificationSchema } from "@/schemas/notification.ts"
import type { NotificationStore } from "@/types/notification.ts"

export const useNotificationsStore = create<NotificationStore>((set, get) => ({
  notifications: [],
  // todo: rename to notify
  enqueueNotification: (notification) =>
    set((state) => {
      return {
        notifications: [
          ...state.notifications,
          { ...NotificationSchema.parse(notification), read: false, silent: false },
        ],
      }
    }),

  // todo: rename to dismissNotification
  dequeueNotification: () =>
    set((state) => ({
      notifications: state.notifications.filter(
        (notification, index) => index !== 0 || notification.silent // Keep the notification if it's silent
      ),
    })),

  markAsRead: (id) =>
    set((state) => ({
      notifications: state.notifications.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      ),
    })),

  getUnreadCount: () => get().notifications.filter((notification) => !notification.read).length - 1, // skip last shown notification, assume as "read"
}))
