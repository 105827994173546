import { z } from "zod"

import type { ProductSelectionItem, PurchaseOrderFormData } from "../Supplier/types/types"

import type { Company, CompanyLocationAssociation } from "@/graphql/codegen/graphql"

export interface PurchaseOrderDialogProps {
  open: boolean
  fetching: boolean
  company: Company | undefined
  products: ProductSelectionItem[]
  shipToLocations: CompanyLocationAssociation[]
  onClose: () => void
  onSubmit: (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => void
}

export const PurchaseOrderDialogSchema = z.object({
  shipTo: z.string().min(1, { message: "This field is required" }),
})

export type PurchaseOrderDialogFormValues = z.infer<typeof PurchaseOrderDialogSchema>

export enum PurchaseOrderSection {
  HEADER = "HEADER",
  DELIVERY_TERMS = "DELIVERY_TERMS",
  SHIPPING_LEGS = "SHIPPING_LEGS",
  NOTES = "NOTES",
  COMMENTS = "COMMENTS",
  ORDER_LINES = "ORDER_LINES",
  SUGGESTED_PRODUCTS = "SUGGESTED_PRODUCTS",
}

export interface SelectOption<T = string> {
  label: string
  value: T
}

export interface DeliveryTermsFormValues {
  incoterms: string
  incotermsLocation: string
  freightTerms: string
  shipVia: string
  shipmentStatus: string
}

export enum PurchaseOrderAction {
  SAVE_DRAFT = "SAVE_DRAFT",
  SAVE_AND_EXIT = "SAVE_AND_EXIT",
  CANCEL = "CANCEL",
  SEND = "SEND",
}
