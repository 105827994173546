import { z } from "zod"

import { ShelfLifeUom } from "./TechnicalInformationContent"

import { casNumberSchema } from "@/utils/validation/casNumberValidation"

export const TechnicalInformationSchema = z.object({
  specificGravity: z
    .number()
    .min(0)
    .max(999999.9999)
    .refine((val) => {
      const decimalPlaces = val.toString().split(".")[1]?.length || 0
      return decimalPlaces <= 4
    }, "Specific Gravity must not exceed 4 decimal places"),

  shelfLifeDays: z.number().min(0),
  shelfLifeUom: z.nativeEnum(ShelfLifeUom),

  cassRegistryId: casNumberSchema.optional(),

  harmonizedTariffSchedule: z
    .string()
    .regex(/^\d{1,10}$/, "HTS must contain only digits")
    .max(10, "HTS must not exceed 10 digits")
    .optional(),

  usmcaClass: z.boolean().default(false).optional(),

  unNumber: z
    .string()
    .regex(/^\d{4}$/, "UN Number must be exactly 4 digits")
    .optional(),
})

export type TechnicalInformationFormData = z.infer<typeof TechnicalInformationSchema>
