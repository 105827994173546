import type { Maybe } from "graphql/jsutils/Maybe"
import type { CombinedError } from "urql"

import { useGetPurchaseOrderCompany } from "./useGetPurchaseOrderCompany"
import { useGetPurchaseOrderCompanyLocationAssociation } from "./useGetPurchaseOrderCompanyLocationAssociation"
import { useGetPurchaseOrderLocation } from "./useGetPurchaseOrderLocation"

import type { CompanyGetSuccess, LocationGetSuccess } from "@/graphql/codegen/graphql"

export interface UsePurchaseOrderShipDetailsData {
  purchaseOrderShipLocation: Maybe<LocationGetSuccess>
  purchaseOrderShipCompany: Maybe<CompanyGetSuccess>
  purchaseOrderShipDetailsFetching: boolean
  purchaseOrderShipDetailsError: CombinedError | undefined
}

export type UsePurchaseOrderShipDetails = (locationAssociationId?: Maybe<string>) => UsePurchaseOrderShipDetailsData

export const useGetPurchaseOrderShipDetails: UsePurchaseOrderShipDetails = (locationAssociationId) => {
  // Fetch the company location association
  const {
    purchaseOrderCompanyLocationAssociation,
    purchaseOrderCompanyLocationAssociationFetching,
    purchaseOrderCompanyLocationAssociationError,
  } = useGetPurchaseOrderCompanyLocationAssociation(locationAssociationId)

  // Fetch the location details
  const { purchaseOrderLocation, purchaseOrderLocationFetching, purchaseOrderLocationError } =
    useGetPurchaseOrderLocation(
      purchaseOrderCompanyLocationAssociation?.companyLocationAssociation?.shipping.locationId
    )

  // Fetch the company details
  const { purchaseOrderCompany, purchaseOrderCompanyFetching, purchaseOrderCompanyError } = useGetPurchaseOrderCompany(
    purchaseOrderCompanyLocationAssociation?.companyLocationAssociation?.companyId
  )

  return {
    purchaseOrderShipLocation: purchaseOrderLocation,
    purchaseOrderShipCompany: purchaseOrderCompany,
    purchaseOrderShipDetailsFetching:
      purchaseOrderLocationFetching || purchaseOrderCompanyLocationAssociationFetching || purchaseOrderCompanyFetching,
    purchaseOrderShipDetailsError:
      purchaseOrderLocationError || purchaseOrderCompanyLocationAssociationError || purchaseOrderCompanyError,
  }
}
