import { z } from "zod"

export const AddProductInformationSchema = z.object({
  companyId: z.string().optional(),
  vendorSku: z.string().optional(),
  vendorDescription: z.string().min(1, { message: "Vendor Description is mandatory" }),
  companyProductDescription: z.string().min(1, { message: "Akrochem Description is mandatory" }),
  productManager: z.object({
    id: z.string().min(1, { message: "Select product manager" }),
    name: z.string().min(1, { message: "Select product manager" }),
  }),
  locationsAssociations: z.array(
    z.object({
      address1: z.string().optional(),
      city: z.string().optional(),
      regionIsoCode: z.string().optional(),
      locationAssociationId: z.string().optional(),
    })
  ),
})

export const AddProductSpecificsSchema = z.object({
  classification: z.object({
    id: z.string().min(1, { message: "Select product classification" }),
    name: z.string().min(1, { message: "Select product classification" }),
  }),
  productFormInfo: z.string().optional(),
  uomId: z.string().optional(),
})

export const AddProductModalSchema = AddProductInformationSchema.merge(AddProductSpecificsSchema)
