import { Box, MenuItem, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"
import type { ReactElement } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { MaturityDateTypeGql, PayPatternGql, type PaymentTermCreateInput } from "@/graphql/codegen/graphql"
import { SelectComponent } from "@/screens/Products/components/AddProductSpecifics"

const payPatternLabel = {
  [PayPatternGql.BiWeekly]: "Biweekly",
  [PayPatternGql.Monthly]: "Monthly",
  [PayPatternGql.Weekly]: "Weekly",
}

const payInvoiceLabel = {
  [MaturityDateTypeGql.CalendarPeriod]: "Calendar Period",
  [MaturityDateTypeGql.PayInterval]: "Pay Interval",
}

const AddPaymentRuleFields = (): ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PaymentTermCreateInput>()

  return (
    <div>
      <Box className='flex items-end gap-3'>
        <Controller
          name={"payRunSchedule.payPattern"}
          control={control}
          render={() => (
            <Box className='w-[176px]'>
              <SelectComponent
                name={"payRunSchedule.payPattern"}
                control={control}
                label='Pay Pattern'
                placeholder='Select pay pattern'
                required
              >
                {Object.keys(PayPatternGql).map((value) => (
                  <MenuItem key={value} value={value}>
                    {payPatternLabel[PayPatternGql[value]]}
                  </MenuItem>
                ))}
              </SelectComponent>
            </Box>
          )}
        />
        <Controller
          name={`payRunSchedule.startDate`}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='mb-2 block text-sm font-thin text-gray-700'>
                Start (reference date)<span className='text-red-600'>*</span>
              </label>
              <DatePicker
                {...field}
                name={`payRunSchedule.startDate`}
                renderInput={(params) => <TextField {...params} />}
                value={field.value ? dayjs(field.value) : null}
                onChange={(date) => {
                  const formattedDate = dayjs(date).format("YYYY-MM-DD")
                  field.onChange(formattedDate)
                }}
              />
            </Box>
          )}
        />
        <Controller
          name={`payRunSchedule.payDays`}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='mb-2 block text-sm font-thin text-gray-700'>
                Pay Days<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                fullWidth
                name={`payRunSchedule.payDays`}
                variant='outlined'
                placeholder='-'
                InputProps={{
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                  inputProps: {
                    className: "hide-stepper",
                  },
                }}
              />
            </Box>
          )}
        />
        <Controller
          name={"payRunSchedule.invoiceMaturity.intervalBetweenPayRuns"}
          control={control}
          render={() => (
            <SelectComponent
              name={"payRunSchedule.invoiceMaturity.intervalBetweenPayRuns"}
              control={control}
              label='Pay Invoices with Maturity Date Within'
              placeholder='Select Pay Invoices'
              required
            >
              {Object.keys(MaturityDateTypeGql).map((value) => (
                <MenuItem key={value} value={value}>
                  {payInvoiceLabel[MaturityDateTypeGql[value]]}
                </MenuItem>
              ))}
            </SelectComponent>
          )}
        />
        <Typography variant='body2' className='mb-3 content-center text-gray-700'>
          offset
        </Typography>
        <Controller
          name={`payRunSchedule.invoiceMaturity.offsetInDays`}
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <TextField
                {...field}
                name={`payRunSchedule.invoiceMaturity.offsetInDays`}
                fullWidth
                variant='outlined'
                placeholder='-'
                InputProps={{
                  classes: {
                    input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                  },
                  inputProps: {
                    className: "hide-stepper",
                  },
                }}
              />
            </Box>
          )}
        />
      </Box>
      <div className='text-sm text-red-600'>
        <div>{errors?.payRunSchedule?.payPattern?.message}</div>
        <div>{errors?.payRunSchedule?.startDate?.message}</div>
        <div>{errors?.payRunSchedule?.payDays?.message}</div>
        <div>{errors?.payRunSchedule?.invoiceMaturity?.intervalBetweenPayRuns?.message}</div>
        <div>{errors?.payRunSchedule?.invoiceMaturity?.offsetInDays?.message}</div>
      </div>
    </div>
  )
}

export default AddPaymentRuleFields
