import { useCallback, useEffect, useMemo } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useShallow } from "zustand/shallow"

import type { DeliveryTermsFormValues } from "../../../types"
import { PurchaseOrderSection } from "../../../types"

import { useGetIncotermsLocationQuery } from "./useGetIncotermsLocationQuery"

import { FREIGHT_TERMS_OPTIONS, SHIPMENT_STATUS_OPTIONS } from "@/constants/freighTerms"
import type { Company, FreightTerms, IncotermsDefinitionMap, Location, ShipStatus } from "@/graphql/codegen/graphql"
import { useGetIncotermsQuery } from "@/screens/Procurement/PurchaseOrder/DeliveryTermsAndShippingLegs/hooks/deliveryTerms/useGetIncotermsQuery"
import { useGetShipViaQuery } from "@/screens/Procurement/PurchaseOrder/DeliveryTermsAndShippingLegs/hooks/useGetShipViaQuery"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"
import type { SelectOption } from "@/types/form-fields"

type UseDeliveryTermsReturn = {
  incotermsOptions: IncotermsDefinitionMap[]
  incotermsLocationOptions: Location[]
  freightTermsOptions: SelectOption[]
  shipViaOptions: Company[]
  shipmentStatusOptions: SelectOption[]
  loadMoreIncoterms: () => void
  loadMoreIncotermsLocation: () => void
  loadMoreShipVia: () => void
  loading: boolean
}

export const useDeliveryTerms = ({
  methods,
}: {
  methods: UseFormReturn<DeliveryTermsFormValues>
}): UseDeliveryTermsReturn => {
  const { data: incotermsOptions, loadMore: loadMoreIncoterms, fetching: incotermsFetching } = useGetIncotermsQuery()
  const {
    data: incotermsLocationOptions,
    loadMore: loadMoreIncotermsLocation,
    fetching: incotermsLocationFetching,
  } = useGetIncotermsLocationQuery()
  const { data: shipViaOptions, loadMore: loadMoreShipVia, fetching: shipViaFetching } = useGetShipViaQuery()

  const { currentPO, updateSectionFields, updateModifiedFields } = usePurchaseOrderStore(
    useShallow((state) => ({
      currentPO: state.currentPO,
      updateSectionFields: state.updateSectionFields,
      updateModifiedFields: state.updateModifiedFields,
    }))
  )

  const handleFormDataUpdate = useCallback(
    (formData: Partial<DeliveryTermsFormValues>) => {
      if (!formData) return

      updateSectionFields(PurchaseOrderSection.DELIVERY_TERMS, formData as DeliveryTermsFormValues)

      const modifiedFields: Record<string, any> = {}

      if (formData.incotermsLocation) {
        modifiedFields.deliveryLocationId = formData.incotermsLocation
      }

      if (formData.freightTerms) {
        modifiedFields.freightTerms = formData.freightTerms as FreightTerms
      }

      if (formData.shipVia) {
        modifiedFields.shipViaCompanyId = formData.shipVia
      }

      if (formData.shipmentStatus) {
        modifiedFields.shipStatus = formData.shipmentStatus as ShipStatus
      }

      if (formData.incoterms) {
        modifiedFields.deliveryTerms = formData.incoterms
      }

      if (Object.keys(modifiedFields).length > 0) {
        console.log("Updating modified fields:", modifiedFields) // Add this line for debugging
        updateModifiedFields(PurchaseOrderSection.DELIVERY_TERMS, modifiedFields)
      }
    },
    [updateSectionFields, updateModifiedFields]
  )

  useEffect(() => {
    if (currentPO?.destinationLocationAssociationId) {
      methods.setValue("incotermsLocation", currentPO.destinationLocationAssociationId)
    }

    const subscription = methods.watch(handleFormDataUpdate)

    return () => subscription.unsubscribe()
  }, [currentPO, methods, handleFormDataUpdate])

  const loading = useMemo(() => {
    return incotermsFetching || incotermsLocationFetching || shipViaFetching
  }, [incotermsFetching, incotermsLocationFetching, shipViaFetching])

  return {
    incotermsOptions,
    incotermsLocationOptions,
    freightTermsOptions: FREIGHT_TERMS_OPTIONS,
    shipViaOptions,
    shipmentStatusOptions: SHIPMENT_STATUS_OPTIONS,
    loadMoreIncoterms,
    loadMoreIncotermsLocation,
    loadMoreShipVia,
    loading,
  }
}
