import { MenuItem, Select } from "@mui/material"
import type { FC } from "react"

import { useGetPaymentTerms } from "./hooks/useGetPaymentTerms"

import { FieldRow, Form, FormField, renderSelectPlaceholder } from "@/components/Forms"

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"
const selectSlotProps = { input: { className: InputFieldClasses } }

export const PaymentInformationContent: FC = () => {
  const { paymentTermList } = useGetPaymentTerms()

  return (
    <Form>
      <FieldRow>
        <FormField
          name='paymentTermId'
          label='Payment Terms'
          required
          render={({ field, fieldState }) => (
            <Select
              {...field}
              slotProps={selectSlotProps}
              type='text'
              error={!!fieldState.error}
              placeholder='Select one'
              fullWidth
              variant='outlined'
              displayEmpty
              renderValue={renderSelectPlaceholder({ field })}
            >
              {paymentTermList.map(({ name, paymentTermId }) => (
                <MenuItem key={paymentTermId} value={paymentTermId}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FieldRow>
    </Form>
  )
}
