import { ProductPackagingType } from "@/graphql/codegen/graphql"

export const productPackagingTypes = Object.values(ProductPackagingType)

function formatEnumValue(enumValue: string): string {
  return enumValue
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const formattedPackagingTypes = productPackagingTypes.map((key) => ({
  original: key,
  formatted: formatEnumValue(key),
}))

export type FormattedCompanyDepartments = {
  original: keyof ProductPackagingType
  formatted: string
}
