import { Typography } from "@mui/material"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { Fragment } from "react"
import { twMerge } from "tailwind-merge"

import type { AerosTableProps } from "../types/table"

import { AerosDataNotFoundRow } from "./AerosDataNotFoundRow"
import { AerosTable } from "./AerosTable"
import { AerosTableBody } from "./AerosTableBody"
import { AerosTableCell } from "./AerosTableCell"
import { AerosTableContainer } from "./AerosTableContainer"
import { AerosTableHead } from "./AerosTableHead"
import { AerosTableHeadRow } from "./AerosTableHeadRow"
import { AerosTableRow } from "./AerosTableRow"

export const AerosBaseTable = <TData,>({
  error,
  features,
  slotProps,
  noDataMessage = "",
}: AerosTableProps<TData>): ReactElement => {
  const {
    data = [],
    columns = [],
    state,
    getCoreRowModel: customCoreRowModel,
    ...tanstackOptions
  } = features?.tanstackOptions || {}
  const table = useReactTable<TData>({
    data,
    columns,
    state,
    getCoreRowModel: customCoreRowModel ?? getCoreRowModel<TData>(),
    ...tanstackOptions,
  })

  return (
    <AerosTableContainer {...slotProps?.container}>
      <AerosTable stickyHeader>
        <AerosTableHead>
          <AerosTableHeadRow {...slotProps?.headerRow}>
            {table.getFlatHeaders().map((header) => (
              <AerosTableCell
                key={header.id}
                align={header.id === "select" ? "center" : (slotProps?.headerCell?.align ?? "left")}
                sx={{
                  width: header.getSize(),
                  minWidth: header.getSize(),
                  maxWidth: header.getSize(),
                }}
                className={twMerge("bg-gray-100 text-gray-700", slotProps?.headerCell?.className)}
                {...slotProps?.headerCell}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </AerosTableCell>
            ))}
          </AerosTableHeadRow>
        </AerosTableHead>
        <AerosTableBody className='table-row-group'>
          {table.getRowCount() === 0 && noDataMessage && (
            <AerosDataNotFoundRow colSpan={columns.length}>{noDataMessage}</AerosDataNotFoundRow>
          )}
          {table.getRowCount() > 0 &&
            table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <AerosTableRow
                  selected={features?.tanstackOptions?.state?.rowSelection && row.getIsSelected()}
                  sx={{ cursor: features?.tanstackOptions?.state?.rowSelection ? "pointer" : "default" }}
                  {...slotProps?.bodyRow}
                >
                  {row.getVisibleCells().map((cell) => {
                    const align = cell.column.id === "select" ? "center" : (slotProps?.bodyCell?.align ?? "left")
                    const onClick = cell.column.id === "select" ? row.getToggleSelectedHandler() : undefined
                    const sizeStyles = {
                      width: cell.column.getSize(),
                      minWidth: cell.column.getSize(),
                      maxWidth: cell.column.getSize(),
                    }
                    const className = twMerge(
                      slotProps?.bodyCell?.className,
                      row.getIsExpanded() ? slotProps?.bodyCellExpanded?.className : ""
                    )

                    return (
                      <AerosTableCell
                        key={cell.id}
                        align={align}
                        onClick={onClick}
                        sx={sizeStyles}
                        className={className}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </AerosTableCell>
                    )
                  })}
                </AerosTableRow>
                {features?.tanstackOptions?.renderExpandedContent && row.getIsExpanded() && (
                  <AerosTableRow {...slotProps?.expandedRow}>
                    <AerosTableCell
                      colSpan={columns.length}
                      className='p-0 hover:bg-transparent'
                      {...slotProps?.expandedCell}
                    >
                      {features?.tanstackOptions?.renderExpandedContent(row)}
                    </AerosTableCell>
                  </AerosTableRow>
                )}
              </Fragment>
            ))}
        </AerosTableBody>
      </AerosTable>
      {error && <Typography color='error'>{error.message}</Typography>}
    </AerosTableContainer>
  )
}
