import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { CloseOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import { type ReactElement, useCallback, useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/common/Button"
import type { CompanyLocationAssociation, Country, Location } from "@/graphql/codegen/graphql"

const InputFieldClasses = "border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"

const schema = z.object({
  shippingOrigin: z.string().min(1, "Shipping origin is required"),
  supplierLocation: z.string().nullish(),
  country: z.string().nullish(),
  city: z.string().nullable(),
  manufacturingOriginType: z.enum(["supplier", "other"]),
})

type FormData = z.infer<typeof schema>

export const ProductOriginDialog = ({
  open,
  shippingOriginLocations,
  companyManfucaturingOriginLocations,
  countries,
  onClose,
  onSubmit,
  initialData,
}: {
  open: boolean
  shippingOriginLocations: CompanyLocationAssociation[]
  companyManfucaturingOriginLocations: Location[]
  countries: Country[]
  onClose: () => void
  onSubmit: (data: Omit<FormData, "manufacturingOriginType">) => void
  initialData?: Omit<FormData, "manufacturingOriginType"> | null
}): ReactElement => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    reset,
    watch,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      supplierLocation: initialData?.supplierLocation || "",
      shippingOrigin: initialData?.shippingOrigin || "",
      country: initialData?.country || "",
      city: initialData?.city || "",
      manufacturingOriginType: initialData?.city || initialData?.country ? "other" : "supplier",
    },
  })

  const manufacturingOriginType = watch("manufacturingOriginType")

  const genericOptionList = useMemo(() => {
    if (!shippingOriginLocations) return []

    return shippingOriginLocations.map(({ companyLocationAssociationId, shipping }) => ({
      label: `${shipping?.name} - ${shipping?.address1}, ${shipping?.city}${
        shipping?.region?.name ? `, ${shipping?.region.name}` : ""
      }, ${shipping?.country?.name}`,
      companyLocationAssociationId,
      companyId: shipping?.companyId,
    }))
  }, [shippingOriginLocations])

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const onFormSubmit = useCallback(
    (data: FormData) => {
      const { manufacturingOriginType, ...submitData } = data
      onSubmit({
        ...submitData,
        shippingOrigin: submitData.shippingOrigin,
        supplierLocation: submitData.supplierLocation || null,
        country: submitData.country || null,
        city: submitData.city || null,
      })
      handleClose()
    },
    [onSubmit, handleClose]
  )

  const handleSupplierLocationChange = useCallback(
    (value: string) => {
      const location = companyManfucaturingOriginLocations.find(({ locationId }) => locationId === value)
      setValue("city", location?.city || null)
      setValue("country", location?.country?.isoCode || null)
      setValue("supplierLocation", value)
    },
    [setValue, companyManfucaturingOriginLocations]
  )

  useEffect(() => {
    if (open) {
      reset({
        supplierLocation: initialData?.supplierLocation || "",
        shippingOrigin: initialData?.shippingOrigin || "",
        country: initialData?.country || "",
        city: initialData?.city || "",
        manufacturingOriginType: initialData?.city || initialData?.country ? "other" : "supplier",
      })
    }
  }, [open, initialData, reset])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth data-testid='product-origin-dialog'>
      <DialogTitle className='pb-3 font-normal text-primary' data-testid='dialog-title'>
        {initialData ? "Edit Product Origin" : "Add Product Origin"}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        className='absolute right-4 top-4 text-primary'
        data-testid='close-button'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <form onSubmit={handleSubmit(onFormSubmit)} className='flex flex-col gap-10' data-testid='product-origin-form'>
          <Box>
            <Typography variant='body1' fontWeight={500} className='leading-6 tracking-[0.15px] text-gray-700'>
              Shipping Origin
            </Typography>
            <FormControl fullWidth margin='normal'>
              <Controller
                name='shippingOrigin'
                control={control}
                render={({ field }) => (
                  <>
                    <label className='-top-6 mb-[7px] block text-sm font-thin text-gray-700'>
                      Ship From<span className='text-red-600'>*</span>
                    </label>
                    <Select
                      {...field}
                      displayEmpty
                      input={<OutlinedInput />}
                      SelectDisplayProps={{
                        className:
                          "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                      }}
                      classes={{
                        icon: "text-gray-600",
                      }}
                      IconComponent={KeyboardArrowDownOutlined}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      variant='outlined'
                      fullWidth
                      renderValue={(selected: string) =>
                        shippingOriginLocations.find(
                          ({ companyLocationAssociationId }) => companyLocationAssociationId === selected
                        )?.shipping?.address1 || ""
                      }
                      data-testid='shipping-origin-select'
                    >
                      {genericOptionList.map(({ label, companyLocationAssociationId }) => (
                        <MenuItem key={label} value={companyLocationAssociationId} divider>
                          <Box className='overflow-auto text-wrap'>{label}</Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          </Box>
          <Box className='flex flex-col gap-3'>
            <Typography variant='body1' fontWeight={500} className='leading-6 tracking-[0.15px] text-gray-700'>
              Manufacturing Origin
            </Typography>

            <Controller
              name='manufacturingOriginType'
              control={control}
              render={({ field }) => (
                <FormControl component='fieldset' margin='normal'>
                  <RadioGroup {...field} row data-testid='manufacturing-origin-type'>
                    <FormControlLabel value='supplier' control={<Radio />} label='Supplier Location' />
                    <FormControlLabel value='other' control={<Radio />} label='Other Location' />
                  </RadioGroup>
                </FormControl>
              )}
            />

            {manufacturingOriginType === "supplier" && (
              <FormControl fullWidth margin='normal'>
                <Controller
                  name='supplierLocation'
                  control={control}
                  render={({ field }) => (
                    <>
                      <label className='-top-6 mb-[7px] block text-sm font-thin text-gray-700'>
                        Select Supplier's Location
                      </label>
                      <Select
                        {...field}
                        displayEmpty
                        input={<OutlinedInput />}
                        SelectDisplayProps={{
                          className:
                            "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                        }}
                        classes={{
                          icon: "text-gray-600",
                        }}
                        IconComponent={KeyboardArrowDownOutlined}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: 250,
                            },
                          },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        variant='outlined'
                        fullWidth
                        renderValue={(selected: string) =>
                          companyManfucaturingOriginLocations.find(({ locationId }) => locationId === selected)
                            ?.address1 || ""
                        }
                        value={field.value || ""}
                        onChange={(event) => handleSupplierLocationChange(event.target.value)}
                        data-testid='supplier-location-select'
                      >
                        {companyManfucaturingOriginLocations.map(
                          ({ name, address1, city, region, country, locationId }) => (
                            <MenuItem key={locationId} value={locationId} divider>
                              <Box className='overflow-auto text-wrap'>
                                {`${name} - ${address1}, ${city}${region?.name ? `, ${region.name}` : ""}, ${country?.name}`}
                              </Box>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
            )}

            {manufacturingOriginType === "other" && (
              <>
                <FormControl fullWidth margin='normal'>
                  <Controller
                    name='country'
                    control={control}
                    render={({ field }) => (
                      <>
                        <label className='-top-6 mb-[7px] block text-sm font-thin text-gray-700'>Country</label>
                        <Select
                          {...field}
                          displayEmpty
                          input={<OutlinedInput />}
                          SelectDisplayProps={{
                            className:
                              "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                          }}
                          data-testid='manufacturing-origin-country-select'
                        >
                          {countries.map(({ name, isoCode }) => (
                            <MenuItem key={isoCode} value={isoCode} divider>
                              <Box className='overflow-hidden text-ellipsis'>{name}</Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </FormControl>

                <Controller
                  name='city'
                  control={control}
                  render={({ field }) => (
                    <Box className='relative'>
                      <label className='-top-6 mb-[7px] block text-sm font-thin text-gray-700'>City</label>
                      <TextField
                        {...field}
                        InputProps={{
                          classes: {
                            input: InputFieldClasses,
                          },
                        }}
                        fullWidth
                        placeholder='City'
                        variant='outlined'
                        data-testid='city-input'
                      />
                    </Box>
                  )}
                />
              </>
            )}
          </Box>
        </form>
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button type='submit' disabled={!isValid} onClick={handleSubmit(onFormSubmit)}>
          {initialData ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
