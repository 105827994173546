import type { OperationContext, OperationResult } from "urql"
import { useMutation } from "urql"

import type {
  ContactMutationBatchCreateContactMethodArgs,
  ContactMutationBatchUpdateContactMethodArgs,
  Mutation,
} from "@/graphql/codegen/graphql"
import { BatchCreateContactMutationDocument, BatchUpdateContactMutationDocument } from "@/graphql/codegen/graphql"

export const useGetContactMethods = (): {
  batchCreateContactMethods: (
    variables: ContactMutationBatchCreateContactMethodArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "contact">, ContactMutationBatchCreateContactMethodArgs>>
  batchUpdateContactMethods: (
    variables: ContactMutationBatchUpdateContactMethodArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "contact">, ContactMutationBatchUpdateContactMethodArgs>>
} => {
  // Mutation to create contact methods
  const [, batchCreateContactMethods] = useMutation<
    Pick<Mutation, "contact">,
    ContactMutationBatchCreateContactMethodArgs
  >(BatchCreateContactMutationDocument)

  // Mutation to update contact methods
  const [, batchUpdateContactMethods] = useMutation<
    Pick<Mutation, "contact">,
    ContactMutationBatchUpdateContactMethodArgs
  >(BatchUpdateContactMutationDocument)

  return {
    batchCreateContactMethods,
    batchUpdateContactMethods,
  }
}
