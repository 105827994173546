import { Box } from "@mui/material"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { twMerge } from "tailwind-merge"

import FieldError from "../FieldError/FieldError"
import FieldLabel from "../FieldLabel/FieldLabel"

interface FormFieldProps {
  name: string
  render?: (controllerRender: any) => React.ReactNode
  id?: string
  required?: boolean
  label?: string
  className?: string
  slotProps?: {
    label?: {
      className?: string
    }
    error?: {
      className?: string
    }
  }
}

/**
 * Displays a form field with a label and error message. Integrates the field with react-hook-form
 *
 * @param props The component's props
 * @param props.name The name of the field
 * @param props.render The render function to render the field. Optional. When not provided, a
 * hidden input field will be rendered
 * @param props.id The id of the field. Optional
 * @param props.required Whether the field is required. Optional
 * @param props.label The label to display for the field. Optional
 * @param props.className The class name to apply to the field. Optional
 * @param props.slotProps The slot props to apply to the component's slots. Optional
 *
 * @example
 * ```tsx
 * <FormField
 *   name="my-field"
 *   label="My Field"
 *   render={({ field, fieldState: { error } }) => (
 *     <TextField
 *       {...field}
 *       placeholder="Enter some text"
 *       error={!!error}
 *       fullWidth
 *       variant="outlined"
 *     />
 *   )}
 * />
 * ```
 */
const FormField = ({
  name,
  render,
  required = false,
  label,
  className,
  slotProps,
  ...rest
}: FormFieldProps): React.ReactElement => {
  const { control } = useFormContext()

  const { label: labelSlotProps, error: errorSlotProps } = slotProps || {}
  const { className: labelClassName, ...labelPropsRest } = labelSlotProps || {}
  const { className: errorClassName, ...errorPropsRest } = errorSlotProps || {}

  return (
    <Controller
      name={name}
      control={control}
      render={(controllerRender) =>
        render ? (
          <Box className={twMerge("flex grow flex-col gap-1", className)}>
            {!!label && (
              <FieldLabel htmlFor={rest?.id || name} required={required} className={labelClassName} {...labelPropsRest}>
                {label}
              </FieldLabel>
            )}

            {render(controllerRender)}

            <FieldError error={controllerRender?.fieldState?.error} className={errorClassName} {...errorPropsRest} />
          </Box>
        ) : (
          <input {...controllerRender.field} type='hidden' />
        )
      }
    />
  )
}

export default FormField
export { FormField }
export type { FormFieldProps }
