import { z } from "zod"

export const AddProductRegulatorySchema = z.object({
  cassRegistryId: z
    .string()
    .regex(/^\d{4}-\d{2}-\d$/, "Must be in the format 0000-00-0")
    .optional()
    .refine((val) => !val || /^\d{4}-\d{2}-\d$/.test(val), {
      message: "CAS Registry ID must be in the format 0000-00-0",
    }),
  unNumber: z
    .string()
    .regex(/^\d{4}$/, "UN Number must be exactly 4 digits")
    .optional()
    .refine((val) => !val || /^\d{4}$/.test(val), {
      message: "UN Number must be exactly 4 digits",
    }),
  isRohsCompliant: z.boolean().optional(),
  isReachSvhcCompliant: z.boolean().optional(),
})
