import { useLocation } from "@tanstack/react-router"
import type { FunctionComponent } from "react"
import { useMemo } from "react"

import BreadcrumbItem from "@/components/common/Breadcrumbs/BreadcrumbItem.tsx"
import type { BreadcrumbsProps } from "@/components/common/Breadcrumbs/Breadcrumbs.schema.tsx"
import { breadcrumbsPropsSchema } from "@/components/common/Breadcrumbs/Breadcrumbs.schema.tsx"

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = (props) => {
  const parsedProps = breadcrumbsPropsSchema.parse(props)
  const { items, separator, maxItems, itemsBeforeCollapse, itemsAfterCollapse, collapseIcon } = parsedProps

  const location = useLocation()
  const currentPath = location.pathname

  const truncatedItems = useMemo(() => {
    if (maxItems > 0 && items.length > maxItems) {
      const startItems = items.slice(0, itemsBeforeCollapse)
      const endItems = items.slice(items.length - itemsAfterCollapse)

      return [...startItems, { label: collapseIcon, pathname: "#" }, ...endItems]
    }

    return items
  }, [items, maxItems, itemsBeforeCollapse, itemsAfterCollapse, collapseIcon])

  if (items.length === 0) return null

  return (
    <nav aria-label='breadcrumb' className='w-max'>
      <ol className='flex flex-wrap items-center bg-transparent p-0'>
        {truncatedItems.map((item, index) => (
          <li key={index} className='flex items-center'>
            <BreadcrumbItem pathname={item.pathname} label={item.label} isActive={currentPath === item.pathname} />
            {index < truncatedItems.length - 1 && (
              <span className='pointer-events-none mx-2 select-none font-sans text-sm font-normal leading-normal text-gray-700 antialiased'>
                {separator}
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
