import { Box, IconButton, MenuItem, Select } from "@mui/material"
import { CloseOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { Button } from "@/components/common/Button"
import type { Company, Location } from "@/graphql/codegen/graphql"

type AssociatedLocationProps = {
  locations: Location[]
  companyNameList: Company[]
  onSubmit: () => void
  loadMore: () => void
}

const AssociatedLocation: FunctionComponent<AssociatedLocationProps> = ({
  locations,
  loadMore,
  companyNameList,
  onSubmit,
}) => {
  const { control, watch } = useFormContext()
  const shippingValue = watch("shipping")
  const billingValue = watch("billing")
  const orderValue = watch("order")

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, offsetTop, scrollHeight } = event.currentTarget
    if (offsetTop > scrollHeight - scrollTop) {
      loadMore()
    }
  }

  const genericOptionList = useMemo(() => {
    return locations.map(({ address1, city, regionIsoCode, locationId, companyId }) => ({
      label: (
        <Box>
          <div>{address1}</div>
          <div>{`${city}, ${regionIsoCode}`}</div>
        </Box>
      ),
      locationId,
      companyId,
    }))
  }, [locations])

  const isAssociateEnabled = shippingValue && billingValue && orderValue

  return (
    <form onSubmit={onSubmit} className='mb-10 flex w-full space-x-8'>
      <Box className='w-full'>
        <Controller
          name='shipping'
          control={control}
          render={({ field, fieldState }) => (
            <>
              <label className='mb-2 block text-sm font-normal text-gray-700'>
                Shipping<span className='text-red-600'>*</span>
              </label>
              <Select
                {...field}
                id='shipping-select'
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                    onScroll: handleScroll,
                  },
                }}
                IconComponent={KeyboardArrowDownOutlined}
                className='h-[42px] border-none hover:border-gray-400'
                sx={{
                  "& .MuiSelect-select": {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  },
                }}
                renderValue={(selected: string) =>
                  locations.find(({ locationId }) => locationId === selected)?.address1
                }
              >
                {genericOptionList.map(({ label, locationId }) => (
                  <MenuItem key={locationId} value={locationId} divider>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error?.message && <span className='text-sm text-red-600'>{fieldState.error?.message}</span>}
            </>
          )}
        />
      </Box>
      <Box className='w-full'>
        <Controller
          name='billing'
          control={control}
          render={({ field, fieldState }) => (
            <>
              <label className='mb-2 block text-sm font-normal text-gray-700'>
                Billing<span className='text-red-600'>*</span>
              </label>
              <Select
                {...field}
                id='billing-select'
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                    onScroll: handleScroll,
                  },
                }}
                IconComponent={KeyboardArrowDownOutlined}
                className='h-[42px] border-none hover:border-gray-400'
                sx={{
                  "& .MuiSelect-select": {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  },
                }}
                renderValue={(selected: string) =>
                  locations.find(({ locationId }) => locationId === selected)?.address1
                }
              >
                {genericOptionList.map(({ label, locationId }) => (
                  <MenuItem key={locationId} value={locationId} divider>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error?.message && <span className='text-sm text-red-600'>{fieldState.error?.message}</span>}
            </>
          )}
        />
      </Box>
      <Box className='w-full'>
        <Controller
          name='order'
          control={control}
          render={({ field, fieldState }) => (
            <>
              <label className='mb-2 block text-sm font-normal text-gray-700'>
                Order<span className='text-red-600'>*</span>
              </label>
              <Select
                {...field}
                id='billing-select'
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                    onScroll: handleScroll,
                  },
                }}
                IconComponent={KeyboardArrowDownOutlined}
                className='h-[42px] border-none hover:border-gray-400'
                sx={{
                  "& .MuiSelect-select": {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  },
                }}
                renderValue={(selected: string) =>
                  locations.find(({ locationId }) => locationId === selected)?.address1
                }
              >
                {genericOptionList.map(({ label, locationId }) => (
                  <MenuItem key={locationId} value={locationId} divider>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error?.message && <span className='text-sm text-red-600'>{fieldState.error?.message}</span>}
            </>
          )}
        />
      </Box>
      <Box className='w-full'>
        <Controller
          name='office'
          control={control}
          render={({ field }) => (
            <>
              <label className='mb-2 block text-sm font-normal text-gray-700'>Office</label>
              <Select
                {...field}
                id='office-select'
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                    onScroll: handleScroll,
                  },
                }}
                IconComponent={() => (
                  <Box className='mr-2 flex items-center justify-center gap-1'>
                    {field.value && (
                      <IconButton onClick={() => field.onChange()} sx={{ marginLeft: 1 }}>
                        <CloseOutlined />
                      </IconButton>
                    )}
                    <KeyboardArrowDownOutlined />
                  </Box>
                )}
                className='h-[42px] border-none hover:border-gray-400'
                sx={{
                  "& .MuiSelect-select": {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  },
                }}
                renderValue={(selected: string) =>
                  locations.find(({ locationId }) => locationId === selected)?.address1
                }
              >
                {genericOptionList.map(({ label, locationId }) => (
                  <MenuItem key={locationId} value={locationId} divider>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </Box>
      <Box className='w-full'>
        <Controller
          name='shipVia'
          control={control}
          render={({ field }) => (
            <>
              <label className='mb-2 block text-sm font-normal text-gray-700'>Ship Via</label>
              <Select
                {...field}
                id='shipVia-select'
                fullWidth
                IconComponent={() => (
                  <Box className='mr-2 flex items-center justify-center gap-1'>
                    {field.value && (
                      <IconButton onClick={() => field.onChange()} sx={{ marginLeft: 1 }}>
                        <CloseOutlined />
                      </IconButton>
                    )}
                    <KeyboardArrowDownOutlined />
                  </Box>
                )}
                className='h-[42px] border-none hover:border-gray-400'
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                    onScroll: handleScroll,
                  },
                }}
                sx={{
                  "& .MuiSelect-select": {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                {companyNameList.map(({ name, companyId }) => (
                  <MenuItem key={companyId} value={companyId} divider>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </Box>
      <Box className='content-end'>
        <Button appearance='contained' type='submit' disabled={!isAssociateEnabled}>
          Associate
        </Button>
      </Box>
    </form>
  )
}

export default AssociatedLocation
