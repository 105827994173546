/**
 * Transforms the default values of a form to ensure that they are not null when passed to the
 * form. Converts all null values to empty strings.
 *
 * @param defaultValues The default values of the form
 */
export const transformFormDefaultValues = (defaultValues: Record<string, any | null>): Record<string, any> => {
  return Object.entries(defaultValues).reduce((acc, [key, value]) => {
    return {
      ...acc,
      // There seems to be a false-positive on this rule
      // eslint-disable-next-line eqeqeq
      [key]: value === null ? "" : value,
    }
  }, {})
}

export default transformFormDefaultValues
