import { Alert, AlertTitle, Box, Divider, Typography } from "@mui/material"
import type { AnyRoute } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"
import React, { useMemo } from "react"
import { useQuery } from "urql"

import WorkInProgressAlert from "@/components/common/Alerts/WorkInProgressAlert.tsx"
import type { Query } from "@/graphql/codegen/graphql.ts"
import { ProductListDocument } from "@/graphql/codegen/graphql.ts"
import { syntaxHighlight } from "@/utils/coloredJson.ts"

const ProductsComponent: React.FC = () => {
  const [{ data, error, fetching }] = useQuery<Pick<Query, "product">>({
    query: ProductListDocument,
    variables: {
      input: {
        filter: {
          query: "",
        },
        offset: 0,
        limit: 20,
      },
    },
  })

  const products = useMemo(() => {
    if (data?.product?.list?.__typename === "ProductListSuccess") {
      return data.product.list.products
    }
    return []
  }, [data?.product?.list])

  const highlightedJson = syntaxHighlight(products)

  return (
    <div className='w-full space-y-4 px-4'>
      <WorkInProgressAlert />
      <Divider />
      <Box className='flex flex-col space-y-2'>
        <Alert color={fetching ? "info" : error ? "error" : "success"}>
          <AlertTitle>Product Query With Auth Token</AlertTitle>
          Status:{" "}
          {fetching
            ? "Loading"
            : !fetching && (error ? `Loaded with errors. ${error?.message}` : "Successfully Loaded")}
        </Alert>
        <>
          <Typography variant='h5'>Products:</Typography>
          <pre
            style={{
              backgroundColor: "#282c34",
              color: "#ffffff",
              padding: "20px",
              borderRadius: "5px",
              overflowX: "auto",
            }}
            dangerouslySetInnerHTML={{ __html: highlightedJson }}
          />
        </>
      </Box>
    </div>
  )
}

export const Route: AnyRoute = createFileRoute("/inventory/products")({
  component: ProductsComponent,
})
