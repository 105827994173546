import { Box } from "@mui/material"
import { SearchOutlined } from "@mui-symbols-material/w300"
import React, { memo, useCallback, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"

import { InputField } from "@/components/common/InputField"
import type { ContactQueryableFields, ContactSubscription } from "@/graphql/codegen/graphql.ts"
import { SubscriptionCheckbox } from "@/screens/Companies/components/filters/contact/SubscriptionCheckbox.tsx"

export const ContactSubscriptionFilter = memo<{
  onFilterChange: (name: keyof ContactQueryableFields, value: ContactSubscription["contactSubscriptionId"][]) => void
  subscriptions: ContactSubscription[]
}>(({ onFilterChange, subscriptions }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { watch, setValue } = useFormContext()

  // Watch the current array of selected contact subscription IDs
  const contactSubscriptionsValue = watch("contactSubscriptions") || []

  // Filter subscriptions based on search term
  const filteredSubscriptions = useMemo(() => {
    const normalizedSearchTerm = searchTerm.trim().toLowerCase()
    return subscriptions.filter((sub) => sub.name.toLowerCase().includes(normalizedSearchTerm))
  }, [searchTerm, subscriptions])

  // Handle changes to the array of selected subscription IDs
  const handleFilterChange = useCallback(
    (newValue: ContactSubscription["contactSubscriptionId"][]) => {
      setValue("contactSubscriptions", newValue) // Update form state
      onFilterChange("contactSubscriptions", newValue) // Call the external handler
    },
    [setValue, onFilterChange]
  )

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box className='flex-none p-4'>
        <InputField
          placeholder='Search by Name'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={<SearchOutlined className='text-gray-600' />}
        />
      </Box>

      <Box className='flex max-h-72 grow flex-col overflow-y-auto px-8 py-2'>
        {/* Render filtered subscription checkboxes */}
        {filteredSubscriptions.map((sub) => (
          <SubscriptionCheckbox
            key={sub.contactSubscriptionId}
            subscription={sub}
            value={contactSubscriptionsValue} // Pass the array of selected values
            onChange={handleFilterChange} // Handle change for multiple selections
          />
        ))}
      </Box>
    </Box>
  )
})
