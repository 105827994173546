import { Box, CircularProgress, IconButton, Typography } from "@mui/material"
import { ChevronLeftOutlined, ContentCopyOutlined, DownloadOutlined, PrintOutlined } from "@mui-symbols-material/w300"
import { useRouter } from "@tanstack/react-router"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { usePurchaseOrderFile } from "../../hooks/usePurchaseOrderFile"
import { useUpdatePO } from "../DeliveryTermsAndShippingLegs/hooks/useUpdatePO"
import { usePurchaseOrderActions } from "../hooks/usePurchaseOrderActions"

import { NoContactsSubscribedModal } from "./NoContactsSubscribedModal"

import { Button } from "@/components/common/Button"
import { OrderStatus } from "@/graphql/codegen/graphql"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

type OptionalStatusList = Array<OrderStatus | null | undefined>

const CancelableStatuses: OptionalStatusList = [OrderStatus.Draft]
const SaveableStatuses: OptionalStatusList = [OrderStatus.Draft, OrderStatus.Sent]
const SendableStatuses: OptionalStatusList = [OrderStatus.Draft, OrderStatus.Sent]
const ClonableStatuses: OptionalStatusList = [OrderStatus.Sent, OrderStatus.Confirmed, OrderStatus.Closed]
const PrintableStatuses: OptionalStatusList = [OrderStatus.Sent, OrderStatus.Confirmed, OrderStatus.Closed]
const DownloadableStatuses: OptionalStatusList = [OrderStatus.Sent, OrderStatus.Confirmed, OrderStatus.Closed]

const downloadRemoteFile = async (url: string | null, name: string = "download") => {
  if (!url) return

  const fileFetchResponse = await fetch(url)
  const fileBlob = await fileFetchResponse?.blob?.()

  if (!fileBlob) return

  const fileURL = URL.createObjectURL(fileBlob)
  const fileLink = document.createElement("a")

  fileLink.href = fileURL
  fileLink.download = name
  fileLink.click()
  URL.revokeObjectURL(url)
}

export const PurchaseOrderHeaderActionsContainer: React.FC = () => {
  const router = useRouter()
  const { modifiedFields, currentPO: order, setCurrentPO } = usePurchaseOrderStore()
  const [noContactsSubscribedModalOpen, setNoContactsSubscribedModalOpen] = useState(false)
  const pdfFileUrl = usePurchaseOrderFile(order?.orderId)
  const orderRef = useRef(order)
  const { updatePO, fetching: updatingPO } = useUpdatePO()

  const requestSend = useCallback(() => {
    const flattenedModifiedFields = Object.values(modifiedFields).reduce((acc, curr) => {
      return { ...acc, ...curr }
    }, {})
    // update the PO on the server
    updatePO({
      input: {
        ...flattenedModifiedFields,
        orderId: Number(order?.orderId),
        status: OrderStatus.Sent,
      },
    })
    setNoContactsSubscribedModalOpen(true)
  }, [])

  const close = useCallback(() => {
    setNoContactsSubscribedModalOpen(false)
  }, [])

  const sendToSelf = useCallback(() => {
    alert("This feature is coming soon")
  }, [])

  const { status, updatedPOQueryData, fetching, handleSaveDraft, handleSaveAndExit, handleCancel, handleSend } =
    usePurchaseOrderActions()

  const downloadAndSend = useCallback(async () => {
    // Download the PDF file
    await downloadRemoteFile(pdfFileUrl, `PurchaseOrder-${order?.orderId}.pdf`)

    // Send the PO and close the modal
    handleSend()
    close()
  }, [close, handleSend, order?.orderId, pdfFileUrl])

  useEffect(() => {
    if (!updatedPOQueryData) {
      return
    }

    const updatedOrder = {
      ...orderRef.current,
      ...updatedPOQueryData,
    }

    orderRef.current = updatedOrder

    // Update the PO in the store
    setCurrentPO({ ...orderRef.current, ...updatedPOQueryData })
  }, [updatedPOQueryData, setCurrentPO])

  return (
    <Box className='flex items-center justify-between gap-4'>
      <NoContactsSubscribedModal
        open={noContactsSubscribedModalOpen}
        updatingPO={updatingPO}
        onClose={close}
        onSendToSelf={sendToSelf}
        onDownload={downloadAndSend}
      />
      <Box className='flex items-center gap-4'>
        <IconButton
          aria-label='back'
          className='text-primary-500 rounded-none bg-gray-200'
          onClick={() => router.history.back()}
        >
          <ChevronLeftOutlined />
        </IconButton>
        <Typography variant='h5' color='primary' fontWeight='normal'>
          Start Purchase Order
        </Typography>
      </Box>
      <Box className='flex items-center gap-2'>
        {PrintableStatuses.includes(order?.status) && (
          <Button appearance='text' startIcon={<PrintOutlined />}>
            Print
          </Button>
        )}
        {DownloadableStatuses.includes(order?.status) && (
          <Button appearance='text' startIcon={<DownloadOutlined />}>
            Download
          </Button>
        )}
        {ClonableStatuses.includes(order?.status) && (
          <Button appearance='text' startIcon={<ContentCopyOutlined />}>
            Clone
          </Button>
        )}
        {CancelableStatuses.includes(order?.status) && (
          <Button appearance='text' onClick={handleCancel}>
            Cancel PO
          </Button>
        )}
        {SaveableStatuses.includes(order?.status) && (
          <Button
            appearance='outlined'
            onClick={status === OrderStatus.Draft ? handleSaveDraft : handleSaveAndExit}
            endIcon={
              fetching ? (
                <Box className='flex items-center'>
                  <CircularProgress size={16} />
                </Box>
              ) : undefined
            }
          >
            {status === OrderStatus.Draft ? "Save Draft" : "Save and Exit"}
          </Button>
        )}
        {SendableStatuses.includes(order?.status) && (
          <Button
            appearance='contained'
            onClick={requestSend}
            disabled={!pdfFileUrl || fetching}
            endIcon={
              fetching ? (
                <Box className='flex items-center'>
                  <CircularProgress size={16} className='text-white' />
                </Box>
              ) : undefined
            }
          >
            {order?.status === OrderStatus.Draft ? "Send" : "Re-Send"}
          </Button>
        )}
      </Box>
    </Box>
  )
}
