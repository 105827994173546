import { Box } from "@mui/material"
import { PhotoOutlined } from "@mui-symbols-material/w300"
import { type ReactElement } from "react"
import { twMerge } from "tailwind-merge"

interface ImagePreviewProps {
  url: string
  onClick?: () => void
  className?: string
}

export const ImagePreview = ({ url, onClick, className }: ImagePreviewProps): ReactElement => (
  <Box
    onClick={onClick}
    className={twMerge(
      "relative flex size-[40px] cursor-pointer items-center justify-center rounded-md border border-gray-300",
      className
    )}
  >
    {url ? (
      <img src={url} alt='Preview' className='size-full rounded-md object-cover' />
    ) : (
      <PhotoOutlined className='text-primary-300' />
    )}
  </Box>
)
