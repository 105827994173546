import { z } from "zod"

export const AddContactSubscriptionSchema = z.object({
  batchContactSubscriptionsToCreate: z.array(
    z.object({
      contactSubscriptionId: z.string().min(1),
      name: z.string().min(1),
    })
  ),
})
