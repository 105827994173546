import { type ReactElement } from "react"

import { FileIconTemplate } from "./FileIconTemplate"

interface JPEGIconProps {
  className?: string
}

export const JPEGIcon = ({ className }: JPEGIconProps): ReactElement => (
  <FileIconTemplate className={className} text='JPEG' stripeColor='#F57C00' />
)
