import { Box, Typography } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

import type { ContentProps } from "../types"

export const DefaultHover = ({ fileUrl }: ContentProps): ReactElement => {
  if (!fileUrl) return <></>

  return (
    <Box
      className='absolute inset-0 flex items-center justify-center bg-black/50 transition-opacity duration-200'
      role='button'
      tabIndex={0}
      aria-label='Edit file'
    >
      <Box className='flex flex-col items-center gap-2'>
        <EditOutlined className='text-4xl text-white' />
        <Typography variant='body2' className='text-white'>
          Change file
        </Typography>
      </Box>
    </Box>
  )
}
