import { Box, MenuItem, OutlinedInput, Select, Typography } from "@mui/material"
import { AddOutlined, CheckCircleFilledRounded, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import { useMemo } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"

import { useLocations } from "../../hooks/useLocations"

import { Button } from "@/components/common/Button"
import type { ContactCreateInput, ContactLocationCreateInput } from "@/graphql/codegen/graphql"
import { ContactRole } from "@/graphql/codegen/graphql"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const AddLocationRoles: FC = () => {
  const { control, getValues } = useFormContext<ContactCreateInput | ContactLocationCreateInput>()
  const {
    companyLocations: { locations },
  } = useLocations()
  const { fields, append, update } = useFieldArray({
    control,
    name: "locationRoles",
  })

  const handleChange = () => {
    fields.forEach((_, idx) => {
      const currentField = getValues(`locationRoles.${idx}`)
      update(idx, currentField)
    })
  }

  const formData = useMemo(() => getValues(), [])

  return (
    <form className='space-y-6'>
      <Box className='space-y-4'>
        <Box className='mb-2' display='flex' alignItems='center'>
          <Typography
            variant='body1'
            fontWeight={700}
            className='leading-6 tracking-[0.15px] text-gray-700'
            display='flex'
            alignItems='center'
          >
            1. General Information
            <CheckCircleFilledRounded className='ml-1 text-success-500' />
          </Typography>
        </Box>
        <Box className='px-10'>
          <p className='py-0 text-sm text-gray-900'>First Name: {formData.firstName}</p>
          <p className='py-0 text-sm text-gray-900'>Last Name: {formData.lastName}</p>
          {formData.companyDepartment && (
            <p className='py-0 text-sm text-gray-900'>Company Department: {formData.companyDepartment}</p>
          )}
          {formData.title && <p className='py-0 text-sm text-gray-900'>Title: {formData.title}</p>}
          {formData?.officeLocation && (
            <p className='py-0 text-sm text-gray-900'>Office Location: {formData.officeLocation}</p>
          )}
        </Box>
      </Box>
      <Box className='space-y-4'>
        <Box className='mb-2' display='flex' alignItems='center'>
          <Typography
            variant='body1'
            fontWeight={700}
            className='leading-6 tracking-[0.15px] text-gray-700'
            display='flex'
            alignItems='center'
          >
            2. Contact Methods
            <CheckCircleFilledRounded className='ml-1 text-success-500' />
          </Typography>
        </Box>
        <Box className='px-10'>
          {(formData.phoneNumber ?? []).map((item, index) => (
            <Box key={index} className='space-y-2'>
              <p className='py-0 text-sm text-gray-900'>
                Phone{`(${item.type ?? "Text"})`}:{" "}
                {item.number ? `${item.number} ${item.isPreferred ? "Preferred" : ""}` : "Not added"}
              </p>
            </Box>
          ))}
          <p className='py-0 text-sm text-gray-900'>Email: {formData.email || "Not added"}</p>
        </Box>
      </Box>
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        3. Locations Roles
      </Typography>
      <Box className='space-y-10'>
        {fields.map((field, index) => (
          <div key={field.id}>
            <Controller
              name={`locationRoles.${index}.locationId`}
              control={control}
              render={({ field }) => (
                <Box className='relative'>
                  <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Location</label>
                  <Select
                    {...field}
                    displayEmpty
                    input={<OutlinedInput />}
                    SelectDisplayProps={{
                      className:
                        "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                    }}
                    classes={{
                      icon: "text-gray-600",
                    }}
                    IconComponent={KeyboardArrowDownOutlined}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    variant='outlined'
                    onChange={(event) => {
                      field.onChange(event)
                      handleChange()
                    }}
                    fullWidth
                  >
                    {locations.map((location) => (
                      <MenuItem key={location.locationId} value={location.locationId}>
                        {`${location.address1} - ${location.city} ${location.regionIsoCode}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />
            <Controller
              name={`locationRoles.${index}.role`}
              control={control}
              render={({ field }) => (
                <Box className='relative'>
                  <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Role</label>
                  <Select
                    {...field}
                    displayEmpty
                    input={<OutlinedInput />}
                    SelectDisplayProps={{
                      className:
                        "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                    }}
                    classes={{
                      icon: "text-gray-600",
                    }}
                    IconComponent={KeyboardArrowDownOutlined}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    variant='outlined'
                    fullWidth
                  >
                    {Object.keys(ContactRole).map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />
          </div>
        ))}
        <Box className='flex w-full justify-end'>
          <Button
            variant='primary'
            appearance='text'
            startIcon={<AddOutlined />}
            onClick={() => append({ locationId: "", role: "" })}
          >
            Role At Location
          </Button>
        </Box>
      </Box>
    </form>
  )
}
