import { Box, CircularProgress, Fade, FormControl, MenuItem, Select, TextField } from "@mui/material"
import { HelpOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { Row } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { useCallback, useEffect } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useShallow } from "zustand/shallow"

import { PurchaseOrderSection } from "../../types"

import { POUND_UOM_ID } from "./getPurchaseOrderProductTableColumns"

import type { OrderLine, OrderUpdateInput } from "@/graphql/codegen/graphql"
import { useGetUnitOfMeasurementList } from "@/screens/Products/hooks/useGetUnitOfMeasurementList"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

export const OrderQuantityColumn = ({ row }: { row: Row<OrderLine> }): ReactElement => {
  const {
    orderLines = [],
    updateModifiedFields,
    updateSectionFields,
  } = usePurchaseOrderStore(
    useShallow((state) => ({
      orderLines: state.formValues[PurchaseOrderSection.ORDER_LINES],
      updateModifiedFields: state.updateModifiedFields,
      updateSectionFields: state.updateSectionFields,
    }))
  )

  const { unitOfMeasurementList, unitOfMeasurementListFetching } = useGetUnitOfMeasurementList()
  // Fetching only pounds and kilograms
  const filteredUoM = unitOfMeasurementList.filter(({ name }) => name === "lb" || name === "kg")

  const methods = useForm({
    defaultValues: orderLines?.length > 0 ? orderLines[row.index] : [],
  })
  const { control, setValue, getValues, watch } = methods
  const uomId = watch(`uomId`)

  const handleChange = useCallback(
    ({ value, name }: { value: string | number; name: string }) => {
      const isPounds = uomId === POUND_UOM_ID
      const uomFactor = isPounds ? 120 : 54.4311

      const getUpdatedValues = () => {
        if (name === "numberOfPallets") {
          const pallets = parseFloat(value.toString())
          return {
            numberOfPallets: pallets,
            quantityInUom: pallets * uomFactor,
          }
        } else if (name === "quantityInUom") {
          const weight = parseFloat(value.toString())
          return {
            quantityInUom: weight,
            numberOfPallets: weight / uomFactor,
          }
        } else if (name === "uomId") {
          const newIsPounds = value === POUND_UOM_ID
          const newUomFactor = newIsPounds ? 120 : 54.4311
          const currentQuantity = getValues("quantityInUom")
          return {
            uomId: value,
            quantityInUom: currentQuantity,
            numberOfPallets: currentQuantity / newUomFactor,
          }
        }
        return { [name]: value }
      }

      const updatedValues = getUpdatedValues()
      setValue("numberOfPallets", parseInt(updatedValues.numberOfPallets))
      setValue("quantityInUom", parseFloat(updatedValues.quantityInUom))
      setValue("uomId", updatedValues.uomId || getValues("uomId"))
    },
    [methods]
  )
  const handleFormDataUpdate = useCallback(
    (formData: Partial<OrderLine>) => {
      if (!formData) return
      const updatedOrderLine = {
        productId: formData.productId,
        quantityInUom: formData.quantityInUom,
        uomId: formData.uomId,
        numberOfPallets: formData.numberOfPallets,
      }
      const updatedOrderLines = orderLines?.map((line) =>
        line.productId === row.original.productId ? { ...line, ...formData, ...updatedOrderLine } : line
      )
      updateSectionFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines as OrderLine[])

      updateModifiedFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines as Partial<OrderUpdateInput>)
    },
    [updateSectionFields, updateModifiedFields]
  )

  useEffect(() => {
    if (orderLines?.length === 0) return

    const subscription = methods.watch(handleFormDataUpdate)
    return () => subscription.unsubscribe()
  }, [orderLines, methods, handleFormDataUpdate])

  return (
    <FormProvider {...methods}>
      {unitOfMeasurementListFetching ? (
        <Box className='flex items-center justify-center'>
          <CircularProgress size={56} />
        </Box>
      ) : (
        <Fade in={Boolean(unitOfMeasurementList?.length)} unmountOnExit timeout={500}>
          <Box className='align-center flex gap-4'>
            <Controller
              name={`numberOfPallets`}
              control={control}
              render={({ field }) => (
                <Box className='relative w-[120px]'>
                  <label className='mb-2 block text-sm font-thin text-gray-700'>Pallets</label>
                  <TextField
                    {...field}
                    fullWidth
                    variant='outlined'
                    type='number'
                    onChange={(e) => handleChange({ value: e.target.value, name: "numberOfPallets" })}
                    InputProps={{
                      classes: {
                        input:
                          "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                      },
                      inputProps: {
                        className: "hide-stepper",
                      },
                    }}
                  />
                </Box>
              )}
            />
            <Controller
              name={`quantityInUom`}
              control={control}
              render={({ field }) => (
                <Box className='relative w-[200px]'>
                  <label className='mb-2 block text-sm font-thin text-gray-700'>Quantity</label>
                  <TextField
                    {...field}
                    fullWidth
                    variant='outlined'
                    type='number'
                    slotProps={{
                      input: {
                        endAdornment: (
                          <Box className='align-end flex gap-1'>
                            <HelpOutlined />
                            <Controller
                              name={`uomId`}
                              control={control}
                              render={({ field }) => (
                                <FormControl variant='outlined' size='small'>
                                  <Select
                                    sx={{
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                      },
                                      "&:hover .MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                      },
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                      },
                                      minWidth: "60px",
                                      "& .MuiSelect-select": {
                                        padding: "2px 4px",
                                      },
                                    }}
                                    {...field}
                                    displayEmpty
                                    value={field.value || POUND_UOM_ID}
                                    IconComponent={KeyboardArrowDownOutlined}
                                    MenuProps={{
                                      PaperProps: {
                                        style: { maxHeight: 48 * 4.5 + 8, width: 60 },
                                      },
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                    onChange={(e) => handleChange({ value: e.target.value, name: "uomId" })}
                                  >
                                    {filteredUoM.map(({ id, name }) => (
                                      <MenuItem key={id} value={id}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Box>
                        ),
                        classes: {
                          input:
                            "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                        },
                        inputProps: {
                          className: "hide-stepper",
                        },
                      },
                    }}
                    onChange={(e) => handleChange({ value: e.target.value, name: "quantityInUom" })}
                    InputProps={{
                      classes: {
                        input:
                          "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                      },
                      inputProps: {
                        className: "hide-stepper",
                      },
                    }}
                  />
                </Box>
              )}
            />
          </Box>
        </Fade>
      )}
    </FormProvider>
  )
}
