import { useCallback } from "react"
import { type ConnectDropTarget, useDrop } from "react-dnd"
import { NativeTypes } from "react-dnd-html5-backend"

interface UseDragAndDropProps {
  onDrop: (file: File) => void
  accept?: string
  maxSize?: number
  enabled?: boolean
}

interface DragAndDropHook {
  dropRef: ConnectDropTarget
  isOver: boolean
  canDrop: boolean
}

export const useDragAndDrop = ({ onDrop, accept, maxSize, enabled = true }: UseDragAndDropProps): DragAndDropHook => {
  const validateFile = useCallback(
    (file: File): boolean => {
      // Validate file type
      if (accept && !accept.split(",").some((type) => file.type.match(new RegExp(type.trim().replace("*", ".*"))))) {
        console.error("File type not accepted")
        return false
      }

      // Validate file size
      if (maxSize && file.size > maxSize) {
        console.error("File size exceeds limit")
        return false
      }

      return true
    },
    [accept, maxSize]
  )

  const handleDrop = useCallback(
    (item: { files: File[] }) => {
      if (!enabled) return false

      const file = item.files[0]
      if (!file) return false

      if (!validateFile(file)) return false

      onDrop(file)
      return true
    },
    [onDrop, validateFile, enabled]
  )

  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: (item: { files: File[] }) => handleDrop(item),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && enabled,
      }),
      canDrop: (item: { files: File[] }) => {
        const file = item.files[0]
        return enabled && file && validateFile(file)
      },
    }),
    [handleDrop, validateFile, enabled]
  )

  return {
    dropRef,
    isOver,
    canDrop,
  }
}
