import type { AppBarProps } from "@mui/material/AppBar"
import AppBar from "@mui/material/AppBar"
import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { NotificationsOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"

import { Avatar } from "@/components/common/Avatar"
import Breadcrumbs from "@/components/common/Breadcrumbs/Breadcrumbs.tsx"
import type { AppHeaderProps } from "@/components/common/Header/AppHeaderSchema.tsx"
import { AppHeaderSchema } from "@/components/common/Header/AppHeaderSchema.tsx"
import akrochemLogo from "@/images/akrochem_logo.svg"
import { stringAvatar } from "@/utils/utils.ts"

const AppHeader: FC<AppHeaderProps & AppBarProps> = (props) => {
  const parsedProps = AppHeaderSchema.parse(props)
  const { user, notifications, breadcrumbs, pathname, ...rest } = parsedProps

  return (
    <AppBar color='inherit' elevation={0} {...props} {...rest}>
      <Toolbar disableGutters>
        <Box sx={{ display: "flex", alignItems: "center", mr: 7 }}>
          <img src={akrochemLogo} alt='placeholder logo' width={220} height={56} />
        </Box>
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <Breadcrumbs items={breadcrumbs} currentPath={pathname} maxItems={3} />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex" }} className='mr-[28px]'>
          <IconButton size='large' aria-label={`show ${notifications} new notifications`} color='inherit'>
            <Badge badgeContent={notifications} color='error'>
              <NotificationsOutlined color='primary' />
            </Badge>
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 0 }} className='space-x-2'>
          <Box className='pointer-events-none flex items-center space-x-2'>
            <IconButton sx={{ p: 0 }}>
              <Avatar alt={user.name} src={user.avatar} size={40} {...stringAvatar(user.name)} />
            </IconButton>
            <Typography variant='body1' color='primary'>
              {user.name}
            </Typography>
          </Box>
          {/* Avatar Menu Goes Here */}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
