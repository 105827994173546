const colors = {
  primary: {
    900: "#011322",
    800: "#011C33",
    700: "#002A50",
    600: "#003D72",
    main: "#004B8D", // base
    400: "#4A85B8",
    300: "#77A3CA",
    200: "#D2E3F3",
    100: "#F1F8FF",
  },
  secondary: {
    900: "#400104",
    800: "#690105",
    700: "#9E080F",
    600: "#C91A22",
    main: "#EF3B43", // base
    400: "#ED5158",
    300: "#ED5158",
    200: "#F7CCCE",
    100: "#FFEFEF",
  },
  grey: {
    100: "#F6F8F9",
    200: "#F6F8F9",
    300: "#ECEFF1",
    400: "#CFD8DC",
    500: "#B0BEC5",
    600: "#78909C",
    700: "#455A64",
    800: "#37474F",
    900: "#263238",
  },
  tertiary: {
    green: {
      100: "#E6FDEE",
      300: "#9EEFA6",
      500: "#1BC336",
      700: "#11A142",
      900: "#107020",
    },
    yellow: {
      100: "#FFF9DF",
      300: "#FFF0B0",
      500: "#FDD835",
      700: "#DBB81F",
      900: "#77640F",
    },
    orange: {
      100: "#FFF2E2",
      300: "#FBDDB1",
      500: "#FF852D",
      700: "#DB841F",
      900: "#9C630D",
    },
  },
  blue: {
    grey: {
      600: "#546E7A",
    },
  },
}

export default colors
