import { useCallback, useState } from "react"

import type { FieldGroupEnum, ReferenceTableName } from "@/graphql/codegen/graphql"
import { useFileUpload } from "@/hooks/useFileUpload"

interface UseImageUploadProps {
  referenceTableName: ReferenceTableName
  type: FieldGroupEnum
  getReferenceId: () => string
}

type UploadState = Record<FieldGroupEnum, boolean>

interface ImageUploadHook {
  uploading: UploadState
  handleGetSignedUrl: (file: File, type: FieldGroupEnum) => Promise<string | null>
  handleUploadToS3: (signedUrl: string, type: FieldGroupEnum, file: File) => Promise<boolean>
}

export const useImageUpload = ({ referenceTableName, getReferenceId }: UseImageUploadProps): ImageUploadHook => {
  const { getSignedUrl, uploadToS3 } = useFileUpload()
  const [uploading, setUploading] = useState<UploadState>({} as UploadState)

  const handleGetSignedUrl = useCallback(
    async (file: File, type: FieldGroupEnum) => {
      if (!file.type.startsWith("image/")) {
        throw new Error("Only image files are allowed.")
      }

      try {
        const signedUrl = await getSignedUrl({
          file,
          type: "image",
          metadata: {
            fieldGroup: type,
            referenceId: getReferenceId(),
            referenceTableName,
          },
        })

        return signedUrl
      } catch (error) {
        console.error("Failed to get signed URL:", error)
        return null
      }
    },
    [getSignedUrl, getReferenceId, referenceTableName]
  )

  const handleUploadToS3 = useCallback(
    async (signedUrl: string, type: FieldGroupEnum, file: File) => {
      setUploading((prev) => ({ ...prev, [type]: true }))
      try {
        await uploadToS3(signedUrl, file)
        return true
      } catch (error) {
        console.error("Failed to upload to S3:", error)
        return false
      } finally {
        setUploading((prev) => ({ ...prev, [type]: false }))
      }
    },
    [uploadToS3]
  )

  return {
    uploading,
    handleGetSignedUrl,
    handleUploadToS3,
  }
}
