import { Box } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import { type ReactElement, useMemo } from "react"
import { useShallow } from "zustand/shallow"

import { useShippingLegs } from "../hooks/shippingLegs/useShippingLegs"
import { useShippingLegsForm } from "../hooks/shippingLegs/useShippingLegsForm"

import { ShippingLegsForm } from "./ShippingLegsForm"

import { Button } from "@/components/common/Button"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

export const ShippingLegsContainer = (): ReactElement => {
  const {
    shipFromOptions,
    shipViaOptions,
    shipToOptions,
    shipmentStatusOptions,
    loadMoreShipVia,
    loadMoreShipTo,
    loading,
  } = useShippingLegs()

  const { methods, fields, handleAddShippingLeg, handleRemove } = useShippingLegsForm()

  const deliveryTerms = usePurchaseOrderStore(useShallow((state) => state.formValues.DELIVERY_TERMS))
  const isDeliveryTermsFilled = useMemo(() => Object.values(deliveryTerms).every(Boolean), [deliveryTerms])

  const shippingLegs = methods.watch("shippingLegs")
  const isCurrentShippingLegFilled =
    shippingLegs.length > 0 ? Object.values(shippingLegs[shippingLegs.length - 1]).every(Boolean) : true

  return (
    <>
      <Box className='flex justify-end'>
        <Button
          appearance='outlined'
          startIcon={<AddOutlined />}
          onClick={handleAddShippingLeg}
          disabled={!isDeliveryTermsFilled || !isCurrentShippingLegFilled}
        >
          Shipping Leg
        </Button>
      </Box>
      {fields.map((field, index) => (
        <ShippingLegsForm
          key={field.id}
          methods={methods}
          index={index}
          shipFromOptions={shipFromOptions}
          shipViaOptions={shipViaOptions}
          shipToOptions={shipToOptions}
          shipmentStatusOptions={shipmentStatusOptions}
          isLast={index === fields.length - 1}
          loadMoreShipFrom={loadMoreShipTo}
          loadMoreShipVia={loadMoreShipVia}
          loadMoreShipTo={loadMoreShipTo}
          loading={loading}
          onRemove={() => handleRemove(index)}
        />
      ))}
    </>
  )
}
