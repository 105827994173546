import { Box, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"

import type { CompanyDepartment, ContactQueryableFields } from "@/graphql/codegen/graphql"
import { StatusChip } from "@/screens/Companies/components/filters/location/StatusChip.tsx"

export const LocationSearchFilterChips: React.FC = () => {
  const navigate = useNavigate({ from: "/companies/" })
  const { isLocationActive } = useSearch({
    from: "/companies/",
    strict: true,
  })

  const handleRemoveFilter = (key: keyof ContactQueryableFields, value: string | CompanyDepartment | boolean) => {
    void navigate({
      search: (prev: Record<string, unknown>) => {
        const { isLocationActive, ...newFilter } = prev
        if (Array.isArray(newFilter[key])) {
          newFilter[key] = (newFilter[key] as Array<string | CompanyDepartment | boolean>).filter(
            (item) => item !== value
          )
          if ((newFilter[key] as Array<string | CompanyDepartment | boolean>).length === 0) {
            delete newFilter[key] // Remove the filter entirely if it's empty
          }
        } else {
          delete newFilter[key]
        }
        return newFilter
      },
      replace: true,
    })
  }

  if (isLocationActive === undefined) {
    return null
  }

  return (
    <Box data-testid='location-search-filter-chips' display='flex' flexWrap='wrap' alignItems='center' gap={2}>
      <Typography variant='body1'>Filters:</Typography>
      <StatusChip isActive={isLocationActive} onRemove={(_, value: boolean) => handleRemoveFilter("isActive", value)} />
    </Box>
  )
}
