import { useMemo } from "react"
import { useShallow } from "zustand/shallow"

import { useGetShipToQuery } from "../useGetShipToQuery"
import { useGetShipViaQuery } from "../useGetShipViaQuery"

import { SHIPMENT_STATUS_OPTIONS } from "@/constants/freighTerms"
import type { Company, Location } from "@/graphql/codegen/graphql"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"
import type { SelectOption } from "@/types/form-fields"
import { uniqueArrayOfObjects } from "@/utils/utils"

type UseShippingLegs = {
  shipFromOptions: SelectOption[]
  shipViaOptions: SelectOption[]
  shipToOptions: SelectOption[]
  shipmentStatusOptions: SelectOption[]
  loadMoreShipVia: () => void
  loadMoreShipTo: () => void
  loading: boolean
}

const convertCompanyToSelectOption = (company: Company): SelectOption => ({
  label: company.name ?? "",
  value: company.companyId,
})

const convertLocationToSelectOption = (location: Location): SelectOption => ({
  label: location.name ?? "",
  value: location.locationId,
})

export const useShippingLegs = (): UseShippingLegs => {
  const { destinationCompanyId } = usePurchaseOrderStore(
    useShallow((s) => ({
      destinationCompanyId: s.currentPO?.destinationLocationAssociationId,
    }))
  )

  if (!destinationCompanyId) {
    throw new Error("destinationCompanyId is required")
  }

  const { data: shipViaOptions, loadMore: loadMoreShipVia, fetching: shipViaFetching } = useGetShipViaQuery()
  const { data: shipToOptions, loadMore: loadMoreShipTo, fetching: shipToFetching } = useGetShipToQuery()

  const filteredShipToOptions = useMemo(() => {
    if (!shipToOptions.length) return []

    return uniqueArrayOfObjects(shipToOptions.map(convertLocationToSelectOption), "value") as SelectOption[]
  }, [shipToOptions])

  const filteredShipViaOptions = useMemo(() => {
    if (!shipViaOptions.length) return []

    return uniqueArrayOfObjects(shipViaOptions.map(convertCompanyToSelectOption), "value") as SelectOption[]
  }, [shipViaOptions])

  const loading = useMemo(() => {
    return shipViaFetching || shipToFetching
  }, [shipViaFetching, shipToFetching])

  return {
    shipFromOptions: filteredShipToOptions,
    shipViaOptions: filteredShipViaOptions,
    shipToOptions: filteredShipToOptions,
    shipmentStatusOptions: SHIPMENT_STATUS_OPTIONS,
    loadMoreShipVia,
    loadMoreShipTo,
    loading,
  }
}
