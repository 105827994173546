import type { TableHeadProps } from "@mui/material"
import { TableHead } from "@mui/material"

/**
 * Header section of the table.
 * Should contain AerosTableHeadRow component.
 *
 * @example
 * ```tsx
 * <AerosTableHead>
 *   <AerosTableHeadRow>
 *     <AerosTableCell>Header 1</AerosTableCell>
 *     <AerosTableCell>Header 2</AerosTableCell>
 *   </AerosTableHeadRow>
 * </AerosTableHead>
 * ```
 *
 * @param props - Extends MUI TableHeadProps
 */
export const AerosTableHead = (props: TableHeadProps): JSX.Element => {
  return <TableHead {...props}></TableHead>
}
