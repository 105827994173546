import { Box, Collapse, Divider, IconButton, Paper, Typography } from "@mui/material"
import {
  AddOutlined,
  EditOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  VisibilityOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { Fragment, useCallback, useMemo, useState } from "react"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import type { CommentListSuccess } from "@/graphql/codegen/graphql"
import { CommentParentTableType } from "@/graphql/codegen/graphql"
import { useGetComments } from "@/screens/Companies/hooks/useGetComments"
import AddContactComment from "@/screens/Contacts/edit/components/AddContactComment"
import { EditComment } from "@/screens/Contacts/edit/components/ContactComments"
import { useGetProduct } from "@/screens/Products/hooks/useGetProduct"

export const ProductCommentsContainer = (): ReactElement => {
  const [expanded, setExpanded] = useState(true)
  const { productId: queryProductId } = useParams({
    from: "/product/$productId/edit",
  })
  // FIXME: we must not fetch all product details here
  const { productDetails } = useGetProduct(Number(queryProductId))
  const { productManager } = productDetails ?? {}
  const { commentDetails, updateComment, reexecuteCommentList } = useGetComments(
    queryProductId,
    CommentParentTableType.Product
  )
  const commentList = (commentDetails?.comment?.list as CommentListSuccess)?.comments || []
  const [showAddComment, setShowAddComment] = useState(false)
  const [editCommentId, setEditCommentId] = useState<string>("")

  const productManagerName = useMemo(
    () =>
      productManager?.firstName && productManager?.lastName
        ? `${productManager.firstName} ${productManager.lastName}`
        : undefined,
    [productManager]
  )

  const handleUpdateComment = useCallback(
    async (commentId: string, value: string) => {
      await updateComment({
        input: {
          commentId,
          value,
        },
      })
    },
    [updateComment]
  )

  const handleEditComment = async (commentId: string, value: string) => {
    setEditCommentId("")
    await handleUpdateComment(commentId, value)
    reexecuteCommentList()
  }
  return (
    <Paper className='border border-gray-300 p-6' elevation={0}>
      <Box className='flex items-center justify-between'>
        <Typography variant='h6' className={expanded ? "mb-6" : ""}>
          Comments
        </Typography>
        <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label='show more'>
          {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        {commentList.length === 0 ? (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Add Comments'
            action={
              <Button
                color='inherit'
                size='small'
                appearance='outlined'
                className='text-primary-500'
                startIcon={<AddOutlined />}
                onClick={() => setShowAddComment(true)}
              >
                Comments
              </Button>
            }
            description={""}
          />
        ) : (
          <>
            <Box className='flex items-start gap-4 px-0'>
              <Button
                variant='primary'
                appearance='text'
                className='pl-0'
                startIcon={<AddOutlined />}
                onClick={() => setShowAddComment(true)}
              >
                New Comment
              </Button>
              <Button variant='primary' appearance='text' startIcon={<VisibilityOutlined />}>
                Archived Comments
              </Button>
            </Box>
            <Box className='w-full items-center justify-between py-3'>
              {commentList.map(({ updatedAt, updatedBy, value, commentId }) =>
                value ? (
                  <Fragment key={updatedAt}>
                    <Divider />
                    {editCommentId === commentId ? (
                      <EditComment value={value} commentId={commentId} onEditComment={handleEditComment} />
                    ) : (
                      <Box className='group relative pl-3 pt-3 hover:bg-primary-100'>
                        <Box className='group flex w-full justify-between'>
                          <Typography variant='caption' className='text-gray-600'>
                            Posted: {updatedAt} By {updatedBy}
                          </Typography>
                          <IconButton
                            color='primary'
                            aria-label='edit comment'
                            size='small'
                            className='absolute right-2.5 top-0.5 hidden rounded-none group-hover:block'
                            onClick={() => setEditCommentId(commentId ?? "")}
                          >
                            <EditOutlined className='mr-1' />
                            <Typography variant='caption' className='text-xs'>
                              Edit
                            </Typography>
                          </IconButton>
                        </Box>
                        <Box>
                          <Typography variant='body2' className='mt-2 truncate pb-3 text-gray-800'>
                            {value}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Fragment>
                ) : null
              )}
            </Box>
          </>
        )}
      </Collapse>
      <AddContactComment
        open={showAddComment}
        name={productManagerName}
        contactId={queryProductId}
        type={CommentParentTableType.Product}
        onClose={() => {
          setShowAddComment(false)
        }}
        onSubmit={() => {
          setShowAddComment(false)
          reexecuteCommentList()
        }}
      />
    </Paper>
  )
}
