import { Box, Typography } from "@mui/material"
import { PictureAsPdfOutlined, UploadFileFilledOutlined, UploadFileOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"
import { PDFIcon } from "shared/assets/icons/PDFIcon"

import type { ContentProps, OverlayProps } from "../types"

export const PDFTemplates = {
  content: ({ fileUrl, label }: ContentProps & { label?: string; onDelete?: () => void }): ReactElement => (
    <Box className='flex size-full flex-col items-center justify-center gap-2'>
      {fileUrl ? (
        <PDFIcon className='size-12' />
      ) : (
        <>
          <PictureAsPdfOutlined fontSize='large' className='text-primary-400' />
          <Typography variant='caption' color='textSecondary' className='text-center'>
            {label || "Click to upload PDF"}
          </Typography>
        </>
      )}
    </Box>
  ),
  overlay: ({ isOver, canDrop }: OverlayProps): ReactElement => (
    <Box
      className={`pointer-events-none absolute inset-0 z-20 flex items-center justify-center rounded-lg border-2 border-dashed transition-all duration-200 ${isOver && canDrop ? "border-primary-500 bg-white/90" : "border-transparent bg-transparent"}`}
    >
      <Box
        className={`flex flex-col items-center gap-4 transition-opacity duration-200 ${isOver && canDrop ? "opacity-100" : "opacity-0"}`}
      >
        <UploadFileFilledOutlined className='text-6xl text-primary' />
        <Typography variant='body1' className='text-primary'>
          Drop PDF here
        </Typography>
      </Box>
    </Box>
  ),
  hover: ({ fileUrl }: ContentProps): ReactElement => (
    <>
      {fileUrl && (
        <Box
          className='absolute inset-0 z-10 flex items-center justify-center bg-transparent transition-opacity duration-200'
          role='button'
          tabIndex={0}
          aria-label='Change PDF'
        >
          <Box className='flex flex-col items-center gap-2'>
            <Box className='flex size-12 items-center justify-center rounded-full bg-gray-100/90'>
              <UploadFileOutlined fontSize='large' className='text-gray-900' />
            </Box>
          </Box>
        </Box>
      )}
    </>
  ),
}
