import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback } from "react"
import type { z } from "zod"

import { SearchFilterDrawer } from "@/components/common/SearchFilterDrawer/SearchFilterDrawer.tsx"
import { CompanySearchFilter } from "@/screens/Companies/components/filters/company/CompanySearchFilter.tsx"
import { ContactSearchFilter } from "@/screens/Companies/components/filters/contact/ContactSearchFilter.tsx"
import { LocationSearchFilter } from "@/screens/Companies/components/filters/location/LocationSearchFilter.tsx"
import { SearchCategory } from "@/screens/Companies/types/enums.ts"
import { CompanyFilterSchema, ContactFilterSchema, LocationFilterSchema } from "@/screens/Companies/types/filters.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"

type SearchFilterProps = {
  showFilterDrawer: boolean
  toggleDrawer: () => void
}

type BaseFilterSchema = {
  query: string
  field: string
  order: string
  category: SearchCategory
}

type FilterSchema =
  | (z.infer<typeof CompanyFilterSchema> & BaseFilterSchema)
  | (z.infer<typeof ContactFilterSchema> & BaseFilterSchema)
  | (z.infer<typeof LocationFilterSchema> & BaseFilterSchema & { isLocationActive?: boolean })

export const FilterDrawer: FC<SearchFilterProps> = ({ showFilterDrawer, toggleDrawer }) => {
  const navigate = useNavigate({ from: "/companies/" })
  const currentSearch = useSearch({
    from: "/companies/",
    strict: true,
  })

  const handleClearFilters = useCallback(() => {
    const { query, field, order, category } = currentSearch
    let clearedParams: FilterSchema = { query, field, order, category } as FilterSchema

    switch (category) {
      case SearchCategory.COMPANY:
        clearedParams = { ...CompanyFilterSchema.parse(clearedParams), field, order, category }
        break
      case SearchCategory.CONTACT:
        clearedParams = { ...ContactFilterSchema.parse(clearedParams), field, order, category }
        break
      case SearchCategory.LOCATION:
        clearedParams = { ...LocationFilterSchema.parse(clearedParams), field, order, category }
        break
      default:
        clearedParams = { query, field, order, category }
    }

    const params = getRefinedSearchParams(clearedParams, {})
    void navigate(params)
  }, [currentSearch, navigate])

  return (
    <SearchFilterDrawer toggleDrawer={toggleDrawer} open={showFilterDrawer} clear={handleClearFilters}>
      {currentSearch.category === SearchCategory.COMPANY && <CompanySearchFilter />}
      {currentSearch.category === SearchCategory.LOCATION && <LocationSearchFilter />}
      {currentSearch.category === SearchCategory.CONTACT && <ContactSearchFilter />}
    </SearchFilterDrawer>
  )
}
