import { zodResolver } from "@hookform/resolvers/zod"
import { Box, CircularProgress, Fade, Paper, Typography } from "@mui/material"
import { AddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import { type ReactElement, useCallback, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "urql"

import AddInstructionsModal from "./AddInstructionsModal"
import { AddInstructionsSchema } from "./AddInstructionsSchema"
import InstructionGroups from "./InsturctionGroups/InstructionGroups"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import { ProductRelatedInstructionsDocument, UpdateProductMutationDocument } from "@/graphql/codegen/graphql"
import type {
  ApplicationToEnumGql,
  IncludedWithEnumGql,
  Mutation,
  ProductMutationUpdateArgs,
  ProductUpdateFailure,
  RelatedProductInstruction,
  RelatedProductInstructionInput,
  TransportationModeEnumGql,
} from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"
import { generateCombinations } from "@/utils/generateCombinations"

export const InstructionsContainer = (): ReactElement => {
  const { productId } = useParams({ strict: false })

  const [{ data: queryResult, fetching }, fetchQuery] = useQuery({
    query: ProductRelatedInstructionsDocument,
    variables: {
      productId: Number(productId),
    },
  })

  const data = useMemo(() => {
    return queryResult?.product?.get.__typename === "ProductGetSuccess"
      ? (queryResult.product.get.product.relatedProductInstructions as RelatedProductInstruction[])
      : []
  }, [queryResult])

  const [_editingInstruction, _setEditingInstruction] = useState<RelatedProductInstruction | null>(null)
  const [_addInstructionsModal, setAddInstructionsModal] = useState(false)
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)
  const defaultValues = {
    applicableTo: [],
    transportationMode: [],
    includedWith: [],
    groupId: "",
  } as unknown as RelatedProductInstructionInput
  const methods = useForm<RelatedProductInstructionInput>({
    defaultValues,
    resolver: zodResolver(AddInstructionsSchema),
    mode: "all",
  })
  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )

  const { setError } = methods

  const handleAddSubmit = useCallback(
    async (formData: RelatedProductInstructionInput) => {
      const newGroupId = crypto.randomUUID()
      const formatData = generateCombinations({
        ...formData,
        groupId: newGroupId,
      })

      const existingInstructions = data
        .filter((inst) => inst.groupId !== newGroupId)
        .map((inst) => ({
          groupId: inst.groupId,
          applicableTo: inst.applicableTo as ApplicationToEnumGql,
          transportationMode: inst.transportationMode as TransportationModeEnumGql,
          includedWith: inst.includedWith as IncludedWithEnumGql,
          productInstructionId: inst.productInstructionId,
        }))

      const { error, data: result } = await updateProduct({
        input: {
          productId: Number(productId),
          relatedProductInstructions: [...existingInstructions, ...formatData],
        },
      })

      if (error || (result?.product.update as ProductUpdateFailure)?.error) {
        setError("root", {
          type: "server",
          message: error?.message || (result?.product?.update as ProductUpdateFailure)?.error?.message,
        })

        notify({
          type: "error",
          content: <p>Failed to add instruction group</p>,
        })
      } else {
        notify({
          type: "success",
          content: <p>Instruction group successfully added.</p>,
        })
      }

      fetchQuery({
        variables: {
          productId: Number(productId),
        },
      })
      methods.reset()
      setAddInstructionsModal(false)
    },
    [notify, methods, productId, setError, updateProduct, data, fetchQuery]
  )

  const handleEditSubmit = useCallback(async (_formData: RelatedProductInstructionInput) => {
    // todo: implement
  }, [])

  const handleEditInstruction = useCallback((_instruction: RelatedProductInstruction) => {
    // todo: implement
  }, [])

  const handleDeleteInstruction = useCallback(async (_instruction: RelatedProductInstruction) => {
    // todo: implement
  }, [])

  return (
    <Box className='flex flex-col gap-4'>
      <Paper className='min-h-56 space-y-6 border border-gray-300 p-6' elevation={0}>
        <Typography variant='subtitle1'>Instruction Groups</Typography>
        <Box>
          {fetching ? (
            <Box className='flex items-center justify-center'>
              <CircularProgress size={56} />
            </Box>
          ) : (
            <>
              <Fade in={!data?.length} unmountOnExit timeout={500}>
                <Box>
                  <InformationMissingAlert
                    icon={<WarningOutlined color='primary' />}
                    title='Start by adding instruction groups'
                    description='No instructions have been added yet. Once you add some, you will be able to see them here.'
                    action={
                      <Button
                        color='inherit'
                        size='small'
                        appearance='outlined'
                        className='text-primary-500'
                        startIcon={<AddOutlined />}
                        onClick={() => setAddInstructionsModal(true)}
                      >
                        Instruction Group
                      </Button>
                    }
                  />
                </Box>
              </Fade>
              <Fade in={Boolean(data?.length)} unmountOnExit timeout={500}>
                <Box className='flex flex-col gap-6'>
                  <Box className='flex justify-end'>
                    <Button
                      startIcon={<AddOutlined />}
                      size='small'
                      color='inherit'
                      appearance='outlined'
                      onClick={() => setAddInstructionsModal(true)}
                    >
                      New Applicaiton
                    </Button>
                  </Box>
                  <Box>
                    <InstructionGroups
                      data={data}
                      onEditInstruction={handleEditInstruction}
                      onDeleteInstruction={handleDeleteInstruction}
                    />
                  </Box>
                </Box>
              </Fade>
            </>
          )}
        </Box>
      </Paper>
      <FormProvider {...methods}>
        <AddInstructionsModal
          open={_addInstructionsModal}
          onClose={() => setAddInstructionsModal(false)}
          onSubmit={_editingInstruction ? handleEditSubmit : handleAddSubmit}
          editingInstruction={_editingInstruction}
        />
      </FormProvider>
    </Box>
  )
}
