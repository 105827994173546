import { Autocomplete, Box, Checkbox, Chip, InputAdornment, MenuItem, TextField, Typography } from "@mui/material"
import { MoreHorizOutlined, SearchOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"
import React, { useCallback, useEffect, useRef, useState } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"

import { useGetInstructions } from "./hooks/useGetInstructions"

import type { ProductInstruction } from "@/graphql/codegen/graphql"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll"

interface InstructionListProps {
  label: string
  name: string
  control: Control
}

const InstructionList: FC<InstructionListProps> = ({ label, name, control, ...rest }) => {
  const [value, setValue] = useState<ProductInstruction[]>([])
  const [inputValue, setInputValue] = useState("")
  const [visibleChips, setVisibleChips] = useState<ProductInstruction[]>([])
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)

  const { instructionsList, instructionsListFetching, loadMore } = useGetInstructions()

  const { containerRef: instructionsListRef } = useInfiniteScroll(loadMore, {
    isLoading: instructionsListFetching,
    hasMore: instructionsList.length % 20 === 0,
    threshold: 200,
  })

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const updateVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 40
      let totalWidth = 0
      const visibleChipsArray: ProductInstruction[] = []
      const selectedProductInstructionIds: string[] = []

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(value[index])
          selectedProductInstructionIds.push(value[index].productInstructionId)
          totalWidth += chipWidth
        }
      })
      field.onChange(selectedProductInstructionIds)
      setVisibleChips(visibleChipsArray)
      setIsOverflowing(visibleChipsArray.length < value.length)
    }
  }, [value])

  useEffect(() => {
    updateVisibleChips()
  }, [value])

  return (
    <Box className='mb-4' position='relative'>
      <label className='mb-2 block text-sm font-normal text-gray-700'>
        {label}
        <span className='text-red-600'>*</span>
      </label>
      <Autocomplete
        multiple
        options={inputValue.length >= 3 || value.length > 0 ? instructionsList : []}
        getOptionLabel={(option: ProductInstruction) => option.name}
        value={value}
        onChange={(_, newValue: string | null) => {
          setValue(newValue)
        }}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        classes={{
          input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none",
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            ref={containerRef}
            className=''
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchOutlined size={20} />
                </InputAdornment>
              ),
            }}
            sx={{ "& .MuiAutocomplete-inputRoot": { height: "42px", alignContent: "center" } }}
          />
        )}
        renderTags={() => (
          <Box className='flex overflow-hidden align-middle'>
            <Box className='flex flex-nowrap gap-1'>
              {visibleChips.map(({ name, productInstructionId }) => {
                return name ? <Chip key={productInstructionId} label={name} className='rounded-sm' /> : null
              })}
            </Box>
            {isOverflowing && (
              <Box className='mx-1 flex size-8 items-center justify-center rounded bg-gray-300'>
                <MoreHorizOutlined className='ml-0 shrink-0' />
              </Box>
            )}
          </Box>
        )}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.productInstructionId}
            ref={instructionsListRef}
            className='flex content-center'
          >
            <Checkbox
              checked={value.some(({ productInstructionId }) => productInstructionId === option.productInstructionId)}
            />
            {option.printableLabel && (
              <img
                src={option.printableLabel}
                alt={`Image for ${option.name}`}
                width={48}
                height={48}
                className='mr-4'
              />
            )}
            {option.name}
          </MenuItem>
        )}
        {...rest}
      />
      {error && <Typography className='text-sm text-red-600'>{error.message}</Typography>}
      <Box className='invisible absolute' ref={measureRef}>
        {value.map(({ name, productInstructionId }) => (
          <Chip key={productInstructionId} label={name} />
        ))}
      </Box>
    </Box>
  )
}

export default InstructionList
