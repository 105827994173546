import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material"
import { ChatOutlined, MoreVertOutlined } from "@mui-symbols-material/w300"
import React, { useState } from "react"

import CompanyHeaderDropdownMenu from "../../components/CompanyHeaderDropdownMenu"

import { CommentHistoryDialog } from "@/components/common/CommentHistory/CommentHistoryDialog"
import type { AccountManager, Comment } from "@/graphql/codegen/graphql"
import { CommentParentTableType, CompanyStatus } from "@/graphql/codegen/graphql"

type CompanyDetails = {
  comments: Comment[]
  accountManagers: Array<AccountManager>
  companyId: string
  externalSystemId: string
  name: string
  status: CompanyStatus
  types?: Array<string>
  fetchComments: () => void
  onEditClick: (value: boolean) => void
  onDeactivate: () => void
  onActivate: () => void
}

const CompanyHeader: React.FC<CompanyDetails> = (props) => {
  const {
    companyId,
    externalSystemId,
    name,
    status,
    types = [],
    comments = [],
    accountManagers,
    onEditClick,
    onDeactivate,
    onActivate,
    fetchComments,
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openCommentHistory, setOpenCommentHistory] = useState(false)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleActivate = () => {
    onActivate()
    handleCloseMenu()
  }

  const handleDeactivate = () => {
    onDeactivate()
    handleCloseMenu()
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    onEditClick(true)
    handleCloseMenu()
  }

  const handleOpenCommentHistory = () => {
    setOpenCommentHistory(true)
  }

  const handleCommentHistorySuccess = () => {
    fetchComments()
  }

  const isActive = status === CompanyStatus.Active

  return (
    <>
      <CommentHistoryDialog
        open={openCommentHistory}
        onClose={() => setOpenCommentHistory(false)}
        title={name}
        comments={comments}
        onSuccess={handleCommentHistorySuccess}
        commentParentTableType={CommentParentTableType.Company}
        referenceId={companyId}
      />
      <Box className='rounded-md border border-solid border-gray-300 p-6'>
        <Box className='flex items-center justify-between gap-3'>
          <Box className='flex items-center gap-3'>
            <Typography variant='h5' className='text-gray-800'>
              {name}
            </Typography>
            <Chip label={status} color='warning' variant='outlined' />
            <Typography variant='body1' className='font-medium text-gray-700'>
              Company ID:&nbsp;
              <Tooltip title={companyId}>
                <span>{externalSystemId || "----"}</span>
              </Tooltip>
            </Typography>
          </Box>
          <IconButton color='primary' aria-label='company actions' onClick={handleOpenMenu}>
            <MoreVertOutlined />
          </IconButton>
          <CompanyHeaderDropdownMenu
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            isActive={isActive}
            onEdit={handleEdit}
            onDeactivate={handleDeactivate}
            onActivate={handleActivate}
          />
        </Box>
        <Box className='mt-6 flex items-center gap-2'>
          <Typography variant='body2' className='text-gray-700'>
            Company Type:
          </Typography>
          {(types ?? []).map((type) => (
            <Chip key={type} label={type} className='rounded-sm font-[13px]' />
          ))}
        </Box>
        <Box className='mt-4 flex items-center justify-between'>
          <Box className='flex items-center gap-2'>
            <Typography variant='body2' className='text-gray-700'>
              Account Manager(s):
            </Typography>
            {accountManagers.map(({ accountManagerId, firstName, lastName }) => (
              <Chip key={accountManagerId} label={`${firstName} ${lastName}`} className='rounded-sm bg-primary-100' />
            ))}
          </Box>
          <IconButton color='primary' onClick={handleOpenCommentHistory} aria-label='Open comment history'>
            <ChatOutlined />
          </IconButton>
        </Box>
      </Box>
    </>
  )
}

export default CompanyHeader
