import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from "@mui/material"
import { FileUploadSection } from "components/upload/components/FileUploadSection"
import { type FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import type { ProductFormData, ProductFormDetailsDialogProps } from "./types"
import { UPLOAD_TYPES, schema } from "./types"

import { Button } from "@/components/common/Button"
import { FieldGroup, Form, FormField } from "@/components/Forms"

const IMAGE_CONFIG = {
  accept: ".jpg,.png,.gif,.jpeg",
  maxSize: 10 * 1024 * 1024, // 10MB
  emptyStateText: "",
  dropStateText: "Drop image here",
}

export const ProductFormDetailsDialog: FC<ProductFormDetailsDialogProps> = ({
  open,
  formOptions,
  unitOfMeasurementOptions,
  onClose,
  onSubmit,
  initialData,
  previewFiles,
  onFileSelect,
  uploading,
}) => {
  const methods = useForm<ProductFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      productFormInfo: initialData?.productFormInfo || undefined,
      uomId: initialData?.uomId || unitOfMeasurementOptions[0]?.id || "",
      signedUrls: initialData?.signedUrls || [],
    },
    mode: "onChange",
  })

  const {
    reset,
    formState: { isValid },
  } = methods

  useEffect(() => {
    if (open) {
      reset({
        productFormInfo: initialData?.productFormInfo || undefined,
        uomId: initialData?.uomId || unitOfMeasurementOptions[0]?.id || "",
        signedUrls: initialData?.signedUrls || [],
      })
    }
  }, [open, initialData, reset, unitOfMeasurementOptions])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Edit Product Form Details</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Form className='py-4'>
            <FieldGroup>
              {/* Product Form Info */}
              <FormField
                name='productFormInfo'
                label='Product Form'
                required
                render={({ field, fieldState: { error } }) => (
                  <Select {...field} fullWidth displayEmpty error={!!error} placeholder='Select Product Form'>
                    {formOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {/* Unit of Measurement */}
              <FormField
                name='uomId'
                label='Unit of Measurement'
                required
                render={({ field, fieldState: { error } }) => (
                  <Select {...field} fullWidth displayEmpty error={!!error} placeholder='Select Unit of Measurement'>
                    {unitOfMeasurementOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {/* File Upload Section */}
              <Box className='flex justify-between'>
                {UPLOAD_TYPES.map(({ type, label }) => (
                  <FileUploadSection
                    key={type}
                    type={type}
                    label={label}
                    fileUrl={
                      previewFiles[type]?.previewUrl ||
                      initialData?.signedUrls?.find((url) => url.file?.fieldGroup === type)?.url ||
                      null
                    }
                    onFileSelect={onFileSelect}
                    uploading={uploading}
                    {...IMAGE_CONFIG}
                  />
                ))}
              </Box>
            </FieldGroup>
          </Form>
        </FormProvider>
      </DialogContent>
      <DialogActions className='justify-between px-6 pb-4'>
        <Button onClick={onClose} appearance='outlined'>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(onSubmit)} appearance='contained' disabled={!isValid}>
          {initialData ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
