import { MenuItem, Select, TextField } from "@mui/material"
import { useFormContext } from "react-hook-form"

import { FieldGroup, FieldRow, Form, FormField, renderSelectPlaceholder } from "@/components/Forms"
import { formattedPackagingTypes } from "@/constants/productPackagingType"

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"
const textFieldSlotProps = { htmlInput: { className: InputFieldClasses } }
const selectSlotProps = { input: { className: InputFieldClasses } }

enum PackagingInfoFormFieldIds {
  FreightItem = "freightItem",
  FreightClass = "freightClass",
  PackagingType = "packagingType",
  PackageWeight = "packageWeight",
  PackageWeightUom = "packageWeightUom",
  PackageLength = "packageLength",
  PackageWidth = "packageWidth",
  PackageHeight = "packageHeight",
  PackageDimensionsUom = "packageDimensionsUom",
  PalletWeight = "palletWeight",
  PalletWeightUom = "palletWeightUom",
  PalletSizeLength = "palletSizeLength",
  PalletSizeWidth = "palletSizeWidth",
  PalletSizeHeight = "palletSizeHeight",
  PalletSizeUom = "palletSizeUom",
  packagesPerPallet = "packagesPerPallet",
}

interface PackagingInfoFormProps {
  disabled?: boolean
}

export const PackagingInfoForm = ({ disabled: disabledProp = false }: PackagingInfoFormProps): React.ReactElement => {
  const {
    formState: { isSubmitting },
  } = useFormContext()

  const disabled = disabledProp || isSubmitting

  return (
    <Form>
      <FieldGroup legend='Freight'>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.FreightItem}
            label='Freight Item'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='000000'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.FreightClass}
            label='Freight Class'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
        </FieldRow>
      </FieldGroup>
      <FieldGroup legend='Individual Standard Packaging'>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PackagingType}
            label='Sandard Packaging'
            render={({ field, fieldState }) => (
              <Select
                {...field}
                slotProps={selectSlotProps}
                type='text'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                displayEmpty
                renderValue={renderSelectPlaceholder({ field })}
                disabled={disabled || field.disabled}
              >
                <MenuItem value='' divider>
                  Select One
                </MenuItem>
                {formattedPackagingTypes.map((packagingType) => (
                  <MenuItem key={packagingType.original} value={packagingType.original} divider>
                    {packagingType.formatted}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PackageWeight}
            label='Weight per Individual Std Package (lb)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='000'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PackageWeightUom} />
        </FieldRow>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PackageLength}
            label='Length (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PackageWidth}
            label='Width (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PackageHeight}
            label='Height (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PackageDimensionsUom} />
        </FieldRow>
      </FieldGroup>
      <FieldGroup legend='Full Pallet Packaging'>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PalletWeight}
            label='Weight per Pallet (lb)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='000'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PalletWeightUom} />
        </FieldRow>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.PalletSizeLength}
            label='Length (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PalletSizeWidth}
            label='Width (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField
            name={PackagingInfoFormFieldIds.PalletSizeHeight}
            label='Height (in)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='00'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
          <FormField name={PackagingInfoFormFieldIds.PalletSizeUom} />
        </FieldRow>
        <FieldRow>
          <FormField
            name={PackagingInfoFormFieldIds.packagesPerPallet}
            label='Quantity per Pallet (Std. Packaging)'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                slotProps={textFieldSlotProps}
                placeholder='000'
                type='number'
                error={!!fieldState.error}
                fullWidth
                variant='outlined'
                disabled={disabled || field.disabled}
              />
            )}
          />
        </FieldRow>
      </FieldGroup>
    </Form>
  )
}

export { PackagingInfoFormFieldIds }
export type { PackagingInfoFormProps }
