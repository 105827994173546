import type { BreadcrumbItemType } from "@/components/common/Breadcrumbs/Breadcrumbs.schema.tsx"
import type { RouteNavObject } from "@/types/routes.ts"

export const generateBreadcrumbItems = (routes: RouteNavObject[], currentPath: string): BreadcrumbItemType[] => {
  const items: BreadcrumbItemType[] = []
  let path = ""

  currentPath
    .split("/")
    .filter(Boolean)
    .forEach((segment) => {
      path += `/${segment}`
      const route = routes.find((route) => route.pathname === path)
      if (route) {
        // HACK: to be discussed if we want to have "All" and if it represents a page
        if (segment === "companies") {
          items.push({ label: `${route.label} / All`, pathname: route.pathname })
        } else {
          items.push({ label: route.label || "", pathname: route.pathname })
        }
        if (route.children) {
          routes = route.children
        }
      }
    })

  return items
}
