import { z } from "zod"

export const AccountManagerSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  title: z.string().nullish(),
  accountManagerId: z.string(),
})

export const AccountManagerListSchema = z.array(AccountManagerSchema)
