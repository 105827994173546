export enum LocationMenuDialogs {
  ADD_ADDRESS = "addAddress",
  ADD_CONTACT = "addContact",
  ADD_NOTES = "addNotes",
  EDIT_ADDRESS = "editAddress",
  EDIT_CONTACT = "editContact",
  EDIT_NOTES = "editNotes",
}

export enum LocationConfirmationDialogs {
  CANCEL = "cancel",
  SUCCESS = "success",
}
