import { Box, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { CloseOutlined, DeleteOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"

import { Button } from "@/components/common/Button"

export const FilterHeader: FC<{ onClear: () => void; onClose: () => void }> = ({ onClear, onClose }) => (
  <Box className='flex flex-col space-y-5 py-2'>
    <IconButton onClick={onClose} aria-label='Close filter drawer' className='flex self-end p-0'>
      <CloseOutlined />
    </IconButton>
    <Box className='flex justify-between'>
      <Typography
        variant='subtitle1'
        color='primary'
        fontWeight={700}
        fontSize={18}
        lineHeight={"27px"}
        letterSpacing={"0.15px"}
      >
        Filters
      </Typography>
      <Button
        variant='primary'
        size='small'
        role='button'
        appearance='text'
        tabIndex={-1}
        startIcon={<DeleteOutlined fontSize='small' />}
        onClick={onClear}
      >
        Clear All
      </Button>
    </Box>
  </Box>
)
