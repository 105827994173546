import type { ChipProps as MuiChipProps } from "@mui/material"
import { Chip as MuiChip } from "@mui/material"
import { forwardRef } from "react"
import { twMerge } from "tailwind-merge"

const StyledChip = forwardRef<HTMLDivElement, MuiChipProps>((props, ref) => {
  const { classes, ...rest } = props

  return (
    <MuiChip
      ref={ref}
      {...rest}
      classes={{
        ...classes,
        colorPrimary: twMerge("bg-primary-200 text-primary", classes?.colorPrimary),
        colorSuccess: twMerge("bg-tertiary-green-100 text-gray-900", classes?.colorSuccess),
        colorError: twMerge("bg-secondary-100 text-gray-900", classes?.colorError),
        colorWarning: twMerge("bg-tertiary-yellow-100 text-gray-900", classes?.colorWarning),
        root: twMerge("rounded-md p-1 h-6", classes?.root),
        label: twMerge("text-xs p-1.5 font-normal", classes?.label),
        avatar: twMerge("m-0 p-0 h-[18px] w-[18px]", classes?.avatar),
      }}
    />
  )
})

StyledChip.displayName = "StyledChip"

export default StyledChip
