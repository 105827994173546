import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useState } from "react"
import type { FieldArrayWithId, FieldValues, UseFormReturn } from "react-hook-form"
import { useFieldArray, useForm } from "react-hook-form"

import type { ShippingLeg, ShippingLegInput } from "@/graphql/codegen/graphql"
import { ShippingLegsSchema } from "@/screens/Procurement/PurchaseOrder/DeliveryTermsAndShippingLegs/schemas/shippingLegsSchemas"
import { PurchaseOrderSection } from "@/screens/Procurement/PurchaseOrder/types"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

type UseShippingLegsForm = {
  methods: UseFormReturn<{ shippingLegs: (ShippingLeg | ShippingLegInput)[] }>
  fields: FieldArrayWithId<FieldValues, "shippingLegs", "id">[]
  handleAddShippingLeg: () => void
  handleRemove: (index: number) => void
}

export const useShippingLegsForm = (): UseShippingLegsForm => {
  const { formValues, updateSectionFields, updateModifiedFields } = usePurchaseOrderStore()
  const shippingLegs = formValues[PurchaseOrderSection.SHIPPING_LEGS]
  const deliveryTerms = formValues[PurchaseOrderSection.DELIVERY_TERMS]

  const [newLeg, setNewLeg] = useState<ShippingLegInput>({
    sourceLocationId: "",
    destinationLocationId: "",
    shipViaCompanyId: "",
    // @ts-expect-error BE Issues: shipmentStatus is a valid field for ShippingLegInput
    shipmentStatus: "",
  })

  const methods = useForm<{ shippingLegs: (ShippingLeg | ShippingLegInput)[] }>({
    defaultValues: {
      shippingLegs: shippingLegs || [],
    },
    resolver: zodResolver(ShippingLegsSchema),
    mode: "onChange",
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "shippingLegs",
  })

  const handleAddShippingLeg = useCallback((): void => {
    if (fields.length === 0) {
      append({
        sourceLocationId: deliveryTerms.incotermsLocation,
        destinationLocationId: "",
        shipViaCompanyId: "",
        // @ts-expect-error BE Issues: shipmentStatus is a valid field for ShippingLegInput
        shipmentStatus: "",
      })
    } else if (newLeg) {
      append(newLeg)
    } else {
      console.error("No new leg to add")
    }
  }, [append, newLeg, fields.length, deliveryTerms])

  const handleRemove = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove]
  )

  // Sync form data with zustand store
  useEffect(() => {
    const subscription = methods.watch((formData) => {
      if (formData.shippingLegs) {
        updateSectionFields(PurchaseOrderSection.SHIPPING_LEGS, formData.shippingLegs as ShippingLeg[])
        updateModifiedFields(PurchaseOrderSection.SHIPPING_LEGS, {
          shippingLegs: formData.shippingLegs as ShippingLeg[],
        })
        const lastLeg = formData.shippingLegs[formData.shippingLegs.length - 1]
        setNewLeg({
          sourceLocationId: lastLeg?.destinationLocationId || "",
          destinationLocationId: "",
          shipViaCompanyId: "",
          // @ts-expect-error BE Issues: shipmentStatus is a valid field for ShippingLegInput
          shipmentStatus: "",
        })
      }
    })

    return () => subscription.unsubscribe()
  }, [methods, updateSectionFields, updateModifiedFields])

  return {
    methods,
    fields,
    handleAddShippingLeg,
    handleRemove,
  }
}
