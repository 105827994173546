import { zodResolver } from "@hookform/resolvers/zod"
import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material"
import {
  AddOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import { type ReactElement, useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "urql"

import { useProductShipping } from "../hooks/useProductShipping"

import AddShippingDetailsModal from "./AddShippingDetailsModal"
import { LeadTimeUoms } from "./ShippingDetailsContent"
import { ShippingDetailsSchema } from "./ShippingDetailsSchema"
import ShippingDetailsTable from "./ShippingDetailsTable"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import {
  type Mutation,
  type ProductMutationUpdateArgs,
  type ProductUpdateFailure,
  UpdateProductMutationDocument,
} from "@/graphql/codegen/graphql"
import { calculateInDays } from "@/screens/Products/utils"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const ProductShippingDetailsContainer = (): ReactElement => {
  const [shippingDetailsModal, setShippingDetailsModal] = useState(false)
  const { enqueueNotification } = useNotificationsStore()
  const methods = useForm({
    defaultValues: {
      weightPerTruckload: null,
      leadTimeInDays: null,
      minOrderQuantity: null,
      leadTimeUom: LeadTimeUoms.Weeks,
    },
    resolver: zodResolver(ShippingDetailsSchema),
    mode: "all",
  })
  const { productId: queryCompanyId } = useParams({
    from: "/product/$productId/edit",
  })
  const { data: productShipping, fetch: fetchProductShipping } = useProductShipping({
    productId: Number(queryCompanyId),
  })

  const [, updateProduct] = useMutation<Pick<Mutation, "product">, ProductMutationUpdateArgs>(
    UpdateProductMutationDocument
  )

  const [expanded, setExpanded] = useState(true)

  const { setError } = methods

  const showShippingDetails =
    productShipping?.weightPerTruckload || productShipping?.leadTimeInDays || productShipping?.minOrderQuantity

  const handleSubmit = useCallback(
    async (data) => {
      const { leadTimeUom, leadTimeInDays, ...restData } = data
      const leadTimeInDaysValue = calculateInDays(Number(data.leadTimeInDays), leadTimeUom)
      const { error, data: result } = await updateProduct({
        input: {
          ...restData,
          productId: Number(queryCompanyId),
          weightPerTruckload: Number(data.weightPerTruckload) || null,
          leadTimeInDays: leadTimeInDaysValue || null,
          minOrderQuantity: Number(data.minOrderQuantity) || null,
        },
      })
      if (error || (result?.product.update as ProductUpdateFailure)?.error) {
        setError("root", {
          // Keep this as "name"
          type: "server",
          message: error?.message || (result?.product?.update as ProductUpdateFailure)?.error?.message,
        })

        enqueueNotification({
          type: "error",
          silent: false,
          content: <p>Failed to {showShippingDetails ? "update" : "add"} product supplier shipping details</p>,
        })
      } else {
        enqueueNotification({
          type: "success",
          silent: false,
          content: <p>Product supplier shipping details successfully {showShippingDetails ? "updated" : "added"}.</p>,
        })
      }
      methods.reset()
      setShippingDetailsModal(false)
      fetchProductShipping({ productId: Number(queryCompanyId) })
    },
    [enqueueNotification, fetchProductShipping, methods, queryCompanyId, setError, showShippingDetails, updateProduct]
  )

  const onEditHandleClick = useCallback(() => {
    const { divisible, format } = getTimeFormat(productShipping?.leadTimeInDays)

    methods.reset({
      weightPerTruckload: productShipping?.weightPerTruckload,
      leadTimeInDays: (productShipping?.leadTimeInDays || 0) / divisible,
      minOrderQuantity: productShipping?.minOrderQuantity,
      leadTimeUom: format,
    })
    setShippingDetailsModal(true)
  }, [methods, productShipping?.leadTimeInDays, productShipping?.minOrderQuantity, productShipping?.weightPerTruckload])

  return (
    <Paper className='border border-gray-300 p-6' elevation={0}>
      <Box className='flex items-center justify-between'>
        <Typography variant='h6' className={expanded ? "mb-6" : ""}>
          Supplier Shipping Details
        </Typography>
        <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label='show more'>
          {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        {showShippingDetails ? (
          <ShippingDetailsTable productDetails={productShipping} onEditConditionClick={onEditHandleClick} />
        ) : (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Add Supplier Shipping Details'
            description={""}
            action={
              <Button
                color='inherit'
                size='small'
                appearance='outlined'
                className='text-primary-500'
                startIcon={<AddOutlined />}
                onClick={() => setShippingDetailsModal(true)}
              >
                Supplier Shipping Details
              </Button>
            }
          />
        )}
      </Collapse>
      <FormProvider {...methods}>
        <AddShippingDetailsModal
          open={shippingDetailsModal}
          onClose={() => {
            methods.reset()
            setShippingDetailsModal(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </Paper>
  )
}

export const getTimeFormat = (value: number): { divisible: number; format: string } => {
  if (value % 7 === 0) {
    return { divisible: 7, format: "Weeks" }
  } else {
    return { divisible: 1, format: "Days" }
  }
}
