import type { TableCellProps } from "@mui/material"
import { twMerge } from "tailwind-merge"

import { AerosTableCell } from "./AerosTableCell"
import { AerosTableRow } from "./AerosTableRow"

/**
 * Not data present in the table.
 *
 * @example
 * ```tsx
 * <AerosDataNotFoundRow>No records available</AerosDataNotFoundRow>
 * ```
 *
 * @param props - Extends MUI TableCellProps
 * @param props.className - Additional Tailwind classes for cell styling
 * @param props.children - Message to display when no data is present
 */
export const AerosDataNotFoundRow = ({ className, children, ...props }: TableCellProps): JSX.Element => {
  return (
    <AerosTableRow className='flex w-full'>
      <AerosTableCell
        className={twMerge(
          "flex h-[64px] items-center justify-start border-none p-4 text-gray-700 first:pr-0",
          className
        )}
        {...props}
      >
        {children}
      </AerosTableCell>
    </AerosTableRow>
  )
}
