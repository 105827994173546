import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"

const inputVariants = cva("flex items-center justify-center gap-2 rounded border px-4 font-normal text-black", {
  variants: {
    size: {
      medium: "h-10 text-sm",
      large: "h-[50px] py-1 text-lg",
    },
    state: {
      success: "border border-success-400",
      error: "border border-error-300",
      regular: "hover:outline hover:outline-blue-200",
    },
    disabled: {
      true: "cursor-not-allowed bg-gray-200",
    },
  },
  defaultVariants: {
    size: "medium",
    state: "regular",
    disabled: false,
  },
})

export type InputVariantsProps = VariantProps<typeof inputVariants>
export default inputVariants
