import { useCallback, useMemo } from "react"
import type { CombinedError, OperationContext, OperationResult } from "urql"
import { useMutation, useQuery } from "urql"

import type {
  ContactSubscription,
  ContactSubscriptionListSuccess,
  ContactSubscriptionMutationBatchCreateContactSubscriptionsArgs,
  ContactSubscriptionMutationDeleteArgs,
  ContactSubscriptionsListSuccess,
  Mutation,
  Query,
} from "@/graphql/codegen/graphql"
import {
  BatchCreateContactSubscriptionMutationDocument,
  ContactSubscriptionGetQueryDocument,
  DeleteContactSubscriptionMutationDocument,
  SubscriptionListQueryDocument,
} from "@/graphql/codegen/graphql"

export const useGetSubscription = (
  contactId: string
): {
  subscriptionList: ContactSubscription[]
  subscriptionListDetailsFetching: boolean
  subscriptionListDetailsError: CombinedError | undefined
  selectedSubscriptionList: ContactSubscription[]
  selectedSubscriptionDetailsFetching: boolean
  selectedSubscriptionDetailsError: CombinedError | undefined
  batchCreateSubscriptions: (
    variables: ContactSubscriptionMutationBatchCreateContactSubscriptionsArgs,
    context?: Partial<OperationContext>
  ) => Promise<
    OperationResult<
      Pick<Mutation, "contactSubscription">,
      ContactSubscriptionMutationBatchCreateContactSubscriptionsArgs
    >
  >
  deleteSubscriptions: (
    variables: ContactSubscriptionMutationDeleteArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "contactSubscription">, ContactSubscriptionMutationDeleteArgs>>
  reexecuteQueryWithReset: () => void
} => {
  const [
    {
      data: selectedSubscriptionDetails,
      fetching: selectedSubscriptionDetailsFetching,
      error: selectedSubscriptionDetailsError,
    },
    reexecuteSelectedSubscriptionListQuery,
  ] = useQuery<Pick<Query, "contactSubscription">>({
    pause: !contactId,
    query: ContactSubscriptionGetQueryDocument,
    variables: { input: { contactId } },
  })

  const [
    { data: subscriptionListDetails, fetching: subscriptionListDetailsFetching, error: subscriptionListDetailsError },
  ] = useQuery<Pick<Query, "contactSubscription">>({
    pause: !contactId,
    query: SubscriptionListQueryDocument,
  })

  // Mutation to create subscriptions
  const [, batchCreateSubscriptions] = useMutation<
    Pick<Mutation, "contactSubscription">,
    ContactSubscriptionMutationBatchCreateContactSubscriptionsArgs
  >(BatchCreateContactSubscriptionMutationDocument)

  // Mutation to delete subscriptions
  const [, deleteSubscriptions] = useMutation<
    Pick<Mutation, "contactSubscription">,
    ContactSubscriptionMutationDeleteArgs
  >(DeleteContactSubscriptionMutationDocument)

  const selectedSubscriptionList = useMemo(
    () =>
      (selectedSubscriptionDetails?.contactSubscription.listContactSubscriptions as ContactSubscriptionsListSuccess)
        ?.contactSubscriptions,
    [selectedSubscriptionDetails]
  )

  const reexecuteQueryWithReset = useCallback(() => {
    reexecuteSelectedSubscriptionListQuery({ requestPolicy: "network-only" })
  }, [reexecuteSelectedSubscriptionListQuery])

  const subscriptionList = useMemo(
    () =>
      (subscriptionListDetails?.contactSubscription.listSubscriptions as ContactSubscriptionListSuccess)
        ?.contactSubscriptions,
    [subscriptionListDetails]
  )

  return {
    subscriptionList,
    subscriptionListDetailsFetching,
    subscriptionListDetailsError,
    selectedSubscriptionList,
    selectedSubscriptionDetailsFetching,
    selectedSubscriptionDetailsError,
    batchCreateSubscriptions,
    deleteSubscriptions,
    reexecuteQueryWithReset,
  }
}
