import { useCallback } from "react"
import { useMutation } from "urql"

import type { ProductUpdateMutation } from "@/graphql/codegen/graphql"
import { ProductUpdateDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const useProductFormHandlers = (
  productId: string
): {
  handleUpdateProduct: ({ productFormInfo, uomId }: { productFormInfo: string; uomId: string }) => Promise<boolean>
} => {
  const notify = useNotificationsStore((state) => state.enqueueNotification)
  const [_updateProductResponse, updateProductFormInfo] = useMutation<ProductUpdateMutation>(ProductUpdateDocument)

  const handleUpdateProduct = useCallback(
    async ({ productFormInfo, uomId }: { productFormInfo: string; uomId: string }) => {
      try {
        const response = await updateProductFormInfo({
          input: {
            productId: Number(productId),
            productFormInfo,
            uomId,
          },
        })

        if (response.data?.product.update.__typename === "ProductUpdateFailure") {
          notify({
            message: "Failed to update product form details",
            type: "error",
          })
          return false
        }

        notify({
          message: "Product form details updated",
          type: "success",
        })
        return true
      } catch {
        notify({
          message: "Failed to update product form details",
          type: "error",
        })
        return false
      }
    },
    [productId, updateProductFormInfo, notify]
  )

  return {
    handleUpdateProduct,
  }
}
