import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { CloseFilledOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import type { FC } from "react"

import { Button } from "@/components/common/Button"
import type { DialogCloseAction } from "@/screens/Companies/types/dialogProps"

type CancelLocationModalProps = {
  open: boolean
  onClose: (reason: DialogCloseAction) => void
  messages: {
    title: string
    description: string
  }
}

const CancelLocationModal: FC<CancelLocationModalProps> = ({ open, onClose, messages }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='flex-end'>
          <Button size='small' appearance='text' onClick={() => onClose("close")}>
            <CloseFilledOutlined className='text-blue-gray' />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign='center'>
          <WarningOutlined className='mb-4 size-[48px] text-secondary-600' />
          <Typography variant='h5' color='primary' className='mt-6 font-normal'>
            {messages.title}
          </Typography>
          <Typography variant='h6' className='mt-6 px-3 text-base leading-[150%] tracking-[-0.85px] text-gray-700'>
            {messages.description}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display='flex' justifyContent='flex-end' width='100%' p={2}>
          <Button appearance='text' variant='secondary' className='mr-2' onClick={() => onClose("cancel")}>
            Go Back
          </Button>
          <Button variant='error' onClick={() => onClose("success")}>
            Yes, Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default CancelLocationModal
