import type { AuthUtilities } from "@urql/exchange-auth"
import { authExchange } from "@urql/exchange-auth"
import type { AnyVariables, ExchangeIO, ExchangeInput, Operation } from "urql"

import { useAuthStore } from "@/stores/authStore.ts"
import { exchangeTokenWithAPI, refreshTokenWithAPI } from "@/utils/auth/exchangeToken.ts"
import { isTokenExpired } from "@/utils/auth/isTokenExpired.ts"

export const createAuthMiddleware = (): ((input: ExchangeInput) => ExchangeIO) => {
  let isRefreshing = false
  let refreshPromise: Promise<void> | null = null

  return authExchange(async (utilities: AuthUtilities): Promise<any> => {
    return {
      addAuthToOperation(operation: Operation<any, AnyVariables>) {
        const { accessToken } = useAuthStore.getState()
        return accessToken
          ? utilities.appendHeaders(operation, {
              Authorization: `Bearer ${accessToken}`,
            })
          : operation
      },

      async willAuthError() {
        const { accessToken } = useAuthStore.getState()
        return !accessToken?.length || isTokenExpired(accessToken)
      },

      async refreshAuth() {
        const { accessToken } = useAuthStore.getState()
        if (accessToken && !isTokenExpired(accessToken)) {
          return
        }

        if (isRefreshing) {
          return refreshPromise
        }

        isRefreshing = true
        refreshPromise = (async () => {
          const { idToken, accessToken, refreshToken, signOut } = useAuthStore.getState()

          if (!refreshToken && !idToken) {
            signOut()
            return
          }

          try {
            let tokens
            if (accessToken && isTokenExpired(accessToken) && refreshToken) {
              tokens = await refreshTokenWithAPI({ accessToken: "", refreshToken })
            } else if (idToken && (!accessToken || !refreshToken)) {
              tokens = await exchangeTokenWithAPI(idToken)
            }

            if (tokens) {
              useAuthStore.getState().setAuthTokens(tokens)
            } else {
              throw new Error("Failed to refresh tokens")
            }
          } catch (error) {
            console.error("Token refresh or exchange failed:", error)
            signOut()
          } finally {
            isRefreshing = false
            refreshPromise = null
          }
        })()

        return refreshPromise
      },
    }
  })
}
