import type { TableCellProps } from "@mui/material"
import { TableCell } from "@mui/material"
import { twMerge } from "tailwind-merge"

/**
 * Individual cell component for the table.
 * Can be used in both header and body rows.
 *
 * @example
 * ```tsx
 * <AerosTableCell>Content</AerosTableCell>
 * <AerosTableCell className="font-bold">Bold Content</AerosTableCell>
 * ```
 *
 * @param props - Extends MUI TableCellProps
 * @param props.className - Additional Tailwind classes for cell styling
 */
export const AerosTableCell = ({ className, ...props }: TableCellProps): JSX.Element => {
  return <TableCell className={twMerge("border-none text-gray-700", className)} {...props} />
}
