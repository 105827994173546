import { Box } from "@mui/material"
import Typography from "@mui/material/Typography"
import React from "react"
import { twMerge } from "tailwind-merge"

interface DetailListProps {
  rows: { label: string; values: Array<string | null | undefined> }[]
  className?: string
}

/**
 * Displays a list of labelled details in a grid-row format. An empty string, null, or undefined
 * value in the values array will be displayed as a dash (-)
 *
 * @param {object} props
 * @param {object} props.rows - An array of objects containing a label and values array
 * @param {string} props.className - Additional classes to apply. Optional
 *
 * @example
 * ```tsx
 * <DetailList
 *   rows={[
 *     { label: "Freight Item / Class", values: ["123", "456789"] },
 *     { label: "Std. Packaging / Weight", values: ["Box", null] },
 *   ]}
 * />
 */
export const DetailList = ({ rows, className, ...rest }: DetailListProps): React.ReactElement => {
  return (
    <Box className={twMerge("grid-min-1-auto-1 grid", className)} {...rest}>
      {rows.map(({ label, values }, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <Typography
            variant='body2'
            className='flex h-[54px] flex-1 items-center whitespace-nowrap border-b border-gray-500 pr-8 text-left text-gray-900'
          >
            {label}
          </Typography>
          <Box className='flex h-[54px]'>
            {values.map((value, valueIndex) => (
              <Typography
                key={valueIndex}
                variant='body2'
                className='flex flex-1 items-center border-b border-gray-500 text-left text-sm'
              >
                {value || "-"}
              </Typography>
            ))}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default DetailList
