import { z } from "zod"

import { MaturityDateTypeGql, PaymentRuleEventGql } from "@/graphql/codegen/graphql"

// Utility functions for validation
const validateNumberInRange = (value, min, max) => {
  const num = Number(value)
  return !isNaN(num) && num >= min && num <= max
}

const payDaysValidation = {
  weekly: { min: 1, max: 7, message: "For Weekly pay pattern, pay days must be between 1 and 7" },
  biweekly: { min: 1, max: 14, message: "For Biweekly pay pattern, pay days must be between 1 and 14" },
  monthly: { min: 1, max: 31, message: "For Monthly pay pattern, pay days must be between 1 and 31" },
}

const offsetDaysValidation = {
  weekly: { min: -52, max: 52, message: "For Weekly pay pattern, offset days must be between -52 and 52" },
  biweekly: { min: -26, max: 26, message: "For Biweekly pay pattern, offset days must be between -26 and 26" },
  monthly: { min: -12, max: 12, message: "For Monthly pay pattern, offset days must be between -12 and 12" },
}

export const AddPaymentTermsSchema = z.object({
  name: z.string().min(1, { message: "Payment term name is mandatory" }),
  options: z.array(
    z.object({
      parts: z.array(
        z
          .object({
            paymentRule: z.object({
              daysAfter: z
                .string()
                .min(1, { message: "Days is mandatory" })
                .refine((value) => validateNumberInRange(value, 0, 364), {
                  message: "Days must be a valid number less than 365 and greater than or equal to 0",
                }),
              event: z.string().default(PaymentRuleEventGql.PlacingOrder),
              percentage: z
                .string()
                .min(1, { message: "Pay percentage is mandatory" })
                .refine((value) => validateNumberInRange(value, 0.01, 99.99), {
                  message: "Pay percentage must be a valid number between 0.01% and 100%",
                }),
            }),
            includeSalesDiscount: z.boolean().default(false),
            discount: z.object({
              percentage: z
                .string()
                .optional()
                .refine((value) => !value || validateNumberInRange(value, 0.01, 99.99), {
                  message: "Discount percentage must be a valid number between 0.01% and 100%",
                }),
              applicableIfWithinDays: z
                .string()
                .optional()
                .refine((value) => !value || validateNumberInRange(value, 0, 364), {
                  message: "Discount days must be a valid number less than 365 and greater than or equal to 0",
                }),
            }),
          })
          .refine(
            (data) =>
              !(data.includeSalesDiscount && !(data.discount?.percentage && data.discount?.applicableIfWithinDays)),
            {
              message:
                "Both discount percentage and applicableIfWithinDays are required if Include Sales Discount is selected.",
              path: ["discount"],
            }
          )
      ),
    })
  ),
  payRunSchedule: z
    .object({
      isEnabled: z.boolean().default(false),
      payPattern: z.string().optional(),
      startDate: z.string().optional(),
      payDays: z
        .string()
        .optional()
        .refine((value) => !value || validateNumberInRange(value, 1, 31), {
          message: "Pay days must be a valid number less than 31 and greater than or equal to 1",
        }),
      invoiceMaturity: z.object({
        intervalBetweenPayRuns: z.string().default(MaturityDateTypeGql.PayInterval),
        offsetInDays: z
          .string()
          .optional()
          .refine((value) => !value || validateNumberInRange(value, -52, 52), {
            message: "Offset days must be a valid number less than -52 and greater than or equal to 52",
          }),
      }),
    })
    .superRefine((data, ctx) => {
      if (!data.isEnabled) return

      const validatePayDays = (pattern) => {
        const { min, max, message } = payDaysValidation[pattern]
        const payDays = Number(data.payDays)
        if (!data.payDays || payDays < min || payDays > max) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["payDays"],
            message,
          })
        }
      }

      const validateOffsetDays = (pattern) => {
        const { min, max, message } = offsetDaysValidation[pattern]
        const offsetDays = Number(data.invoiceMaturity?.offsetInDays)
        if (!data.invoiceMaturity?.offsetInDays || offsetDays < min || offsetDays > max) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["invoiceMaturity", "offsetInDays"],
            message,
          })
        }
      }

      // Validate based on pay pattern selected
      if (data.payPattern) {
        validatePayDays(data.payPattern.toLowerCase())
        validateOffsetDays(data.payPattern.toLowerCase())
      }
    }),
})
