import { useEffect, useMemo } from "react"
import { useQuery } from "urql"

import type { Product, ProductShippingQueryVariables } from "@/graphql/codegen/graphql"
import { ProductShippingDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseShippingProps {
  productId: number
}

type ProductShipping = Pick<Product, "weightPerTruckload" | "leadTimeInDays" | "minOrderQuantity">

interface UseShippingResponse {
  data: ProductShipping | null
  fetching: boolean
  fetch: (variables: ProductShippingQueryVariables) => void
}

export const useProductShipping = ({ productId }: UseShippingProps): UseShippingResponse => {
  const [{ data, error, fetching }, fetch] = useQuery({
    query: ProductShippingDocument,
    variables: {
      productId,
    },
  })

  const hasError = error?.message || data?.product.get.__typename === "ProductGetFailure"
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  useEffect(() => {
    if (hasError) {
      notify({
        message: "Error fetching product shipping details",
        type: "error",
        silent: false,
      })
    }
  }, [hasError, notify])

  const conditions = useMemo(() => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return data.product.get.product
    }
    return null
  }, [data])

  return {
    data: conditions as ProductShipping | null,
    fetching,
    fetch,
  }
}
