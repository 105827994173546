import { z } from "zod"

export const CompanyModalSchema = z.object({
  name: z
    .string()
    .trim()
    .min(3, { message: "Company name must be at least 3 characters." })
    .max(50, { message: "Company name must not exceed 50 characters." })
    .regex(/^[a-zA-Z0-9&.,'’\-()[\]! ]*$/, {
      message:
        "Company name can only contain letters, numbers, spaces, and these special characters: . , ' ’ - ( ) [ ] & !",
    }),
  types: z.array(z.string()).min(1, { message: "This field is mandatory" }),
  accountManagers: z
    .array(
      z.object({
        accountManagerId: z.string().min(1, { message: "This field is mandatory" }),
      })
    )
    .min(1, { message: "This field is mandatory" }),
})

export type CreateCompanyFormData = z.infer<typeof CompanyModalSchema>
