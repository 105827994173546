import type { RouteObject } from "./availableRoutes"

import type { LabelMapping, RouteNavObject } from "@/types/routes.ts"
import { toCamelCase } from "@/utils/utils.ts"

const addLabelToRoute = (route: RouteObject, labelMapping: LabelMapping): RouteNavObject => {
  const hasChildren = route.children && route.children.length > 0
  const defaultLabel = hasChildren ? undefined : toCamelCase(route.pathname.split("/").pop() || "Home")

  return {
    ...route,
    label: labelMapping[route.pathname] || defaultLabel,
    children: route.children?.map((child) => addLabelToRoute(child, labelMapping)),
  }
}

export const addLabels = (routes: RouteObject[], labelMapping: LabelMapping): RouteNavObject[] => {
  return routes.map((route) => addLabelToRoute(route, labelMapping))
}
