import type { TableProps } from "@mui/material"
import { Table } from "@mui/material"

/**
 * Main table component that serves as the root container for the table layout.
 *
 * @example
 * ```tsx
 * <AerosTable>
 *   <AerosTableHead>...</AerosTableHead>
 *   <AerosTableBody>...</AerosTableBody>
 * </AerosTable>
 * ```
 *
 * @param props - Extends MUI TableProps
 */
export const AerosTable = (props: TableProps): JSX.Element => {
  return <Table {...props}></Table>
}
