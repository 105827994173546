import type { CombinedError, OperationContext } from "urql"
import { useQuery } from "urql"

import type { Query } from "@/graphql/codegen/graphql"
import { CompanyByIdDocument } from "@/graphql/codegen/graphql"

// TODO: Move this hook to zustand store to avoid refetching data
export const useGetCompanies = (
  companyId: string
): {
  companyDetailsError: CombinedError | undefined
  companyDetailsFetching: boolean
  companyDetails: Pick<Query, "company"> | undefined
  reexecuteCompanyDetailsQuery: (opts?: Partial<OperationContext>) => void
} => {
  const [
    { data: companyDetails, fetching: companyDetailsFetching, error: companyDetailsError },
    reexecuteCompanyDetailsQuery,
  ] = useQuery<Pick<Query, "company">>({
    query: CompanyByIdDocument,
    variables: { companyId },
  })

  return {
    companyDetails,
    companyDetailsFetching,
    companyDetailsError,
    reexecuteCompanyDetailsQuery,
  }
}
