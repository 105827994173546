import { capitalize } from "@mui/material"
import type { FC } from "react"
import React, { useMemo } from "react"

import { CompanyStatus } from "@/graphql/codegen/graphql"
import { FilterChip } from "@/screens/Companies/components/filters/FilterChip.tsx"

interface StatusChipProps {
  isActive: boolean | undefined
  onRemove: (key: keyof any, value: boolean) => void
}

export const StatusChip: FC<StatusChipProps> = ({ isActive, onRemove }) => {
  return useMemo(() => {
    if (isActive === undefined) return null
    const status = isActive ? CompanyStatus.Active : CompanyStatus.Inactive
    const label = `Status: ${capitalize(`${status}`.toLowerCase())}`
    return <FilterChip key={`isActive-${isActive}`} label={label} onDelete={() => onRemove("isActive", isActive)} />
  }, [isActive, onRemove])
}
