import { TableRow } from "@mui/material"
import type { VirtualItem, Virtualizer } from "@tanstack/virtual-core"
import type { ReactElement, ReactNode } from "react"

export const BaseTableVirtualRow = ({
  virtualItem,
  virtualizer,
  children,
}: {
  virtualItem: VirtualItem
  virtualizer: Virtualizer<HTMLDivElement, Element>
  children: ReactNode
}): ReactElement => (
  <TableRow
    data-index={virtualItem.index}
    data-testid={`tableBodyRow-${virtualItem.index}`}
    ref={(node) => virtualizer.measureElement(node)}
    style={{
      transform: `translateY(${virtualItem.start}px)`,
    }}
    className='absolute left-0 top-0 flex w-full'
  >
    {children}
  </TableRow>
)
