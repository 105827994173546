import { Box } from "@mui/material"
import Button from "@mui/material/Button"
import React, { useCallback, useEffect, useRef, useState } from "react"

interface NoteProps {
  title?: string
  children: React.ReactNode
}

const Notes: React.FC<NoteProps> = React.memo(({ title = "Note", children }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const textRef = useRef<HTMLParagraphElement | null>(null)

  const checkOverflow = useCallback(() => {
    if (textRef.current) {
      const textHeight = textRef.current.scrollHeight
      const lineHeight = parseFloat(window.getComputedStyle(textRef.current).lineHeight)
      const maxHeight = lineHeight * 3

      setIsOverflowing(textHeight > maxHeight)
    }
  }, [])

  useEffect(() => {
    checkOverflow()
    window.addEventListener("resize", checkOverflow)

    return () => window.removeEventListener("resize", checkOverflow)
  }, [children, checkOverflow])

  const handleToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  return (
    <>
      <Box className='text-normal text-primary'>{title}</Box>
      <Box ref={textRef} className={expanded || !isOverflowing ? "" : "line-clamp-3"} style={{ overflow: "hidden" }}>
        {children}
      </Box>
      {isOverflowing && (
        <Button
          size='small'
          variant='text'
          className='capitalize text-primary-400'
          onClick={handleToggle}
          aria-expanded={expanded}
          aria-label={expanded ? "Collapse text" : "Expand text"}
        >
          {expanded ? "" : "read more"}
        </Button>
      )}
    </>
  )
})

export default Notes
