import { Box, IconButton } from "@mui/material"
import { MoreVertOutlined } from "@mui-symbols-material/w300"
import type { CellContext } from "@tanstack/react-table"
import { useRef, useState } from "react"

import TableRowDropdownMenu from "./TableRowDropdownMenu"

import type { Location } from "@/graphql/codegen/graphql"
import type {
  LocationConfirmationDialogs,
  LocationMenuDialogs,
} from "@/screens/Companies/edit/components/locations/types/dialogs"

interface ActionsComponentProps {
  row: CellContext<Location, unknown>["row"]
  setOpenedDialog: (dialog: LocationMenuDialogs | LocationConfirmationDialogs | null) => void
}

export const ActionsComponent: React.FC<ActionsComponentProps> = ({ row, setOpenedDialog }) => {
  const anchorElRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box className='flex items-center justify-center' ref={anchorElRef}>
      <IconButton onClick={handleOpen}>
        <MoreVertOutlined />
      </IconButton>
      <TableRowDropdownMenu
        anchorEl={anchorElRef.current}
        open={open}
        isActive={row.original?.isActive}
        onClose={handleClose}
        hasNote={Boolean(row.original?.notes?.length)}
        openDialog={setOpenedDialog}
        hasAddress={Boolean(row.original?.address1)}
        hasContact={Boolean(row.original?.phoneNumber)}
      />
    </Box>
  )
}
