import { LinearProgress, Paper, Table, TableContainer, TableHead, Typography } from "@mui/material"
import React, { useEffect, useRef } from "react"

import { TableBodyWrapper } from "./TableRows.tsx"

import { SortableTableHeadRow } from "@/components/Tables/SortableTableHeadRow.tsx"

export const TableWrapper: React.FC<any> = ({ table, data, error, fetching, onScroll, tableContainerRef }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateHeight = () => {
      if (wrapperRef.current && tableContainerRef.current) {
        const windowHeight = window.innerHeight
        const wrapperTop = wrapperRef.current.getBoundingClientRect().top
        const newHeight = windowHeight - wrapperTop - 20 // 20px buffer
        tableContainerRef.current.style.height = `${newHeight}px`
      }
    }

    updateHeight()
    window.addEventListener("resize", updateHeight)

    return () => {
      window.removeEventListener("resize", updateHeight)
    }
  }, [tableContainerRef])

  return (
    <div ref={wrapperRef} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {fetching ? (
        <LinearProgress className='sticky top-0 z-10' style={{ flexShrink: 0 }} />
      ) : (
        <div style={{ height: 4, flexShrink: 0 }} />
      )}
      <TableContainer
        component={Paper}
        elevation={0}
        onScroll={onScroll}
        ref={tableContainerRef}
        style={{ flex: 1, overflow: "auto" }}
      >
        <Table style={{ width: "100%" }} className='border border-gray-300'>
          <TableHead className='sticky top-0 z-10 bg-gray-100'>
            <SortableTableHeadRow table={table} />
          </TableHead>
          <TableBodyWrapper table={table} data={data} tableContainerRef={tableContainerRef} fetching={fetching} />
        </Table>
      </TableContainer>
      {error && (
        <Typography color='error' style={{ flexShrink: 0 }}>
          {error.message}
        </Typography>
      )}
    </div>
  )
}
