import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material"
import { AddOutlined, EditOutlined, MoreVertOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useState } from "react"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import { CommentHistoryDialog } from "@/components/common/CommentHistory/CommentHistoryDialog"
import type { CommentListSuccess } from "@/graphql/codegen/graphql"
import { CommentParentTableType } from "@/graphql/codegen/graphql"
import { useGetComments } from "@/screens/Companies/hooks/useGetComments"

export const CommentsContainer = (): ReactElement => {
  const [openCommentHistory, setOpenCommentHistory] = useState(false)
  const { purchaseOrderId } = useParams({
    from: "/purchase-order/$purchaseOrderId/",
  })
  const { commentDetails, commentDetailsFetching, reexecuteCommentList } = useGetComments(
    purchaseOrderId,
    CommentParentTableType.PurchaseOrder
  )
  const commentList = (commentDetails?.comment?.list as CommentListSuccess)?.comments || []
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <>
      <Box className='flex items-center justify-between'>
        <Typography variant='h6'>Comments</Typography>
      </Box>
      {commentDetailsFetching ? (
        <Box className='flex size-full items-center justify-center'>
          <CircularProgress size={56} />
        </Box>
      ) : (
        <>
          <Fade in={commentList.length === 0} unmountOnExit timeout={500}>
            <InformationMissingAlert
              icon={<WarningOutlined color='primary' />}
              title='Add Comments'
              action={
                <Button
                  color='inherit'
                  size='small'
                  appearance='outlined'
                  className='text-primary-500'
                  startIcon={<AddOutlined />}
                  onClick={() => setOpenCommentHistory(true)}
                >
                  Comments
                </Button>
              }
              description={""}
            />
          </Fade>

          <Fade in={commentList.length > 0} unmountOnExit timeout={500}>
            <Paper
              elevation={0}
              className='relative h-[70px] w-full items-center justify-between border border-gray-300 p-6'
            >
              <Typography variant='body2' className='align-center h-full truncate pr-[120px] text-gray-800'>
                {commentList[commentList.length - 1]?.value || ""}
              </Typography>
              <IconButton
                aria-label='purchase order comment actions'
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className='absolute right-2.5 top-0.5 rounded-none'
              >
                <MoreVertOutlined />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setOpenCommentHistory(false)}>
                <MenuItem
                  onClick={() => {
                    setOpenCommentHistory(true)
                    setAnchorEl(null)
                  }}
                >
                  <ListItemIcon>
                    <EditOutlined />
                  </ListItemIcon>
                  <ListItemText primary='View All Comments' />
                </MenuItem>
              </Menu>
            </Paper>
          </Fade>
        </>
      )}
      <CommentHistoryDialog
        open={openCommentHistory}
        onClose={() => setOpenCommentHistory(false)}
        title=' '
        comments={commentList}
        onSuccess={reexecuteCommentList}
        commentParentTableType={CommentParentTableType.PurchaseOrder}
        referenceId={purchaseOrderId}
      />
    </>
  )
}
