import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { CloseOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useState } from "react"

import { useGetPurchaseOrderShipDetails } from "../hooks/useGetPurchaseOrderShipLocation"

import { Button } from "@/components/common/Button"
import { FieldRow, Form, FormField } from "@/components/Forms"
import { usePurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

type HeaderDialog = {
  open: boolean
  supplierName: string
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: string) => void
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"
const selectSlotProps = { input: { className: InputFieldClasses } }

const EditPurchaseOrderHeaderDialog: FunctionComponent<HeaderDialog> = ({ open, supplierName, onClose, onSubmit }) => {
  const order = usePurchaseOrderStore((state) => {
    return state.currentPO
  })
  const [shipToLocation, setShipToLocation] = useState<string | null>(null)
  const { purchaseOrderShipLocation: purchaseOrderShipToLocation, purchaseOrderShipDetailsFetching } =
    useGetPurchaseOrderShipDetails(order?.destinationLocationAssociationId)

  const handleSubmit = useCallback(() => {
    if (!shipToLocation) return
    onSubmit(shipToLocation)
    onClose("success")
  }, [onClose, onSubmit, shipToLocation])

  const handleResetClick = useCallback(() => {
    onClose("cancel")
  }, [onClose])

  return (
    <Dialog open={open} onClose={(_, reason) => onClose(reason)} fullWidth>
      <DialogTitle className='pb-3 pl-10 font-normal text-primary'>
        <Typography variant='subtitle1' className='h-[40px] text-gray-700'>
          {supplierName}
        </Typography>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        {purchaseOrderShipDetailsFetching ? (
          <Box className='flex size-full items-center justify-center'>
            <CircularProgress size={56} />
          </Box>
        ) : (
          <Form className='space-y-4'>
            <FieldRow>
              <FormField
                name='shipTo'
                label='Ship To'
                required
                render={() => (
                  <Select
                    slotProps={selectSlotProps}
                    type='text'
                    fullWidth
                    variant='outlined'
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlined}
                    onChange={(e) => setShipToLocation(e.target.value)}
                  >
                    {[purchaseOrderShipToLocation?.location].map((location) => (
                      <MenuItem key={location?.locationId} value={location?.locationId}>
                        {location?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FieldRow>
          </Form>
        )}
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!shipToLocation}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPurchaseOrderHeaderDialog
