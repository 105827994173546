import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback } from "react"

import { ProductSearchFilter } from "./ProductSearchFilter"
import { ProductFilterSchema } from "./types/schema"

import { SearchFilterDrawer } from "@/components/common/SearchFilterDrawer/SearchFilterDrawer.tsx"
import { getRefinedSearchParams } from "@/screens/Companies/utils"

type ProductFilterProps = {
  showFilterDrawer: boolean
  toggleDrawer: () => void
}

export const ProductFilterDrawer: FC<ProductFilterProps> = ({ showFilterDrawer, toggleDrawer }) => {
  const navigate = useNavigate({ from: "/companies/$companyId/edit" })
  const currentSearch = useSearch({
    from: "/companies/$companyId/edit",
    strict: true,
  })

  const handleClearFilters = useCallback(() => {
    const filter = ProductFilterSchema.parse({})
    const params = getRefinedSearchParams(currentSearch, filter)
    void navigate(params)
  }, [currentSearch, navigate])

  return (
    <SearchFilterDrawer toggleDrawer={toggleDrawer} open={showFilterDrawer} clear={handleClearFilters}>
      <ProductSearchFilter />
    </SearchFilterDrawer>
  )
}
