import type { ReactElement } from "react"
import type { UseFormReturn } from "react-hook-form"
import { FormProvider } from "react-hook-form"

import type { SelectOption } from "../../types"

import { SelectField } from "./SelectField"

import { FieldGroup, FieldRow, Form, FormField } from "@/components/Forms"
import type { Company, IncotermsDefinitionMap, Location } from "@/graphql/codegen/graphql"

interface DeliveryTermsFormProps {
  isShippingLegsFilled: boolean
  incotermsOptions: IncotermsDefinitionMap[]
  incotermsLocationOptions: Location[]
  freightTermsOptions: SelectOption[]
  shipViaOptions: Company[]
  shipmentStatusOptions: SelectOption[]
  methods: UseFormReturn<any>
  loading: boolean
  loadMoreIncoterms: () => void
  loadMoreIncotermsLocation: () => void
  loadMoreShipVia: () => void
}

export const DeliveryTermsForm = ({
  isShippingLegsFilled,
  incotermsOptions,
  incotermsLocationOptions,
  freightTermsOptions,
  shipViaOptions,
  shipmentStatusOptions,
  methods,
  loading,
  loadMoreIncoterms,
  loadMoreIncotermsLocation,
  loadMoreShipVia,
}: DeliveryTermsFormProps): ReactElement => {
  return (
    <FormProvider {...methods}>
      <Form className='flex flex-row gap-4'>
        {/* Incoterms */}
        <FieldRow className='w-1/5'>
          <FieldGroup>
            <FormField
              name='incoterms'
              label='Incoterms'
              render={({ field, fieldState }) => (
                <SelectField
                  field={field}
                  fieldState={fieldState}
                  options={incotermsOptions}
                  loadMore={loadMoreIncoterms}
                  fetching={loading}
                />
              )}
            />
          </FieldGroup>
        </FieldRow>
        {/* Incoterms Location */}
        <FieldRow className='w-1/5'>
          <FieldGroup>
            <FormField
              name='incotermsLocation'
              label='Incoterms Location'
              render={({ field, fieldState }) => (
                <SelectField
                  field={field}
                  fieldState={fieldState}
                  options={incotermsLocationOptions}
                  loadMore={loadMoreIncotermsLocation}
                  fetching={loading}
                  optionValueKey='locationId'
                  optionLabelKey='name'
                  disabled={isShippingLegsFilled}
                />
              )}
            />
          </FieldGroup>
        </FieldRow>
        {/* Freight Terms */}
        <FieldRow className='w-1/5'>
          <FieldGroup>
            <FormField
              name='freightTerms'
              label='Freight Terms'
              render={({ field, fieldState }) => (
                <SelectField field={field} fieldState={fieldState} options={freightTermsOptions} />
              )}
            />
          </FieldGroup>
        </FieldRow>
        {/* Ship Via */}
        <FieldRow className='w-1/5'>
          <FieldGroup>
            <FormField
              name='shipVia'
              label='Ship Via'
              render={({ field, fieldState }) => (
                <SelectField
                  field={field}
                  fieldState={fieldState}
                  options={shipViaOptions}
                  loadMore={loadMoreShipVia}
                  fetching={loading}
                  optionValueKey='companyId'
                  optionLabelKey='name'
                />
              )}
            />
          </FieldGroup>
        </FieldRow>
        {/* Shipment Status */}
        <FieldRow className='w-1/5'>
          <FieldGroup>
            <FormField
              name='shipmentStatus'
              label='Shipment Status'
              render={({ field, fieldState }) => (
                <SelectField field={field} fieldState={fieldState} options={shipmentStatusOptions} />
              )}
            />
          </FieldGroup>
        </FieldRow>
      </Form>
    </FormProvider>
  )
}
