import { Avatar, Tooltip } from "@mui/material"
import React, { memo } from "react"

import type { AccountManager } from "@/graphql/codegen/graphql.ts"

export const AccountManagersCell = memo(({ accountManagers }: { accountManagers: AccountManager[] }) => {
  if (accountManagers?.length === 0) {
    return null
  }

  const mainManager = accountManagers[0]
  const extraManagersCount = accountManagers.length - 1

  return (
    <div className='flex items-center'>
      <div className='relative mr-2 flex items-center'>
        <span className='text-sm'>
          {mainManager.firstName} {mainManager.lastName}
        </span>
        <Avatar className='z-10 ml-2 size-8 bg-neutral-300 ring-2 ring-white' />
        {extraManagersCount > 0 && (
          <Tooltip title={`${extraManagersCount} more`}>
            <Avatar className='absolute right-[-30px] flex size-8 items-center justify-center bg-neutral-300 text-sm'>
              +{extraManagersCount}
            </Avatar>
          </Tooltip>
        )}
      </div>
    </div>
  )
})
