import { Link } from "@tanstack/react-router"
import type { FunctionComponent } from "react"
import { twMerge } from "tailwind-merge"

import type { BreadcrumbItemType } from "@/components/common/Breadcrumbs/Breadcrumbs.schema.tsx"

type BreadcrumbItemProps = BreadcrumbItemType & {
  pathname: string
  isActive: boolean
}

const BreadcrumbItem: FunctionComponent<BreadcrumbItemProps> = ({ pathname, label, isActive }) => (
  <>
    {typeof label === "string" ? (
      <Link
        to={pathname}
        className={twMerge([
          "cursor-pointer font-sans text-sm font-normal leading-normal no-underline duration-300 hover:underline hover:underline-offset-2",
          isActive ? "decoration-primary! text-primary" : "decoration-primary-400! text-primary-400",
        ])}
      >
        {label}
      </Link>
    ) : (
      <span className='cursor-pointer font-sans text-sm font-normal leading-normal text-primary no-underline decoration-primary'>
        {label}
      </span>
    )}
  </>
)

export default BreadcrumbItem
