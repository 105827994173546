import { create } from "zustand"

import type { Query } from "@/graphql/codegen/graphql"
import { CompanyTypeTreeDocument } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import type { TreeNode } from "@/types/tree"

type CompanyTypesStore = {
  companyTypes: TreeNode<string>[]
  fetching: boolean
  error: string | null
  fetchCompanyTypes: () => Promise<void>
}

export const useCompanyTypesStore = create<CompanyTypesStore>((set) => ({
  companyTypes: [],
  fetching: false,
  error: null,
  fetchCompanyTypes: async () => {
    set({ fetching: true })
    try {
      const { data, error } = await queryClient
        .query<Pick<Query, "companyType">>(CompanyTypeTreeDocument, {})
        .toPromise()

      if (error) {
        set({ error: error.message, fetching: false })
      } else if (data?.companyType.tree.__typename === "CompanyTypeTreeSuccess") {
        set({
          companyTypes: data.companyType.tree.tree as unknown as TreeNode<string>[],
          fetching: false,
          error: null,
        })
      } else {
        set({ companyTypes: [], fetching: false, error: "Failed to fetch company types" })
      }
    } catch (error) {
      set({ error: (error as Error).message, fetching: false })
    }
  },
}))
