import { useEffect, useMemo } from "react"
import { useQuery } from "urql"

import type { Product, ProductPackagingInformationQueryVariables } from "@/graphql/codegen/graphql"
import { ProductPackagingInformationDocument } from "@/graphql/codegen/graphql"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export interface UsePackagingInfoProps {
  productId: number
}

export type PackagingInfo = Pick<
  Product,
  | "freightItem"
  | "freightClass"
  | "packagingType"
  | "packageWeight"
  | "packageWeightUom"
  | "packageLength"
  | "packageWidth"
  | "packageHeight"
  | "packageDimensionsUom"
  | "palletWeight"
  | "palletWeightUom"
  | "palletSizeLength"
  | "palletSizeWidth"
  | "palletSizeHeight"
  | "palletSizeUom"
  | "packagesPerPallet"
>

export interface UsePackagingInfoResponse {
  data: PackagingInfo | null
  fetching: boolean
  fetch: (variables: ProductPackagingInformationQueryVariables) => void
}

export const usePackagingInfo = ({ productId }: UsePackagingInfoProps): UsePackagingInfoResponse => {
  const [{ data, error, fetching }, fetch] = useQuery({
    query: ProductPackagingInformationDocument,
    variables: {
      productId,
    },
  })

  const hasError = error?.message || data?.product.get.__typename === "ProductGetFailure"
  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  useEffect(() => {
    if (hasError) {
      notify({
        message: "Error fetching product packaging information",
        type: "error",
      })
    }
  }, [hasError, notify])

  const packagingInfo = useMemo(() => {
    if (data?.product.get.__typename === "ProductGetSuccess") {
      return data.product.get.product
    }
    return null
  }, [data])

  return {
    data: packagingInfo as PackagingInfo | null,
    fetching,
    fetch,
  }
}
