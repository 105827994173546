import { Box, Divider, TextareaAutosize, Typography } from "@mui/material"
import { ArchiveOutlined, EditOutlined } from "@mui-symbols-material/w300"
import React, { useCallback, useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { Button } from "@/components/common/Button"
import type { Comment } from "@/graphql/codegen/graphql.ts"

interface CommentProps {
  comment: Comment
  isEditing: boolean
  onEditClick?: () => void
  onArchiveClick?: () => void
  onCommentAction: (value: string) => void
  onCancelEdit: () => void
  isLast: boolean
}

const GenericComment: React.FC<CommentProps> = React.memo(
  ({ comment, isEditing, onEditClick, onArchiveClick, onCommentAction, onCancelEdit, isLast }) => {
    const { commentId, createdAt, createdByUser, updatedAt, updatedByUser, value } = comment
    const { control, watch } = useFormContext()
    const currentValue = watch(`comments.${commentId ?? "new"}.value`)

    const handleCommentAction = useCallback(() => {
      if (currentValue && currentValue.trim()) {
        onCommentAction(currentValue)
      }
    }, [currentValue, onCommentAction])

    const formatDate = (dateString: string | null | undefined) => {
      if (!dateString) return ""
      const date = new Date(dateString)
      return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`
    }

    const isNewComment = !commentId

    const renderCommentView = useMemo(
      () => (
        <>
          <Box className='p-3 transition-colors duration-200 hover:bg-primary-100'>
            <Box className='mb-2 flex items-center justify-between'>
              <Typography variant='caption' className='text-gray-600'>
                Posted: {formatDate(createdAt)} by {createdByUser?.details?.name}
                {updatedAt && updatedAt !== createdAt && (
                  <>
                    <br />
                    Updated: {formatDate(updatedAt)} by {updatedByUser?.details?.name}
                  </>
                )}
              </Typography>
              <Box>
                <Button
                  size='small'
                  appearance='text'
                  startIcon={<EditOutlined />}
                  onClick={onEditClick}
                  className='mr-2 hover:bg-primary-200'
                  disabled={isEditing}
                >
                  Edit
                </Button>
                <Button
                  size='small'
                  appearance='text'
                  startIcon={<ArchiveOutlined />}
                  onClick={onArchiveClick}
                  className='hover:bg-primary-200'
                  disabled={isEditing}
                >
                  Archive
                </Button>
              </Box>
            </Box>
            <Typography variant='body2'>{value}</Typography>
          </Box>
          {!isLast && <Divider />}
        </>
      ),
      [
        createdAt,
        createdByUser?.details?.name,
        updatedAt,
        updatedByUser?.details?.name,
        onEditClick,
        isEditing,
        onArchiveClick,
        value,
        isLast,
      ]
    )

    const renderCommentEdit = useMemo(
      () => (
        <>
          <Box className='relative w-full p-3'>
            <Box className='relative'>
              <Controller
                name={`comments.${commentId ?? "new"}.value`}
                control={control}
                defaultValue={value}
                render={({ field }) => (
                  <TextareaAutosize
                    {...field}
                    placeholder='Add a comment...'
                    minRows={2}
                    className='w-full rounded-md border border-solid border-gray-300 p-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#BFDBFE]'
                  />
                )}
              />
              <Box className='absolute bottom-5 right-4 space-x-2'>
                <Button onClick={onCancelEdit} size='small' appearance='outlined'>
                  Cancel
                </Button>
                <Button onClick={handleCommentAction} size='small' disabled={!currentValue || !currentValue.trim()}>
                  {isNewComment ? "Add" : "Update"}
                </Button>
              </Box>
            </Box>
          </Box>
          {!isLast && <Divider />}
        </>
      ),
      [commentId, control, value, handleCommentAction, isLast, currentValue, isNewComment, onCancelEdit]
    )

    return <>{!isEditing ? renderCommentView : renderCommentEdit}</>
  }
)

GenericComment.displayName = "GenericComment"

export default GenericComment
