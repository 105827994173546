import { z } from "zod"

import { breadcrumbItemSchema } from "@/components/common/Breadcrumbs/Breadcrumbs.schema.tsx"
import { UserSchema } from "@/types/user/user.schema.tsx"

export const AppHeaderSchema = z.object({
  user: UserSchema,
  notifications: z.number(),
  breadcrumbs: z.array(breadcrumbItemSchema),
  pathname: z.string(),
})

export type AppHeaderProps = z.input<typeof AppHeaderSchema>
