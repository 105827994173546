import { useCallback } from "react"
import { useMutation } from "urql"

import type { FileMetadata, GeneratePresignedUrlMutation } from "@/graphql/codegen/graphql"
import { GeneratePresignedUrlDocument } from "@/graphql/codegen/graphql"
import { useAuthStore } from "@/stores/authStore"

interface FileUploadParams {
  file: File
  type: string
  metadata: Omit<FileMetadata, "fileName" | "label" | "userId">
  label?: string
}

interface FileUploadHook {
  getSignedUrl: (params: FileUploadParams) => Promise<string | null>
  uploadToS3: (signedUrl: string, file: File) => Promise<boolean>
}

export const useFileUpload = (): FileUploadHook => {
  const [_getSignedURLResponse, getFileSignedURL] =
    useMutation<GeneratePresignedUrlMutation>(GeneratePresignedUrlDocument)
  const userId = useAuthStore.getState().user?.id

  const getSignedUrl = useCallback(
    async ({ file, type, metadata, label }: FileUploadParams): Promise<string | null> => {
      const fileExtension = file.name.substring(file.name.lastIndexOf("."))
      const baseFileName = `${type}${Date.now()}`

      const response = await getFileSignedURL({
        input: {
          fileMetadata: {
            ...metadata,
            fileName: baseFileName + fileExtension,
            label: label || baseFileName + fileExtension,
            userId,
          },
        },
      })

      if (response.data?.fileUpload.generatePresignedUrl.__typename === "GetPresignedUrlSuccess") {
        return response.data.fileUpload.generatePresignedUrl.presignedUrl
      }

      return null
    },
    [getFileSignedURL, userId]
  )

  const uploadToS3 = useCallback(async (signedUrl: string, file: File): Promise<boolean> => {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
      })

      return response.ok
    } catch (error) {
      console.error("Error uploading file:", error)
      return false
    }
  }, [])

  return {
    getSignedUrl,
    uploadToS3,
  }
}
