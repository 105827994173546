import { Box } from "@mui/material"
import type { AnyRoute } from "@tanstack/react-router"
import { createFileRoute } from "@tanstack/react-router"

import { LabelInformationContainer } from "@/screens/Products/components/Product/Information/LabelInformation/LabelInformationContainer"
import { ProductOriginContainer } from "@/screens/Products/components/Product/Information/Origin/ProductOriginContainer"
import { ProductCommentsContainer } from "@/screens/Products/components/Product/Information/ProductCommentsContainer"
import { ProductConditionsContainer } from "@/screens/Products/components/Product/Information/ProductConditions/ProductConditionsContainer"
import { ProductFormContainer } from "@/screens/Products/components/Product/Information/ProductForm/ProductFormContainer"
import { ProductPackageInformationContainer } from "@/screens/Products/components/Product/Information/ProductPackageInformationContainer"
import { ProductShippingDetailsContainer } from "@/screens/Products/components/Product/Information/ShippingDetails/ProductShippingDetailsContainer"
import { ProductTechnicalInformationContainer } from "@/screens/Products/components/Product/Information/TechnicalInformation/TechnicalInformationContainer"
import { productInformationLoader } from "@/screens/Products/routeLoader"

export const Route: AnyRoute = createFileRoute("/product/$productId/edit/_layout/information")({
  loader: productInformationLoader,
  component: () => {
    return (
      <Box className='flex flex-col gap-4'>
        <ProductFormContainer />

        <ProductShippingDetailsContainer />

        <Box className='flex gap-4'>
          <ProductPackageInformationContainer />
          <ProductTechnicalInformationContainer />
        </Box>

        <LabelInformationContainer />

        <ProductOriginContainer />

        <ProductConditionsContainer />

        <ProductCommentsContainer />
      </Box>
    )
  },
})
