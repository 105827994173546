import { Box, TextField } from "@mui/material"
import type { FC } from "react"
import { Controller, useFormContext } from "react-hook-form"

export const LocationNotesForm: FC = () => {
  const { control } = useFormContext()
  return (
    <form>
      <Controller
        name='notes'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box className='relative'>
            <TextField
              {...field}
              placeholder='Add a note...'
              multiline
              minRows={4}
              fullWidth
              InputProps={{
                classes: {
                  input: "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2",
                },
              }}
              variant='outlined'
              error={!!error}
              helperText={error?.message}
            />
          </Box>
        )}
      />
    </form>
  )
}
